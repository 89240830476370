import { FC, useEffect, useState } from "react";
import useTranslation from "../../../hooks/translation.hook";
import { MDBBtn } from "mdb-react-ui-kit";
import { Link } from "react-router-dom";
import DefaultBlock from "../../../component/defaultBlock";
import InputContainer from "../../../component/inputContainer";
import MainContainer from "../../../component/mainContainer";
import PageTitle from "../../../component/pageTitle";
import AppAsyncSelect from "../../../component/select/appAsyncSelect";
import { SelectValue } from "../../../component/select/SelectValue";
import { NotificationConfiguration } from "../../../eenApi/configuration/notification";
import { ConfigurationService } from "../../../eenApi/configuration/service";
import { ExternalProviderService } from "../../../eenApi/externalProvider/service";
import { ErrorHandler } from "../../../service/errorHandler";
import { ToastManager } from "../../../service/toastManager";
import { SettingsRoutesPath } from "../routes";

const SettingsNotification: FC = () => {
    const { t } = useTranslation();

    const [entity, setEntity] = useState<NotificationConfiguration>({
        providerId: 0
    });
    const [provider, setProvider] = useState<SelectValue>();

    useEffect(() => {
        refreshData();
    }, []);

    const refreshData = async () => {
        try {
            const result = await ConfigurationService.getNotification();
            if (result) {
                setEntity({
                    ...result
                });

                if (result.providerId > 0) {
                    const providers = await ExternalProviderService.list({ filter: { id: [result.providerId] } });
                    if (providers.items.length) {
                        setProvider({ value: `${providers.items[0].id}`, label: providers.items[0].name })
                    }
                }
            }
        }
        catch (err) {
            ErrorHandler.handle('extproviderEdit refreshData', err);
        }
    }

    const saveAction = async () => {
        try {
            const rd = {
                ...entity
            };
            await ConfigurationService.saveNotification(rd);
            ToastManager.success({ title: 'main:msg-success', message: 'main:msg-changes-saved' });
        }
        catch (err) {
            ErrorHandler.handle('settings notification add/edit', err);
        }
    }

    const onSave = async (e: React.FormEvent) => {
        e.preventDefault();
        saveAction();
    }

    const onChangeProvider = (selected: SelectValue) => {
        setProvider(selected);
        setEntity({ ...entity, providerId: selected ? parseInt(selected.value) || 0 : 0 })
    }

    return (
        <MainContainer>
            <PageTitle text={t('settings:page-title-notification')} backLink={SettingsRoutesPath.Prefix} />
            <DefaultBlock>
                <form onSubmit={onSave}>
                    <InputContainer>
                        <div className="d-flex">
                            <div className="flex-grow-1">
                                <AppAsyncSelect className="select-lg" value={provider} isClearable
                                    loadOptions={ExternalProviderService.selectLoadOptions} onChange={onChangeProvider}
                                    title={'settings:field-provider'}
                                />
                            </div>
                            <div>
                                <Link className="btn btn-outline-success btn-lg ms-1 px-3" to={ExternalProviderService.getEditLink(0)}><i className="fas fa-plus"></i></Link>
                            </div>
                        </div>
                    </InputContainer>
                    <div>
                        <MDBBtn type="submit" color="primary">{t('main:btn-save')}</MDBBtn>
                    </div>
                </form>
            </DefaultBlock>
        </MainContainer>
    );
}

export default SettingsNotification;
