import MainContainer from '../../component/mainContainer';
import PageTitle from '../../component/pageTitle';
import WarningMessage from './warningMessage';

export default function Home() {
    return (
        <MainContainer>
            <PageTitle text={''} />
            <WarningMessage />
        </MainContainer>
    );
}
