import { EenApi } from '..';
import LanguageEnum, { isSupportedLanguage } from '../../enums/language.enum';
import { LocalStorageService } from '../../utils/localStorage.service';
import { User } from '../user/user';

class CAccountService {
    async selectLanguage(user: User | undefined, language: string) {
        LocalStorageService.setItem('lang', language);
        if (!user?.id) {
            return;
        }
        await EenApi.put('account/language', { language: language });
    }

    getLang(accountLanguage?: string | null) {
        if (accountLanguage && isSupportedLanguage(accountLanguage)) {
            return accountLanguage;
        }
        const savedLang = LocalStorageService.getItem<string>('lang');
        if (savedLang && isSupportedLanguage(savedLang)) {
            return savedLang;
        }
        return LanguageEnum.EN as string;
    }
}

export const AccountService = new CAccountService();
