import ModelValidator from "../model.validator";
import { Client } from '../../../eenApi/client/client';

class EditValidator extends ModelValidator<Client, Client> {

   validate(dto: Client, tabs: string[], entity: Client) {
      const erroredTabs: string[] = [];

      this.checkRequired(dto.name, erroredTabs, 'main');
      this.checkLength(dto.name ?? '', erroredTabs, 300, 'main');
      this.checkRequired(dto.shortName, erroredTabs, 'main');
      this.checkLength(dto.shortName ?? '', erroredTabs, 300, 'main');
      this.checkRequired(dto.type, erroredTabs, 'main');
      this.checkRequired(dto.annualTurnover, erroredTabs, 'main');
      this.checkRequired(dto.languageProficiency, erroredTabs, 'main');
      this.checkLength(dto.competencyAssessment ?? '', erroredTabs, 1000, 'main');
      this.checkRequired(dto.inn, erroredTabs, 'main');
      this.checkLength(dto.inn ?? '', erroredTabs, 100, 'main');

      this.checkRequired(dto.memberCountryId, erroredTabs, 'contacts');
      this.checkRequired(dto.centerId, erroredTabs, 'contacts');
      this.checkRequired(dto.legalAddress, erroredTabs, 'contacts');
      this.checkLength(dto.legalAddress ?? '', erroredTabs, 300, 'contacts');
      this.checkRequired(dto.actualAddress, erroredTabs, 'contacts');
      this.checkLength(dto.actualAddress ?? '', erroredTabs, 300, 'contacts');
      this.checkRequired(dto.contactFullName, erroredTabs, 'contacts');
      this.checkLength(dto.contactFullName ?? '', erroredTabs, 300, 'contacts');
      this.checkRequired(dto.contactPosition ?? '', erroredTabs, 'contacts');
      this.checkLength(dto.contactPosition ?? '', erroredTabs, 300, 'contacts');
      this.checkLength(dto.contactPhone ?? '', erroredTabs, 100, 'contacts');
      this.checkLength(dto.contactEmail ?? '', erroredTabs, 100, 'contacts');
      this.checkLength(dto.directorFullName ?? '', erroredTabs, 300, 'contacts');
      this.checkLength(dto.directorPosition ?? '', erroredTabs, 300, 'contacts');
      this.checkLength(dto.directorPhone ?? '', erroredTabs, 100, 'contacts');
      this.checkLength(dto.directorEmail ?? '', erroredTabs, 100, 'contacts');
      this.checkLength(dto.communicationPreferred ?? '', erroredTabs, 1000, 'contacts');
      this.checkLength(dto.web ?? '', erroredTabs, 300, 'contacts');

      return erroredTabs;
   }
}
export const ClientEditValidator = new EditValidator()