import LanguageEnum from '../../../enums/language.enum';
import { ObjectType } from '../../../enums/objectType';
import DriveFileDto from '../../drive/dto/drive.file.dto';
import MailingPeriodicityEnum from '../enum/mailing.periodicity.enum';
import MailingStatusEnum from '../enum/mailing.status.enum';
import MailingTypeEnum from '../enum/mailing.type.enum';
import { MailingPeriodDataDto } from './mailing.perioddata.dto';
import IdNameItemDto from "../../common/id.name.item.dto";
import { Center } from '../../center/center';
 
export default class MailingDto {
    id?: number;
    forceSending: boolean = false;
    isImportant: boolean = false;
    center?: Center;
    created: string = '';
    sendDate: string = '';
    subject: string = '';
    subjectEn: string = '';
    text: string = '';
    textEn: string = '';
    lastSent: string = '';
    nextDate: string = '';
    type: MailingTypeEnum | string = '';
    status: MailingStatusEnum = MailingStatusEnum.Draft;
    periodicity: MailingPeriodicityEnum | string = '';
    periodData: MailingPeriodDataDto;
    isAllClients: boolean = false;
    isAllEenClients: boolean = false;
    isEventInterestedClients: boolean = false;
    additionalEmails?: string = '';
    isProfile: boolean = false;
    roles: IdNameItemDto[] = [];
    rolesIds: number[] = [];
    language: LanguageEnum;
    files: DriveFileDto[] = [];
    filesId: string[] = [];
    //TODO
    ownerType?: string = ObjectType.Center;
    ownerId?: string = '17';

    constructor(entity: MailingDto | undefined, language: LanguageEnum) {
        Object.entries(entity ?? {}).forEach(([key, val]) => {
            if (this.hasOwnProperty(key)) {
                this[key as keyof MailingDto] = val as never;
            }
        });
        this.language = entity?.language ?? language;
        this.filesId = entity?.files?.map(x => x.id) ?? [];
        this.periodData = entity?.periodData ?? new MailingPeriodDataDto();
        this.rolesIds = entity?.roles?.map((c) => Number(c.id)) ?? [];
    }
}
