import React, { FC, useContext, useMemo } from 'react';
import { Link } from 'react-router-dom';
import useTranslation from '../../../../hooks/translation.hook';
import { NewsEntity } from '../../../../eenApi/news/news';
import { NewsService } from '../../../../eenApi/news/service';
import { NewsRoutesPath } from '../../routes';
import { useCurrentUser } from '../../../../hooks/useCurrentUser';
import Rules from '../../../../rules';
import { CenterService } from '../../../../eenApi/center/service';
import { UserContext } from '../../../../context/user-context';
import { TableColumn } from '../../../../component/common/layout/DataTable/table.model';
import { useGroupDeleteAction, useIdColumn, useRowActions } from '../../../../component/common/layout/DataTable/table.utils';
import { formatDate } from '../../../../utils/date.utils';
import { DataTable } from '../../../../component/common/layout';
import { EntityQueryKey } from '../../../../query/query.keys';
import { GridSelectionModel } from "@mui/x-data-grid";

interface IProps {
    filterValues?: { [key: string]: any };
}

const NewsCenterTable: FC<IProps> = (props) => {
    const { t, i18n } = useTranslation();
    const { data: currentUser } = useCurrentUser();
    const { checkAccess } = useContext(UserContext);

    const idColumn = useIdColumn<NewsEntity>();
    const columns = useMemo<TableColumn<NewsEntity>[]>(
        () => [
            idColumn,
            new TableColumn({
                headerName: t('news:field.title'),
                field: 'title',
                sortable: true,
                renderCell: ({ id, value }) => <Link to={`${NewsRoutesPath.View.replace(':id', id!.toString())}`}>{value}</Link>,
            }),
            new TableColumn({
                headerName: t('news:field.status'),
                field: 'status',
                valueFormatter: ({ value }) => t(`news:status.${value}`),
            }),
            new TableColumn({
                headerName: t('news:field.creation-data'),
                field: 'created',
                sortable: true,
                valueFormatter: ({ value }) => formatDate(value),
            }),
            new TableColumn({
                headerName: t('news:field.change-data'),
                field: 'lastModified',
                sortable: true,
                valueFormatter: ({ value }) => formatDate(value),
            }),
            new TableColumn({
                headerName: t('news:field.center'),
                field: 'center',
                sortable: true,
                renderCell: ({ row }) =>
                    checkAccess(Rules.Center.View) || checkAccess(Rules.Center.ViewOwn, [String(row.centerId)]) ? (
                        <Link to={`${CenterService.getDetailsRoute(row.centerId!)}`}>{row.centerName}</Link>
                    ) : (
                        row.centerName
                    ),
            }),
        ],
        [i18n.language],
    );

    const clickDelete = (ids: GridSelectionModel) => NewsService.delete(ids.map((x) => Number(x)));

    const rowActions = useRowActions<NewsEntity>({
        edit: (row) => NewsRoutesPath.Edit.replace(':id', String(row.id)),
        remove: (row) => clickDelete([row.id]),
    });
    const groupActions = useGroupDeleteAction({ remove: clickDelete });

    return (
        <DataTable
            id="center-news-table-component"
            queryKey={[EntityQueryKey.News, 'center']}
            filterValues={props.filterValues}
            columns={columns}
            fetchData={(requestData) =>
                NewsService.list({
                    ...requestData,
                    centerId: currentUser?.rules.find((x) => !!x.objectId)?.objectId,
                })
            }
            rowActions={rowActions}
            groupActions={groupActions}
        />
    );
};

export default NewsCenterTable;
