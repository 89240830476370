import { FC, useContext, useMemo, useState } from 'react';
import useTranslation from '../../../hooks/translation.hook';
import { Link } from 'react-router-dom';
import PageTitle from '../../../component/pageTitle';
import Filter from '../../../component/filter';
import RoleTable from './table';
import FilterItemHidden from '../../../component/filter/types/filterItem/items/hidden';
import MainContainer from '../../../component/mainContainer';
import { UserContext } from '../../../context/user-context';
import { RoleService } from '../../../eenApi/role/service';
import Rules from '../../../rules';
import { useCurrentUser } from '../../../hooks/useCurrentUser';

const RoleList: FC = () => {
    const context = useContext(UserContext);
    const { t } = useTranslation();
    const [filterValues, setFilterValues] = useState<{ [key: string]: any }>();
    const currentUser = useCurrentUser();
    const filterItems = useMemo(() => [new FilterItemHidden('name', { isTyping: 'Y' })], []);

    return (
        <MainContainer>
            <PageTitle text={t('role:menu-title')} backLink="/" />
            <div className="d-xl-flex mb-1 mb-xl-3">
                <div className="app-filter-actions mb-2 mb-xl-0">
                    {context.checkAccess(Rules.Role.Edit) && (
                        <Link className="btn btn-success me-2" to={RoleService.getEditLink(0)}>
                            {t('main:btn-add')}
                        </Link>
                    )}
                </div>
                <Filter id="filter-roles" items={filterItems} onFind={setFilterValues} />
            </div>
            {currentUser.data?.language && <RoleTable filterValues={filterValues} language={currentUser.data?.language} />}
        </MainContainer>
    );
};

export default RoleList;
