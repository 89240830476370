import { EenApi } from '..';
import { NewsEntity } from './news';
import { NewsRoutesPath } from '../../page/news/routes';
import NewsEditDto from './dto/news.edit.dto';
import NewsDto from './dto/news.dto';


class CNewsService {

    async list(requestData?: any) {
        return await EenApi.list<NewsEntity>('news/list', requestData);
    }

    async networkList(requestData?: any) {
        return await EenApi.list<NewsDto>('news/networkNews', requestData);
    }

    async add(requestData: NewsEditDto): Promise<number> {
        return await EenApi.post<number>('news/add', requestData);
    }

    async get(id: number) {
        return await EenApi.get<NewsEntity>(`news/get/${id}`);
    }


    async delete(ids: (number)[]) {
        return await EenApi.delete('news/delete', { ids: ids });
    }

    async update(requestData: NewsEditDto) {
        return await EenApi.put<number>('news/edit', requestData);
    }

    async validate(id: number, status: string) {
        return await EenApi.post('news/validate', { id, status });
    }

    async addOrUpdate(requestData: NewsEditDto) {
        if (requestData.id === 0 || requestData.id === undefined) {
            return await this.add(requestData);
        }
        return await EenApi.put<number>('news/edit', requestData);
    }

    getEditLink(id: number) {
        return `${NewsRoutesPath.Edit}${id}`;
    }

    getViewLink(id: number) {
        return `${NewsRoutesPath.View}${id}`;
    }

}

export const NewsService = new CNewsService();
