import { MDBBtn, MDBTabs, MDBTabsContent, MDBTabsItem, MDBTabsLink, MDBTabsPane } from "mdb-react-ui-kit";
import { FC, useState } from "react";
import useTranslation from "../../../../hooks/translation.hook";
import InputContainer from "../../../../component/inputContainer";
import AppAsyncSelect from "../../../../component/select/appAsyncSelect";
import { SelectValue } from "../../../../component/select/SelectValue";
import { CenterService } from "../../../../eenApi/center/service";
import { ClientService } from "../../../../eenApi/client/service";
import { RoleService } from "../../../../eenApi/role/service";
import { UserService } from "../../../../eenApi/user/service";
import { ObjectType } from "../../../../enums/objectType";
import { ErrorHandler } from "../../../../service/errorHandler";

interface IProps {
    userId: number;
    afterSave: () => void;
}

const UserRoleAddForm: FC<IProps> = props => {
    const { t } = useTranslation();
    const [tab, setTab] = useState("none");
    const [role, setRole] = useState<SelectValue>();
    const [client, setClient] = useState<SelectValue | null>();
    const [center, setCenter] = useState<SelectValue | null>();

    const clickChangeTab = (name: string) => {
        if (name === "client") {
            setCenter(null);
        } else {
            setClient(null);
        }
        setTab(name);
    };

    const onSubmit = async (e: React.FormEvent) => {
        try {
            e.preventDefault();
            const roleId = role ? parseInt(role.value) : 0;
            const clientId = client ? parseInt(client.value) : 0;
            const centerId = center ? parseInt(center.value) : 0;
            await UserService.addRole(props.userId, roleId, clientId, centerId);
            props.afterSave();
        } catch (err) {
            ErrorHandler.handle("user add role", err);
        }
    };

    return (
        <form onSubmit={onSubmit}>
            <InputContainer inModal>
                <AppAsyncSelect className="select-lg" value={role} loadOptions={RoleService.selectLoadOptions} onChange={setRole} isClearable required title="user:field-role" />
            </InputContainer>
            <strong>{t("user:role-bind-title")}</strong>
            <MDBTabs pills className="mb-3">
                <MDBTabsItem>
                    <MDBTabsLink className="py-2" onClick={() => clickChangeTab("none")} active={tab === "none"}>
                        {t("user:role-bind-none")}
                    </MDBTabsLink>
                </MDBTabsItem>
                <MDBTabsItem>
                    <MDBTabsLink className="py-2" onClick={() => clickChangeTab(ObjectType.Client)} active={tab === ObjectType.Client}>
                        {t("user:role-bind-client")}
                    </MDBTabsLink>
                </MDBTabsItem>
                <MDBTabsItem>
                    <MDBTabsLink className="py-2" onClick={() => clickChangeTab(ObjectType.Center)} active={tab === ObjectType.Center}>
                        {t("user:role-bind-center")}
                    </MDBTabsLink>
                </MDBTabsItem>
            </MDBTabs>
            <MDBTabsContent>
                <MDBTabsPane show={tab === "none"}></MDBTabsPane>
                <MDBTabsPane show={tab === ObjectType.Client}>
                    <InputContainer inModal>
                        <AppAsyncSelect
                            className="select-lg"
                            value={client}
                            loadOptions={ClientService.selectLoadOptions}
                            onChange={setClient}
                            isClearable
                            required={tab === ObjectType.Client}
                            title="main:field-client"
                        />
                    </InputContainer>
                </MDBTabsPane>
                <MDBTabsPane show={tab === ObjectType.Center}>
                    <InputContainer inModal>
                        <AppAsyncSelect
                            className="select-lg"
                            value={center}
                            loadOptions={CenterService.selectLoadOptions}
                            onChange={setCenter}
                            isClearable
                            required={tab === ObjectType.Center}
                            title="main:field-center"
                        />
                    </InputContainer>
                </MDBTabsPane>
            </MDBTabsContent>
            <div>
                <MDBBtn type="submit">{t("main:btn-save")}</MDBBtn>
            </div>
        </form>
    );
};

export default UserRoleAddForm;
