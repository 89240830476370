import SupportStatusEnum from '../enum/support.status.enum';
import SupportCriticalityEnum from '../enum/support.criticality.enum';
import SupportDto from './support.dto';
import SupportSubjectDto from '../../subjects/dto/support.subject.dto';
import DriveFileDto from '../../../drive/dto/drive.file.dto';

export default class SupportEditDto {
    id?: number = 0;
    title: string = '';
    supportSubject: SupportSubjectDto | undefined = undefined;
    supportSubjectId: number = 0;
    status: SupportStatusEnum | string = '';
    criticality: SupportCriticalityEnum | string = '';
    text: string = '';
    files: DriveFileDto[] = [];
    filesId: string[] = [];

    constructor(entity?: SupportDto | undefined) {
        Object.entries(entity ?? {}).forEach(([key, val]) => {
            if (this.hasOwnProperty(key)) {
                this[key as keyof SupportEditDto] = val as never;
            }
        });
        this.supportSubjectId = this.supportSubject?.id ?? 0;
        this.filesId = entity?.files?.map((x) => x.id) ?? [];
    }
}
