import React, {useContext, useEffect, useState} from 'react';
import {Autocomplete, CircularProgress, FormControl, FormHelperText} from '@mui/material';
import {SelectValue} from "../../../select/SelectValue";
import {StyledTextField} from "../index";
import IControlProps from "../control.props";
import InputContainer from "../../../inputContainer";
import {useValidation} from "../../../../hooks/UseValidation";
import {DictionaryType} from "../../../../models/dictionaryType";
import LanguageEnum from "../../../../enums/language.enum";
import {useInfiniteQuery, useQuery} from "react-query";
import {DictionaryService} from "../../../../eenApi/dictionary/service";
import {ErrorHandler} from "../../../../service/errorHandler";
import Modal from "./modal";
import {MDBBtn} from "mdb-react-ui-kit";
import useDebounce from "../../../../hooks/debounce.hook";
import ValidationContext from "../../../../hooks/UseValidation/validation.context";
import useTranslation from "../../../../hooks/translation.hook";

interface IProps extends IControlProps {
   dictionary: DictionaryType;
   language: LanguageEnum;
   showAll?: boolean;
   disabled?: boolean;
   minInput?: number;
}

interface IPropsSingle extends IProps {
   multiple?: false;
   value: string | null;
   onChange: (selected: string | null) => void;
}

interface IPropsMulti extends IProps {
   multiple: true;
   value: string[] | null;
   onChange: (selected: string[] | null) => void;
}

export default function DictionaryControl(props: IPropsSingle | IPropsMulti) {
   const { t } = useTranslation();
   const validate = useValidation(props.validators);
   const {validateOn} = useContext(ValidationContext)

   const [inputValue, setInputValue] = useState('');
   const searchTerm = useDebounce(inputValue, 100);
   const [errorText, setErrorText] = useState<string>();

   const [isModalOpen, setIsModalOpen] = useState(false);

   const [selected, setSelected] = useState<SelectValue|SelectValue[]|null>(props.multiple ? [] : null);
   const { data: initialValue } = useQuery([props.dictionary, props.language, props.multiple ? props.value : String(props.value ?? '')],
      () => {
         return (props.value ?? '').length
            ? DictionaryService.getByCodes(props.dictionary, props.language, new Array<string>().concat(props.value!))
            : null
      },
      {
         select: data => {
            if (data?.length) {
               if (props.multiple) {
                  return data.map(d => ({value: String(d.code), label: d.name ?? d.code}))
               } else {
                  return ({value: String(data[0].code), label: data[0].name ?? data[0].code})
               }
            }
            return props.multiple ? [] : null
         },
         onError: (err) => {
            ErrorHandler.handle(`Dictionary [${props.dictionary}] load failed`, err);
            setSelected(null)
         }
      })
   useEffect(() => setSelected(initialValue ?? (props.multiple ? [] : null)), [initialValue])

   const fetchItems = ({ pageParam } :any) =>{
      return searchTerm?.length >= (props.minInput ?? 1)
         ? DictionaryService.getByFilter(props.dictionary, props.language, {
            name: searchTerm.trim()
         }, pageParam)
         : null;}
   const {
      data: options,
      fetchNextPage,
      hasNextPage,
      isFetching,
      isFetchingNextPage
   } = useInfiniteQuery([props.dictionary, props.language, searchTerm], params => fetchItems(params), {
      getNextPageParam: (lastPage, allPages) => {
         const currentCount = allPages.flatMap(d => d?.items ?? []).length;
         if (currentCount === 0 || currentCount === lastPage?.total) return false;
         return currentCount;
      },
      select: data => {
         const items = data?.pages.flatMap(d => d?.items ?? []) ?? [];
         return {
            pages: items.map(d => ({value: String(d.code), label: d.name ?? d.code})),
            pageParams: [items.length] as unknown[]
         }
      },
      onError: (err) => {
         ErrorHandler.handle(`Dictionary [${props.dictionary}] load failed`, err);
         setSelected(null)
      }
   })

   useEffect(() => {
      if (validateOn) {
         setErrorText(props.error || validate(props.value, props.tab ?? 'main'))
      }
   }, [props.value, props.error, validateOn])

   return (
      <InputContainer>
         <FormControl required={props.required} error={!!props.error} fullWidth size="small">
            <div className="d-flex">
               <div className="form-outline flex-grow-1">
                  <Autocomplete
                     noOptionsText={(inputValue?.length ?? 0) < (props.minInput ?? 0) ? t('main:select:minInput') : t('main:select:notFound')}
                     loadingText={t('main:loading')}
                     autoComplete
                     value={selected}
                     filterOptions={(x) => x}
                     includeInputInList={!props.multiple}
                     onChange={(event, newValue) => {
                        if (props.multiple && Array.isArray(newValue)) {
                           props.onChange(newValue.map(o => o.value));
                        }
                        if (!props.multiple && !Array.isArray(newValue)) {
                           props.onChange(newValue?.value ?? null);
                        }
                        setSelected(newValue)
                     }}
                     inputValue={inputValue}
                     onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
                     isOptionEqualToValue={(option, selectValue) => String(option.value) === String(selectValue.value)}
                     getOptionLabel={(option) => option.label}
                     options={options?.pages ?? []}
                     loading={isFetching || isFetchingNextPage}
                     multiple={props.multiple}
                     disabled={props.disabled}
                     renderInput={(params) => (
                        <StyledTextField
                           {...params}
                           size="small"
                           label={t(props.labelKey)}
                           required={props.required}
                           error={!!errorText}
                           helperText={errorText}
                           InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                 <React.Fragment>
                                    {(isFetching || isFetchingNextPage) ? <CircularProgress color="inherit" size={20}/> : null}
                                    {params.InputProps.endAdornment}
                                 </React.Fragment>
                              ),
                           }}
                        />
                     )}
                     ListboxProps={{
                        onScroll: (event: React.SyntheticEvent) => {
                           const listboxNode = event.currentTarget;
                           if (listboxNode.scrollTop + listboxNode.clientHeight === listboxNode.scrollHeight) {
                              if (hasNextPage && !isFetchingNextPage) {
                                 fetchNextPage()
                              }
                           }
                        }
                     }}
                  />
               </div>
               {props.showAll && (
                  <div className="mt-2 ms-1">
                     <MDBBtn color="link" size="sm" disabled={isModalOpen} onClick={() => setIsModalOpen(true)}>
                        {t("main:btn-show")}
                     </MDBBtn>
                  </div>
               )}
            </div>
            {props.hintKey && <FormHelperText>{t(props.hintKey)}</FormHelperText>}
         </FormControl>
         {props.showAll &&
         <Modal dictionary={props.dictionary}
                language={props.language}
                header={t(`main:field-${props.dictionary}`)}
                value={selected as SelectValue[]|null}
                onSave={(checked) => {
                   setSelected(checked);
                   if (props.multiple) {
                      props.onChange(Array.isArray(checked) ? checked.map(c => c.value) : []);
                   } else {
                      props.onChange(checked?.[0]?.value ?? null);
                   }
                   setIsModalOpen(false)
                }}
                isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}/>}
      </InputContainer>
   );
};