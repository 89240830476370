import React from 'react';
import { useHistory } from 'react-router-dom';
import useTranslation from '../../../../hooks/translation.hook';

export default function BackButton({ to, label }: { to: string, label?: string }) {
    const { t } = useTranslation();
    const history = useHistory();

    return (
        <button className='btn btn-primary d-none d-sm-inline-block me-2' onClick={() => {
            if (history.length) {
                history.goBack();
            } else if (to) {
                history.replace(to);
            } else {
                history.replace('/');
            }
        }}>
            {t(label ?? 'main:btn-back')}
        </button>
    );
}