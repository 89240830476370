import { Route, Switch } from 'react-router-dom';
import NotFound from '../../home/notFound';
import ProtectedRoute from '../../../component/common/ProtectedRoute';
import Rules from '../../../rules';
import ListNews from '../list';
import NewsView from '../view';
import EditNews from '../edit';
import { ObjectType } from '../../../enums/objectType';
import NewsNetworkTable from '../list/tables/networkTable';
import ValidateNews from '../validate';

export default function NewsRoute() {

    return (
        <Switch>
            <ProtectedRoute path={NewsRoutesPath.AdminList} component={ListNews} rule={Rules.News.List} />
            <ProtectedRoute path={NewsRoutesPath.NetworkList} component={NewsNetworkTable} rule={Rules.News.List} />
            <ProtectedRoute path={NewsRoutesPath.CenterList} component={ListNews} rule={Rules.News.List}
                            ownerType={ObjectType.Center} />
            <ProtectedRoute path={NewsRoutesPath.View} component={NewsView} rule={Rules.News.Edit} />
            <ProtectedRoute path={NewsRoutesPath.Create} component={EditNews} ownerType={ObjectType.Center}
                            rule={Rules.News.Add}
            />
            <ProtectedRoute path={NewsRoutesPath.Edit} component={EditNews} rule={Rules.News.Edit}
                            ownerType={ObjectType.Center} />
            <ProtectedRoute path={NewsRoutesPath.Validate} component={ValidateNews} rule={Rules.News.Edit}
                            ownerType={ObjectType.Center} />
            <Route path='*' component={NotFound} />
        </Switch>
    );
};

const prefix = '/news';
export const NewsRoutesPath = {
    Prefix: `${prefix}`,
    CenterList: `${prefix}/center/list`,
    AdminList: `${prefix}/list`,
    NetworkList: `${prefix}/network/list`,
    Create: `${prefix}/create`,
    Validate: `${prefix}/:id/validate`,
    Edit: `${prefix}/:id/edit`,
    View: `${prefix}/:id/details`,
};