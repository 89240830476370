import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import React, { useContext, useEffect, useState } from 'react';
import { useValidation } from '../hooks/UseValidation';
import { FormControl, FormHelperText } from '@mui/material';
import { StyledInputLabel } from './common/ui';
import useTranslation from '../hooks/translation.hook';
import styles from './common/ui/FileControl/index.module.css';
import { styled } from '@mui/material/styles';
import ValidationContext from '../hooks/UseValidation/validation.context';

const StyledEditor = styled(CKEditor)`
  border-color: red
`;

export default function TextEditor(props) {
    const { t } = useTranslation();

    const validate = useValidation(props.validators);
    const { validateOn } = useContext(ValidationContext);
    const [errorText, setErrorText] = useState('');

    useEffect(() => {
        setErrorText(props.error || validate(props.text, props.tab ?? 'main'));
    }, [props.text, props.error, validateOn]);

    return (
        <FormControl required={props.required} error={!!errorText} fullWidth size='small'>
            <StyledInputLabel id={styles.fileLabel} style={{fontWeight: 400}}>{t(props.labelKey)}</StyledInputLabel>
            <StyledEditor
                editor={ClassicEditor}
                data={props.text}
                className='mt-5'
                onChange={(event, editor) => {
                    props.onChange(event, editor.getData());
                    //console.log({ event, editor, data });
                }}
                removePlugins={['Insert Media', 'CKFinderUploadAdapter', 'CKFinder', 'EasyImage', 'Image', 'ImageCaption', 'ImageStyle', 'ImageToolbar', 'ImageUpload', 'MediaEmbed']}
                remove
                // onBlur={(event, editor) => {
                //     console.log('Blur.', editor);
                // }}
                // onFocus={(event, editor) => {
                //     console.log('Focus.', editor);
                // }}
            />
            <FormHelperText>{errorText}</FormHelperText>
        </FormControl>
    );


    // onReady={editor => {
    //     // You can store the "editor" and use when it is needed.
    //     console.log('Editor is ready to use!', editor);
    // }}
    // onBlur={(event, editor) => {
    //     console.log('Blur.', editor);
    // }}
    // onFocus={(event, editor) => {
    //     console.log('Focus.', editor);
    // }}
}