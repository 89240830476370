import { Route, Switch } from 'react-router-dom';
import NotFound from '../../home/notFound';
import ExternalProviderEdit from '../edit';
import ExternalProviderList from '../list';
import ExternalProviderView from '../view';

export default function ExternalProviderRoutes() {
    return (
        <Switch>
            <Route path={ExternalProviderRoutesPath.List} component={ExternalProviderList}/>
            <Route path={`${ExternalProviderRoutesPath.View}:id`} component={ExternalProviderView} />
            <Route path={`${ExternalProviderRoutesPath.Edit}:id`} component={ExternalProviderEdit} />
            <Route path="*" component={NotFound} />
        </Switch>
    );
}

export const ExternalProviderRoutesPath = {
    Prefix: '/externalprovider',
    List: '/externalprovider/list',
    View: '/externalprovider/view/',
    Edit: '/externalprovider/edit/'
};
