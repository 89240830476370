import { FC } from 'react';
import { Route } from 'react-router';
import { Switch } from 'react-router-dom';
import { ApplicationPaths, LoginActions, LogoutActions } from '../../../component/auth-api/ApiAuthorizationConstants';
import { Login } from '../../../component/auth-api/Login';
import { Logout } from '../../../component/auth-api/Logout';
import NotFound from '../../home/notFound';
import WarningMessage from '../../home/warningMessage';

const ApiAuthorizationRoutes: FC = () => {
    return (
        <>
            <WarningMessage />

            <Switch>
                <Route path={ApplicationPaths.Login} render={() => loginAction(LoginActions.Login)} />
                <Route path={ApplicationPaths.LoginFailed} render={() => loginAction(LoginActions.LoginFailed)} />
                <Route path={ApplicationPaths.LoginCallback} render={() => loginAction(LoginActions.LoginCallback)} />
                <Route path={ApplicationPaths.Profile} render={() => loginAction(LoginActions.Profile)} />
                <Route path={ApplicationPaths.Register} render={() => loginAction(LoginActions.Register)} />
                <Route path={ApplicationPaths.LogOut} render={() => logoutAction(LogoutActions.Logout)} />
                <Route path={ApplicationPaths.LogOutCallback} render={() => logoutAction(LogoutActions.LogoutCallback)} />
                <Route path={ApplicationPaths.LoggedOut} render={() => logoutAction(LogoutActions.LoggedOut)} />
                <Route path="*" component={NotFound} />
            </Switch>
        </>
    );
};

function loginAction(name: string) {
    return <Login action={name}></Login>;
}

function logoutAction(name: string) {
    return <Logout action={name}></Logout>;
}

export default ApiAuthorizationRoutes;
