import { FC } from 'react';
import { MDBBtn } from 'mdb-react-ui-kit';
import BaseModal from '../../../../component/modal/baseModal';
import ModalHeader from '../../../../component/modal/modalHeader';
import { ObjectType } from '../../../../enums/objectType';
import useTranslation from '../../../../hooks/translation.hook';

interface IProps {
    isOpened: boolean;
    onClose: () => void;
    onAddFolder: () => void;
    onAddFile: () => void;
}

const AddEntityModal: FC<IProps> = ({ isOpened, onClose, onAddFolder, onAddFile }) => {
    const { t } = useTranslation();

    return (
        <BaseModal centered isOpen={isOpened} onRequestClose={onClose} modalSize="md">
            <ModalHeader title={t('drive:add-entity-modal-title')} onClickBack={onClose} onRequestClose={onClose} />
            <div className="modal-body py-2">
                <div className="row">
                    <div className="col-6">
                        <div className="d-flex justify-content-center">
                            <MDBBtn onClick={onAddFolder}>
                                <i className="far fa-folder me-2"></i>
                                {t(`main:object-type-${ObjectType.DriveFolder}`)}
                            </MDBBtn>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="d-flex justify-content-center">
                            <MDBBtn onClick={onAddFile}>
                                <i className="fas fa-file me-2"></i>
                                {t(`main:object-type-${ObjectType.DriveFile}`)}
                            </MDBBtn>
                        </div>
                    </div>
                </div>
            </div>
        </BaseModal>
    );
};

export default AddEntityModal;
