import React, { FC, useMemo } from 'react';
import { Role } from '../../../eenApi/role/role';
import { RoleService } from '../../../eenApi/role/service';
import useTranslation from '../../../hooks/translation.hook';
import { useGroupDeleteAction, useIdColumn, useRowActions } from '../../../component/common/layout/DataTable/table.utils';
import { TableColumn } from '../../../component/common/layout/DataTable/table.model';
import { GridSelectionModel } from '@mui/x-data-grid';
import { DataTable } from '../../../component/common/layout';
import { EntityQueryKey } from '../../../query/query.keys';

interface IProps {
    filterValues?: { [key: string]: any };
    language: string;
}

const RoleTable: FC<IProps> = (props) => {
    const { t } = useTranslation();
    const idColumn = useIdColumn<Role>();
    const columns = useMemo<TableColumn<Role>[]>(
        () => [
            idColumn,
            new TableColumn({
                headerName: t('main:field-name'),
                field: 'name',
            }),
            new TableColumn({
                headerName: t('main:field-description'),
                field: 'description',
            }),
        ],
        [],
    );

    const clickDelete = (ids: GridSelectionModel) => RoleService.delete(ids.map((id) => Number(id)));

    const rowActions = useRowActions<Role>({
        edit: (row) => RoleService.getEditLink(row.id),
        remove: (row) => clickDelete([row.id]),
    });
    const groupActions = useGroupDeleteAction({ remove: clickDelete });

    return (
        <DataTable
            id="role-table-component"
            queryKey={EntityQueryKey.Role}
            filterValues={props.filterValues}
            columns={columns}
            fetchData={(requestData) => RoleService.list(requestData)}
            rowActions={rowActions}
            groupActions={groupActions}
        />
    );
};

export default RoleTable;
