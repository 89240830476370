import React, { useMemo, useState } from 'react';
import useTranslation from '../../../../hooks/translation.hook';
import { useHistory, useParams } from 'react-router-dom';
import { ActionItem } from '../../../../models/actionItem';
import { SupportRoutesPath } from '../../routes';
import SupportService from '../../../../eenApi/support/service';
import { ErrorHandler } from '../../../../service/errorHandler';
import MainContainer from '../../../../component/mainContainer';
import PageTitle from '../../../../component/pageTitle';
import BottomActionsContainer from '../../../../component/actions/bottomActionsContainer';
import BottomActions from '../../../../component/actions/bottomActions';
import { ToastManager } from '../../../../service/toastManager';
import BaseModal from '../../../../component/modal/baseModal';
import ModalHeader from '../../../../component/modal/modalHeader';
import { MDBBtn } from 'mdb-react-ui-kit';
import Utils from '../../../../utils';
import MainTab from '../../../../component/support/supports/MainTab';
import TabItem from '../../../../component/tabs/tabItem';
import TopActions from '../../../../component/actions/topActions';
import TopActionsContainer from '../../../../component/actions/topActionsContainer';
import TabControls from '../../../../component/tabs/tabControls';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import SupportDto from '../../../../eenApi/support/supports/dto/support.dto';
import SupportEditDto from '../../../../eenApi/support/supports/dto/support.edit.dto';
import SupportStatusEnum from '../../../../eenApi/support/supports/enum/support.status.enum';

export default function ViewSupport() {
    const { id } = useParams<{ id?: string }>();
    const { t, i18n } = useTranslation();
    const history = useHistory();
    const queryClient = useQueryClient();

    const [isDelete, setIsDelete] = useState(false);

    let entityId = Utils.getEntityId(id);

    if (isNaN(entityId)) {
        history.push('*');
    }

    const { data: entity } = useQuery<SupportDto>(['support', id], {
        onError: (error) => {
            ErrorHandler.handle('publication refreshData', error);
        },
    });

    const deleteMutation = useMutation(() => SupportService.delete([entityId]), {
        onSuccess: async () => {
            ToastManager.success({ title: 'main:msg-success', message: 'main:msg-entity-deleted' });
            history.push(SupportRoutesPath.List);
        },
        onError: (error) => {
            ErrorHandler.handle('support delete', error);
        },
        onSettled: async () => {
            setIsDelete(false);
        },
    });

    const makeClosedMutation = useMutation(() => SupportService.makeClosed(new SupportEditDto(entity!)), {
        onSuccess: async () => {
            ToastManager.success({ title: 'main:msg-success', message: 'main:msg-changes-saved' });
            await queryClient.invalidateQueries(['support', id]);
        },
        onError: (error) => {
            ErrorHandler.handle('support close', error);
        },
    });

    const tabItems = useMemo(() => [new TabItem('main', t('main:tab-information'))], [i18n.language]);

    const [activeTab, setActiveTab] = useState('main');

    const actions = useMemo<ActionItem[]>(() => {
        const items: ActionItem[] = [];
        items.push({
            name: t('main:btn-edit'),
            onClick: async () => {
                await queryClient.invalidateQueries(['support', id]);
                history.push(SupportRoutesPath.Edit.replace(':id', id!));
            },
            icon: 'fas fa-pen',
        });
        items.push({
            name: t('main:btn-delete'),
            icon: 'far fa-trash-alt',
            onClick: () => {
                setIsDelete(true);
            },
        });
        if (entity?.status !== SupportStatusEnum.Closed) {
            items.push({
                name: t('main:btn-close'),
                onClick: async () => {
                    await makeClosedMutation.mutate();
                },
            });
        }

        return items;
    }, [entity, i18n.language]);

    const closeDelete = () => setIsDelete(false);

    return (
        <MainContainer>
            <PageTitle text={t('support:supports.view.title')} backLink={SupportRoutesPath.List} />
            <TopActionsContainer>
                <TopActions actions={actions} />
            </TopActionsContainer>
            <TabControls selectedId={activeTab} items={tabItems} onChange={setActiveTab} />
            <MainTab id="main" activeId={activeTab} entity={entity} />
            <BottomActionsContainer>
                <BottomActions actions={actions} />
            </BottomActionsContainer>
            <BaseModal isOpen={isDelete} onRequestClose={closeDelete} modalSize="s">
                <ModalHeader title={t('main:msg-entity-delete-confirm')} onRequestClose={closeDelete} />
                <div className="text-center p-3">
                    <MDBBtn onClick={() => deleteMutation.mutate()}>{t('main:yes')}</MDBBtn>
                </div>
            </BaseModal>
        </MainContainer>
    );
};