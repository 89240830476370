import React, { FC, useState } from 'react';
import useTranslation from '../../../../hooks/translation.hook';
import { useHistory, useParams } from 'react-router-dom';
import BottomActions from '../../../../component/actions/bottomActions';
import BottomActionsContainer from '../../../../component/actions/bottomActionsContainer';
import Loader from '../../../../component/loader';
import MainContainer from '../../../../component/mainContainer';
import PageTitle from '../../../../component/pageTitle';
import SupportService from '../../../../eenApi/support/service';
import { ToastManager } from '../../../../service/toastManager';
import { SupportRoutesPath } from '../../routes';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import TopActions from '../../../../component/actions/topActions';
import TopActionsContainer from '../../../../component/actions/topActionsContainer';
import MainEditTab from '../../../../component/support/supports/MainEditTab';
import TabControls from '../../../../component/tabs/tabControls';
import ValidationContext from '../../../../hooks/UseValidation/validation.context';
import TabItem from '../../../../component/tabs/tabItem';
import { SupportEditValidator } from '../../../../utils/validators';
import SupportDto from '../../../../eenApi/support/supports/dto/support.dto';
import SupportEditDto from '../../../../eenApi/support/supports/dto/support.edit.dto';
import { isRoutePathCreate } from '../../../../utils/check.route.path';
import { resetQueryFn } from "../../../../query/query.utils";
import { EntityQueryKey } from "../../../../query/query.keys";

const EditSupport: FC = () => {
    const { id } = useParams<{ id?: string }>();
    const { t } = useTranslation();
    const history = useHistory();
    const queryClient = useQueryClient();

    const [tabs, setTabs] = useState<string[]>([]);
    const [validateOn, setValidateOn] = useState(false);

    const createPath = SupportRoutesPath.Create;

    const { data: entity, isFetching } = useQuery<SupportDto>(['support', id], {
        onSuccess: async (data) => {
            setData(new SupportEditDto(data));
        },
        enabled: !isRoutePathCreate(history.location.pathname, createPath),
    });

    const [data, setData] = useState<SupportEditDto>(
        isRoutePathCreate(history.location.pathname, createPath) ? new SupportEditDto() : new SupportEditDto(entity),
    );

    const [activeTab, setActiveTab] = useState('main');
    let showDetails = true;

    const updateMutation = useMutation(() => SupportService.addOrUpdate(data), {
        onSuccess: async (data) => {
            ToastManager.success({ title: 'main:msg-success', message: 'main:msg-changes-saved' });
            await queryClient.resetQueries({ predicate: query => resetQueryFn(query, EntityQueryKey.Support) });
            if (showDetails) {
                history.replace(`${SupportRoutesPath.View.replace(':id', data.toString())}`);
            } else {
                history.replace(`${SupportRoutesPath.Edit.replace(':id', data.toString())}`);
            }
        },
    });

    const actions = [
        {
            name: t('main:btn-save'),
            onClick: () => save(false),
        },
        {
            name: t('main:btn-apply'),
            onClick: () => save(true),
        },
        {
            name: t('main:btn-cancel'),
            onClick: async () => {
                if (isRoutePathCreate(history.location.pathname, createPath)) {
                    await queryClient.invalidateQueries(['support']);
                    history.goBack();
                } else {
                    await queryClient.invalidateQueries(['support', id]);
                    history.push(SupportRoutesPath.View.replace(':id', id!));
                }
            },
            color: '#8E9FA7',
        },
    ];

    const tabItems = [new TabItem('main', t('main:tab-information'))];

    const save = (stay: boolean) => {
        setValidateOn(true);
        showDetails = !stay;
        window.scrollTo(0, 0);
        const erroredTabs = SupportEditValidator.validate(data!, tabs);
        setTabs(erroredTabs);
        if (erroredTabs.length == 0) {
            updateMutation.mutate();
        }
    };

    const updateData = (field: keyof SupportEditDto, value: any) => {
        let newValue = value?.value ?? value;
        setData({ ...data!, [field]: Array.isArray(newValue) ? [...newValue] : newValue });
    };

    return (
        <MainContainer>
            <PageTitle
                text={t(!isRoutePathCreate(history.location.pathname, createPath) ? 'support:supports.edit.title' : 'support:supports.create.title')}
                backLink={SupportRoutesPath.List}
            />
            <TopActionsContainer>
                <TopActions actions={actions} />
            </TopActionsContainer>
            <ValidationContext.Provider value={{ validateOn, tabs, setTabs }}>
                <TabControls selectedId={activeTab} items={tabItems} onChange={setActiveTab} errored={validateOn ? tabs : []} />
                {isFetching ? (
                    <Loader />
                ) : (
                    <MainEditTab activeId={activeTab} id="main" data={data} entity={entity} updateData={updateData} validate={validateOn} />
                )}
                <BottomActionsContainer>
                    <BottomActions actions={actions} />
                </BottomActionsContainer>
            </ValidationContext.Provider>
        </MainContainer>
    );
};

export default EditSupport;
