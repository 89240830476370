import { useContext } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import LogList from '../list';
import { UserContext } from '../../../context/user-context';
import Rules from '../../../rules';
import NotFound from '../../home/notFound';

export default function LogRoutes() {
    const context = useContext(UserContext);
    return (
        <Switch>
            {context.checkAccess(Rules.Log.List)
                ? <Route path="/log" component={LogList} />
                : <Redirect to="/error/access-denied" />
            }
            <Route path="*" component={NotFound} />
        </Switch>
    );
}

export const LogRoutesPath = {
    Prefix: '/log'
};
