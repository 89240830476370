import { EenApi } from '..';
import { DeliveryConfiguration } from './delivery';
import MailingConfiguraton from './mailing.setting.dto';
import { NotificationConfiguration } from './notification';

class CConfigurationService {
    async saveDelivery(entity: DeliveryConfiguration) {
        await EenApi.put('configuration/delivery', entity);
    }

    async getDelivery() {
        const data = await EenApi.get<DeliveryConfiguration>('configuration/delivery');
        return data;
    }

    async saveMailing(entity: MailingConfiguraton) {
        await EenApi.put('configuration/mailing', entity);
    }

    async getMailing() {
        const data = await EenApi.get<MailingConfiguraton>('configuration/mailing');
        return data;
    }

    async getNotification() {
        const data = await EenApi.get<NotificationConfiguration>(`configuration/notification`);
        return data;
    }

    async saveNotification(entity: NotificationConfiguration) {
        await EenApi.put('configuration/notification', entity);
    }
}

export const ConfigurationService = new CConfigurationService();
