import React, { FC, useMemo } from 'react';
import { Link } from 'react-router-dom';
import SupportService from '../../../../eenApi/support/service';
import { SupportRoutesPath } from '../../routes';
import SupportSubjectDto from '../../../../eenApi/support/subjects/dto/support.subject.dto';
import useTranslation from '../../../../hooks/translation.hook';
import { TableColumn } from '../../../../component/common/layout/DataTable/table.model';
import { DataTable } from '../../../../component/common/layout';
import { EntityQueryKey } from '../../../../query/query.keys';

interface IProps {
    filterValues?: { [key: string]: any };
}

const SubjectsTable: FC<IProps> = (props) => {
    const { t, i18n } = useTranslation();

    const columns = useMemo<TableColumn<SupportSubjectDto>[]>(
        () => [
            new TableColumn({
                headerName: t('support:fields.name'),
                field: 'name',
                renderCell: ({ id, value }) => <Link to={`${SupportRoutesPath.EditSubject.replace(':id', id.toString())}`}>{value}</Link>,
            }),
            new TableColumn({
                headerName: t('support:fields.fullName'),
                field: 'fullName',
            }),
            new TableColumn({
                headerName: t('support:fields.requestCount'),
                field: 'requestCount',
                width: 180,
                type: 'number',
            }),
        ],
        [i18n.language],
    );

    return (
        <DataTable
            id="support-subjects-table-component"
            queryKey={EntityQueryKey.SupportSubject}
            filterValues={props.filterValues}
            columns={columns}
            fetchData={(requestData) => SupportService.listSubjects(requestData)}
        />
    );
};

export default SubjectsTable;
