import {DictionaryEntity} from "../../../../models/dictionaryEntity";
import {SelectValue} from "../../../select/SelectValue";
import {MDBCheckbox} from "mdb-react-ui-kit";
import {useState} from "react";

interface IProps {
   items: DictionaryEntity[],
   value: SelectValue[]|null,
   onChange: (items: SelectValue[]) => void
}

export default function ModalTreeView({ items, value, onChange }: IProps) {

   const [checked, setChecked] = useState(() => Array.isArray(value) ? value.map(v => v.value) : [])
   const onCheck = (item: DictionaryEntity) => {
      const idx = checked.indexOf(item.code);
      if (idx >= 0) {
         checked.splice(idx, 1)
      } else {
         checked.push(item.code)
      }
      setChecked([...checked])
      onChange(items.filter(item => checked.includes(item.code)).map(item => ({ value: item.code, label: item.name ?? item.code})));
   }

   return (
      <div>
         {items.map(item => (
            <div className="pb-2" key={item.code}>
               <MDBCheckbox label={item.name}
                            checked={checked.includes(item.code)}
                            onChange={() => onCheck(item)} />
            </div>
         ))}
      </div>
   );
}