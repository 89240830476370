import { Dispatch, SetStateAction, useContext } from 'react';
import { UserContext, UserContextProps } from "../../context/user-context";
import { QueryClient, QueryKey, useQuery } from 'react-query';
import { TFunction } from 'react-i18next';
import { i18n } from 'i18next';
import * as H from 'history';
import { useCommonEntityStates } from '../UseCommonHooks';

interface IOptions<T> {
    queryKey: QueryKey,
    extendedKey?: QueryKey
}

interface IDetailsStates<T> {
    t: TFunction,
    i18n: i18n,
    history: H.History<unknown>,
    queryClient: QueryClient,
    id: string,
    entity: T|undefined,
    activeTab: string,
    setActiveTab:  Dispatch<SetStateAction<string>>
}

interface IDetailsAccessStates<T> extends IDetailsStates<T>, UserContextProps {}

export function useDetailsStatesBase<T extends { id?: number }>(options: IOptions<T>): IDetailsStates<T> {
    const { t, i18n, history, queryClient, activeTab, setActiveTab, id } = useCommonEntityStates()
    let queryKey = [options.queryKey];
    queryKey.push(id);
    queryKey.push(i18n.language);
    if (options.extendedKey) {
        queryKey = queryKey.concat(options.extendedKey)
    }

    const { data: entity } = useQuery<T>(queryKey, {
        onSuccess: async () => {
            setActiveTab('main');
        },
        enabled: Number(id) > 0
    });

    return { t, i18n, history, queryClient, id, entity, activeTab, setActiveTab };
}

export function useDetailsStates<T extends { id?: number }>(options: IOptions<T>): IDetailsAccessStates<T> {
    return {
        ...useDetailsStatesBase(options),
        ...useContext(UserContext)
    }
}