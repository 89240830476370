import ProfileDto from '../../../eenApi/profile/dto/profile.dto';
import TabPanel, { ITabPanelProps } from '../../tabs/tabPanel';
import { Field } from '../../common/layout';
import LinkField from '../../common/layout/LinkField';
import { ClientRoutesPath } from '../../../page/client/routes';
import { formatDateTime } from '../../../utils/date.utils';
import React, { useContext } from 'react';
import useTranslation from '../../../hooks/translation.hook';
import { ObjectType } from '../../../enums/objectType';
import ProfileStatusEnum from '../../../eenApi/profile/enum/profile.status.enum';
import { UserContext } from '../../../context/user-context';
import Rules from '../../../rules';

interface IProps extends ITabPanelProps {
    entity: ProfileDto;
}

export default function DetailsTab({ entity, ...rest }: IProps) {
    const { t } = useTranslation();
    const { checkAccess } = useContext(UserContext);
    const canEditValidation = ProfileStatusEnum.Validation === entity.status && checkAccess(Rules.Profile.Validate);
    const isValidate = ProfileStatusEnum.Validation === entity.status || ProfileStatusEnum.CommentsReceived === entity.status;

    return (
        <TabPanel {...rest}>
            <Field labelKey='profile:fields.title'
                   isValidate={isValidate} fieldName="title" objectType={ObjectType.Profile} objectId={entity.id} editable={canEditValidation}>
                {entity.title}
            </Field>
            <LinkField labelKey='profile:fields.clientId'
                       route={ClientRoutesPath.View.replace(':id', String(entity.client?.id))}>{entity.client?.name}</LinkField>
            <Field labelKey='profile:fields.profileType'>{t(`profile:type.${entity.type}`)}</Field>
            <Field labelKey='profile:fields.memberCountryId' optional>{entity.memberCountry?.name}</Field>
            <Field labelKey='profile:fields.createDate'>{formatDateTime(entity.created)}</Field>
            <Field labelKey='profile:fields.updateDate'>{formatDateTime(entity.modified)}</Field>
            <Field labelKey='profile:fields.expireDate' optional>{formatDateTime(entity.expirationDate)}</Field>
            <Field labelKey='profile:fields.annotation' optional
                   isValidate={isValidate} fieldName="annotation" objectType={ObjectType.Profile} objectId={entity.id} editable={canEditValidation}>
                {entity.annotation}
            </Field>
            <Field labelKey='profile:fields.description' optional html value={entity.description}
                   isValidate={isValidate} fieldName="description" objectType={ObjectType.Profile} objectId={entity.id} editable={canEditValidation}/>
            <Field labelKey='profile:fields.stage' optional
                   isValidate={isValidate} fieldName="stage" objectType={ObjectType.Profile} objectId={entity.id} editable={canEditValidation}>
                {t(`profile:stage.${entity.stage}`)}
            </Field>
            <Field labelKey='profile:fields.iprStatuses' optional
                   value={entity.iprStatuses?.map(s => t(`profile:iprStatus.${s}`))}
                   isValidate={isValidate} fieldName="iprStatuses" objectType={ObjectType.Profile} objectId={entity.id} editable={canEditValidation} />
            <Field labelKey='profile:fields.advantages' optional
                   isValidate={isValidate} fieldName="advantages" objectType={ObjectType.Profile} objectId={entity.id} editable={canEditValidation}>
                {entity.advantages}
            </Field>
            <Field labelKey='profile:fields.keyWords' optional value={entity.keyWordsView} />
            <Field labelKey='profile:fields.frameworkProgramme' optional>{entity.frameworkProgramme}</Field>
            <Field labelKey='profile:fields.innovationProjectId' optional>{entity.innovationProject?.name}</Field>
            <Field labelKey='profile:fields.partnerRole' optional>{entity.partnerRole}</Field>
            <Field labelKey='profile:fields.partnershipTypes' optional value={entity.partnershipTypes?.map(p => t(`profile:partnershipType.${p}`))} />
            <Field labelKey='profile:fields.partnerTypes' optional value={entity.partnerTypesView} />
            <Field labelKey='profile:fields.comment' optional
                   isValidate={isValidate} fieldName="comment" objectType={ObjectType.Profile} objectId={entity.id} editable={canEditValidation}>
                {entity.comment}
            </Field>
        </TabPanel>
    );
}