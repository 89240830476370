import { FC, useState } from 'react';
import useTranslation from '../../../hooks/translation.hook';
import PageTitle from '../../../component/pageTitle';
import Filter from '../../../component/filter';
import LogTable from './table';
import FilterItemHidden from '../../../component/filter/types/filterItem/items/hidden';
import FilterItemText from '../../../component/filter/types/filterItem/items/text';
import FilterItemDate from '../../../component/filter/types/filterItem/items/date';
import FilterItemAsyncSelect from '../../../component/filter/types/filterItem/items/asyncSelect';
import { UserService } from '../../../eenApi/user/service';
import MainContainer from '../../../component/mainContainer';
import { useCurrentUser } from '../../../hooks/useCurrentUser';
import FilterItemSelect from '../../../component/filter/types/filterItem/items/select';
import { useQuery } from 'react-query';
import { EenApi } from '../../../eenApi';

const LogList: FC = () => {
    const { t, i18n } = useTranslation();
    const currentUser = useCurrentUser();
    const [filterValues, setFilterValues] = useState<{ [key: string]: any }>();

    const { data: objectTypes } = useQuery(['objectTypes', i18n.language], async () => {
        const result = await EenApi.get<{ code: string }[]>('log/objectTypes');
        return (result ?? []).map(type => ({
            value: type.code,
            label: t(`main:object-type-${type.code}`)
        }))
    })
    const { data: logTypes } = useQuery(['logTypes', i18n.language], async () => {
        const result = await EenApi.get<{ code: string }[]>('log/logTypes');
        return (result ?? []).map(type => ({
            value: type.code,
            label: t(`log:type-${type.code}`)
        }))
    })

    const filterItems = [
        new FilterItemHidden('id', { isTyping: 'Y' }),
        new FilterItemDate('created', 'main:field-date-and-time', { isDefault: 'Y' }),
        new FilterItemText('ip', 'main:field-ip'),
        new FilterItemSelect('type', 'main:field-type', { isDefault: "Y", options: logTypes ?? [] }),
        new FilterItemSelect('objectType', 'log:field-object-type', { isDefault: "Y", options: objectTypes ?? [] }),
        new FilterItemAsyncSelect('userId', 'main:field-user', { isDefault: 'Y',
            loadOptions: term => UserService.selectLoadOptions(term, 'userId') }),
    ];
    return (
        <MainContainer>
            <PageTitle text={t('log:menu-title')} backLink="/" />
            <div className="d-xl-flex mb-1 mb-xl-3">
                <Filter id="filter-logs" items={filterItems} onFind={setFilterValues} />
            </div>
            {currentUser.data?.language && <LogTable filterValues={filterValues} language={currentUser.data?.language} />}
        </MainContainer>
    );
};

export default LogList;
