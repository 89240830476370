import { ReactNode, useContext } from 'react';
import { Redirect, Route } from 'react-router-dom';
import * as React from 'react';
import { RouteComponentProps, RouteProps } from 'react-router';
import { UserContext } from '../../../context/user-context';
import { ObjectType } from '../../../enums/objectType';
import { useCurrentUser } from '../../../hooks/useCurrentUser';

interface IProps extends RouteProps {
    component?: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
    render?: (props: RouteComponentProps<{ [x: string]: string | undefined; }>) => ReactNode;
    rule: string | string[];
    ownerType?: ObjectType | ObjectType[];
}

export default function ProtectedRoute({ component, rule, ownerType, render, ...rest }: IProps) {
    const context = useContext(UserContext);
    const currentUser = useCurrentUser();

    let allowedOwner = true;
    if (ownerType) {
        ownerType = Array.isArray(ownerType) ? ownerType : [ownerType];
        allowedOwner = (currentUser.data?.rules.every(r => r.objectType === null)
          || currentUser.data?.rules.some((r) => r.objectType && ownerType?.includes(r.objectType))) ?? false;
    }

    return Array<string>().concat(rule).reduce((acc, rule) => acc || context.checkAccess(rule), false)
        && allowedOwner
            ? <Route {...rest} component={component} render={render} />
            : <Redirect to='/error/access-denied' />;
}
