import React, { PropsWithChildren, useCallback, useContext, useEffect, useState } from 'react';
import {Autocomplete, CircularProgress, debounce, FormControl} from '@mui/material';
import {StyledTextField} from "../index";
import IControlProps from "../control.props";
import InputContainer from "../../../inputContainer";
import {useValidation} from "../../../../hooks/UseValidation";
import useTranslation from "../../../../hooks/translation.hook";
import ValidationContext from '../../../../hooks/UseValidation/validation.context';

interface IProps extends IControlProps {
   disabled?: boolean;
   options?: (text: string, language?: string) => Promise<string[]>;
}

interface IPropsFreeSolo extends IProps {
   multiple?: false;
   value: string | null;
   onChange: (selected: string | null) => void;
}

interface IPropsFreeSoloMulti extends IProps {
   multiple: true;
   value: string[] | null;
   onChange: (selected: string[] | null) => void;
}

export default function TagControl(props: PropsWithChildren<IPropsFreeSolo | IPropsFreeSoloMulti>) {
   const { t, i18n } = useTranslation();
   const validate = useValidation(props.validators);
   const { validateOn } = useContext(ValidationContext);

   const [inputValue, setInputValue] = useState('');
   const [options, setOptions] = useState<readonly string[]>([]);
   const [loading, setLoading] = useState(false);
   const [errorText, setErrorText] = useState<string>();

   const getOptionsDelayed = useCallback(
      debounce((text, callback) => {
         if (!props.options) return;

         setOptions([]);
         (text?.length
            ? props.options(text, i18n.language)
            : Promise.resolve([{ value: null, label: t('main:select.minInput')}]))
            .then(callback);
      }, 400),
      [],
   );

   useEffect(() => {
      if (!props.options) return;

      let active = true;

      setLoading(true);
      getOptionsDelayed(inputValue, (data: string[]) => {
         if (active) {
            setOptions(data);
            setLoading(false);
         }
      });

      return () => {
         active = false;
      };
   }, [inputValue]);

   useEffect(() => {
      setErrorText(props.error || validate(props.value, props.tab ?? 'main'))
   }, [props.value, props.error, validateOn])

   const getValue = () => {
      if (props.value) {
         return props.value;
      }

      if (props.multiple) {
         return props.value ?? [];
      }

      return null;
   };

   return (
      <InputContainer>
      <FormControl required={props.required} error={!!props.error} fullWidth size="small">
         <Autocomplete
            autoComplete
            value={getValue()}
            filterOptions={(x) => x}
            includeInputInList={!props.multiple}
            onChange={(event: React.SyntheticEvent, newValue: string | string[] | null) => {
               if (props.multiple && Array.isArray(newValue)) {
                  props.onChange(newValue);
                  return;
               }
               if (!props.multiple && !Array.isArray(newValue)) {
                  props.onChange(newValue);
                  return;
               }
               props.onChange(null);
            }}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
               setInputValue(newInputValue);
            }}
            options={options}
            loading={loading}
            multiple={props.multiple}
            disabled={props.disabled}
            freeSolo
            onBlurCapture={() => {
               if (props.multiple && inputValue?.length) {
                  props.onChange([...(props.value ?? []), inputValue]);
               }
            }}
            clearOnBlur
            renderInput={(params) => (
               <StyledTextField
                  {...params}
                  size="small"
                  label={t(props.labelKey)}
                  required={props.required}
                  error={!!errorText}
                  helperText={errorText}
                  InputProps={{
                     ...params.InputProps,
                     endAdornment: (
                        <React.Fragment>
                           {loading ? <CircularProgress color="inherit" size={20}/> : null}
                           {params.InputProps.endAdornment}
                        </React.Fragment>
                     ),
                  }}
               />
            )}
         />
      </FormControl>
      </InputContainer>
   );
};