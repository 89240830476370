import {EenApi} from "../index";
import { SpecialistDto } from './dto/specialist.dto';
import { SpecialistStatusEnum } from './enum/specialis.status.enum';
import { SpecialistRoutesPath } from "../../page/specialist/routes";

class SpecialistServiceImpl {

   async save(data: SpecialistDto): Promise<any> {
      return (data.id ?? 0) > 0
          ? await EenApi.put<any>("specialist/edit", data)
          : await EenApi.post<any>("specialist/add", data)
   }

   async remove(ids: string | number | string[] | number[]) {
      if (!Array.isArray(ids)) {
         ids = [ids as string]
      }
      return await EenApi.delete('specialist/delete', { ids: ids })
   }

   list(requestData: any, validation: boolean) {
      return EenApi.list<SpecialistDto>(`specialist/list${validation ? '/validation' : ''}`, requestData)
   }

   async updateStatus(id: number, status: SpecialistStatusEnum) {
      await EenApi.put('specialist/status', { id, status })
   }

   getDetailsRoute(id: number|string) {
      return SpecialistRoutesPath.View.replace(':id', String(id))
   }

   getEditRoute(id: number|string) {
      return SpecialistRoutesPath.Edit.replace(':id', String(id))
   }
}

export const SpecialistService = new SpecialistServiceImpl()