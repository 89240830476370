import React, { useContext, useMemo } from 'react';
import { UserContext } from '../../../../../context/user-context';
import Rules from '../../../../../rules';
import { MailingService } from '../../../../../eenApi/mailing/service';
import MailingUnsubscriptionDto from '../../../../../eenApi/mailing/dto/mailing.unsubscription.dto';
import useTranslation from '../../../../../hooks/translation.hook';
import { TableColumn, TableGroupAction, TableRowAction } from '../../../../../component/common/layout/DataTable/table.model';
import { DataTable } from '../../../../../component/common/layout';
import { EntityQueryKey } from '../../../../../query/query.keys';

interface IProps {
    filterValues?: { [key: string]: any };
}

export function UnsubscribeMailingTable(props: IProps) {
    const { checkAccess } = useContext(UserContext);
    const { t, i18n } = useTranslation();

    const columns = useMemo<TableColumn<MailingUnsubscriptionDto>[]>(
        () => [
            new TableColumn({
                headerName: t('mailing:unsubscribe.email'),
                field: 'email',
            }),
            new TableColumn({
                headerName: t('mailing:unsubscribe.mailing'),
                field: 'mailing',
                valueFormatter: ({ value }) => (value === true ? t('main:yes') : t('main:no')),
            }),
            new TableColumn({
                headerName: t('mailing:unsubscribe.notification'),
                field: 'notification',
                valueFormatter: ({ value }) => (value === true ? t('main:yes') : t('main:no')),
            }),
        ],
        [i18n.language],
    );

    const rowActions = useMemo<TableRowAction<MailingUnsubscriptionDto>[]>(() => {
        const items = [];

        if (checkAccess(Rules.Mailing.Delete)) {
            items.push({
                id: 'delete',
                label: t('main:btn-delete'),
                action: (item: MailingUnsubscriptionDto) => clickDelete([item.id!]),
            });
        }

        return items;
    }, []);

    const groupActions = useMemo<TableGroupAction[]>(
        () =>
            checkAccess(Rules.Mailing.Delete)
                ? [
                      {
                          id: 'delete',
                          label: t('main:btn-delete'),
                          action: (selected) => clickDelete(selected.map((x) => Number(x))),
                      },
                  ]
                : [],
        [],
    );

    const clickDelete = (ids: number[]) => MailingService.deleteUnsubscribing(ids);

    return (
        <DataTable
            id="mailing-unsubscribe-table-component"
            queryKey={EntityQueryKey.MailingUnsubscribe}
            filterValues={props.filterValues}
            columns={columns}
            fetchData={(requestData) => MailingService.unsubscribinglist(requestData)}
            rowActions={rowActions}
            groupActions={groupActions}
        />
    );
}
