import TabPanel, { ITabPanelProps } from '../../tabs/tabPanel';
import React from 'react';
import { InputControl, SelectControl } from '../../common/ui';
import { Length, NotEmpty } from '../../../hooks/UseValidation/validators';
import { useOptions } from '../../../hooks/select.options.hook';
import { NewsEntity } from '../../../eenApi/news/news';
import { NewsStatusType } from '../../../enums/NewsStatusType';
import NewsEditDto from '../../../eenApi/news/dto/news.edit.dto';
import { isRoutePathCreate } from '../../../utils/check.route.path';
import { useHistory } from 'react-router-dom';
import { NewsRoutesPath } from '../../../page/news/routes';
import _ from 'lodash';

interface IProps extends ITabPanelProps {
    data: NewsEditDto,
    updateData: (key: keyof NewsEntity, val: any) => void,
}

export default function NewsMainTab({ data, updateData, ...rest }: IProps) {
    const history = useHistory();

    const statusOptions = useOptions(NewsStatusType, 'news:status.');

    if (isRoutePathCreate(history.location.pathname, NewsRoutesPath.Create)) {
        _.remove(statusOptions, function(option) {
            return option.value === NewsStatusType.Remarks;
        });
        _.remove(statusOptions, function(option) {
            return option.value === NewsStatusType.Active;
        });
    }

    return (
        <TabPanel {...rest}>
            <InputControl
                value={data.title}
                onChange={value => updateData('title', value)}
                labelKey={'news:fields.field-title'}
                required validators={[NotEmpty, Length(250)]}
                tab={'main'}
            />
            <SelectControl
                labelKey='news:fields.field-status'
                value={data.status ? data.status : null}
                onChange={value => {
                    updateData('status', value);
                }}
                options={statusOptions}
                required validators={[NotEmpty]}
                tab={'main'}
            />
        </TabPanel>
    );
}
