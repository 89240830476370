import { Link, useLocation } from "react-router-dom";

interface IProps {
  getLink: (id: string|number) => string,
  root: ICrumb
}

export interface ICrumb {
  id: string|number,
  name: string
}

export function Breadcrumbs ({ getLink, root }: IProps) {
  const { state } = useLocation<{crumbs: ICrumb[]}>();

  return (
      <div className="mb-2">
          {state?.crumbs?.length ? (
              <>
                  <Link to={String(root.id)}>{root.name}</Link>
                  <small>
                    <i className="fas fa-angle-right mx-2" />
                  </small>
                  {state.crumbs.map((crumb, idx) => (
                      <span key={idx}>
                          {idx < state.crumbs.length - 1 ? (
                              <>
                                  <Link to={{ pathname: getLink(crumb.id), state: { crumbs: state.crumbs.slice(0, idx + 1) } }}>{crumb.name}</Link>
                                  <small>
                                      <i className="fas fa-angle-right mx-2" />
                                  </small>
                              </>
                          ) : (
                              crumb.name
                          )}
                      </span>
                  ))}
              </>
          ) : null}
      </div>
  );
}