enum ProfileIpStatusEnum {
   Copyright = "Copyright",
   IndustrialModel = "IndustrialModel",
   ExclusiveRight = "ExclusiveRight",
   PatentRequired = "PatentRequired",
   Trademark = "Trademark",
   PatentPending = "PatentPending",
   PatentReceived = "PatentReceived",
   ProductionSecrets = "ProductionSecrets",
   Other = "Other",
}

export default ProfileIpStatusEnum