import React, { FC, useMemo } from "react";
import MainContainer from '../../../component/mainContainer';
import PageTitle from '../../../component/pageTitle';
import { Notification } from '../../../eenApi/notification/notification';
import { NotificationService } from '../../../eenApi/notification/service';
import TopActionsContainer from "../../../component/actions/topActionsContainer";
import TopActions from "../../../component/actions/topActions";
import BottomActions from "../../../component/actions/bottomActions";
import BottomActionsContainer from "../../../component/actions/bottomActionsContainer";
import { AutocompleteControl, InputControl, TextEditorControl } from "../../../component/common/ui";
import { useEditPage } from "../../../hooks/UseEditPage";
import { NotificationEditValidator } from "../../../utils/validators/notification/edit.validator";
import { Length, NotEmpty } from "../../../hooks/UseValidation/validators";
import TabControls from "../../../component/tabs/tabControls";
import TabPanel from "../../../component/tabs/tabPanel";
import Loader from "../../../component/loader";
import TabItem from "../../../component/tabs/tabItem";
import { DictionaryType } from "../../../models/dictionaryType";
import CheckboxControl from "../../../component/common/ui/CheckboxControl";
import { RoleService } from "../../../eenApi/role/service";
import ValidationContext from '../../../hooks/UseValidation/validation.context';
import { NotificationRoutesPath } from "../routes";

const NotificationEdit: FC = () => {
    const {
        entity, isFetching,
        t, i18n,
        data,
        validateOn,
        tabs, setTabs,
        activeTab, setActiveTab,
        saveActions,
        updateData
    } = useEditPage<Notification>({
        dataFactory: (entity, language) => new Notification(entity, language),
        queryKey: 'notification',
        handler: data => NotificationService.addOrUpdate(data),
        validator: NotificationEditValidator,
        applyRoute: NotificationService.getEditLink,
        saveRoute: NotificationService.getEditLink,
        cancelRoute: [NotificationRoutesPath.List, NotificationRoutesPath.List]
    });

    const tabItems = useMemo(() => [new TabItem('main', t('main:tab.main')),], [i18n.language]);

    return (
        <MainContainer>
            <PageTitle text={t(entity?.id ? 'notification:edit-title' : 'notification:create-title')} backLink="/notification/list" />
            <TopActionsContainer>
                <TopActions actions={saveActions} />
            </TopActionsContainer>
            <ValidationContext.Provider value={{ validateOn, tabs, setTabs }}>
                <TabControls selectedId={activeTab} items={tabItems} onChange={setActiveTab} errored={validateOn ? tabs : []} />
                <TabPanel activeId={activeTab} id='loading'><Loader /></TabPanel>
                {!isFetching && <>
                    <TabPanel activeId={activeTab} id='main'>
                        <InputControl labelKey="main:field-name" value={data.name} required
                                      onChange={val => updateData('name', val)}
                                      validators={[NotEmpty, Length(100)]} />
                        <AutocompleteControl labelKey="main:field-type" value={data.type} required validators={[NotEmpty]}
                                             onChange={val => updateData('type', val?.value ?? null)}
                                             dictionary={DictionaryType.NotificationType} all options={async (term) => {
                                                 const types = await NotificationService.types();
                                                 return types?.filter(item => Array.isArray(term) ? term.includes(item.code) : item.code.includes(term.trim()))
                                                   ?.map(item => ({
                                                     value: item.code ?? '',
                                                     label: t(`notification:type-${item.code}`)
                                                 }))?? []
                                             }}/>
                        <CheckboxControl labelKey="main:field-active" value={data.active} onChange={val => updateData('active', val)}/>
                        <CheckboxControl labelKey="notification:field-is-email" value={data.isEmail} onChange={val => updateData('isEmail', val)}/>
                        {data.isEmail && (<>
                            <InputControl labelKey="notification:field-email-subject" value={data.emailSubject}
                                          required={data.isEmail} validators={data.isEmail ? [NotEmpty, Length(1000)] : [Length(1000)]}
                                          onChange={val => updateData('emailSubject', val)} />
                            <TextEditorControl labelKey={'notification:field-email-text'} value={data.emailText}
                                               onChange={(e: any, text: string) => updateData('emailText', text)}
                                               required={data.isEmail}
                                               validators={data.isEmail ? [NotEmpty, Length(10000)] : [Length(10000)]} />
                        </>)}
                        <CheckboxControl labelKey="notification:field-is-feed" value={data.isFeed} onChange={val => updateData('isFeed', val)}/>
                        {data.isFeed && (<>
                            <TextEditorControl labelKey="notification:field-feed-text" value={data.feedText}
                                               required={data.isFeed} validators={data.isFeed ? [NotEmpty, Length(1000)] : [Length(1000)]}
                                               onChange={(e: any, text: string) => updateData('feedText', text)} />
                        </>)}
                        <CheckboxControl labelKey="notification:field-is-push" value={data.isPush} onChange={val => updateData('isPush', val)}/>
                        {data.isPush && (<>
                            <InputControl labelKey="notification:field-push-link" value={data.pushLink}
                                          required={data.isPush} validators={data.isPush ? [NotEmpty, Length(100)] : [Length(100)]}
                                          onChange={val => updateData('pushLink', val)} />
                            <InputControl labelKey="notification:field-push-text" value={data.pushText}
                                          required={data.isPush} validators={data.isPush ? [NotEmpty, Length(100)] : [Length(500)]}
                                          onChange={val => updateData('pushText', val)} />
                        </>)}
                        <CheckboxControl labelKey="notification:field-is-all-users-receive"
                                         value={data.isAllUsersReceive} onChange={val => updateData('isAllUsersReceive', val)}/>
                        {data.isAllUsersReceive && (<>
                            <AutocompleteControl labelKey="notification:field-is-all-users-receive" value={data.roleIds} multiple
                                                 dictionary={DictionaryType.Role} all options={RoleService.selectLoadOptions}
                                                 validators={[Length(1000)]}
                                                 onChange={val => updateData('roleIds', val?.map(s => s.value) ?? [])} />
                        </>)}
                        {data.isEmail && (<>
                            <InputControl labelKey="notification:field-copy-email-recipients" value={data.copyEmailRecipients}
                                          onChange={val => updateData('copyEmailRecipients', val)} />
                        </>)}
                    </TabPanel>
                    <BottomActionsContainer>
                        <BottomActions actions={saveActions} />
                    </BottomActionsContainer>
                </>}
            </ValidationContext.Provider>
        </MainContainer>
    );
};

export default NotificationEdit;
