import { Route, Switch } from 'react-router-dom';
import NotFound from '../../home/notFound';
import Rules from '../../../rules';
import ProtectedRoute from '../../../component/common/ProtectedRoute';
import { InnovationProjectEdit } from '../edit';
import { InnovationProjectList } from '../list';
import { InnovationProjectDetails } from '../details';

export default function InnovationProjectRoutes() {
    return (
        <Switch>
            <ProtectedRoute path={InnovationProjectRoutesPath.List} exact component={InnovationProjectList}
                            rule={Rules.InnovationProject.List} />
            <ProtectedRoute path={InnovationProjectRoutesPath.Create} exact component={InnovationProjectEdit}
                            rule={Rules.InnovationProject.Add} />
            <ProtectedRoute path={InnovationProjectRoutesPath.Edit} exact component={InnovationProjectEdit}
                            rule={[Rules.InnovationProject.Edit, Rules.InnovationProject.EditOwn]} />
            <ProtectedRoute path={InnovationProjectRoutesPath.View} exact component={InnovationProjectDetails}
                            rule={[Rules.InnovationProject.View, Rules.InnovationProject.Edit]} />
            <Route path='*' component={NotFound} />
        </Switch>
    );
}

const prefix = '/innovation-project';
export const InnovationProjectRoutesPath = {
    Prefix: `${prefix}`,
    Create: `${prefix}/create`,
    Edit: `${prefix}/:id/edit`,
    View: `${prefix}/:id/details`,
    List: `${prefix}/list`,
};