import { FC } from "react";
import { ActionItem } from "../../models/actionItem";
import ActionList from "./actionList";

interface IProps {
    actions: ActionItem[]
}

const BottomActions: FC<IProps> = (props: IProps) => {
    return (
        <ActionList actions={props.actions} />
    );
}

export default BottomActions;
