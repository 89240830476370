import { SelectItemDto } from "../../eenApi/common/select.item.dto";
import { useEffect } from "react";
import { ToastManager } from "../../service/toastManager";

export function useCountryWarning(entity: Partial<{ id?: number }>|undefined, ...countries: [SelectItemDto|undefined, ...(SelectItemDto|undefined)[]]) {
  useEffect(() => {
    countries.forEach(c => {
      if (c?.attrs?.active === false) {
        ToastManager.warn({ title: 'main:msg-warn', message: 'membercountry:warning.deleted', params: [c.name] });
      }
    })
  }, [entity?.id]);
}