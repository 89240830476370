import { EenApi } from '../../index';
import { InnovationProjectStatusEnum } from '../../innovationproject/enum/innovation.project.status.enum';
import { ListRequestData } from '../listRequestData';

export abstract class CrudService<T extends { id?: number }> {
    protected readonly baseUrl: string;

    protected constructor(baseUrl: string) {
        this.baseUrl = baseUrl;
    }

    save(data: T, ...args: any): Promise<any> {
        return (data.id ?? 0) > 0
            ? EenApi.put<any>(`${this.baseUrl}/edit`, data)
            : EenApi.post<any>(`${this.baseUrl}/add`, data)
    }

    remove(ids: string | number | string[] | number[]) {
        if (!Array.isArray(ids)) {
            ids = [ids as string];
        }
        return EenApi.delete(`${this.baseUrl}/delete`, { ids: ids });
    }

    list(requestData: ListRequestData, listType = 'list') {
        return EenApi.list<T>(`${this.baseUrl}/${listType}`, requestData);
    }

    updateStatus(id: number, status: InnovationProjectStatusEnum) {
        return EenApi.put(`${this.baseUrl}/status`, { id, status });
    }
}
