import { FC, useState } from "react";
import useTranslation from "../../../../../hooks/translation.hook";
import Select, { MultiValue } from 'react-select';
import { IFilterFieldProps } from "../../../types/filterFieldProps";
import { ISelectProps } from "../../../types/filterItem/additionalParams";
import { RequestDataValue } from "../../../types/requestData";
import { SingleValue } from 'react-select/dist/declarations/src/types';

const FilterFieldSelect: FC<IFilterFieldProps> = (props) => {
    const { t } = useTranslation();
    const [focus, setFocus] = useState(false);
    const additionalPrams = props.filterItem.additionalParams as ISelectProps;
    const options = additionalPrams.options as { value: string, label: string }[];
    var value = props.data ? props.data.map(x => {
        return { value: x.value, label: x.label ?? '' };
    }) : null;

    const onChangeValue = (selected: MultiValue<{ value: string, label: string }>|SingleValue<{ value: string, label: string }>) => {
        const result: RequestDataValue[] = [];
        if (selected) {
            const values = Array.isArray(selected) ? selected : [selected];
            values.forEach(element => {
                result.push(element);
            });
        }
        props.onChange(result, props.filterItem);
    }

    const onFocus = () => {
        if (focus) { return; }
        setFocus(true);
    }

    const obBlur = () => {
        if (!focus) { return; }
        setFocus(false);
    }

    return (
        <div className="form-outline">
            <Select value={value} onChange={onChangeValue} options={options} isClearable isMulti={props.filterItem.additionalParams?.multiple ?? true} placeholder=""
                    onFocus={onFocus} onBlur={obBlur} />
            <label className={`select-label ${focus || (value && value.length > 0) ? 'active' : ''}`}>{t(props.filterItem.Title)}</label>
        </div>
    );
}

export default FilterFieldSelect;
