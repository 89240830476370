import {Component} from 'react';
import {Route, Switch} from 'react-router-dom';
import NotFound from '../notFound';
import Home from '../home';
import AccessDenied from '../accessDenied';

export default class HomeRoutes extends Component {
   render() {
      return (
         <Switch>
            <Route path={HomeRoutesPath.Home} exact component={Home}/>
            <Route path={HomeRoutesPath.AccessDenied} exact component={AccessDenied}/>
            <Route path={HomeRoutesPath.NotFound} component={NotFound}/>
         </Switch>
      );
   }
}

export const HomeRoutesPath = {
   Prefix: '/',
   Home: '/',
   NotFound: '/*',
   AccessDenied: '/error/access-denied'
};
