import { useMutation, useQueryClient } from 'react-query';
import { ToastManager } from '../../service/toastManager';
import { resetQueryFn } from '../../query/query.utils';

export function useChangeStatusMutation<T>(handler: (status: T) => Promise<void>, entity: string) {
    const queryClient = useQueryClient();
    return useMutation((status: T) => handler(status), {
        onSuccess: async () => {
            ToastManager.success({ title: 'main:msg-success', message: 'main:msg-changes-saved' });
            await queryClient.resetQueries({ predicate: (query) => resetQueryFn(query, entity) });
        },
    });
}
