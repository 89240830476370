import { useContext, useMemo, useState } from 'react';
import useTranslation from '../../../hooks/translation.hook';
import { UserContext } from '../../../context/user-context';
import MainContainer from '../../../component/mainContainer';
import PageTitle from '../../../component/pageTitle';
import Rules from '../../../rules';
import { SpecialistRoutesPath } from '../routes';
import { Link } from 'react-router-dom';
import { SpecialistTable } from './table';
import FilterItemText from '../../../component/filter/types/filterItem/items/text';
import FilterItemAsyncSelect from '../../../component/filter/types/filterItem/items/asyncSelect';
import Filter from '../../../component/filter';
import { MemberCountryService } from '../../../eenApi/membercountry/member.country.service';
import FilterItemSelect from '../../../component/filter/types/filterItem/items/select';
import { useOptions } from '../../../hooks/select.options.hook';
import { SpecialistStatusEnum } from '../../../eenApi/specialist/enum/specialis.status.enum';

interface IProps {
    validation: boolean
}

export function SpecialistList({ validation }: IProps) {
    const { t, i18n } = useTranslation();
    const { checkAccess } = useContext(UserContext);
    const [filterValues, setFilterValues] = useState<{ [key: string]: any }>();

    const statusOptions = useOptions(SpecialistStatusEnum, 'specialist:status.').filter(x => x.value !== SpecialistStatusEnum.Active);
    const filterItems = useMemo(() => {
        const items = [
            new FilterItemText('name', t('specialist:fields.name'), {isDefault: "Y"}),
            new FilterItemAsyncSelect('memberCountryId', t('specialist:fields.citizenship'), { isDefault: "Y", loadOptions: MemberCountryService.selectLoadOptions }),
            new FilterItemText('education', t('specialist:fields.education'), {isDefault: "Y"}),
            new FilterItemText('specialization', t('specialist:fields.specialization'), {isDefault: "Y"}),
            new FilterItemText('email', t('specialist:fields.email'), {isDefault: "Y"})
        ];
        if (validation) {
            items.push(
                new FilterItemSelect('status', t('specialist:fields.status'), { options: statusOptions, isDefault: "Y" }),
            )
        }
        return items
    }, [validation, i18n.language]);

    return (
        <MainContainer>
            <PageTitle text={t(`specialist:title.${validation ? 'validationList' : 'list'}`)} backLink="/" />
            <div className="d-xl-flex mb-1 mb-xl-3">
                <div className="app-filter-actions mb-2 mb-xl-0">
                    {checkAccess(Rules.Specialist.Create) && (
                        <Link className="btn btn-success me-2" to={SpecialistRoutesPath.Create}>
                            {t('main:btn-add')}
                        </Link>
                    )}
                </div>
                <Filter id={`filter-specialist-${validation}`} items={filterItems} onFind={setFilterValues} query={{}} />
            </div>
            <SpecialistTable validation={validation} filterValues={{ ...filterValues, validation }} />
        </MainContainer>
    );
}