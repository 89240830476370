import ModelValidator from "../model.validator";
import { SpecialistDto } from '../../../eenApi/specialist/dto/specialist.dto';

class EditValidator extends ModelValidator<SpecialistDto, SpecialistDto> {

   validate(dto: SpecialistDto) {
      const erroredTabs: string[] = [];

      this.checkRequired(dto.name, erroredTabs, 'main');
      this.checkRequired(dto.biography, erroredTabs, 'main');
      this.checkRequired(dto.areaOfInterest, erroredTabs, 'main');
      this.checkLength(dto.name, erroredTabs, 50, 'main');
      this.checkRequired(dto.dateOfBirth, erroredTabs, 'main');
      this.checkRequired(dto.memberCountryId, erroredTabs, 'main');
      this.checkLength(dto.city, erroredTabs, 50, 'main');
      this.checkLength(dto.education, erroredTabs, 100, 'main');
      this.checkLength(dto.speciality, erroredTabs, 100, 'main');
      this.checkLength(dto.specialization, erroredTabs, 255, 'main');
      this.checkLength(dto.areaOfInterest, erroredTabs, 100, 'main');
      this.checkLength(dto.biography, erroredTabs, 500, 'main');
      this.checkLength(dto.workExperience, erroredTabs, 255, 'main');

      this.checkRequired(dto.email, erroredTabs, 'contacts');
      this.checkLength(dto.email, erroredTabs, 4000, 'contacts');
      this.checkLength(dto.phone ?? '', erroredTabs, 4000, 'contacts');
      this.checkLength(dto.linkedIn ?? '', erroredTabs, 4000, 'contacts');

      return erroredTabs;
   }
}
export const SpecialistEditValidator = new EditValidator()