import {useLocation} from "react-router-dom";
import {useMemo} from "react";

export const useQueryParams = <T extends Record<string, string>> () => {
   const { search } = useLocation();

   return useMemo(() => {
      const params: Record<string, string> = {};
      Array.from(new URLSearchParams(search).entries()).forEach(([key, val]) => {
         params[key] = val
      })
      return params as T
   }, [search]);
}