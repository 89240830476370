import { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import { UserContext, UserContextProps } from "../../context/user-context";
import { QueryClient, QueryKey, useQuery } from 'react-query';
import { TFunction } from 'react-i18next';
import { i18n } from 'i18next';
import * as H from 'history';
import { useCommonEntityStates } from '../UseCommonHooks';

export interface IEditStatesOptions<T> {
    dataFactory: (data: T|undefined, language: string) => T,
    queryKey: QueryKey
}

interface IEditStates<T> {
    t: TFunction,
    i18n: i18n,
    history: H.History<unknown>,
    queryClient: QueryClient,
    isFetching: boolean,
    id: string,
    entity: T|undefined,
    data: T,
    setData:  Dispatch<SetStateAction<T>>,
    activeTab: string,
    setActiveTab:  Dispatch<SetStateAction<string>>
    validateOn: boolean,
    setValidateOn:  Dispatch<SetStateAction<boolean>>,
    tabs: string[],
    setTabs:  Dispatch<SetStateAction<string[]>>
}

export interface IEditAllStates<T> extends IEditStates<T>, UserContextProps {}

export function useEditStatesBase<T extends { id?: number }>(options: IEditStatesOptions<T>): IEditStates<T> {
    const { t, i18n, history, queryClient, activeTab, setActiveTab, id } = useCommonEntityStates();
    const queryKey = Array.isArray(options.queryKey) ? options.queryKey : [options.queryKey];
    queryKey.push(id);
    queryKey.push(i18n.language);

    const { data: entity, isFetching } = useQuery<T>(queryKey, {
        enabled: Number(id) > 0
    });
    const [data, setData] = useState<T>(options.dataFactory(entity, i18n.language));
    useEffect(() => {
        if (isFetching) {
            setActiveTab('loading');
        } else {
            setData(() => options.dataFactory(entity, i18n.language));
            setActiveTab('main');
        }
    }, [entity?.id, i18n.language, isFetching]);

    const [validateOn, setValidateOn] = useState(false);
    const [tabs, setTabs] = useState<string[]>([]);

    return {
        t, i18n, history,
        queryClient, isFetching,
        id, entity, data, setData, activeTab, setActiveTab,
        validateOn, setValidateOn, tabs, setTabs
    };
}

export function useEditStates<T extends { id?: number }>(options: IEditStatesOptions<T>): IEditAllStates<T> {
    return {
        ...useEditStatesBase(options),
        ...useContext(UserContext)
    }
}