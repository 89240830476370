import MailingUnsubscriptionDto from '../../../eenApi/mailing/dto/mailing.unsubscription.dto';
import ModelValidator from '../model.validator';

class EditValidator extends ModelValidator<MailingUnsubscriptionDto, MailingUnsubscriptionDto> {
    validate(dto: MailingUnsubscriptionDto, tabs: string[], entity: MailingUnsubscriptionDto) {
        const erroredTabs: string[] = [];

        this.checkLength(dto.comment ?? '', erroredTabs, 300);

        return erroredTabs;
    }
}
const MailingUnsubscriptionValidator = new EditValidator();
export default MailingUnsubscriptionValidator;
