import ModelValidator from '../model.validator';
import { Notification } from '../../../eenApi/notification/notification';

class Validator extends ModelValidator<Notification, Notification> {
    validate(dto: Notification) {
        const erroredTabs: string[] = [];

        this.checkRequired(dto.name, erroredTabs, 'main');
        this.checkLength(dto.name, erroredTabs, 100, 'main');
        this.checkRequired(dto.type, erroredTabs, 'main');
        if (dto.isEmail) {
            this.checkRequired(dto.emailSubject, erroredTabs, 'main');
            this.checkLength(dto.emailSubject, erroredTabs, 1000, 'main');
            this.checkRequired(dto.emailText, erroredTabs, 'main');
            this.checkLength(dto.emailText, erroredTabs, 10000, 'main');
        }
        if (dto.isFeed) {
            this.checkRequired(dto.feedText, erroredTabs, 'main');
            this.checkLength(dto.feedText, erroredTabs, 1000, 'main');
        }
        if (dto.isPush) {
            this.checkRequired(dto.pushLink, erroredTabs, 'main');
            this.checkLength(dto.pushLink, erroredTabs, 100, 'main');
            this.checkRequired(dto.pushText, erroredTabs, 'main');
            this.checkLength(dto.pushText, erroredTabs, 500, 'main');
        }
        this.checkLength(dto.copyEmailRecipients, erroredTabs, 1000, 'main');

        return erroredTabs;
    }
}

export const NotificationEditValidator = new Validator();
