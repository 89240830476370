import { MDBBtn, MDBInput, MDBSpinner, MDBValidation } from "mdb-react-ui-kit";
import { FC, useEffect, useState } from "react";
import useTranslation from "../../hooks/translation.hook";
import DefaultBlock from "../../component/defaultBlock";
import InputContainer from "../../component/inputContainer";
import MainContainer from "../../component/mainContainer";
import PageTitle from "../../component/pageTitle";
import { EenApi } from "../../eenApi";
import { ErrorHandler } from "../../service/errorHandler";
import { ValidationRules } from "../../service/validation/rules";
import Utils from "../../utils";

type WorkData = {
    total: number,
    processed: number,
    data: {
        code: string,
        count: number,
        items: any[]
    }
}

const Import: FC = () => {
    const take = 50;
    const { t } = useTranslation();
    const [link, setLink] = useState('');
    const [data, setData] = useState('');
    const [isBusy, setIsBusy] = useState(false);
    const [workData, setWorkData] = useState<WorkData>();

    const onSubmit = async () => {
        try {
            if (!!workData || link.length < 1) {
                return;
            }
            const jsonData = Utils.tryParseJson(data) as { code: string, count: number, items: any[] };
            setWorkData({
                data: jsonData,
                processed: 0,
                total: jsonData.count
            });
            setIsBusy(true);
        }
        catch (err) {
            ErrorHandler.handle('import dictionary', err);
        }
    }

    useEffect(() => {
        if (!workData) {
            return;
        }
        sendWork();
    }, [workData])

    const sendWork = async () => {
        if (!workData)
            return;

        if (!isBusy) {
            //может быть сделать остановку через дополнительный флаг с возможностью продолжить
            setWorkData(undefined);
            return;
        }

        try {
            const skip = workData?.processed ?? 0;
            const response = await EenApi.postRequest(link, { data: { ...workData?.data, items: workData?.data.items.slice(skip, skip + take) } });
            const result = response.data;
            console.log('result: processed', result, 'skip', skip);

            if (!isBusy || skip + take > workData.total) {
                setWorkData(undefined);
            }
            else {
                setWorkData({ ...workData, processed: skip + take });
            }
        }
        catch (err) {
            setIsBusy(false);
            setWorkData(undefined);
            ErrorHandler.handle('dictionary import', err);
        }
    }

    const clickUpdateParents = async () => {
        try {
            if (link.length < 1) {
                return;
            }
            const response = await EenApi.postRequest(link);
            const result = response.data;
            console.log('result: upd parents', result);
        }
        catch (err) {
            ErrorHandler.handle('dictionary update parents', err);
        }
    };

    return (
        <MainContainer>
            <PageTitle text={t('dictionary:import-title')} backLink="/" />
            <DefaultBlock>
                <MDBValidation onSubmit={onSubmit} noValidate>
                    <InputContainer>
                        <MDBInput type="text" size="lg" value={link} name='name' onChange={(e: any) => setLink(e.target.value)} label={"link"} {...ValidationRules.required()} />
                    </InputContainer>
                    <InputContainer>
                        <MDBInput type="text" textarea rows={5} size="lg" value={data} name='name' onChange={(e: any) => setData(e.target.value)} label={"data"} />
                    </InputContainer>
                    <div>
                        {!workData
                            ? <MDBBtn type="submit">{t('main:btn-save')}</MDBBtn>
                            : <MDBBtn type="button" onClick={() => {
                                setIsBusy(false);
                                setWorkData(undefined);
                            }}>{t('main:btn-cancel')} <MDBSpinner size='sm' role='status' tag='span' className="ms-2" /></MDBBtn>
                        }
                        <MDBBtn type="button" className="ms-2" onClick={clickUpdateParents} disabled={!(link.length > 0)}>{t('dictionary:btn-update-parents')}</MDBBtn>
                    </div>
                </MDBValidation>
            </DefaultBlock>
        </MainContainer>
    );
}

export default Import;