import React, { FC, useMemo } from "react";
import Select, { SingleValue } from 'react-select';
import useTranslation from "../../../../../hooks/translation.hook";
import { IFilterFieldProps } from "../../../types/filterFieldProps";
import { RequestDataValue } from "../../../types/requestData";
import { SelectValue } from "../../../../select/SelectValue";
import FilterRangeType from '../../../types/filterRangeType';
import { MDBInput } from "mdb-react-ui-kit";

const FilterFieldNumberRange: FC<IFilterFieldProps> = (props) => {
    const { t } = useTranslation();
    const options = useMemo(() => {
        const result: SelectValue[] = [];
        FilterRangeType.Items().forEach((item) => {
            result.push({
                value: item.id,
                label: t(item.name)
            });
        });
        return result;
    }, []);

    const getCurrentValue = () => {
        return props.data ? props.data[0] : {
            value: '',
            label: ''
        };
    }


    var type = props.data && props.data.length > 0 ? options.find(x => props.data && x.value === props.data[0].type) : options[0];
    if (!type) {
        type = options[0];
    }
    const value = props.data && props.data.length > 0 ? props.data[0].value : '';
    const from = props.data && props.data.length > 0 ? props.data[0].from : '';
    const to = props.data && props.data.length > 0 ? props.data[0].to : '';

    const onChange = (data: RequestDataValue) => {
        data.label = undefined;
        if (data.type === FilterRangeType.Range) {
            data.value = '';
            if (data.from && data.to) {
                data.label = `${data.from} - ${data.to}`;
            }
            else {
                if (data.from) {
                    data.label = `> ${data.from}`;
                }
                if (data.to) {
                    data.label = `< ${data.to}`;
                }
            }
        }
        if (data.type !== FilterRangeType.Range) {
            data.from = undefined;
            data.to = undefined;
            if (data.value.length > 0) {
                switch (data.type) {
                    case FilterRangeType.GreaterThan:
                        data.label = `> ${data.value}`;
                        break;
                    case FilterRangeType.LessThan:
                        data.label = `< ${data.value}`;
                        break;
                    default:
                        data.label = `${data.value}`;
                        break;
                }
            }
        }
        props.onChange([data], props.filterItem);
    }

    const onChangeType = (selected: SingleValue<SelectValue>) => {
        const data = getCurrentValue();
        data.type = selected?.value;
        onChange(data);
    }

    const onChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
        const data = getCurrentValue();
        data.type = type?.value;
        data.value = e.target.value;
        onChange(data);
    }

    const onChangeFrom = (e: React.ChangeEvent<HTMLInputElement>) => {
        const data = getCurrentValue();
        data.from = e.target.value;
        onChange(data);
    }

    const onChangeTo = (e: React.ChangeEvent<HTMLInputElement>) => {
        const data = getCurrentValue();
        data.to = e.target.value;
        onChange(data);
    }

    return (
        <div className="filter-range">
            <label className="active">{t(props.filterItem.Title)}</label>
            <div className="row">
                <div className="col-7 col-xl-4 mb-2 mb-xl-0">
                    <Select value={type} onChange={onChangeType} options={options} />
                </div>
                {type.value === FilterRangeType.Range &&
                    <>
                        <div className="col-6 col-xl-4">
                            <MDBInput type="number" className="form-control mb-0" onChange={onChangeFrom} value={from} label={t('filter:from')} />
                        </div>
                        <div className="col-6 col-xl-4">
                            <MDBInput type="number" className="form-control mb-0" onChange={onChangeTo} value={to} label={t('filter:to')} />
                        </div>
                    </>
                }
                {type.value !== FilterRangeType.Range &&
                    <div className="col-12 col-xl-8">
                        <MDBInput type="number" className="form-control mb-0" name={props.filterItem.Name} id={`f-${props.filterItem.Name}`} onChange={onChangeValue} value={value} />
                    </div>
                }
            </div>
        </div>
    );
}//<MDBInput type="text" value={props.data && props.data.length > 0 ? props.data[0].value : ''} onChange={onChangeValue} label={t(filterItem.Title)} />

export default FilterFieldNumberRange;
