import { useContext, useMemo, useState } from "react";
import useTranslation from "../../../hooks/translation.hook";
import { Link } from "react-router-dom";
import PageTitle from '../../../component/pageTitle';
import Filter from '../../../component/filter';
import MainContainer from "../../../component/mainContainer";
import { UserContext } from "../../../context/user-context";
import { CenterRoutesPath } from '../routes';
import Rules from '../../../rules';
import FilterItemText from '../../../component/filter/types/filterItem/items/text';
import FilterItemSelect from '../../../component/filter/types/filterItem/items/select';
import { useOptions } from '../../../hooks/select.options.hook';
import { CenterTypeEnum } from '../../../eenApi/center/enum/center.type.enum';
import { BoolEnum } from '../../../enums/bool.enum';
import { CenterTable } from './table';

interface IProps {
    type?: string
}

export function CenterList({ type }: IProps) {
    const context = useContext(UserContext);
    const { t, i18n } = useTranslation();
    const [filterValues, setFilterValues] = useState<{ [key: string]: any }>();
    const typeOptions = useOptions(CenterTypeEnum, 'center:type.');
    const activeOptions = useOptions(BoolEnum, 'main:');

    const filterItems = useMemo(() => {
        const items = [
            new FilterItemText('name', t('center:table.name'), {isDefault: "Y"})
        ]

        if (!type) {
            items.push(new FilterItemSelect('type', t('center:field.type'), { options: typeOptions, isDefault: "Y", multiple: false }))
            items.push(new FilterItemSelect('active', t('center:field.active'), { options: activeOptions, isDefault: "Y", multiple: false }))
        }

        return items;
    }, [i18n.language, type]);

    return (
        <MainContainer>
            <PageTitle text={t(`center:title.list.${type ?? 'admin'}`)} backLink="/" />
            <div className="d-xl-flex mb-1 mb-xl-3">
                <div className="app-filter-actions mb-2 mb-xl-0">
                    {context.checkAccess(Rules.Center.Add) &&
                        <Link className="btn btn-success me-2" to={CenterRoutesPath.Create}>{t('main:btn-add')}</Link>
                    }
                </div>
                <Filter id={`filter-center-${type}`} items={filterItems} onFind={setFilterValues} />
            </div>
            <CenterTable filterValues={filterValues} type={type}/>
        </MainContainer>
    );
}
