import ModelValidator from '../model.validator';
import NewsEditDto from '../../../eenApi/news/dto/news.edit.dto';
import NewsDto from '../../../eenApi/news/dto/news.dto';

class EditValidator extends ModelValidator<NewsEditDto, NewsDto> {
    validate(dto: NewsEditDto, tabs: string[]) {
        const erroredTabs: string[] = [];

        this.checkRequired(dto.title, erroredTabs, 'main');
        this.checkLength(dto.title, erroredTabs, 250, 'main');
        this.checkRequired(dto.status, erroredTabs, 'main');

        return erroredTabs;
    }
}

export default new EditValidator();
