import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import { useMutation, UseMutationResult, useQuery, useQueryClient } from 'react-query';
import { MDBBtn } from 'mdb-react-ui-kit';
import useTranslation from '../../../../hooks/translation.hook';
import { useCurrentUser } from '../../../../hooks/useCurrentUser';
import { useQueryParams } from '../../../../hooks/query.params.hook';
import React, { useMemo, useState } from 'react';
import { InterestDto } from '../../../../eenApi/profile/dto/interest.dto';
import { ObjectType } from '../../../../enums/objectType';
import TabItem from '../../../../component/tabs/tabItem';
import { ToastManager } from '../../../../service/toastManager';
import { ActionItem } from '../../../../models/actionItem';
import { ProfileRoutesPath } from '../../routes';
import MainContainer from '../../../../component/mainContainer';
import PageTitle from '../../../../component/pageTitle';
import TopActionsContainer from '../../../../component/actions/topActionsContainer';
import TopActions from '../../../../component/actions/topActions';
import ValidationContext from '../../../../hooks/UseValidation/validation.context';
import TabControls from '../../../../component/tabs/tabControls';
import TabPanel from '../../../../component/tabs/tabPanel';
import Loader from '../../../../component/loader';
import ProfileService from '../../../../eenApi/profile/service';
import BaseModal from '../../../../component/modal/baseModal';
import ModalHeader from '../../../../component/modal/modalHeader';
import BottomActions from '../../../../component/actions/bottomActions';
import BottomActionsContainer from '../../../../component/actions/bottomActionsContainer';
import InterestEditTab from '../../../../component/profile/InterestEditTab';
import ProfileDto from '../../../../eenApi/profile/dto/profile.dto';
import { ProfileInterestValidator } from '../../../../utils/validators/profile/edit.interest.validator';
import { AccessActionEnum } from '../../../../models/access.dto';

export default function InterestEdit() {
    const { t, i18n } = useTranslation();
    const history = useHistory();
    const queryClient = useQueryClient();
    const currentUser = useCurrentUser();

    const { id } = useParams<{ id: string }>();
    const { profileId, language } = useQueryParams<{ profileId: string; language: string }>();
    const clientId = useMemo(() => Number(currentUser.data?.rules?.find((x) => x.objectType === ObjectType.Client)?.objectId), []);

    const [validateOn, setValidateOn] = useState(false);
    const [tabs, setTabs] = useState<string[]>([]);

    const { data: entity } = useQuery<InterestDto>(['profile', id, null, 'interest/'], {
        onSuccess: async (data) => {
            setData(new InterestDto(clientId, data, profileId, language ?? i18n.language));
            setActiveTab('main');
        },
        enabled: !!id,
    });
    const { data: profile } = useQuery<ProfileDto>(['profile', profileId, language ?? currentUser.data?.language], {
        onSuccess: (data) => {
            setData((prev) => ({ ...prev, profileClientId: data.client?.id }));
        },
        enabled: !!profileId,
    });
    const [data, setData] = useState<InterestDto>(new InterestDto(clientId, entity, profileId, language ?? i18n.language));

    const [activeTab, setActiveTab] = useState(entity?.id || !id ? 'main' : 'loading');
    const tabItems = useMemo(() => [new TabItem('main', t('profile:tabs.mainInterest'))], [i18n.language]);
    let currentTab = '';
    let showDetails = true;

    const mutation = useMutation(() => ProfileService.saveInterest(data!), {
        onSuccess: async (result) => {
            ToastManager.success({ title: 'main:msg-success', message: 'main:msg-changes-saved' });
            await queryClient.invalidateQueries(['profile', id, null, 'interest/']);
            if (showDetails) {
                history.replace(ProfileRoutesPath.InterestView.replace(':id', String(result)));
            } else {
                history.replace(ProfileRoutesPath.InterestEdit.replace(':id', String(result)));
                setActiveTab(currentTab);
            }
        },
        onSettled: (data, error) => {
            if (error) {
                setActiveTab(currentTab);
            }
        },
    });
    const deleteMutation = useMutation(() => ProfileService.removeInterest(Number(id)), {
        onSuccess: async () => {
            ToastManager.success({ title: 'main:msg-success', message: 'main:msg-entity-deleted' });
            await queryClient.removeQueries(['interest', id]);
            history.push(ProfileRoutesPath.InterestList);
        },
        onSettled: () => {
            setActiveTab(currentTab);
        },
    });

    const save = (stay: boolean) => {
        setValidateOn(true);
        showDetails = !stay;
        window.scrollTo(0, 0);
        const erroredTabs = ProfileInterestValidator.validate(data!, tabs, entity!);
        setTabs(erroredTabs);
        if (erroredTabs.length == 0) {
            currentTab = activeTab;
            setActiveTab('loading');
            mutation.mutate();
        }
    };

    const actions = (() => {
        const items: ActionItem[] = [
            { name: t('main:btn-save'), onClick: () => save(false) },
            { name: t('main:btn-apply'), onClick: () => save(true) },
        ];

        if (entity?.accessDto[AccessActionEnum.Delete]) {
            items.push({ name: t('main:btn-delete'), icon: 'far fa-trash-alt', onClick: () => setIsDeleteOpen(true) });
        }

        items.push({
            name: t('main:btn-cancel'),
            onClick: !profileId
                ? async () => {
                      if (id) {
                          await queryClient.invalidateQueries(['interest', id]);
                          history.push(ProfileRoutesPath.InterestView.replace(':id', id));
                      } else {
                          history.push(ProfileRoutesPath.InterestList);
                      }
                  }
                : async () => {
                      await queryClient.invalidateQueries(['profile', profileId, language ?? currentUser.data?.language]);
                      history.push(`${ProfileRoutesPath.View.replace(':id', id)}?language=${language}`);
                  },
        });

        return items;
    })();

    const updateData = (field: keyof InterestDto, value: any) => {
        let newValue = value?.value ?? value;
        setData((prev) => ({ ...prev!, [field]: Array.isArray(newValue) ? [...newValue] : newValue }));
    };

    const [isDeleteOpen, setIsDeleteOpen] = useState(false);
    const closeDelete = () => setIsDeleteOpen(false);
    const executeModalAction = (mutation: UseMutationResult<any, unknown, void>) => {
        closeDelete();
        window.scrollTo(0, 0);
        currentTab = activeTab;
        setActiveTab('loading');
        mutation.mutate();
    };

    return (
        <MainContainer>
            <PageTitle text={t(`profile:title.interest${entity?.id ? 'Edit' : 'Create'}`)} backLink={ProfileRoutesPath.InterestList} />
            <TopActionsContainer>
                <TopActions actions={actions} />
            </TopActionsContainer>
            <ValidationContext.Provider value={{ validateOn, tabs, setTabs }}>
                <TabControls selectedId={activeTab} items={tabItems} onChange={setActiveTab} errored={validateOn ? tabs : []} />
                <TabPanel activeId={activeTab} id="loading">
                    <Loader />
                </TabPanel>
                {(data?.id || !id) && (
                    <>
                        <InterestEditTab
                            activeId={activeTab}
                            id="main"
                            entity={entity}
                            data={data}
                            updateData={updateData}
                            profile={profile}
                            clientId={clientId}
                        />
                        <BottomActionsContainer>
                            <BottomActions actions={actions} />
                        </BottomActionsContainer>
                    </>
                )}
            </ValidationContext.Provider>
            <BaseModal isOpen={isDeleteOpen} onRequestClose={closeDelete} modalSize="s">
                <ModalHeader title={t('main:msg-entity-delete-confirm')} onRequestClose={closeDelete} />
                <div className="text-center p-3">
                    <MDBBtn onClick={() => executeModalAction(deleteMutation)}>{t('main:yes')}</MDBBtn>
                </div>
            </BaseModal>
        </MainContainer>
    );
}
