import React, { FC, useMemo } from 'react';
import { Link, useHistory } from 'react-router-dom';
import useTranslation from '../../../../hooks/translation.hook';
import { SupportRoutesPath } from '../../routes';
import SupportDto from '../../../../eenApi/support/supports/dto/support.dto';
import SupportService from '../../../../eenApi/support/service';
import { UserRoutesPath } from '../../../user/routes';
import { formatDate } from '../../../../utils/date.utils';
import styled from '@emotion/styled';
import SupportCriticalityEnum from '../../../../eenApi/support/supports/enum/support.criticality.enum';
import SupportEditDto from '../../../../eenApi/support/supports/dto/support.edit.dto';
import { GreenDot, RedDot, YellowDot } from '../../../../component/common/ui/Dots';
import { TableColumn } from '../../../../component/common/layout/DataTable/table.model';
import { GridSelectionModel } from '@mui/x-data-grid';
import { useGroupDeleteAction, useRowActions } from '../../../../component/common/layout/DataTable/table.utils';
import { DataTable } from '../../../../component/common/layout';
import { EntityQueryKey } from '../../../../query/query.keys';

const StyledLink = styled(Link)`
    font-weight: 600;
`;

interface IProps {
    filterValues?: { [key: string]: any };
}

const SupportTable: FC<IProps> = (props) => {
    const history = useHistory();
    const { t, i18n } = useTranslation();

    const columns = useMemo<TableColumn<SupportDto>[]>(
        () => [
            new TableColumn({
                headerName: t('support:supports.fields.fullName'),
                field: 'user',
                renderCell: ({ value }) => <Link to={`${UserRoutesPath.View.replace(':id', value.id.toString())}`}>{value.fullName}</Link>,
            }),
            new TableColumn({
                headerName: t('support:supports.fields.title'),
                field: 'title',
                renderCell: ({ id, value, row }) =>
                    row.isViewed ? (
                        <Link to={`${SupportRoutesPath.View.replace(':id', id.toString())}`}>{value}</Link>
                    ) : (
                        <StyledLink to={`${SupportRoutesPath.View.replace(':id', id.toString())}`}>{value}</StyledLink>
                    ),
            }),
            new TableColumn({
                headerName: t('support:supports.fields.status.title'),
                field: 'status',
                width: 120,
                valueFormatter: ({ value }) => t(`support:supports.fields.status.${value}`),
            }),
            new TableColumn({
                headerName: t('support:supports.fields.criticality.title'),
                field: 'criticality',
                width: 100,
                renderCell: ({ value }) =>
                    value === SupportCriticalityEnum.Low ? <GreenDot /> : value === SupportCriticalityEnum.Medium ? <YellowDot /> : <RedDot />,
            }),
            new TableColumn({
                headerName: t('support:supports.fields.created'),
                field: 'created',
                width: 150,
                valueFormatter: ({ value }) => formatDate(value),
            }),
            new TableColumn({
                headerName: t('support:supports.fields.modified'),
                field: 'modified',
                width: 150,
                valueFormatter: ({ value }) => formatDate(value),
            }),
            new TableColumn({
                headerName: t('support:supports.fields.supportSubject-name'),
                field: 'supportSubject',
                valueGetter: ({ value }) => value.name,
            }),
        ],
        [i18n.language],
    );

    const clickDelete = (ids: GridSelectionModel) => SupportService.delete(ids.map((id) => Number(id)));
    const clickClose = async (item: SupportDto) => {
        await SupportService.makeClosed(new SupportEditDto(item!));
        history.push(SupportRoutesPath.View.replace(':id', item.id!.toString()));
    };

    const rowActions = useRowActions<SupportDto>(
        {
            edit: (row) => SupportRoutesPath.Edit.replace(':id', row.id!.toString()),
            remove: (row) => clickDelete([row.id!]),
        },
        useMemo(
            () => ([{
                id: 'close',
                label: t('main:btn-close'),
                action: (item: SupportDto) => clickClose(item),
            }]),
            [i18n.language],
        ),
    );

    const groupActions = useGroupDeleteAction({ remove: clickDelete });

    return (
        <DataTable
            id="supports-table-component"
            queryKey={EntityQueryKey.Support}
            filterValues={props.filterValues}
            columns={columns}
            fetchData={(requestData) => SupportService.list(requestData)}
            rowActions={rowActions}
            groupActions={groupActions}
        />
    );
};

export default SupportTable;
