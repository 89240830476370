import styled from '@emotion/styled';

export const GreenDot = styled.span`
  &:before {
    content: "";
    display: block;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background: #82c250;
  }

`;
export const YellowDot = styled.span`
  &:before {
    content: "";
    display: block;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background: #FE0;
  }
`;
export const RedDot = styled.span`
  &:before {
    content: "";
    display: block;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background: #e74c3c;
  }
`;