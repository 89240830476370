import { useMemo, useState } from 'react';
import useTranslation from '../../../../../hooks/translation.hook';
import MainContainer from '../../../../../component/mainContainer';
import PageTitle from '../../../../../component/pageTitle';
import { UnsubscribeMailingTable } from './table';
import FilterItemText from '../../../../../component/filter/types/filterItem/items/text';
import Filter from '../../../../../component/filter';

export function UnsubscribeMailingList() {
    const { t, i18n } = useTranslation();
    const [filterValues, setFilterValues] = useState<{ [key: string]: any }>();

    const filterItems = useMemo(() => {
        const items = [
            new FilterItemText('id', t('mailing:filter.id'), {
                isDefault: 'Y',
            }),
            new FilterItemText('email', t('mailing:filter.email'), {
                isDefault: 'Y',
            }),
        ];
        return items;
    }, [i18n.language]);

    return (
        <MainContainer>
            <PageTitle text={t(`mailing:unsubscribe.title`)} backLink="/" />

            <Filter
                id="filter-mailing"
                items={filterItems}
                onFind={setFilterValues}
                query={{}}
            />

            <UnsubscribeMailingTable filterValues={{ ...filterValues }} />
        </MainContainer>
    );
}
