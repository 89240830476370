import React, { useContext, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { CenterService } from '../../../eenApi/center/service';
import useTranslation from '../../../hooks/translation.hook';
import { CenterDto } from '../../../eenApi/center/dto/center.dto';
import { UserContext } from '../../../context/user-context';
import Rules from '../../../rules';
import { MemberCountryRoutesPath } from '../../membercountry/routes';
import { TableColumn } from '../../../component/common/layout/DataTable/table.model';
import { DataTable } from '../../../component/common/layout';
import { EntityQueryKey } from '../../../query/query.keys';
import { useGroupDeleteAction, useRowActions } from '../../../component/common/layout/DataTable/table.utils';
import { GridSelectionModel } from '@mui/x-data-grid';

interface IProps {
    filterValues?: { [key: string]: any };
    type?: string;
}

export function CenterTable(props: IProps) {
    const { t, i18n } = useTranslation();
    const { checkAccess } = useContext(UserContext);

    const columns = useMemo(() => {
        const items: TableColumn<CenterDto>[] = [
            new TableColumn({
                headerName: t('center:field.acronym'),
                field: 'acronym',
                width: 100,
            }),
            new TableColumn({
                headerName: t('center:field.name'),
                field: 'name',
                renderCell: (param) =>
                    checkAccess(Rules.Center.View) || checkAccess(Rules.Center.ViewOwn, [String(param.id)]) ? (
                        <Link to={`${CenterService.getDetailsRoute(param.id!)}`}>{param.value}</Link>
                    ) : (
                        param.value
                    ),
            }),
            new TableColumn({
                headerName: t('center:field.fullName'),
                field: 'fullName',
            }),
            new TableColumn({
                headerName: t('center:field.memberCountryId'),
                field: 'memberCountryId',
                renderCell: ({ row }) =>
                    checkAccess(Rules.MemberCountry.View) ? (
                        <Link to={`${MemberCountryRoutesPath.View.replace(':id', String(row.memberCountry?.id))}`}>{row.memberCountry?.name}</Link>
                    ) : (
                        row.memberCountry?.name
                    ),
            }),
        ];

        if (!props.type) {
            items.push(
                new TableColumn({
                    headerName: t('center:field.type'),
                    field: 'type',
                    valueFormatter: ({ value }) => t(`center:type.${value}`),
                }),
            );
            items.push(
                new TableColumn({
                    headerName: t('center:field.active'),
                    field: 'active',
                    valueFormatter: ({ value }) => t(`main:${value ? 'yes' : 'no'}`),
                }),
            );
        } else {
            items.push(
                new TableColumn({
                    headerName: t('center:field.city'),
                    field: 'city',
                }),
            );
        }

        return items;
    }, [i18n.language, props.type]);

    const clickDelete = (ids: GridSelectionModel) => CenterService.remove(ids.map((x) => Number(x)));

    const rowActions = useRowActions<CenterDto>({
        edit: checkAccess(Rules.Center.Edit) || checkAccess(Rules.Center.EditOwn) ? row => CenterService.getEditRoute(row.id!) : null,
        hideEdit: row => !checkAccess(Rules.Center.Edit) && !checkAccess(Rules.Center.EditOwn, [String(row.id)]),
        remove: checkAccess(Rules.Center.Delete) || checkAccess(Rules.Center.DeleteOwn) ? row => clickDelete([row.id!]) : null,
        hideRemove: row => !checkAccess(Rules.Center.Delete) && !checkAccess(Rules.Center.DeleteOwn, [String(row.id)]),
    });
    const groupActions = useGroupDeleteAction({
        remove: checkAccess(Rules.Center.Delete) || checkAccess(Rules.Center.DeleteOwn) ? clickDelete : null,
    });

    return (
        <DataTable
            id={`${props.type}center-table-component`}
            queryKey={[EntityQueryKey.Center, props.type]}
            filterValues={props.filterValues}
            columns={columns}
            fetchData={(requestData) => CenterService.list(requestData, props.type)}
            rowActions={rowActions}
            groupActions={groupActions}
        />
    );
}
