export enum ObjectType {
    Client = 'client',
    Center = 'center',
    DriveFolder = 'drivefolder',
    DriveFile = 'drivefile',
    SuccessStory = 'success-story',
    Profile = 'profile',
    Specialist = 'specialist',
    News = 'news'
}
