import { HtmlString, LongString } from '../../../../types/string.types';
import React, { PropsWithChildren } from 'react';
import useTranslation from '../../../../hooks/translation.hook';
import { ValidationComment } from '../../ui/ValidationComment';
import { ObjectType } from '../../../../enums/objectType';

interface IProps {
    labelKey: string;
    value?: string | LongString | HtmlString | string[] | boolean;
    html?: boolean;
    optional?: boolean;
    isValidate?: boolean;
    objectId?: number;
    objectType?: ObjectType;
    fieldName?: string;
    editable?: boolean;
    className?: string;
}

interface IPropsView extends IProps {
    isValidate?: false;
}

interface IPropsValidation extends IProps {
    isValidate: true;
    objectId: number;
    objectType: ObjectType;
    fieldName: string;
    editable: boolean;
}

export default function Field(props: PropsWithChildren<IPropsValidation | IPropsView>) {
    const { t } = useTranslation();

    // Кому показалось хорошей идеей использовать строки вместо булевых значений, как в битриксе?!
    const boolLike = props.value === 'Y' ? true : props.value === 'N' ? false : props.value;

    const value = typeof boolLike === 'boolean' ? t(`main:${boolLike ? 'yes' : 'no'}`) : boolLike;

    return props.optional && !props.value && !props.children ? (
        <></>
    ) : (
        <>
            <div className="field-view mb-2 d-flex w-100 flex-column flex-md-row">
                <div className="w-35 pe-2 flex-lg-grow-0 flex-shrink-0">
                    <strong>{t(props.labelKey)}</strong>
                </div>
                {value && (
                    <>
                        <div>
                            {Array.isArray(value) ? (
                                <ul>
                                    {value.map((v, idx) => (props.html ? <li key={idx} dangerouslySetInnerHTML={{ __html: v }} /> : <li key={idx}>{v}</li>))}
                                </ul>
                            ) : props.html ? (
                                <span dangerouslySetInnerHTML={{ __html: value }} />
                            ) : (
                                value
                            )}
                        </div>
                    </>
                )}
                {props.children}
            </div>
            {props.isValidate && (
                <ValidationComment objectId={props.objectId} objectType={props.objectType} fieldName={props.fieldName!} editable={props.editable} />
            )}
        </>
    );
}
