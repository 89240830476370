import { InterestStatusEnum } from '../enum/interest.status.enum';
import { Client } from '../../client/client';
import IdNameItemDto from '../../common/id.name.item.dto';
import { Center } from '../../center/center';
import { AccessDto } from "../../../models/access.dto";

export class InterestDto extends AccessDto {
    id?: number;
    profileInterestId?: number;
    profileId: number|null = null;
    language?: string;
    clientId: number|null = null;
    profileClientId?: number;
    clientName?: string;
    cooperationKind: string = '';
    missingInformation: string = '';
    status: InterestStatusEnum = InterestStatusEnum.Awaiting;
    client?: Client;
    profile?: IdNameItemDto
    title?: string;
    interestStatus?: InterestStatusEnum;
    center?: Center
    createdBy?: string

    constructor(clientId: number|undefined, entity?: InterestDto, profileId?: string, language?: string) {
        super();
        Object.entries(entity ?? {}).forEach(([key, val]) => {
            if (this.hasOwnProperty(key)) {
                this[key as keyof InterestDto] = val as never
            }
        });

        if (profileId) {
            this.profileId = Number(profileId);
        } else {
            this.profileId = entity?.profile?.id ?? null
        }
        this.language = language;
        this.clientId = entity?.client?.id ?? clientId ?? null
    }
}