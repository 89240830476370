import TabControl from './tabControl';
import TabItem from './tabItem';
import classes from './tabControls.module.css';
import LanguageEnum from '../../enums/language.enum';
import LangSwitch from './LangSwitch';
import AddLang from './AddLang';

interface IProps {
    children?: React.ReactNode;
    selectedId: string,
    items: TabItem[],
    onChange: (id: string, lang?: LanguageEnum) => void,
    errored?: string[]
    addLang?: boolean
    link?: string
    language?: string[]
}

export default function TabControls(props: IProps) {
    return (
        <div className='tab-controls pb-2 position-relative'>
            {props.items.map(item => {
                if (item.lang) {
                    return (
                        <div key={item.id} className={classes.langContainer}>
                            {props.language ?
                                props.language![0] === 'ru' ? <>
                                        <AddLang link={props.link!} />
                                        <LangSwitch id={item.id}
                                                    selected={item.lang}
                                                    lang={LanguageEnum.EN}
                                                    onChange={props.onChange} />
                                    </> :
                                    <>
                                        <AddLang link={props.link!} />
                                        <LangSwitch id={item.id}
                                                    selected={item.lang}
                                                    lang={LanguageEnum.RU}
                                                    onChange={props.onChange} />

                                    </>
                                :
                                <>
                                    <LangSwitch id={item.id}
                                                selected={item.lang}
                                                lang={LanguageEnum.RU}
                                                onChange={props.onChange} />
                                    <LangSwitch id={item.id}
                                                selected={item.lang}
                                                lang={LanguageEnum.EN}
                                                onChange={props.onChange} />
                                </>
                            }
                        </div>
                    );
                } else {
                    return (
                        <TabControl key={item.id} id={item.id}
                                    active={props.selectedId == item.id}
                                    text={item.text}
                                    onChange={props.onChange}
                                    error={props.errored?.includes(item.id)} />
                    );
                }
            })}
        </div>
    );
}