import { MDBBtn, MDBInput } from 'mdb-react-ui-kit';
import React, { FC, useEffect, useState } from 'react';
import useTranslation from '../../../../hooks/translation.hook';
import InputContainer from '../../../../component/inputContainer';
import Loader from '../../../../component/loader';
import BaseModal from '../../../../component/modal/baseModal';
import ModalHeader from '../../../../component/modal/modalHeader';
import { DriveEntity } from '../../../../eenApi/drive/driveEntity';
import { DriveService } from '../../../../eenApi/drive/service';
import { ErrorHandler } from '../../../../service/errorHandler';

interface IProps {
    entity?: DriveEntity;
    onClose: () => void;
}

const DownloadEntityModal: FC<IProps> = ({ entity, onClose }) => {
    const { t } = useTranslation();
    const isModalOpen = !!entity;
    const [isLoading, setIsLoading] = useState(false);
    const [downloadLink, setDownloadLink] = useState('');

    useEffect(() => {
        if (!entity) {
            setDownloadLink('');
            return;
        }
        loadData();
    }, [entity]);

    const loadData = async () => {
        try {
            if (!entity) return;

            setIsLoading(true);
            const info = await DriveService.getFileInfo(entity.id);
            setDownloadLink(DriveService.getDownloadLink(info.token));
        } catch (err) {
            ErrorHandler.handle('download drive modal', err);
        } finally {
            setIsLoading(false);
        }
    };

    const clickCopyLink = () => {
        navigator.clipboard.writeText(downloadLink);
    };
    const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => e.target.select();
    return (
        <BaseModal centered isOpen={isModalOpen} onRequestClose={onClose} modalSize="md">
            <ModalHeader title={t('drive:download-modal-title')} onRequestClose={onClose} />
            <div className="modal-body py-2">
                {isLoading && <Loader />}
                {!isLoading && entity && (
                    <>
                        <InputContainer inModal>
                            <MDBInput autoFocus type="text" value={downloadLink} readonly onFocus={handleFocus} />
                        </InputContainer>
                        <div>
                            <a className="btn btn-primary me-2" href={downloadLink}>
                                {t('main:btn-download')}
                            </a>
                            <MDBBtn color="link" onClick={clickCopyLink}>
                                {t('main:btn-copy')}
                            </MDBBtn>
                        </div>
                    </>
                )}
            </div>
        </BaseModal>
    );
};

export default DownloadEntityModal;
