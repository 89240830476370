import { EenApi } from '..';
import { DictionaryEntity } from '../../models/dictionaryEntity';
import { DictionaryType } from '../../models/dictionaryType';

class CDictionaryService {
    cacheDelay = 300;
    cacheAll: { [key: string]: DictionaryEntity[] | 'loading' };

    constructor() {
        this.cacheAll = {};
    }

    private static getCacheKey(type: DictionaryType, language: string) {
        return `${type}_${language}`;
    }

    async getByCodes(type: DictionaryType, language: string, codes: string[]): Promise<DictionaryEntity[]> {
        try {
            const cacheKey = CDictionaryService.getCacheKey(type, language);

            const cachedValue = this.cacheAll[cacheKey];
            if (cachedValue === 'loading') {
                const all = await this.getFromCache(cacheKey);
                return all.filter(x => codes.includes(x.code));
            }

            if (Array.isArray(cachedValue)) {
                return cachedValue.filter(x => codes.includes(x.code));
            }

            let result;
            switch (type) {
                case DictionaryType.ProfileOrigin:
                case DictionaryType.SectoralGroup:
                case DictionaryType.Country:
                case DictionaryType.FederalDistrict:
                case DictionaryType.SubjectRF:
                case DictionaryType.ClientType:
                case DictionaryType.FrameworkProgramme:
                case DictionaryType.Language:
                case DictionaryType.CertificationStandart:
                case DictionaryType.AnnualTurnover:
                case DictionaryType.KeyWords:
                    result = { items: await CDictionaryService.getAllFromServer(type, language) };
                    break;
                case DictionaryType.MemberCountry:
                case DictionaryType.InnovationProject:
                    result = { items: await this.getByIds(type, language, codes) ?? [] };
                    break;
                default:
                    result = await this.getByFilter(type, language, { code: codes });
            }

            if (result.items.length < 1) {
                return [];
            }

            return result.items.filter(x => codes.includes(x.code));
        } catch (err) {
            console.error('DictionaryService getByCodes', err, type, language);
        }
        return [];
    }

    async getById(type: DictionaryType, language: string, id: string|number) {
        const result = await this.getByIds(type, language, [id]);
        return (result ?? [])[0]
    }
    async getByIds(type: DictionaryType, language: string, id: (string|number)[]) {
        if (!id) return undefined;

        try {
            const cacheKey = CDictionaryService.getCacheKey(type, language);

            const cachedValue = this.cacheAll[cacheKey];
            if (cachedValue === 'loading') {
                const all = await this.getFromCache(cacheKey);
                return all.filter(x => id.includes(x.id ?? ''));
            }

            if (Array.isArray(cachedValue)) {
                return cachedValue.filter(x => id.includes(x.id ?? ''));
            }

            const items = (await EenApi.post<{ items: DictionaryEntity[] }>(`${type}/list`, { filter: { id }, language })).items;
            return items?.map((item) => ({
                ...item,
                code: String(item.id)
            })) ?? []
        } catch (err) {
            console.error('DictionaryService getByCodes', err, type, language);
        }
        return undefined;
    }

    async getByCode(type: DictionaryType, language: string, code: string) {
        const result = await this.getByCodes(type, language, [code]);
        if (result.length < 1) {
            return undefined;
        }
        return result[0];
    }

    async getAll(type: DictionaryType, language: string): Promise<DictionaryEntity[]> {
        const cacheKey = CDictionaryService.getCacheKey(type, language);

        if (this.cacheAll[cacheKey] === 'loading') {
            return await this.getFromCache(cacheKey);
        }

        if (Array.isArray(this.cacheAll[cacheKey])) {
            return this.cacheAll[cacheKey] as DictionaryEntity[];
        }
        this.cacheAll[cacheKey] = 'loading';
        const result = await CDictionaryService.getAllFromServer(type, language);
        this.cacheAll[cacheKey] = result;

        return result;
    }

    private static async getAllFromServer(type: DictionaryType, language: string) {
        return await EenApi.get<DictionaryEntity[]>(`${type}/all?language=${language}`);
    }

    async getByFilter(type: DictionaryType, language: string,
                      requestData: { code?: string[]; name?: string },
                      skip?: number) {
        switch (type) {
            case DictionaryType.SectoralGroup:
            case DictionaryType.ProfileOrigin:
            case DictionaryType.Country:
            case DictionaryType.FederalDistrict:
            case DictionaryType.SubjectRF:
            case DictionaryType.ClientType:
            case DictionaryType.FrameworkProgramme:
            case DictionaryType.Language:
            case DictionaryType.CertificationStandart:
            case DictionaryType.AnnualTurnover:
            case DictionaryType.KeyWords:
                let items = await this.getAll(type, language);
                items = items.filter(item => (item.name ?? item.code).toLowerCase().includes(requestData.name?.toLowerCase() ?? ''));
                return {
                    items,
                    total: items.length,
                };
            default:
                const result = await EenApi.list<DictionaryEntity>(`${type}/list`, {
                    filter: requestData,
                    language: language,
                    skip,
                });
                result.items?.forEach(item => {
                    item.code = item.code ?? item.id
                });
                result.total = result.total ?? result.items?.length
                return result;
        }
    }

    private async getFromCache(cacheKey: string) {
        let tryCount = 10;

        while (tryCount > 0) {
            tryCount -= 1;
            await new Promise(f => setTimeout(f, this.cacheDelay));
            if (Array.isArray(this.cacheAll[cacheKey])) {
                return this.cacheAll[cacheKey] as DictionaryEntity[];
            }
        }

        return [];
    }
}

export const DictionaryService = new CDictionaryService();
