import { FC } from "react";
import useTranslation from "../../../../hooks/translation.hook";
import { NavLink } from "react-router-dom";
import './style.css';

interface IProps {
    panels: { link: string, name: string, icon: string }[]
}

const AdminPanel: FC<IProps> = ({ panels }) => {
    const { t } = useTranslation();

    return (
        <div className="row">
            {panels.map(panel => (
                <div key={panel.link} className="col-12 col-md-6 col-xl-4 col-xxl-3 mb-2">
                    <NavLink className="default-block admin-block p-4 d-flex" to={panel.link}>
                        <div className="w-100 d-flex flex-column">
                            <i className={panel.icon}></i>
                            <span className="mx-auto mt-3">{t(panel.name)}</span>
                        </div>
                    </NavLink>
                </div>
            ))}
        </div>
    );
}

export default AdminPanel;
