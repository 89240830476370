import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import MainContainer from '../../../component/mainContainer';
import PageTitle from '../../../component/pageTitle';
import Loader from '../../../component/loader';
import BottomActionsContainer from '../../../component/actions/bottomActionsContainer';
import BottomActions from '../../../component/actions/bottomActions';
import { ActionItem } from '../../../models/actionItem';
import Utils from '../../../utils';
import { useHistory, useParams } from 'react-router-dom';
import { ErrorHandler } from '../../../service/errorHandler';
import { ToastManager } from '../../../service/toastManager';
import { UserContext } from '../../../context/user-context';
import BaseModal from '../../../component/modal/baseModal';
import ModalHeader from '../../../component/modal/modalHeader';
import { MDBBtn } from 'mdb-react-ui-kit';
import { NewsService } from '../../../eenApi/news/service';
import { NewsEntity } from '../../../eenApi/news/news';
import { NewsRoutesPath } from '../routes';
import Rules from '../../../rules';
import TopActionsContainer from '../../../component/actions/topActionsContainer';
import TabItem from '../../../component/tabs/tabItem';
import { useMutation, UseMutationResult, useQuery, useQueryClient } from 'react-query';
import TabControls from '../../../component/tabs/tabControls';
import ViewTab from '../../../component/news/ViewTab';
import TopActions from '../../../component/actions/topActions';


const NewsView: FC = () => {
    const context = useContext(UserContext);
    const { id } = useParams<{ id?: string }>();
    const { t } = useTranslation();
    const history = useHistory();
    const queryClient = useQueryClient();

    const [activeTab, setActiveTab] = useState('view');
    const [isDelete, setIsDelete] = useState(false);

    let entityId = Utils.getEntityId(id);

    const tabItems = useMemo(() => {
        return [
            new TabItem('view', t('news:tabs.view')),
        ];
    }, []);

    const actions = useMemo<ActionItem[]>(() => {
        const items: ActionItem[] = [];
        context.checkAccess(Rules.News.Edit) && items.push({
            name: t('main:btn-edit'),
            icon: 'fas fa-pen',
            onClick: async () => {
                await queryClient.invalidateQueries(['news', entityId]);
                history.push(`${NewsRoutesPath.Edit.replace(':id', entityId.toString())}`);
            },
        });

        context.checkAccess(Rules.News.Validate) && items.push({
            name: t('main:btn-validate'),
            onClick: async () => {
                await queryClient.invalidateQueries(['news', entityId]);
                history.push(`${NewsRoutesPath.Validate.replace(':id', entityId.toString())}`);
            },
            icon: 'far fa-check-circle',
        });
        context.checkAccess(Rules.News.Delete) && items.push({
            name: t('main:btn-delete'),
            icon: 'far fa-trash-alt',
            onClick: () => {
                setIsDelete(true);
            },
        });


        return items;
    }, []);

    const { data, isFetching, refetch } = useQuery<NewsEntity>(['news', entityId], {
        onError: async (err) => {
            if (isNaN(entityId)) {
                history.push('*');
            }
            ErrorHandler.handle('news refreshData', err);
        },
    });

    useEffect(() => {
        refetch();
    }, [data]);

    const deleteMutation = useMutation(() => NewsService.delete([entityId]), {
        onSuccess: async () => {
            ToastManager.success({ title: 'main:msg-success', message: 'main:msg-entity-deleted' });
            history.goBack();
        },
        onError: async (err) => {
            ErrorHandler.handle('News delete', err);
        },
        onSettled: async () => {
            closeDelete();
        },
    });

    const executeModalAction = (mutation: UseMutationResult<any, unknown, void>) => {
        mutation.mutate();
    };

    const closeDelete = () => setIsDelete(false);

    return (
        <MainContainer>
            <BaseModal isOpen={isDelete} onRequestClose={closeDelete} modalSize='s'>
                <ModalHeader title={t('main:msg-entity-delete-confirm')} onRequestClose={closeDelete} />
                <div className='text-center p-3'>
                    <MDBBtn
                        onClick={() => {
                            executeModalAction(deleteMutation);
                        }}
                    >{t('main:yes')}</MDBBtn>
                </div>
            </BaseModal>
            <PageTitle text={data?.title || ''} backLink={NewsRoutesPath.CenterList} />
            <TopActionsContainer>
                <TopActions actions={actions} />
            </TopActionsContainer>
            <TabControls selectedId={activeTab} items={tabItems} onChange={setActiveTab} />
            {isFetching ?
                <Loader />
                :
                <ViewTab activeId={activeTab} id='view' data={data!} />
            }
            <BottomActionsContainer>
                <BottomActions actions={actions} />
            </BottomActionsContainer>
        </MainContainer>
    );
};

export default NewsView;
