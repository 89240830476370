import ProfileStageEnum from "../enum/profile.stage.enum";
import ProfileIpStatusEnum from "../enum/profile.ip.status.enum";
import ProfilePartnershipTypeEnum from "../enum/profile.partnership.type.enum";
import ProfileFundingSchemeEnum from "../enum/profile.funding.scheme.enum";
import ProfileDto from "./profile.dto";
import LanguageEnum from "../../../enums/language.enum";
import ProfileTypeEnum from "../enum/profile.type.enum";
import { Client } from "../../client/client";
import ProfileStatusEnum from "../enum/profile.status.enum";


export default class ProfileEditDto {
   id: number = 0;
   type?: ProfileTypeEnum;
   client?: Client;
   status?: ProfileStatusEnum;
   podNumber: string = '';
   expirationDate: string = '';
   stage: ProfileStageEnum = ProfileStageEnum.ConceptStage;
   iprStatuses: ProfileIpStatusEnum[] = [];
   profileOrigin: string|null = null;
   marketCodes: string[] = [];
   naceCodes: string[] = [];
   techCodes: string[] = [];
   sectoralGroup: string|null = null;
   countries: string[]|null = null;
   isOpenForInterest: boolean = false;
   partnerTypes: string[] = [];
   partnershipTypes: ProfilePartnershipTypeEnum[] = [];
   frameworkProgramme: string = ''
   isCoordinatorRequired: boolean = false;
   deadline: string = '';
   fundingScheme: ProfileFundingSchemeEnum|null = null;
   title: string = '';
   annotation: string = '';
   description: string = '';
   stageComment: string = '';
   iprStatusComment: string = '';
   advantages: string = '';
   partnerRole: string = '';
   technicalSpecification: string = '';
   projectTitle: string = '';
   callName: string = '';
   evaluationScheme: string = '';
   projectBudget: string = '';
   projectDuration: string = '';
   weblink: string = '';
   files: string[] = [];
   language?: string;
   keyWords: string[] = [];
   comment: string = '';
   memberCountryId: string|null = null;
   innovationProjectId: number|null = null;

   constructor(entity: ProfileDto|undefined, defaultLanguage: LanguageEnum) {
      Object.entries(entity ?? {}).forEach(([key, val]) => {
         if (this.hasOwnProperty(key)) {
            this[key as keyof ProfileEditDto] = val as never
         }
      });

      if (!this.language) {
         this.language = defaultLanguage
      }
      this.memberCountryId = entity?.memberCountry?.id?.toString() ?? null;
      this.innovationProjectId = entity?.innovationProject?.id ?? null;
   }
}