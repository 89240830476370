import { FC, useEffect, useState } from 'react';
import useTranslation from '../../../hooks/translation.hook';
import { useParams } from 'react-router-dom';
import DefaultBlock from '../../../component/defaultBlock';
import MainContainer from '../../../component/mainContainer';
import PageTitle from '../../../component/pageTitle';
import { SettingsRoutesPath } from '../routes';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useCurrentUser } from '../../../hooks/useCurrentUser';
import BottomActions from '../../../component/actions/bottomActions';
import BottomActionsContainer from '../../../component/actions/bottomActionsContainer';
import { ConfigurationService } from '../../../eenApi/configuration/service';
import { ToastManager } from '../../../service/toastManager';
import MailingConfiguraton from '../../../eenApi/configuration/mailing.setting.dto';
import SettingMailingTab from '../../../component/mailing/SettingMailing';
import MailingUnsubscribeSettingValidator from '../../../utils/validators/mailing/edit.unsubscribe.setting.validator';

const SettingsMailing: FC = () => {
    const { id } = useParams<{ id?: string }>();
    const { t } = useTranslation();
    const currentUser = useCurrentUser();
    const queryClient = useQueryClient();
    const [validateOn, setValidateOn] = useState(false);
    const [tabs, setTabs] = useState<string[]>([]);
    const { data: entity } = useQuery<MailingConfiguraton>(['mailing', currentUser.data!.language], {
        onSuccess: async (data) => {
            setData(new MailingConfiguraton(data));
        },
        enabled: !!id,
    });

    const [data, setData] = useState<MailingConfiguraton>(new MailingConfiguraton(entity));
    const [activeTab, setActiveTab] = useState(data ? 'main' : 'loading');

    const updateData = (field: keyof MailingConfiguraton, value: any) => {
        let newValue = value?.value ?? value;
        setData({ ...data!, [field]: Array.isArray(newValue) ? [...newValue] : newValue });
    };
    const actions = [
        {
            name: t('main:btn-save'),
            onClick: () => saveAction(false),
        },
        {
            name: t('main:btn-apply'),
            onClick: () => saveAction(true),
        },
    ];

    const updateMutation = useMutation((_: string) => ConfigurationService.saveMailing(data!), {
        onSuccess: async (result, tab) => {
            ToastManager.success({
                title: 'main:msg-success',
                message: 'main:msg-changes-saved',
            });
            await queryClient.resetQueries({
                predicate: (query) => query.queryKey.includes('mailing'),
            });
            setActiveTab(tab);
        },
        onSettled: (data, error, tab) => {
            if (error) {
                setActiveTab(tab);
            }
        },
    });

    const saveAction = (stay: boolean) => {
        setValidateOn(true);
        window.scrollTo(0, 0);
        const erroredTabs = MailingUnsubscribeSettingValidator.validate(data!, tabs, entity!);
        setTabs(erroredTabs);

        if (erroredTabs.length === 0) {
            updateMutation.mutate(activeTab);
            setActiveTab('loading');
        }
    };

    return (
        <MainContainer>
            <PageTitle text={t('settings:page-title-delivery')} backLink={SettingsRoutesPath.Prefix} />
            <DefaultBlock>
                <SettingMailingTab id="main" data={data} updateData={updateData} activeId={'main'} />
                <BottomActionsContainer>
                    <BottomActions actions={actions} />
                </BottomActionsContainer>
            </DefaultBlock>
        </MainContainer>
    );
};

export default SettingsMailing;
