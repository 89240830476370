class CLocalStorageService {
    getItem<T>(name: string) {
        try {
            const value = localStorage.getItem(name);
            if (!value) return null;
            return JSON.parse(value) as T;
        } catch (e) {
            console.warn("LocalStorageService getItem", name, e);
            return null;
        }
    }

    setItem(name: string, value: any) {
        try {
            localStorage.setItem(name, JSON.stringify(value));
        } catch (e) {
            console.warn("LocalStorageService setItem", name, value, e);
        }
    }
}

export const LocalStorageService = new CLocalStorageService();
