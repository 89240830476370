import { FC } from "react";
import useTranslation from "../../hooks/translation.hook";
import { Link, useHistory, useLocation } from "react-router-dom";
import { ActionItem } from "../../models/actionItem";
import ActionButton from "./actionButton";
import ModalActions from "./modalActions";

interface IProps {
    actions: ActionItem[],
    top?: boolean
}

const ActionList: FC<IProps> = (props: IProps) => {
    const { t } = useTranslation();
    const location = useLocation<any>();
    const history = useHistory();

    const clickAction = (action: ActionItem) => {
        if (action.link && action.link.length > 0) {
            history.push(action.link);
            return;
        } else if (action.onClick) {
            action.onClick()
        }
    }

    if (!props.top) {
        return (
            <div className="bottom-actions d-none d-sm-inline-block">
                {props.actions.filter(x => !x.isTop).map((x, index) => (
                    <ActionButton key={index} action={x} onClick={clickAction} />
                ))}
            </div>
        );
    }

    return (
        <>
            <Link className="btn btn-primary me-2 d-inline-block d-sm-none" to="#actions">{t('main:btn-actions')}</Link>
            <div className="top-actions d-none d-sm-inline-block">
                {props.actions.filter(x => x.isTop).map((x, index) => (
                    <ActionButton key={index} action={x} onClick={clickAction} />
                ))}
            </div>
            {location.hash === '#actions' &&
                <ModalActions actions={props.actions} onClick={clickAction} />
            }
        </>
    );
}

export default ActionList;
