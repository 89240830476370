import TabPanel from '../../tabs/tabPanel';
import { InputControl } from '../../common/ui';
import RadioControl from '../../common/ui/RadioControl';
import { useOptions } from '../../../hooks/select.options.hook';
import IEditSettingProps from '../edit.reference.props';
import useTranslation from '../../../hooks/translation.hook';
import MailingPrefTypeEnum from '../../../eenApi/mailing/enum/mailing.pref.type.enum';

export default function SettingMailingTab({ data, updateData, ...rest }: IEditSettingProps) {
    const { t } = useTranslation();

    const stageOptions = useOptions<MailingPrefTypeEnum>(MailingPrefTypeEnum, 'mailing:setting.', true);

    return (
        <TabPanel {...rest}>
            <RadioControl
                labelKey={'mailing:setting.type'}
                items={stageOptions}
                onChange={(value) => updateData('type', value)}
                value={data.type}
                tab="main"
                required
            />
            {data.type === MailingPrefTypeEnum.UniOne && (
                <>
                    <InputControl
                        labelKey={t('mailing:setting.apikey')}
                        onChange={(data) => updateData('apiKey', data)}
                        value={data.apiKey}
                        tab={'main'}
                        required
                    />
                    <InputControl labelKey={t('mailing:setting.user')} onChange={(data) => updateData('user', data)} value={data.user} tab={'main'} required />
                    <InputControl
                        labelKey={t('mailing:setting.email')}
                        onChange={(data) => updateData('email', data)}
                        value={data.email}
                        tab={'main'}
                        required
                    />
                    <InputControl
                        labelKey={t('mailing:setting.name')}
                        onChange={(data) => updateData('sender', data)}
                        value={data.sender}
                        tab={'main'}
                        required
                    />
                </>
            )}
        </TabPanel>
    );
}
