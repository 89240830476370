import PageTitle from '../../../component/pageTitle';
import TopActionsContainer from '../../../component/actions/topActionsContainer';
import TopActions from '../../../component/actions/topActions';
import React, { Suspense, useMemo } from 'react';
import MainContainer from '../../../component/mainContainer';
import { useMutation } from 'react-query';
import { ToastManager } from '../../../service/toastManager';
import TabItem from '../../../component/tabs/tabItem';
import BottomActions from '../../../component/actions/bottomActions';
import BottomActionsContainer from '../../../component/actions/bottomActionsContainer';
import TabControls from '../../../component/tabs/tabControls';
import MainEditTab from '../../../component/innovationproject/MainEditTab';
import ValidationContext from '../../../hooks/UseValidation/validation.context';
import Loader from '../../../component/loader';
import { ActionItem } from '../../../models/actionItem';
import Rules from '../../../rules';
import { InnovationProjectDto } from '../../../eenApi/innovationproject/dto/innovation.project.dto';
import { InnovationProjectRoutesPath } from '../routes';
import { InnovationProjectEditValidator } from '../../../utils/validators/innovationproject/edit.validator';
import { InnovationProjectService } from '../../../eenApi/innovationproject/service';
import { InnovationProjectStatusEnum } from '../../../eenApi/innovationproject/enum/innovation.project.status.enum';
import LanguageEnum from '../../../enums/language.enum';
import { useCurrentUser } from '../../../hooks/useCurrentUser';
import { LoaderTab } from '../../../component/common/layout/LoaderTab';
import { DeleteModal } from '../../../component/common/ui/DeleteModal';
import { useEditPageWithDelete } from '../../../hooks/UseEditPage';

const PartnersEditTab = React.lazy(() => import('../../../component/innovationproject/PartnersEditTab'));

export function InnovationProjectEdit() {
    const { data: currentUser } = useCurrentUser();
    const {
        t,
        i18n,
        id,
        queryClient,
        isFetching,
        data,
        entity,
        activeTab,
        setActiveTab,
        updateData,
        validateOn,
        tabs,
        setTabs,
        saveActions,
        isDeleteOpen,
        closeDelete,
        remove,
        checkAccess,
    } = useEditPageWithDelete<InnovationProjectDto>({
        dataFactory: (entity, language) => new InnovationProjectDto(entity, language as LanguageEnum, currentUser?.rules),
        queryKey: 'innovationproject',
        handler: (data) => InnovationProjectService.save(data),
        validator: InnovationProjectEditValidator,
        applyRoute: InnovationProjectService.getEditRoute,
        saveRoute: InnovationProjectService.getDetailsRoute,
        cancelRoute: [InnovationProjectRoutesPath.List, InnovationProjectService.getDetailsRoute],
        canDeleteUser: {
            userId: 'createdBy',
            rules: Rules.InnovationProject,
        },
        deleteHandler: (id) => InnovationProjectService.remove(id),
    });

    const tabItems = useMemo(() => {
        return [new TabItem('main', t('innovationproject:tab.main')), new TabItem('partners', t('innovationproject:tab.partners'))];
    }, [i18n.language]);

    const publishMutation = useMutation((status: InnovationProjectStatusEnum) => InnovationProjectService.updateStatus(entity!.id!, status), {
        onSuccess: async () => {
            ToastManager.success({ title: 'main:msg-success', message: 'main:msg-changes-saved' });
            await queryClient.invalidateQueries(['innovationproject', id]);
        },
        onSettled: (data, error) => {
            if (error) {
                setActiveTab(activeTab);
            }
        },
    });

    const actions = (() => {
        const items: ActionItem[] = [...saveActions];

        if (id && checkAccess(Rules.InnovationProject.Status) && entity?.status !== InnovationProjectStatusEnum.Active) {
            items.splice(2, 0, {
                name: t('main:btn-publish'),
                onClick: () => publishMutation.mutate(InnovationProjectStatusEnum.Active),
            });
        }

        return items;
    })();

    return (
        <MainContainer>
            <PageTitle text={t(`innovationproject:title.${entity?.id ? 'edit' : 'add'}`)} backLink={InnovationProjectRoutesPath.List} />
            <TopActionsContainer>
                <TopActions actions={actions} />
            </TopActionsContainer>
            <ValidationContext.Provider value={{ validateOn, tabs, setTabs }}>
                <TabControls selectedId={activeTab} items={tabItems} onChange={setActiveTab} errored={validateOn ? tabs : []} />
                <LoaderTab activeId={activeTab} />
                {!isFetching && (
                    <>
                        <MainEditTab activeId={activeTab} id="main" entity={entity} data={data} updateData={updateData} />
                        <Suspense fallback={<Loader />}>
                            <PartnersEditTab activeId={activeTab} id="partners" entity={entity} data={data} updateData={updateData} />
                        </Suspense>
                        <BottomActionsContainer>
                            <BottomActions actions={actions} />
                        </BottomActionsContainer>
                    </>
                )}
            </ValidationContext.Provider>
            <DeleteModal isDeleteOpen={isDeleteOpen} close={closeDelete} remove={remove} />
        </MainContainer>
    );
}