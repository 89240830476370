import TabPanel from '../../tabs/tabPanel';
import React, { useMemo } from 'react';
import { Field } from '../../common/layout';
import { formatDateTime } from '../../../utils/date.utils';
import { AutocompleteControl, InputControl, SelectControl } from "../../common/ui";
import { Capacity, Length, NotEmpty } from '../../../hooks/UseValidation/validators';
import { useOptions } from '../../../hooks/select.options.hook';
import ProfileIpStatusEnum from '../../../eenApi/profile/enum/profile.ip.status.enum';
import { DictionaryType } from '../../../models/dictionaryType';
import RadioControl from '../../common/ui/RadioControl';
import ProfileStageEnum from '../../../eenApi/profile/enum/profile.stage.enum';
import LanguageEnum from '../../../enums/language.enum';
import DictionaryControl from '../../common/ui/DictionaryControl';
import ProfileTypeEnum from '../../../eenApi/profile/enum/profile.type.enum';
import IEditPanelProps from '../edit.panel.props';
import useTranslation from '../../../hooks/translation.hook';
import {
    BusinessPartnershipTypeEnum, ResearchRequestPartnershipTypeEnum,
    TechnicalPartnersipTypeEnum,
} from '../../../eenApi/profile/enum/profile.partnership.type.enum';
import { InnovationProjectService } from "../../../eenApi/innovationproject/service";


export default function MainEditTab({ entity, data, updateData, ...rest }: IEditPanelProps) {

    const { t, i18n } = useTranslation();

    const ipStatusOptions = useOptions<ProfileIpStatusEnum>(ProfileIpStatusEnum, 'profile:iprStatus.');
    const stageOptions = useOptions<ProfileStageEnum>(ProfileStageEnum, 'profile:stage.');

    const typeEnum = useMemo(() => {
        switch (entity.type) {
            case ProfileTypeEnum.BusinessOffer:
            case ProfileTypeEnum.BusinessRequest:
                return BusinessPartnershipTypeEnum;
            case ProfileTypeEnum.TechnologyOffer:
            case ProfileTypeEnum.TechnologyRequest:
                return TechnicalPartnersipTypeEnum;
            case ProfileTypeEnum.ResearchRequest:
                return ResearchRequestPartnershipTypeEnum;
            default:
                return {};
        }
    }, []);
    const typeOptions = useOptions(typeEnum, 'profile:partnershipType.');

    return (
        <TabPanel {...rest}>
            <InputControl labelKey={'profile:fields.title'}
                          onChange={value => updateData('title', value)}
                          value={data.title}
                          required
                          validators={[NotEmpty, Length(255)]} tab='main'
                          hintKey='profile:hint.title' />
            <Field labelKey='main:field.language' optional>{t(`main:language.${data.language}`)}</Field>
            <Field labelKey='profile:fields.profileType' optional>{t(`profile:type.${entity.type}`)}</Field>
            <Field labelKey='profile:fields.memberCountryId' optional>{entity.memberCountry?.name}</Field>
            <Field labelKey='profile:fields.createDate'>{formatDateTime(entity.created)}</Field>
            <Field labelKey='profile:fields.updateDate'>{formatDateTime(entity.modified)}</Field>
            <InputControl labelKey={'profile:fields.expireDate'}
                          onChange={value => updateData('expirationDate', value)}
                          value={data.expirationDate}
                          type='date' />
            <InputControl labelKey={'profile:fields.annotation'}
                          onChange={value => updateData('annotation', value)}
                          value={data.annotation} multiline
                          required validators={[NotEmpty, Length(500)]} tab='main'
                          hintKey='profile:hint.annotation' />
            <InputControl labelKey={'profile:fields.description'}
                          onChange={value => updateData('description', value)}
                          value={data.description} multiline
                          required validators={[NotEmpty, Length(4000, 100)]} tab='main'
                          hintKey='profile:hint.description' />
            <RadioControl labelKey={'profile:fields.stage'} items={stageOptions}
                          onChange={value => updateData('stage', value)}
                          value={data.stage}
                          required validators={[NotEmpty]} tab='main' />
            <SelectControl labelKey='profile:fields.iprStatuses'
                           value={data.iprStatuses || []} multiple
                           onChange={value => updateData('iprStatuses', value)}
                           options={ipStatusOptions}
                           required validators={[NotEmpty]} tab='main' />
            {(entity.type !== ProfileTypeEnum.ResearchRequest) && <>
                <InputControl labelKey={'profile:fields.advantages'}
                              onChange={value => updateData('advantages', value)}
                              value={data.advantages} multiline
                              required
                              validators={[NotEmpty, Length(2000, 50)]}
                              tab='main'
                              hintKey='profile:hint.advantages' />
                <DictionaryControl value={data.keyWords} multiple
                                   onChange={value => updateData('keyWords', value)}
                                   dictionary={DictionaryType.KeyWords}
                                   language={i18n.language as LanguageEnum}
                                   labelKey='profile:fields.keyWords'
                                   required validators={[NotEmpty]} minInput={0} />
            </>}
            {entity.type === ProfileTypeEnum.ResearchRequest && <>
                <InputControl value={data.frameworkProgramme}
                              onChange={value => updateData('frameworkProgramme', value)}
                              labelKey='profile:fields.frameworkProgramme' validators={[Length(255)]}/>
                <AutocompleteControl value={data.innovationProjectId}
                                     onChange={value => updateData('innovationProjectId', value)}
                                     dictionary={DictionaryType.InnovationProject}
                                     labelKey='profile:fields.innovationProjectId'
                                     options={(term, language, skip) =>
                                       InnovationProjectService.selectLoadOptions(term, language, skip, entity.memberCountry?.id)}
                                     required validators={[NotEmpty]} />
            </>}
            <InputControl labelKey={'profile:fields.partnerRole'}
                          onChange={value => updateData('partnerRole', value)}
                          value={data.partnerRole} multiline
                          required
                          validators={[NotEmpty, Length(300)]}
                          tab='main' />
            <DictionaryControl value={data.partnerTypes} multiple
                               onChange={value => updateData('partnerTypes', value)}
                               dictionary={DictionaryType.ClientType}
                               language={i18n.language as LanguageEnum}
                               labelKey='profile:fields.partnerTypes' minInput={0} />
            <SelectControl labelKey='profile:fields.partnershipTypes'
                           value={data.partnershipTypes || []} multiple
                           onChange={value => updateData('partnershipTypes', value)}
                           options={typeOptions}
                           required
                           validators={[NotEmpty, Capacity(entity.type === ProfileTypeEnum.ResearchRequest ? 1 : Infinity)]}
                           tab='cooperation' />
            <InputControl labelKey={'profile:fields.comment'}
                          onChange={value => updateData('comment', value)}
                          value={data.comment} multiline
                          validators={[Length(255)]}
                          tab='main' />
        </TabPanel>
    );
}