import { useMutation } from 'react-query';
import { ToastManager } from '../../service/toastManager';
import { resetQueryFn } from '../../query/query.utils';
import { useCallback } from 'react';
import ModelValidator from '../../utils/validators/model.validator';
import { IEditAllStates, IEditStatesOptions, useEditStates } from '../UseEditStates';
import { ActionItem } from '../../models/actionItem';
import { IDeleteModal, useDeleteModal } from '../UseDeleteModal';

export interface IEditPageOptions<T> extends IEditStatesOptions<T> {
    handler: (data: T) => Promise<number>;
    validator: ModelValidator<T, T>;
    saveRoute: (id: number) => string;
    applyRoute: (id: number) => string;
    cancelRoute: [createEntityCancel: string | (() => string), editEntityCancel: string | ((id: number, entity?: T) => string)];
    stayTab?: string;
}

interface IEditPageWithDeleteOptions<T> extends IEditPageOptions<T> {
    deleteHandler: (id: string | number) => Promise<any>;
    canDelete?: boolean | string;
    canDeleteUser?: {
        userId: keyof T;
        rules: Record<string, string>;
        rule?: string;
    };
}

interface IMutationParams {
    stay: boolean;
}

interface IEditPage<T> extends IEditAllStates<T> {
    saveActions: ActionItem[];
    updateData: (field: keyof T, value: any) => void;
}

interface IEditPageWithDelete<T> extends IEditPage<T>, IDeleteModal {}

export function useEditPage<T>(options: IEditPageOptions<T>): IEditPage<T> {
    const editStates = useEditStates(options);

    const save = useCallback((data: T, entity: T | undefined, stay: boolean) => {
        editStates.setValidateOn(true);
        window.scrollTo(0, 0);
        const erroredTabs = options.validator.validate(data!, [], entity);
        editStates.setTabs(erroredTabs);
        if (erroredTabs.length == 0) {
            mutation.mutate({ stay });
            editStates.setActiveTab('loading');
        }
    }, []);

    const mutation = useMutation<number, T, IMutationParams>(() => options.handler(editStates.data!), {
        onSuccess: async (result, params: IMutationParams) => {
            ToastManager.success({ title: 'main:msg-success', message: 'main:msg-changes-saved' });
            await editStates.queryClient.resetQueries({ predicate: (query) => resetQueryFn(query, options.queryKey) });
            if (params.stay) {
                editStates.history.replace(options.applyRoute(result));
                editStates.setActiveTab(options.stayTab ?? "main");
            } else {
                editStates.history.replace(options.saveRoute(result));
            }
        },
        onSettled: (data, error) => {
            if (error) {
                editStates.setActiveTab(editStates.activeTab);
            }
        }
    });

    return {
        ...editStates,
        saveActions: [
            { name: editStates.t('main:btn-save'), onClick: () => save(editStates.data, editStates.entity, false) },
            { name: editStates.t('main:btn-apply'), onClick: () => save(editStates.data, editStates.entity, true) },
            {
                name: editStates.t("main:btn-cancel"),
                onClick: async () => {
                    if (editStates.id) {
                        editStates.history.push(
                            typeof options.cancelRoute[1] === "string"
                                ? options.cancelRoute[1]
                                : options.cancelRoute[1](Number(editStates.id), editStates.entity)
                        );
                    } else {
                        editStates.history.push(typeof options.cancelRoute[0] === "string" ? options.cancelRoute[0] : options.cancelRoute[0]());
                    }
                }
            }
        ],
        updateData: (field: keyof T, value: any) => {
            let newValue = value?.value ?? value;
            editStates.setData((prev) => ({ ...prev, [field]: Array.isArray(newValue) ? [...newValue] : newValue }));
        }
    };
}

export function useEditPageWithDelete<T>(options: IEditPageWithDeleteOptions<T>): IEditPageWithDelete<T> {
    const editPage = useEditPage(options);
    const deleteModal = useDeleteModal({
        queryKey: options.queryKey,
        handler: options.deleteHandler,
        successPath: typeof options.cancelRoute[0] === 'string' ? options.cancelRoute[0] : options.cancelRoute[0]()
    });

    let canDelete = (editPage as any).entity?.accessDto?.delete;
    if (options.canDelete !== undefined) {
        canDelete = typeof options.canDelete === 'string' ? editPage.checkAccess(options.canDelete) : options.canDelete;
    }
    if (options.canDeleteUser !== undefined) {
        canDelete = editPage.checkAccessUser(String(editPage.entity?.[options.canDeleteUser.userId]), options.canDeleteUser.rules, options.canDeleteUser.rule ?? 'Delete');
    }
    if (Number(editPage.id) > 0 && canDelete) {
        editPage.saveActions.splice(editPage.saveActions.length - 1, 0, deleteModal.deleteButton);
    }

    return {
        ...editPage,
        ...deleteModal
    };
}
