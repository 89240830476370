import { EenApi } from '..';
import { SelectValue } from "../../component/select/SelectValue";
import { ClientRoutesPath } from '../../page/client/routes';
import { Client } from './client';

class CClientService {
    async list(requestData: any) {
        return await EenApi.list<Client>("client/list", requestData);
    }

    async get(id: number, language: string) {
        return await EenApi.get<Client>(`client/get/${id}?language=${language}`);
    }

    async addOrUpdate(entity: Client) {
        if (entity.id < 1) {
            return await EenApi.post<number>("client/add", entity);
        }
        return await EenApi.put<number>("client/edit", entity);
    }

    async delete(id: number[]) {
        return await EenApi.delete("client/delete", { ids: id });
    }

    getViewLink(id: number) {
        return ClientRoutesPath.View.replace(':id', String(id))
    }

    getEditLink(id: number) {
        return ClientRoutesPath.Edit.replace(':id', String(id))
    }

    async selectLoadOptions(inputValue: string|(string|number)[], language: string, skip = 0): Promise<SelectValue[]> {
        const filter: Record<string, any> = { skip };
        if (Array.isArray(inputValue)) {
            filter.id = inputValue
        } else {
            filter.name = inputValue?.trim()
        }
        const rd = {
            filter,
            language
        };
        const data = await ClientService.list(rd);

        return data.items.map((item) => ({
            value: `${item.id}`,
            label: item.name ?? '',
        }))
    }
}

export const ClientService = new CClientService();
