import { CrudService } from '../common/service/crud.service';
import MailingDto from './dto/mailing.dto';
import { EenApi } from '..';
import MailingUnsubscriptionDto from './dto/mailing.unsubscription.dto';

class MailingServiceImpl extends CrudService<MailingDto> {
    constructor() {
        super('mailing');
    }

    async unsubscribinglist(requestData: any) {
        return EenApi.list<MailingUnsubscriptionDto>(`${this.baseUrl}/unsubscribingList`, requestData);
    }

    async unsubscribe(entity: MailingUnsubscriptionDto) {
        return await EenApi.put<number>(`${this.baseUrl}/unsubscribe`, entity);
    }

    async deleteUnsubscribing(ids: string | number | string[] | number[]) {
        if (!Array.isArray(ids)) {
            ids = [ids as string];
        }
        return await EenApi.delete(`${this.baseUrl}/deleteUnsubscribing`, { ids: ids });
    }

    async get(id: number) {
        return await EenApi.get<MailingDto>(`${this.baseUrl}/get/${id}`);
    }

    async getUnsubscribing(email: string) {
        return await EenApi.get<MailingUnsubscriptionDto>(`${this.baseUrl}/getUnsubscribing/${email}`);
    }

    async forceSending(data: MailingDto, forceSending: boolean): Promise<any> {
        return await EenApi.put<any>(`${this.baseUrl}/edit`, { ...data, forceSending });
    }
}
export const MailingService = new MailingServiceImpl();
