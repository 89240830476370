import Config from '../../config';

export const QueryParameterNames = {
    ReturnUrl: 'returnUrl',
    Message: 'message',
};

export const LogoutActions = {
    LogoutCallback: 'logout-callback',
    Logout: 'logout',
    LoggedOut: 'logged-out',
};

export const LoginActions = {
    Login: 'login',
    LoginCallback: 'login-callback',
    LoginFailed: 'login-failed',
    Profile: 'profile',
    Register: 'register',
};

const prefix = '/authentication';

export const ApplicationPaths = {
    ApplicationName: Config.ApplicationName,
    DefaultLoginRedirectPath: '/',
    ApiAuthorizationClientConfigurationUrl: `${Config.AuthServer}/_configuration/${Config.ApplicationName}`,
    ApiAuthorizationPrefix: prefix,
    Login: `${prefix}/${LoginActions.Login}`,
    LoginFailed: `${prefix}/${LoginActions.LoginFailed}`,
    LoginCallback: `${prefix}/${LoginActions.LoginCallback}`,
    Register: `${prefix}/${LoginActions.Register}`,
    Profile: `${prefix}/${LoginActions.Profile}`,
    LogOut: `${prefix}/${LogoutActions.Logout}`,
    LoggedOut: `${prefix}/${LogoutActions.LoggedOut}`,
    LogOutCallback: `${prefix}/${LogoutActions.LogoutCallback}`,
    IdentityRegisterPath: `${Config.AuthServer}/account/register`,
    IdentityManagePath: `${Config.AuthServer}/account/manage`,
};
