import React, {PropsWithChildren} from "react";
import BackButton from "../common/ui/BackButton";
import {useTypedSelector} from "../../hooks/useTypedSelector";

interface IProps {
    className?: string
}

export default function TopActionsContainer({ className, children }: PropsWithChildren<IProps>) {
    const { backLink } = useTypedSelector(state => state.layout);

    return (
        <main className={className ?? 'mb-3'}>
            {backLink && <BackButton to={backLink}/>}
            {children}
        </main>
    )
}