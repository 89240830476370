import {Route, Switch} from "react-router-dom";
import NotFound from "../../home/notFound";
import Rules from "../../../rules";
import ProtectedRoute from "../../../component/common/ProtectedRoute";
import SpecialistEdit from '../edit';
import SpecialistDetails from '../details';
import { SpecialistList } from '../list';

export default function SpecialistRoutes() {
   return (
      <Switch>
         <ProtectedRoute path={SpecialistRoutesPath.List} exact component={SpecialistList} rule={Rules.Specialist.List}/>
         <ProtectedRoute path={SpecialistRoutesPath.Validate} exact
                         render={(props) => <SpecialistList {...props} validation={true}/>}
                         rule={Rules.Specialist.ListValidation}/>
         <ProtectedRoute path={SpecialistRoutesPath.Create} exact component={SpecialistEdit} rule={Rules.Specialist.Create}/>
         <ProtectedRoute path={SpecialistRoutesPath.Edit} exact component={SpecialistEdit} rule={[Rules.Specialist.Edit, Rules.Specialist.EditOwn]}/>
         <ProtectedRoute path={SpecialistRoutesPath.View} exact component={SpecialistDetails}
                         rule={[Rules.Specialist.ViewBasic, Rules.Specialist.ViewContacts, Rules.Specialist.ViewValidations,
                           Rules.Specialist.ViewBasicOwn, Rules.Specialist.ViewContactsOwn, Rules.Specialist.ViewValidationsOwn,
                           Rules.Specialist.Validate, Rules.Specialist.Edit, Rules.Specialist.EditOwn]}/>
         <Route path="*" component={NotFound} />
      </Switch>
   );
}

const prefix = '/specialist'
export const SpecialistRoutesPath = {
   Prefix: `${prefix}`,
   Create: `${prefix}/create`,
   List: `${prefix}/list`,
   Edit: `${prefix}/:id/edit`,
   View: `${prefix}/:id/details`,
   Validate: `${prefix}/list/validation`
}