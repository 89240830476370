import MailingConfiguraton from '../../../eenApi/configuration/mailing.setting.dto';
import ModelValidator from '../model.validator';

class EditValidator extends ModelValidator<MailingConfiguraton, MailingConfiguraton> {
    validate(dto: MailingConfiguraton, tabs: string[], entity: MailingConfiguraton) {
        const erroredTabs: string[] = [];

        this.checkRequired(dto.apiKey, erroredTabs);
        this.checkRequired(dto.email, erroredTabs);
        this.checkRequired(dto.sender, erroredTabs);
        this.checkRequired(dto.type, erroredTabs);

        return erroredTabs;
    }
}
const MailingUnsubscribeSettingValidator = new EditValidator();
export default MailingUnsubscribeSettingValidator;
