import { ActionItem } from "../../models/actionItem";

interface IProps {
    action: ActionItem,
    onClick: (action: ActionItem) => void
}

const ActionModalButton = (props: IProps) => {
    return (
        <button className="btn-mobile-menu" onClick={() => props.onClick(props.action)}>
            {props.action.icon && props.action.icon.length > 0 &&
                <i className={props.action.icon} style={{ color: props.action.color ? props.action.color : '#8E9FA7' }}></i>
            }
            <span>{props.action.name}</span>
        </button>
    );
}

export default ActionModalButton;
