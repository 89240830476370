import React, { FC, useState } from "react";
import useTranslation from "../../../../hooks/translation.hook";
import FilterField from '../filterField';
import FilterType from '../../types/filterType';
import FilterItem from "../../types/filterItem";
import FilterVisibility from "../visibility";
import { FilterEntity } from "../../types/filter";

interface IProps {
    items: FilterItem[],
    requestParams: any,
    currentFilterId: string,
    setCurrentFilterId: (value: string) => void,
    filterSorting: number[],
    loadedFilters: { [key: string]: FilterEntity },
    clickFind: (e: React.SyntheticEvent) => void,
    loadFilterDefaultFields: () => void,
    clickReset: (e: React.SyntheticEvent) => void,
    clickSave: (name: string) => void,
    clickRemoveUserFilters: () => void,
    onSortFilters: (data: { oldIndex: number, newIndex: number }) => void,
    currentFilter: any,
    clickSelectFilter: (id: string) => void,
    clickRemoveFilter: (e: React.SyntheticEvent, id: string) => void,
    getFilterItemByName: (name: string) => void,
    onSortFields: (data: { oldIndex: number, newIndex: number }) => void,
    changeValue: (value: any, filterItem: FilterItem) => void,
    clickRemoveField: (name: string) => void,
    clickSaveFieldsVisibility: (data: string[]) => void
}

const Modal: FC<IProps> = (props) => {
    const { t } = useTranslation();
    const [editMode, setEditMode] = useState(false);
    const [editFilter, setEditFilter] = useState(false);
    const [editFilterName, setEditFilterName] = useState('');
    const [showFieldsVisibility, setShowFieldsVisibility] = useState(false);

    const onClickSave = () => {
        if (editFilterName.length < 1) {
            return;
        }
        props.clickSave(editFilterName);
        setEditMode(false);
    }

    const clickCreateFilterMode = () => {
        setEditMode(true);
        props.setCurrentFilterId('new');
    }

    const clickEditMode = () => {
        const currentFilter = props.loadedFilters[props.currentFilterId];
        if (currentFilter) {
            setEditFilterName(currentFilter.name);
        }
        else {
            props.setCurrentFilterId('');
        }
        setEditMode(true);
    }

    const openModalFields = (e: React.SyntheticEvent) => {
        setShowFieldsVisibility(true);
    }

    const changeEditFilterName = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e) e.preventDefault();
        setEditFilterName(e.target.value)
    }

    const onClickSaveFieldsVisibility = (fields: string[] | undefined) => {
        setShowFieldsVisibility(false);
        if (fields) {
            props.clickSaveFieldsVisibility(fields);
        }
    }

    const clickEditFilterName = (e: React.SyntheticEvent, id: string) => {
        e.preventDefault();
        setEditFilter(true);
        setEditFilterName(props.loadedFilters[id].name);
    }

    const onClickCancel = (e: React.SyntheticEvent) => {
        e.preventDefault();
        console.log('onClickCancel', editMode);
        setEditMode(false);
    }

    const onLoadFilterDefaultFields = () => {
        setEditMode(false);
        props.loadFilterDefaultFields();
    }

    return (
        <div className="row">
            <div className="col-12 col-xl-3 d-fl ex flex-col umn">
                <div className="main-ui-filter-sidebar-title">
                    <h5 className="main-ui-filter-sidebar-title-item">{t('filter:title-filters')}</h5>
                </div>
                <div className={`${props.filterSorting && props.filterSorting.length > 0 ? 'flex-grow-1' : ''} main-ui-filter-sidebar-item-container`}>
                    <SortableFilterList helperClass='sortable-helper' lockAxis='y' useDragHandle={true} pressDelay={200}
                        editMode={editMode} editFilter={editFilter} editFilterName={editFilterName}
                        items={props.filterSorting}
                        loadedFilters={props.loadedFilters}
                        currentFilterId={props.currentFilterId} currentFilter={props.currentFilter}
                        onSortEnd={props.onSortFilters} changeEditFilterName={changeEditFilterName} clickSelectFilter={props.clickSelectFilter} clickEditFilterName={clickEditFilterName} clickRemoveFilter={props.clickRemoveFilter} />
                    {editMode && props.currentFilterId === 'new' &&
                        <div className="main-ui-filter-sidebar-item main-ui-filter-ne w-filter">
                            <div className="main-ui-filter-edit-mask">
                                <input type="text" className="main-ui-filter-sidebar-edit-control" placeholder={t('filter:name-placeholder')} value={editFilterName} onChange={changeEditFilterName} />
                            </div>
                        </div>
                    }
                </div>
                <div className="d-flex px-2 mt-2">
                    {!editMode &&
                        <span className="main-ui-filter-add-item flex-grow-1" onClick={clickCreateFilterMode}>
                            <i className="fas fa-plus me-1"></i>
                            <span>{t('filter:btn-save-filter')}</span>
                        </span>
                    }
                    {!editMode &&
                        <span className="main-ui-filter-add-edit" title={t('filter:btn-edit-filter-title')} onClick={clickEditMode}>
                            <i className="fas fa-cog mt-1"></i>
                        </span>
                    }
                    {editMode &&
                        <span className="main-ui-filter-reset-link mb-2" onClick={props.clickRemoveUserFilters}>
                            <i className="fas fa-reply me-1"></i>
                            <span>{t('filter:btn-reset-filter')}</span>
                        </span>
                    }
                </div>
                <div className="d-block d-xl-none">
                    <hr className="my-2" />
                </div>
            </div>
            <div className="col-12 col-xl-9">
                {!showFieldsVisibility &&
                    <>
                        <div className="d-flex flex-column flex-gro w-1">
                            <SortableFieldList helperClass='sortable-helper' lockAxis='y' useDragHandle={true} pressDelay={200} currentFilterId={props.currentFilterId} items={props.requestParams}
                                getFilterItemByName={props.getFilterItemByName} onSortEnd={props.onSortFields} onChangeValue={props.changeValue} onClickRemove={props.clickRemoveField} />
                            <div className="d-flex justify-content-center mt-2 mb-3">
                                <span className="main-ui-filter-field-add-item" onClick={openModalFields}>{t('filter:btn-add-field')}</span>
                                <span className="main-ui-filter-field-restore-items" onClick={onLoadFilterDefaultFields}>{t('filter:btn-restore-fields')}</span>
                            </div>
                        </div>
                        <div className="">
                            <div className="d-flex justify-content-center">
                                {!editMode &&
                                    <div className="mb-2">
                                        <button className="btn btn-primary btn-md waves-effect waves-light ps-3 me-2" onClick={props.clickFind}>
                                            <i className="fas fa-search me-2"></i>
                                            <span>{t('filter:btn-search')}</span>
                                        </button>
                                        <button className="btn btn-outline-primary btn-md waves-effect" onClick={props.clickReset}>{t('filter:btn-reset')}</button>
                                    </div>
                                }
                                {editMode &&
                                    <div className="mb-2">
                                        <button className="btn btn-success btn-md waves-effect waves-light me-2" onClick={onClickSave}>{t('filter:btn-save')}</button>
                                        <button className="btn btn-outline-success btn-md waves-effect" onClick={onClickCancel}>{t('filter:btn-cancel')}</button>
                                    </div>
                                }
                            </div>
                        </div>
                    </>
                }
                {showFieldsVisibility &&
                    <FilterVisibility items={props.items} requestParams={props.requestParams} save={onClickSaveFieldsVisibility} />
                }
            </div>
        </div>
    )
}

function DragHandle() {
    const { t } = useTranslation();
    return (
        <div className="main-ui-item-icon main-ui-filter-icon-grab" title={t('filter:sort-filter-title')}><i className="fas fa-bars"></i></div>
    );
}

function SortableFieldItem(props: any) {
    return (
        <div className="d-flex mb-3">
            <DragHandle />
            <div className="flex-grow-1">
                <FilterField data={props.item.value} filterItem={props.filterItem} onChange={props.onChangeValue} />
            </div>
            <div className="main-ui-item-icon pe-2 me-1" onClick={() => props.onClickRemove(props.item.name)}>
                <i className="fas fa-times"></i>
            </div>
        </div>
    );
}

function SortableFieldList(props: any) {
    return (
        <div className='mt-3'>
            {props.items.map((item: any, index: number) => {
                const filterItem = props.getFilterItemByName(item.name);
                if (filterItem && filterItem.Type !== FilterType.Hidden) {
                    return (
                        <SortableFieldItem key={`${item.name}_${props.currentFilterId}`} index={index} item={item} filterItem={filterItem} onChangeValue={props.onChangeValue} onClickRemove={props.onClickRemove} />
                    )
                }
            })}
        </div>
    );
}

function SortableFilterItem(props: any) {
    const { t } = useTranslation();
    return (
        <div className={`d-flex main-ui-filter-sidebar-item ${props.currentFilter && props.currentFilterId === props.id ? 'main-ui-filter-current-item' : ''}`}>
            <DragHandle />
            {(props.editFilter && props.currentFilterId === props.id)
                ?
                <div className="flex-grow-1 p-2 main-ui-filter-sidebar-item-text-container">
                    <input type="text" className="main-ui-filter-sidebar-item-input" placeholder={t('filter:name-placeholder')} autoFocus={true} value={props.editFilterName} onChange={props.changeEditFilterName} />
                </div>
                :
                <div className="flex-grow-1 p-2 main-ui-filter-sidebar-item-text-container" onClick={() => props.clickSelectFilter(props.id)}>
                    <span className="main-ui-filter-sidebar-item-text">
                        {props.filter?.name}
                    </span>
                </div>
            }
            {props.editMode &&
                <>
                    <div className="main-ui-item-icon main-ui-filter-icon-edit" title={t('filter:edit-filter-name-title')} onClick={(e) => props.clickEditFilterName(e, props.id)}>
                        <i className="fas fa-pen"></i>
                    </div>
                    <div className="main-ui-item-icon main-ui-delete main-ui-filter-filter-delete" title={t('filter:delete-filter-title')} onClick={(e) => props.clickRemoveFilter(e, props.id)}>
                        <i className="fas fa-times"></i>
                    </div>
                </>
            }
        </div>
    );
}

function SortableFilterList(props: any) {//: { loadedFilters: { [key: string]: FilterEntity } }
    const items: number[] = props.items;
    return (
        <div>
            {items.map((id, index) => (
                <SortableFilterItem key={index} index={index} id={id} currentFilter={props.currentFilter} currentFilterId={props.currentFilterId} editMode={props.editMode} editFilter={props.editFilter}
                    editFilterName={props.editFilterName} changeEditFilterName={props.changeEditFilterName} clickSelectFilter={props.clickSelectFilter}
                    filter={props.loadedFilters[id]}
                    clickEditFilterName={props.clickEditFilterName} clickRemoveFilter={props.clickRemoveFilter} />
            ))}
        </div>
    );
}

export default Modal;
