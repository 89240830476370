import { FC, useContext, useMemo, useState } from 'react';
import useTranslation from '../../../../hooks/translation.hook';
import { Link } from 'react-router-dom';
import PageTitle from '../../../../component/pageTitle';
import Filter from '../../../../component/filter';
import MainContainer from '../../../../component/mainContainer';
import Rules from '../../../../rules';
import SupportTable from './table';
import { UserContext } from '../../../../context/user-context';
import { SupportRoutesPath } from '../../routes';
import { useMutation, useQuery } from 'react-query';
import SupportService from '../../../../eenApi/support/service';
import FilterItem from '../../../../component/filter/types/filterItem';
import FilterType from '../../../../component/filter/types/filterType';
import FilterItemSelect from '../../../../component/filter/types/filterItem/items/select';
import { useOptions } from '../../../../hooks/select.options.hook';
import SupportCriticalityEnum from '../../../../eenApi/support/supports/enum/support.criticality.enum';
import SupportStatusEnum from '../../../../eenApi/support/supports/enum/support.status.enum';
import FilterItemDateRange from '../../../../component/filter/types/filterItem/items/date';
import SupportIsViewedEnum from '../../../../eenApi/support/supports/enum/support.is.viewed.enum';

const SupportList: FC = () => {
    const { t, i18n } = useTranslation();
    const context = useContext(UserContext);
    const [filterValues, setFilterValues] = useState<{ [key: string]: any }>();

    const { data: subjectsOptions } = useQuery(['subject'], SupportService.selectLoadOptions);
    const supportCriticalityOptions = useOptions(SupportCriticalityEnum, 'support:supports.fields.criticality.');
    const supportStatusOptions = useOptions(SupportStatusEnum, 'support:supports.fields.status.');
    const isViewedOptions = useOptions(SupportIsViewedEnum, 'support:supports.fields.isViewed.');

    const filterItems = useMemo(() => [
            new FilterItem(FilterType.Text, 'title', 'support:supports.fields.title'),
            new FilterItemSelect('subjectId', t('support:fields.name'), { options: subjectsOptions ?? [] }),
            new FilterItemSelect('criticality', t('support:supports.fields.criticality.title'), { options: supportCriticalityOptions }),
            new FilterItemSelect('status', t('support:supports.fields.status.title'), { options: supportStatusOptions }),
            new FilterItemDateRange('created', t('support:supports.fields.created')),
            new FilterItemSelect('isViewed', t('support:supports.fields.isViewed.Title'), { options: isViewedOptions }),
        ],

        [filterValues, i18n.language]);

    const MarkAllAsReadMutation = useMutation(() => SupportService.markAllAsRead());

    return (
        <MainContainer>
            <PageTitle text={t('support:supports.list.title')} backLink='/' />
            <div className='d-xl-flex mb-1 mb-xl-3'>
                <div className='app-filter-actions mb-2 mb-xl-0'>
                    {context.checkAccess(Rules.Support.Add) &&
                        <Link className='btn btn-success me-2'
                              to={SupportRoutesPath.Create}>{t('support:supports.list.button-add')}</Link>
                    }
                </div>
                <Filter id='filter-supports'
                        items={filterItems}
                        onFind={setFilterValues}
                />
            </div>
            <div className='mb-4'>
                <Link className='btn btn-success me-2 mt-2'
                      to={SupportRoutesPath.ListSubject}>{t('support:supports.list.button-theme-list')}</Link>
                <div className='btn btn-success me-2'
                     onClick={() => MarkAllAsReadMutation.mutate()}>{t('support:supports.list.button-mark-all-as-read')}</div>
            </div>

            <SupportTable filterValues={filterValues} />
        </MainContainer>
    );
};

export default SupportList;