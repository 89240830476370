import ProfileCreateType from "./type/profile.create.type";
import {EenApi} from "../index";
import ProfileEditDto from "./dto/profile.edit.dto";
import LanguageEnum from "../../enums/language.enum";
import ProfileStatusEnum from "./enum/profile.status.enum";
import { InterestDto } from './dto/interest.dto';
import { SelectValue } from "../../component/select/SelectValue";
import ProfileDto from './dto/profile.dto';
import { CenterRoutesPath } from '../../page/center/routes';
import { ProfileRoutesPath } from "../../page/profile/routes";

class ProfileServiceImpl {

   async create(data: ProfileCreateType): Promise<number> {
      return await EenApi.post<number>("profile/add", data)
   }

   async crateVersion(id: number, language: LanguageEnum): Promise<number> {
      return await EenApi.post<number>("profile/add-version", { id, language })
   }

   async update(data: ProfileEditDto): Promise<any> {
      return await EenApi.put<any>("profile/edit", data)
   }

   async remove(id: number|string) {
      return await this.removeAll([id])
   }

   async removeAll(ids: (number|string)[]) {
      return await EenApi.delete("profile/delete", { ids })
   }

   async status(id: number, language: LanguageEnum, status: ProfileStatusEnum) {
      await EenApi.put("profile/status", { id, language, status })
   }

   list(requestData: any, prefix = '') {
      return prefix === CenterRoutesPath.Prefix
          ? EenApi.list<ProfileDto>(`center/profiles`, requestData)
          : EenApi.list<ProfileDto>(`profile/list/${prefix}`, requestData)
   }

   saveInterest(data: InterestDto) {
      return !data.id
          ? EenApi.put('profile/add/interest', data)
          : EenApi.put('profile/edit/interest', data)
   }

   removeInterest(ids: number|number[]) {
      return EenApi.delete("profile/delete/interest", { ids: Array.isArray(ids) ? ids : [ids] })
   }

   listInterest(list: 'my-interest'|'interest', requestData: any) {
      return EenApi.list<InterestDto>(`profile/list/${list}`, requestData);
   }

   async selectLoadOptions(inputValue: string|(string|number)[], language: string, skip = 0): Promise<SelectValue[]> {
      const filter: Record<string, any> = { skip };
      if (Array.isArray(inputValue)) {
         filter.id = inputValue
      } else {
         filter.name = inputValue?.trim()
      }
      const rd = {
         filter,
         language
      };
      const data = await ProfileService.list(rd, 'een');

      return data.items.map((item) => ({
         value: `${item.id}`,
         label: item.title ?? '',
         ext: item.client?.id
      }))
   }

   getDetailsRoute(id: number|string) {
      return ProfileRoutesPath.View.replace(':id', String(id))
   }

   getEditRoute(id: number|string) {
      return ProfileRoutesPath.Edit.replace(':id', String(id))
   }
}

const ProfileService = new ProfileServiceImpl()
export default ProfileService