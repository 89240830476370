import { FC } from "react";
import { ActionItem } from "../../models/actionItem";

interface IProps {
    action: ActionItem;
    onClick: (action: ActionItem) => void;   
}

const ActionButton: FC<IProps> = props => {
    return (
        <button type={props.action.submit ? "submit" : "button"} className="btn btn-primary me-2" 
            onClick={() => { 
                props.onClick(props.action)
            }}
        >
            {props.action.name}
        </button>
    );
};

export default ActionButton;
