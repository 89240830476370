import { createReducer } from '@reduxjs/toolkit';
import { LayoutActionEnum, LayoutState } from './types';

const initialState: LayoutState = {
    pageTitle: '',
}

const layoutReducer = createReducer(initialState, {
    [LayoutActionEnum.SET_TITLE]: (state, action) => {
        return { ...state, pageTitle: action.payload };
    },
    [LayoutActionEnum.SET_BACKLINK]: (state, action) => {
        return { ...state, backLink: action.payload };
    },
});

export default layoutReducer;
