import { Route, Switch } from 'react-router-dom';
import ProtectedRoute from '../../../component/common/ProtectedRoute';
import Rules from '../../../rules';
import NotFound from '../../home/notFound';
import SubjectList from '../subjects/list';
import EditSubject from '../subjects/edit';
import SupportList from '../supports/list';
import EditSupport from '../supports/edit';
import ViewSupport from '../supports/view';

export default function SupportRoutes() {

    return (
        <Switch>
            <ProtectedRoute path={SupportRoutesPath.Create} component={EditSupport}
                            rule={Rules.Support.Add} />
            <ProtectedRoute path={SupportRoutesPath.Edit} component={EditSupport}
                            rule={Rules.Support.Add} />
            <ProtectedRoute path={SupportRoutesPath.List} component={SupportList}
                            rule={Rules.Support.List} />
            <ProtectedRoute path={SupportRoutesPath.View} component={ViewSupport}
                            rule={Rules.Support.List} />
            <ProtectedRoute path={SupportRoutesPath.ListSubject} component={SubjectList}
                            rule={Rules.Support.ListSubject} />
            <ProtectedRoute path={SupportRoutesPath.EditSubject} component={EditSubject}
                            rule={Rules.Support.AddSubject} />
            <ProtectedRoute path={SupportRoutesPath.CreateSubject} component={EditSubject}
                            rule={Rules.Support.AddSubject} />
            <Route path='*' component={NotFound} />
        </Switch>
    );
}

const prefix = '/support';
export const SupportRoutesPath = {
    Prefix: `${prefix}`,
    Create: `${prefix}/create`,
    CreateSubject: `${prefix}/subjects/create`,
    List: `${prefix}/list`,
    ListSubject: `${prefix}/subjects/list`,
    Edit: `${prefix}/:id/edit`,
    EditSubject: `${prefix}/subjects/:id/edit`,
    View: `${prefix}/:id/view`,
};