import { FC, useContext, useMemo, useState } from "react";
import useTranslation from "../../../hooks/translation.hook";
import { Link } from "react-router-dom";
import PageTitle from '../../../component/pageTitle';
import Filter from '../../../component/filter';
import ExternalProviderTable from './table';
import FilterItemHidden from "../../../component/filter/types/filterItem/items/hidden";
import MainContainer from "../../../component/mainContainer";
import { UserContext } from "../../../context/user-context";
import Rules from "../../../rules";
import { ExternalProviderService } from "../../../eenApi/externalProvider/service";

const ExternalProviderList: FC = () => {
    const context = useContext(UserContext);
    const { t } = useTranslation();
    const [filterValues, setFilterValues] = useState<{ [key: string]: any }>();

    const filterItems = useMemo(() => [
        new FilterItemHidden('name', { isTyping: 'Y' })
    ], []);

    return (
        <MainContainer>
            <PageTitle text={t('extprovider:menu-title')} backLink="/" />
            <div className="d-xl-flex mb-1 mb-xl-3">
                <div className="app-filter-actions mb-2 mb-xl-0">
                    {context.checkAccess(Rules.ExternalProvider.Edit) &&
                        <Link className="btn btn-success me-2" to={ExternalProviderService.getEditLink(0)}>{t('main:btn-add')}</Link>
                    }
                </div>
                <Filter id="filter-external-provider" items={filterItems} onFind={setFilterValues} />
            </div>
            <ExternalProviderTable filterValues={filterValues} />
        </MainContainer>
    );
}

export default ExternalProviderList;
