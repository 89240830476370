import TabPanel, { ITabPanelProps } from '../../tabs/tabPanel';
import { Length, NotEmpty } from '../../../hooks/UseValidation/validators';
import { AutocompleteControl, InputControl, SelectControl } from '../../common/ui';
import React, { useContext } from "react";
import { useOptions } from '../../../hooks/select.options.hook';
import { InterestDto } from '../../../eenApi/profile/dto/interest.dto';
import ProfileDto from '../../../eenApi/profile/dto/profile.dto';
import { Field } from '../../common/layout';
import { InterestStatusEnum } from '../../../eenApi/profile/enum/interest.status.enum';
import ProfileService from '../../../eenApi/profile/service';
import { DictionaryType } from "../../../models/dictionaryType";
import Rules from "../../../rules";
import { UserContext } from "../../../context/user-context";
import { ClientService } from "../../../eenApi/client/service";
import ValidationRule from "../../../hooks/UseValidation/validation.rule.enum";

interface IProps extends ITabPanelProps {
    entity: InterestDto | undefined,
    data: InterestDto,
    updateData: (key: keyof InterestDto, val: any) => void,
    profile: ProfileDto | undefined,
    clientId: number
}

export default function InterestEditTab({ entity, data, updateData, profile, clientId, ...rest }: IProps) {
    const statusOptions = useOptions(InterestStatusEnum, 'profile:interestStatus.');
    const { checkAccess } = useContext(UserContext);
    const selfInterestValidator = {
      rule: ValidationRule.Custom,
      validate: () => (Number(data.clientId) === Number(data.profileClientId)) ? ['profile:error.selfInterest'] as [string, ...any[]] : null

    }

    return (
        <TabPanel {...rest}>
            {profile?.id
                ? <Field labelKey='profile:fields.interest.profile'>{profile.title}</Field>
                : <AutocompleteControl
                    labelKey='profile:fields.interest.profile'
                    dictionary={DictionaryType.Profile}
                    onChange={(value) => {
                        updateData('profileId', value ?? null)
                        updateData('profileClientId', value?.ext)
                    }}
                    options={ProfileService.selectLoadOptions}
                    value={data.profileId}
                    required
                    validators={clientId ? [NotEmpty, selfInterestValidator] : [NotEmpty]}
                />
            }
            {isNaN(clientId) &&
                <AutocompleteControl
                  labelKey="profile:fields.clientId"
                  dictionary={DictionaryType.Client}
                  onChange={(value) => {
                    updateData('clientId', value?.value);
                  }}
                  options={ClientService.selectLoadOptions}
                  value={data.clientId}
                  required validators={[NotEmpty, selfInterestValidator]} tab='main' />
              }
            <SelectControl labelKey='profile:fields.interest.status'
                           value={data.status}
                           onChange={value => updateData('status', value)}
                           options={statusOptions}
                           required validators={[NotEmpty]}
                           disabled={!checkAccess(Rules.Profile.InterestStatus)}
                           tab='main' />
            <InputControl labelKey={'profile:fields.interest.cooperationKind'}
                          onChange={value => updateData('cooperationKind', value)}
                          value={data.cooperationKind} multiline
                          required validators={[NotEmpty, Length(255)]} tab='main' />
            <InputControl labelKey={'profile:fields.interest.missingInformation'}
                          onChange={value => updateData('missingInformation', value)}
                          value={data.missingInformation} multiline
                          required validators={[NotEmpty, Length(255)]} tab='main' />
        </TabPanel>
    );
}