import { EenApi } from '..';
import { MenuItem } from './menuItem';
import { MenuOrder } from './menuOrder';

class CMenuService {
    async get(params: { language: string }) {
        const allItems = await EenApi.get<MenuItem[]>(`menu?language=${params.language}`);

        for (const item of allItems) {
            item.children = allItems.filter((x) => x.parentId === item.id);
        }

        return allItems;
    }

    async getUserMenu(params: { language: string }) {
        const allItems = await EenApi.get<MenuItem[]>(`menu/user?language=${params.language}`);

        for (const item of allItems) {
            item.children = allItems.filter((x) => x.parentId === item.id);
        }

        return allItems.filter((x) => x.parentId === 0);
    }

    itemToArray(item: MenuItem, index: number): MenuOrder[] {
        var result: MenuOrder[] = [];
        const orderItem: MenuOrder = { id: item.id, parentId: item.parentId, sort: item.sort, changed: false };
        if (orderItem.sort !== index) {
            orderItem.changed = true;
            orderItem.sort = index;
        }
        result.push(orderItem);
        if (item.children && item.children.length > 0) {
            item.children.forEach((child, i) => {
                result = result.concat(MenuService.itemToArray(child, i));
            });
        }
        return result;
    }
}

export const MenuService = new CMenuService();
