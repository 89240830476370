import { EenApi } from "..";
import { ListRequestData } from "../common/listRequestData";
import { Log } from "./log";

class CLogService {
    async list(requestData: ListRequestData) {
        const data = await EenApi.list<Log>('log/list', requestData);
        return data;
    }
}

export const LogService = new CLogService();
