export enum EntityQueryKey {
  Client = 'client',
  User = 'user',
  Profile = 'profile',
  ProfileInterest = 'profileinterest',
  Center = 'center',
  Drive = 'drive',
  ExternalProvider = 'externalprovider',
  InnovationProject = 'innovationproject',
  Log = 'log',
  Mailing = 'mailing',
  MailingUnsubscribe = 'unsubscribe',
  News = 'news',
  Notification = 'notification',
  Role = 'role',
  Specialist = 'specialist',
  Support = 'support',
  SupportSubject = 'supportsubject',
}