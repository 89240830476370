import { HubConnection } from '@microsoft/signalr';
import { Middleware } from '@reduxjs/toolkit';
import { EenApi } from '../../../../eenApi';
import { ToastManager } from '../../../../service/toastManager';
import { SocketActionEnum } from '../types';
import { setupSignalRConnection } from './socket';

const connectionHub = '/hub';

var connection: HubConnection;

export const SignalRMiddleware: Middleware = (api) => (next) => (action: { type: string; payload: any }) => {
    if (!connection) {
        connection = setupSignalRConnection(connectionHub, EenApi.getAccessToken);
        connection.on('receiveMessage', (userName, message) => {
            ToastManager.test({ message: message, title: userName });
        });
    } else {
        switch (action.type) {
            case SocketActionEnum.SEND_MESSAGE:
                connection.invoke('sendMessage', action.payload).catch((e) => console.log(e));
                break;
        }
    }

    return next(action);
};
