import { SelectItemDto } from "../common/select.item.dto";
import LanguageEnum, { isSupportedLanguage } from "../../enums/language.enum";

export class Notification {
    id?: number;
    name = '';
    type: string|null = null;
    active = true;
    isEmail = true;
    emailSubject = '';
    emailText = '';
    isFeed = false;
    feedText = '';
    isPush = false;
    pushLink = '';
    pushText = '';
    isAllUsersReceive = false;
    roles: SelectItemDto[] = [];
    roleIds: number[];
    copyEmailRecipients = '';
    language: LanguageEnum;
    created?: string;
    lastModified?: string;

    constructor(entity: Notification|undefined, language: string) {
        Object.entries(entity ?? {}).forEach(([key, val]) => {
            if (this.hasOwnProperty(key)) {
                this[key as keyof Notification] = val as never
            }
        });

        this.language = entity?.language ??
            isSupportedLanguage(language) ? language as LanguageEnum : LanguageEnum.EN;
        this.roleIds = entity?.roles?.map(r => Number(r.id)) ?? [];
    }
}
