import React, { useContext, useEffect, useRef, useState } from 'react';
import { Box, Chip, FormControl, FormHelperText, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { SelectValue } from '../../../select/SelectValue';
import IControlProps from '../control.props';
import { StyledInputLabel } from '../index';
import InputContainer from '../../../inputContainer';
import { useValidation } from '../../../../hooks/UseValidation';
import useTranslation from '../../../../hooks/translation.hook';
import ValidationContext from '../../../../hooks/UseValidation/validation.context';
import styled from '@emotion/styled';
import './SelectControl.css';

interface IProps extends IControlProps {
    options: SelectValue[];
    nullable?: boolean;
    multiple?: boolean;
}

const StyledMuiMenuItem = styled(MenuItem)`
  word-break: normal;
  white-space: pre-line;
  z-index: 2000;
`;

export default function SelectControl(props: IProps) {
    const { t } = useTranslation();
    const validate = useValidation(props.validators);
    const [errorText, setErrorText] = useState<string>();
    const { validateOn } = useContext(ValidationContext);
    const select = useRef<HTMLInputElement>(null);

    useEffect(() => {
        setErrorText(props.error || validate(props.value, props.tab ?? 'main'));
    }, [props.value, props.error, validateOn]);

    return (
        <InputContainer>
            <FormControl required={props.required} error={!!errorText} fullWidth size='small'>
                <StyledInputLabel>{t(props.labelKey)}</StyledInputLabel>
                <Select ref={select} label={t(props.labelKey ?? 'main:field.language')}
                        value={props.value}
                        onChange={(event: SelectChangeEvent) => {
                            props.onChange(event.target.value);
                        }}
                        multiple={props.multiple}
                        disabled={props.disabled}
                        renderValue={props.multiple ? (selected) => (
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                {(selected as unknown as any[]).map((value) => (
                                    <Chip key={value} label={props.options.find(o => o.value === value)!.label}
                                          onDelete={() => {
                                              props.onChange(props.value?.filter((v: any) => v !== value));
                                              if (select.current != null) {
                                                  select.current.blur(); //TODO при удалении всех не снимается фокус с поля. Нужно это исправить.
                                              }
                                          }}
                                          onMouseDown={(event) => event.stopPropagation()} />
                                ))}
                            </Box>
                        ) : undefined}
                >
                    {props.nullable &&
                        <StyledMuiMenuItem key={-1} value=''>{t('main:select.unset')}</StyledMuiMenuItem>}
                    {props.options.map((option, idx) => <StyledMuiMenuItem key={idx}
                                                                           value={option.value}>{t(option.label)}</StyledMuiMenuItem>)}
                </Select>
                <FormHelperText>{errorText}</FormHelperText>
            </FormControl>
        </InputContainer>
    );
}