enum ProfileStatusEnum {
   None = "None",
   Draft = "Draft",
   Validation = "Validation",
   CommentsReceived = "CommentsReceived",
   Active = "Active",
   Archived = "Archived",
   UploadedToEEN = "UploadedToEEN",
   ValidatedInEEN = "ValidatedInEEN",
   BlockedInEEN = "BlockedInEEN",
   ArchivedInEEN = "ArchivedInEEN",
}

export default ProfileStatusEnum;