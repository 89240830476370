import { TableColumn, TableGroupAction, TableRowAction, TableSettings } from './table.model';
import { QueryClient } from 'react-query';
import useTranslation from '../../../../hooks/translation.hook';
import { useMemo } from 'react';
import { GridSelectionModel } from '@mui/x-data-grid';
import { useHistory } from 'react-router-dom';
import Utils from "../../../../utils";

export function saveSettings(queryClient: QueryClient, key: string, settings: TableSettings) {
    Utils.saveToLocalStorage(`table-${key}-settings`, JSON.stringify(settings));
    queryClient.setQueryData(`table-${key}-settings`, settings);
}

export function loadSettings(key: string, onPage?: number, sortBy?: string, sortOrder?: string) {
    return new TableSettings(Utils.getFromLocalStorage(`table-${key}-settings`), onPage, sortBy, sortOrder);
}

//---------------------------- COMMON COLUMNS ----------------------------
export function useIdColumn<T>(headerName?: string) {
    const { t, i18n } = useTranslation();
    return useMemo(
        () =>
            new TableColumn<T>({
                headerName: t(headerName ?? 'main:field-id'),
                field: 'id',
                sortable: true,
                width: 65,
            }),
        [i18n.language],
    );
}

//------------------------------------------------------------------------

//---------------------------- COMMON ACTIONS ----------------------------
interface RowActionsOptions<T> {
    edit?: ((row: T) => string) | null;
    hideEdit?: (row: T) => boolean;
    remove?: ((row: T) => Promise<any>) | null;
    hideRemove?: (row: T) => boolean;
}

export function useRowActions<T>(options: RowActionsOptions<T>, actions?: TableRowAction<T>[], memoDeps?: any[]) {
    const { t, i18n } = useTranslation();
    const history = useHistory();
    const clickNavigate = (link: string) => history.push(link);

    return useMemo(() => {
        let items: TableRowAction<T>[] = [];

        if (options.edit) {
            items.push({
                id: 'edit',
                label: t('main:btn-edit'),
                action: (row: T) => clickNavigate(options.edit!(row)),
                refreshTableAfterAction: false,
                isHide: options.hideEdit,
            });
        }

        if (actions) {
            items = items.concat(actions);
        }

        if (options.remove) {
            items.push({
                id: 'delete',
                label: t('main:btn-delete'),
                action: (row: T) => options.remove!(row),
                isHide: options.hideRemove,
            });
        }

        return items;
    }, [i18n.language, ...(memoDeps ?? [])]);
}

interface GroupActionsOptions {
    remove?: ((selected: GridSelectionModel) => Promise<any>) | null;
}

export function useGroupDeleteAction({ remove }: GroupActionsOptions, ...memoDeps: any[]) {
    const { t, i18n } = useTranslation();
    return useMemo(() => {
        const items: TableGroupAction[] = [];
        if (remove) {
            items.push({
                id: 'delete',
                label: t('main:btn-delete'),
                action: (selected) => remove(selected),
            });
        }
        return items;
    }, [i18n.language, ...memoDeps]);
}

//------------------------------------------------------------------------
