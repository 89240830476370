import { FC } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { MDBNavbar, MDBNavbarNav } from 'mdb-react-ui-kit';
import LoginMenu from '../auth-api/LoginMenu';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import Logo from '../../images/logoinversia.png';

const Header: FC = () => {
    return (
        <MDBNavbar className="mb-3 bg-een-black" expand="lg" dark>
            <div className="container-fluid">
                <NavBrand />
                <MDBNavbarNav right fullWidth={false} className="mb-2 mb-lg-0">
                    <LoginMenu />
                </MDBNavbarNav>
            </div>
        </MDBNavbar>
    );
};

const NavBrand: FC = () => {
    const { pageTitle, backLink } = useTypedSelector((state) => state.layout);
    const history = useHistory();

    return (
        <div className="navbar-brand">
            <div className="d-flex d-md-none">
                {backLink && backLink.length > 0 ? (
                    <>
                        <a
                            className="my-auto me-2"
                            onClick={() => {
                                if (history.length > 1) {
                                    history.goBack();
                                } else {
                                    history.replace(backLink);
                                }
                            }}
                        >
                            <i className="fas fa-arrow-left"></i>
                        </a>
                    </>
                ) : (
                    <Link to="/">
                        <img src={Logo} alt="logo" height={56} />
                    </Link>
                )}
                {pageTitle && pageTitle.length > 0 && <span className="navbar-brand">{pageTitle}</span>}
            </div>
            <div className="d-none d-md-block">
                <Link to="/">
                    <img src={Logo} alt="logo" height={56} width={56} />
                </Link>
                <span className="white ms-3">
                    INTERNATIONALIZATION DIGITAL PLATFORM
                </span>
            </div>
        </div>
    );
};

export default Header;
