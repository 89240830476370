import {DriveEntity} from "../../../../eenApi/drive/driveEntity";
import styles from './index.module.css'
import DriveFileDto from '../../../../eenApi/drive/dto/drive.file.dto';
import FileSvg from '../../../../images/files/xls.svg';
import styled from '@emotion/styled';
import { DriveService } from "../../../../eenApi/drive/service";
import { createRef, useMemo } from "react";

const StyledImg = styled.img`
  padding: 8px 10px;
  border-radius: 4px;
  width: 32px;
  height: 32px;
  align-items: center;
  background-color: #82C250;
`;

const FileName = styled.div`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  color: #212121;
`;
const FileLength = styled.div`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  color: #929FAA;
`;

interface IProps {
   file: DriveEntity|DriveFileDto|undefined
}

export default function FileItem({file}: IProps) {

  const link = createRef<HTMLAnchorElement>();

  const download = useMemo(() => async (id: string|undefined, fileName: string) => {
    if (!id || !link.current || link.current.href) { return }

    const href = window.URL.createObjectURL(await DriveService.download(id))

    link.current.download = fileName
    link.current.href = href
    link.current.click()
  }, []);

  const getLink = () => {
    if (file?.source?.length) {
      return {
        href: `/${file.source.replace('\\', '/')}`
      }
    }

    return {
        ref: link,
        onClick: () => download(file?.id, file?.name ?? '')
    };
  }

   return file ? (
      <div className="d-flex">
          <a key={file.id} className='d-flex align-items-center cursor-pointer' download target="_blank" {...getLink() }>
              <StyledImg src={`${FileSvg}`} />
              <div className='ps-2'>
                  <FileName>{file.name}</FileName>
                  <FileLength>{(file.contentLength! / 1024 / 1024).toFixed(2)} Mb</FileLength>
              </div>
          </a>
         <span className={styles.fileDescription}>{file.description}</span>
      </div>
   ) : <></>
}