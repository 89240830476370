import useTranslation from "../../hooks/translation.hook";

interface IProps {
    title: string,
    text: string
}

const ViewTextEditorField: React.FunctionComponent<IProps> = props => {
    const { t } = useTranslation();//todo something better than dangerouslySetInnerHTML
    return (
        <div className="mb-2">
            <div><strong>{t(props.title)}</strong></div>
            <div dangerouslySetInnerHTML={{ __html: props.text }}></div>
        </div>
    );
}

export default ViewTextEditorField;