import { FC } from 'react';
import { useSpring, animated } from 'react-spring';

interface IProps {
    children?: React.ReactNode;
}

const Modal: FC<IProps> = (props) => {
    const springContentProps = useSpring({
        from: {
            opacity: 0,
            transform: `translate3d(0,-40px,0) scale(0.8) rotate(-5deg)`,
        },
        to: {
            opacity: 1,
            transform: `translate3d(0,0,0) scale(1) rotate(0deg)`,
        },
    });
    return (
        <animated.div className="filter-modal" style={springContentProps}>
            {props.children}
        </animated.div>
    );
};

export default Modal;
