import { FC, useContext } from 'react';
import useTranslation from '../../../../hooks/translation.hook';
import { Link } from 'react-router-dom';
import PageTitle from '../../../../component/pageTitle';
import MainContainer from '../../../../component/mainContainer';
import Rules from '../../../../rules';
import { UserContext } from '../../../../context/user-context';
import InfoMaterialTable from './table';
import { SupportRoutesPath } from '../../routes';

const SubjectsList: FC = () => {
    const { t } = useTranslation();
    const context = useContext(UserContext);

    return (
        <MainContainer>
            <PageTitle text={t('support:subjects.list.title')} backLink='/' />
            <div className='d-xl-flex mb-1 mb-xl-3'>
                <div className='app-filter-actions mb-2 mb-xl-0'>
                    {context.checkAccess(Rules.Support.AddSubject) &&
                        <Link className='btn btn-success me-2'
                              to={SupportRoutesPath.CreateSubject}>{t('support:subjects.list.button-add')}</Link>
                    }
                </div>
            </div>
            <InfoMaterialTable filterValues={{}} />
        </MainContainer>
    );
};

export default SubjectsList;