import { Route, Switch } from 'react-router-dom';
import NotFound from '../../home/notFound';
import { ClientEdit } from '../edit';
import ClientList from '../list';
import ProtectedRoute from '../../../component/common/ProtectedRoute';
import Rules from '../../../rules';
import ClientDetails from '../details';

export default function ClientRoutes() {
    return (
        <Switch>
            <ProtectedRoute rule={Rules.Client.List} exact path={ClientRoutesPath.List} component={ClientList} />
            <ProtectedRoute rule={Rules.Client.Edit} exact path={ClientRoutesPath.Edit} component={ClientEdit} />
            <ProtectedRoute rule={Rules.Client.Add} exact path={ClientRoutesPath.Create} component={ClientEdit} />
            <ProtectedRoute rule={[Rules.Client.ViewBasic, Rules.Client.ViewBasicOwn,
              Rules.Client.ViewContacts, Rules.Client.ViewContactsOwn, Rules.Client.Edit]}
                            path={ClientRoutesPath.View} exact component={ClientDetails} />
            <Route path='*' component={NotFound} />
        </Switch>
    );
}

const prefix = '/client'
export const ClientRoutesPath = {
    Prefix: prefix,
    List: `${prefix}/my`,
    Edit: `${prefix}/:id/edit/`,
    Create: `${prefix}/create/`,
    View: `${prefix}/:id/details`,
};
