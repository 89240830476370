import { Dispatch, SetStateAction, useState } from 'react';
import useTranslation from '../translation.hook';
import { useHistory, useParams } from 'react-router-dom';
import { QueryClient, useQueryClient } from 'react-query';
import { TFunction } from 'react-i18next';
import { i18n } from 'i18next';
import * as H from 'history';

interface ICommonStates {
    t: TFunction,
    i18n: i18n,
    history: H.History<unknown>,
    queryClient: QueryClient,
    activeTab: string,
    setActiveTab:  Dispatch<SetStateAction<string>>
}

interface ICommonEntityStates extends ICommonStates {
    id: string,
}

export function useCommonStates(): ICommonStates {
    const { t, i18n } = useTranslation();
    const history = useHistory();
    const queryClient = useQueryClient();
    const [activeTab, setActiveTab] = useState(queryClient.isFetching() ? 'loading' : 'main');

    return { t, i18n, history, queryClient, activeTab, setActiveTab };
}

export function useCommonEntityStates(): ICommonEntityStates {
    return {
        ...useCommonStates(),
        id: useParams<{ id: string }>().id
    }
}