import React, { Suspense, useMemo, useState } from 'react';
import useTranslation from '../../../hooks/translation.hook';
import { NewsRoutesPath } from '../routes';
import MainContainer from '../../../component/mainContainer';
import PageTitle from '../../../component/pageTitle';
import Loader from '../../../component/loader';
import BottomActionsContainer from '../../../component/actions/bottomActionsContainer';
import BottomActions from '../../../component/actions/bottomActions';
import TabItem from '../../../component/tabs/tabItem';
import TabControls from '../../../component/tabs/tabControls';
import ValidationContext from '../../../hooks/UseValidation/validation.context';
import Utils from '../../../utils';
import { useHistory, useParams } from 'react-router-dom';
import { NewsEntity } from '../../../eenApi/news/news';
import { ErrorHandler } from '../../../service/errorHandler';
import { ToastManager } from '../../../service/toastManager';
import { NewsService } from '../../../eenApi/news/service';
import TopActionsContainer from '../../../component/actions/topActionsContainer';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import NewsValidator from '../../../utils/validators/news/edit.validator';
import NewsMainTab from '../../../component/news/NewsMainTab';
import NewsEditDto from '../../../eenApi/news/dto/news.edit.dto';
import NewsDto from '../../../eenApi/news/dto/news.dto';

const DetailTab = React.lazy(() => import('../../../component/news/DetailTab'));
const AnnouncementTab = React.lazy(() => import('../../../component/news/AnnouncementTab'));

export default function EditNews() {
    const { t } = useTranslation();
    const history = useHistory();
    const queryClient = useQueryClient();
    const { id } = useParams<{ id?: string }>();

    const [tabs, setTabs] = useState<string[]>([]);
    const [validateOn, setValidateOn] = useState(false);
    const [activeTab, setActiveTab] = useState('main');
    const [isRoutePathCreate] = useState(history.location.pathname === NewsRoutesPath.Create);

    let showDetails = true;

    const updateData = (field: keyof NewsEntity, value: any) => {
        let newValue = value?.value ?? value;
        setData({ ...data!, [field]: Array.isArray(newValue) ? [...newValue] : newValue });
    };

    let entityId = Utils.getEntityId(id || '');

    const { data: entity, isFetching } = useQuery<NewsDto>(['news', entityId], {
        onSuccess: async (data) => {
            setData(new NewsEditDto(data));
        },
        onError: async (err) => {
            if (isNaN(entityId)) {
                history.push('*');
            }
            ErrorHandler.handle('news refreshData', err);
        },
        enabled: !isRoutePathCreate,
    });

    const [data, setData] = useState<NewsEditDto>(isRoutePathCreate ? new NewsEditDto() : new NewsEditDto(entity));

    const updateMutation = useMutation(() => NewsService.addOrUpdate(data), {
        onSuccess: async (data) => {
            ToastManager.success({ title: 'main:msg-success', message: 'main:msg-changes-saved' });
            await queryClient.invalidateQueries(['news', id]);
            if (!showDetails) {
                history.push(`${NewsRoutesPath.Edit.replace(':id', data.toString())}`);
            } else {
                history.push(`${NewsRoutesPath.View.replace(':id', data.toString())}`);
            }
        },
        onSettled: (data, error) => {
            if (error) {
                setActiveTab('main');
            }
            console.log(data);
        },
    });

    const save = async (stay: boolean) => {
        setValidateOn(true);
        showDetails = !stay;
        window.scrollTo(0, 0);

        const erroredTabs = NewsValidator.validate(data!, tabs);
        setTabs(erroredTabs);

        if (erroredTabs.length === 0) {
            updateMutation.mutate();
        }
    };

    const tabItems = useMemo(() => {
        return [
            new TabItem('main', t('news:tabs.news')),
            new TabItem('announcement', t('news:tabs.announcement')),
            new TabItem('detail', t('news:tabs.detail')),
        ];
    }, []);

    const actions = [
        {
            name: t('main:btn-save'),
            onClick: async () => save(false),
        },
        {
            name: t('main:btn-apply'),
            onClick: async () => save(true),
        },
        {
            name: t('main:btn-cancel'),
            onClick: async () => {
                await queryClient.invalidateQueries(['news', id]);
                if (isRoutePathCreate) {
                    history.goBack();
                } else {
                    history.push(`${NewsRoutesPath.View.replace(':id', entityId.toString())}`);
                }
            },
            color: '#8E9FA7',
        },
    ];

    return (
        <MainContainer>
            <PageTitle text={t(!isRoutePathCreate ? 'news:edit-title' : 'news:create-title')} backLink={NewsRoutesPath.CenterList} />
            <TopActionsContainer />
            <ValidationContext.Provider value={{ validateOn, tabs, setTabs }}>
                <TabControls selectedId={activeTab} items={tabItems} onChange={setActiveTab} errored={validateOn ? tabs : []} />
                {isFetching ? (
                    <Loader />
                ) : (
                    <>
                        <NewsMainTab id="main" activeId={activeTab} updateData={updateData} data={data} />
                        <Suspense fallback={<Loader />}>
                            <AnnouncementTab activeId={activeTab} id="announcement" data={data} updateData={updateData} />
                            <DetailTab activeId={activeTab} id="detail" data={data} entity={entity} updateData={updateData} />
                        </Suspense>
                        <BottomActionsContainer>
                            <BottomActions actions={actions} />
                        </BottomActionsContainer>
                    </>
                )}
            </ValidationContext.Provider>
        </MainContainer>
    );
}
