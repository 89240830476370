import { useLocation } from 'react-router-dom';
import MainContainer from '../../component/mainContainer';
import PageTitle from '../../component/pageTitle';
import useTranslation from '../../hooks/translation.hook';

export default function NotFound() {
    const location = useLocation();
    const {t} = useTranslation();
    return (
        <MainContainer>
            <PageTitle text={t('main:notFoundPage.title')} backLink="/" />
            <h4>{t('main:notFoundPage.reasonsTitle')}</h4>
            <ul>
                {(t('main:notFoundPage.reasons', { returnObjects: true, location: location.pathname}) as string[])
                    .map(item => <li>{item}</li>)}
            </ul>
            <h4>{t('main:notFoundPage.solutionsTitle')}</h4>
            <ul>
                {(t('main:notFoundPage.solutions', { returnObjects: true, location: location.pathname}) as string[])
                    .map(item => <li dangerouslySetInnerHTML={{ __html: item }}/>)}
            </ul>
        </MainContainer>
    );
}