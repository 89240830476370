import useTranslation from "../../../hooks/translation.hook";
import PageTitle from '../../../component/pageTitle';
import RicTable from './table';
import MainContainer from "../../../component/mainContainer";

export default function UserList() {
    const { t } = useTranslation();

    return (
        <MainContainer>
            <PageTitle text={t('user:menu-title')} backLink="/" />
            <RicTable filterValues={{}} />
        </MainContainer>
    );
}
