import TabPanel from '../../tabs/tabPanel';
import React from 'react';
import { Field } from '../../common/layout';
import IDetailsPanelProps from '../details.panel.props';
import FileList from '../../common/layout/FileList';


export default function MainDetailsTab({ entity, ...rest }: IDetailsPanelProps) {
    return (
        <TabPanel {...rest}>
            <Field labelKey='client:field.inn'>{entity.inn}</Field>
            <Field labelKey='client:field.name'>{entity.name}</Field>
            <Field labelKey='client:field.short-name'>{entity.shortName}</Field>
            <Field labelKey='client:field.centerId'>{entity.center?.name}</Field>
            <Field labelKey='client:field.keyWords' optional value={entity.keyWords} />
            <Field labelKey='client:field.type' optional>{entity.typeView}</Field>
            <Field labelKey='client:field.foundation-year'>{entity.foundationYear}</Field>
            <Field labelKey='client:field.annual-turnover'>{entity.annualTurnoverView}</Field>
            <Field labelKey='client:field.certificaion-standart' optional
                   value={entity.certificationStandartsView} />
            <Field labelKey='client:field.language-proficiency' optional
                   value={entity.languageProficiencyView} />
            <Field labelKey='client:field.has-international-experience' value={entity.hasInternationalExperience} />
            <Field labelKey='client:field.competency-assessment'>{entity.competencyAssessment}</Field>
            <Field labelKey='client:field.agreement'>
                <FileList files={entity.agreement}/>
            </Field>
        </TabPanel>
    );
}