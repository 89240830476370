import TabPanel, { ITabPanelProps } from '../../tabs/tabPanel';
import React, { useCallback, useContext, useMemo } from 'react';
import useTranslation from '../../../hooks/translation.hook';
import { Link } from 'react-router-dom';
import { ClientService } from '../../../eenApi/client/service';
import Rules from '../../../rules';
import { UserContext } from '../../../context/user-context';
import ProfileDto from '../../../eenApi/profile/dto/profile.dto';
import { ProfileRoutesPath } from '../../../page/profile/routes';
import { MemberCountryRoutesPath } from '../../../page/membercountry/routes';
import ProfileService from '../../../eenApi/profile/service';
import ProfileTypeEnum from '../../../eenApi/profile/enum/profile.type.enum';
import { useCanViewClient } from '../../../page/client/access.hook';
import { TableColumn } from '../../common/layout/DataTable/table.model';
import { DataTable } from '../../common/layout';
import { EntityQueryKey } from '../../../query/query.keys';

interface IProps extends ITabPanelProps {
    innovationProjectId: number;
}

export function ProfilesTab({ innovationProjectId, ...rest }: IProps) {
    const { t, i18n } = useTranslation();
    const { checkAccess } = useContext(UserContext);
    const profileAccess = useCallback(
        (objectId: number | undefined) =>
            checkAccess(Rules.Profile.ViewBasic) ||
            checkAccess(Rules.Profile.ViewBasicOwn, [String(objectId)]) ||
            checkAccess(Rules.Profile.ViewContact) ||
            checkAccess(Rules.Profile.ViewContactOwn, [String(objectId)]) ||
            checkAccess(Rules.Profile.ViewValidation) ||
            checkAccess(Rules.Profile.ViewValidationOwn, [String(objectId)]),
        [],
    );
    const canViewClient = useCanViewClient();

    const header = useMemo<TableColumn<ProfileDto>[]>(
        () => [
            new TableColumn({
                headerName: t('main:field-id'),
                field: 'id',
            }),
            new TableColumn({
                headerName: t('profile:fields.title'),
                field: 'title',
                renderCell: (params) =>
                    profileAccess(params.row.client?.center?.id)
                      ? <Link to={`${ProfileRoutesPath.View.replace(':id', params.id.toString())}`}>{params.value}</Link>
                      : params.value
            }),
            new TableColumn({
                headerName: t('profile:fields.clientId'),
                field: 'name',
                renderCell: (params) =>
                    canViewClient(params.row.client)
                      ? <Link to={ClientService.getViewLink(params.row.client?.id)}>{params.row.client?.name}</Link>
                      : params.row.client?.name
            }),
            new TableColumn({
                headerName: t('profile:fields.profileType'),
                field: 'type',
                valueFormatter: (params) => t(`profile:type.${params.value}`),
            }),
            new TableColumn({
                headerName: t('profile:fields.memberCountryId'),
                field: 'memberCountryId',
                renderCell: (params) =>
                    checkAccess(Rules.MemberCountry.View)
                      ? <Link to={MemberCountryRoutesPath.View.replace(':id', String(params.value))}>{params.row.memberCountry?.name}</Link>
                      : params.row.memberCountry?.name
            }),
        ],
        [i18n.language],
    );

    return (
        <TabPanel {...rest}>
            <div className="mb-2">
                <DataTable
                    id="innovation-project-profiles-table"
                    queryKey={EntityQueryKey.Profile}
                    filterValues={{
                        innovationProjectId,
                        type: ProfileTypeEnum.ResearchRequest,
                    }}
                    columns={header}
                    fetchData={(requestData) => ProfileService.list(requestData, 'een')}
                />
            </div>
        </TabPanel>
    );
}
