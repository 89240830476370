import { ReactComponent as CloseIcon } from '../../icons/close.svg';
import { ReactComponent as BackIcon } from '../../icons/back.svg';
import { FC } from 'react';

interface IProps {
    title: string,
    onRequestClose: any,
    onClickBack?: any,
    hideBorder?: boolean
}

const ModalHeader: FC<IProps> = (props) => {
    return (
        <div className={`modal-header px-2 py-1 mt-1 ${props.hideBorder ? 'border-0' : ''}`}>
            {props.onClickBack
                ? <button type="button" className="close px-2 py-1" onClick={props.onClickBack}>
                    <span aria-hidden="true"><BackIcon /></span>
                </button>
                : <div></div>
            }
            {props.title &&
                <p className="modal-title">
                    {props.title}
                </p>
            }
            <button type="button" className="close px-2 py-1" aria-label="Close" onClick={props.onRequestClose}>
                <span aria-hidden="true"><CloseIcon /></span>
            </button>
        </div>
    );
}

export default ModalHeader;
