import TabPanel, { ITabPanelProps } from '../../../tabs/tabPanel';
import { Field } from '../../../common/layout';
import React from 'react';
import useTranslation from '../../../../hooks/translation.hook';
import Loader from '../../../loader';
import SupportDto from '../../../../eenApi/support/supports/dto/support.dto';

interface IProps extends ITabPanelProps {
    entity: SupportDto | undefined;
}

export default function MainTab({ entity, ...rest }: IProps) {
    const { t } = useTranslation();

    //TODO files after back fix
    return (
        <TabPanel {...rest}>
            {!entity?.id ? (
                <Loader />
            ) : (
                <>
                    <Field labelKey="support:supports.fields.title">{entity.title}</Field>
                    <Field labelKey="support:supports.fields.supportSubject-name">{entity.supportSubject.name}</Field>
                    <Field labelKey="support:supports.fields.text">{entity.text}</Field>
                    <Field labelKey="support:supports.fields.files">{entity.text}</Field>
                    <Field labelKey="support:supports.fields.status.title">{t(`support:supports.fields.status.${entity.status}`)}</Field>
                    <Field labelKey="support:supports.fields.criticality.title">{t(`support:supports.fields.criticality.${entity.criticality}`)}</Field>
                </>
            )}
        </TabPanel>
    );
}
