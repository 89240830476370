import LanguageEnum from "../../enums/language.enum";

export default class TabItem {
    id: string
    text: string;
    lang?: LanguageEnum

    constructor(id: string, text: string, lang?: LanguageEnum) {
        this.id = id;
        this.text = text;
        this.lang = lang;
    }
}