import { FC, useEffect, useState } from 'react';
import { MDBBtn } from 'mdb-react-ui-kit';
import { InputControl } from '../index';
import useTranslation from '../../../../hooks/translation.hook';
import { useMutation, UseMutationResult } from 'react-query';
import { ToastManager } from '../../../../service/toastManager';
import ValidationCommentService from '../../../../eenApi/validationComment/service';
import ValidationCommentDto from '../../../../eenApi/validationComment/dto/validationComment.dto';
import { ObjectType } from '../../../../enums/objectType';
import ValidationCommentEditDto from '../../../../eenApi/validationComment/dto/validationComment.edit.dto';
import ViewField from '../../../fields/viewField';
import ModalHeader from '../../../modal/modalHeader';
import BaseModal from '../../../modal/baseModal';
import styled from '@emotion/styled';
import { Box } from '@mui/material';
import { formatDateTime } from "../../../../utils/date.utils";

interface IComponentProps {
    objectId: number;
    objectType: ObjectType;
    fieldName: string;
    comment?: string;
    editable: boolean;
}

const StyledButton = styled.a`
    text-decoration: none;
    color: #b2bbc2;
    fill: #b2bbc2;
    cursor: pointer;
    &:hover {
        color: #b2bbc2;
    }
`;

export const ValidationComment: FC<IComponentProps> = ({ objectId, objectType, fieldName, editable}) => {
    const { t, i18n } = useTranslation();

    const [onEditComment, setOnEditComment] = useState(false);
    const [onValidate, setOnValidate] = useState(false);

    const setDefaultComment = (value: string) => {
        updateData('comment', value);
    };

    const loadData = async () => {
        const requestData = {
            objectId: objectId,
            objectType: objectType,
            language: i18n.language,
        };

        const result = await ValidationCommentService.list(requestData, fieldName);

        setDefaultComment(result[0]?.comment);

        setDataList(result!);
    };

    const [dataList, setDataList] = useState<ValidationCommentDto[]>([]);

    const [data, setData] = useState<ValidationCommentEditDto>({
        objectId: objectId,
        objectType: objectType,
        language: i18n.language,
        fieldName: fieldName,
        comment: '',
    });

    const updateData = (key: string, value: any) => {
        const newValue = {
            ...data,
            [key]: value,
        };
        setData(newValue);
    };

    const mutation = useMutation(() => ValidationCommentService.set(data!), {
        onSuccess: async () => {
            ToastManager.success({ title: 'main:msg-success', message: 'main:msg-comment-success-add' });
            showResult();
        },
    });

    const [isDeleteOpen, setIsDeleteOpen] = useState(false);

    const openDelete = () => setIsDeleteOpen(true);
    const closeDelete = () => setIsDeleteOpen(false);

    const deleteMutation = useMutation(() => ValidationCommentService.delete(data!), {
        onSuccess: async () => {
            ToastManager.success({ title: 'main:msg-success', message: 'main:msg-entity-deleted' });
            showResult();
            closeDelete();
        },
    });

    const executeModalAction = (mutation: UseMutationResult<any, unknown, void>) => {
        mutation.mutate();
    };

    const saveComment = () => {
        mutation.mutate();
    };

    const addComment = () => {
        setOnValidate(true);
    };

    const editComment = () => {
        setOnEditComment(true);
    };

    const showResult = () => {
        setOnValidate(false);
        setOnEditComment(false);
    };

    const cancelComment = () => {
        setOnValidate(false);
        setOnEditComment(false);
        setDefaultComment(dataList[0].comment);
    };

    useEffect(() => {
        loadData();
    }, [onEditComment, onValidate, isDeleteOpen]);

    return (
        <>
            {dataList.length > 0 || onValidate ? (
                <small>
                    {onEditComment || onValidate ? (
                        <Box className="">
                            <InputControl
                                labelKey="main:field.validate"
                                onChange={(value) => updateData('comment', value)}
                                value={data.comment}
                                multiline
                            />
                            <div className="mb-2 flex flex-gap-2" style={{marginTop: '-1rem'}}>
                                <StyledButton type="button" className="me-2" onClick={saveComment}>
                                    {t('main:btn-save')}
                                </StyledButton>
                                <StyledButton type="button" onClick={cancelComment}>
                                    {t('main:btn-cancel')}
                                </StyledButton>
                            </div>
                        </Box>
                    ) : (
                        <Box className="">
                            <div className="rounded-4 bg-grey py-2 px-2">
                                <ViewField title="">
                                    {dataList.map((item, index) => (
                                      <div key={index}>
                                          {`${formatDateTime(item.modified)} - ${item.modifiedBy?.fullName}`}<br/>
                                          <div dangerouslySetInnerHTML={{ __html: item.comment?.replaceAll('\n', '<br>') }}/>
                                      </div>
                                    ))}
                                    {editable && <>
                                        <StyledButton type="button" className="me-2" onClick={editComment}>
                                            {t('main:btn-edit')}
                                        </StyledButton>
                                        <StyledButton type="button" onClick={openDelete}>
                                            {t('main:btn-delete')}
                                        </StyledButton>
                                        <BaseModal isOpen={isDeleteOpen} onRequestClose={closeDelete} modalSize="s">
                                            <ModalHeader title={t('main:msg-entity-delete-confirm')} onRequestClose={closeDelete} />
                                            <div className="text-center p-3">
                                                <MDBBtn onClick={() => executeModalAction(deleteMutation)}>{t('main:yes')}</MDBBtn>
                                            </div>
                                        </BaseModal>
                                    </>}
                                </ViewField>
                            </div>
                        </Box>
                    )}
                </small>
            ) : (
                <small>
                    {editable && <StyledButton type="button" className="mb-2" onClick={addComment}>
                        {t('main:btn-addValidateComment')}
                    </StyledButton>}
                </small>
            )}
        </>
    );
};
