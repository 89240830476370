import { EenApi } from '..';
import { SelectValue } from '../../component/select/SelectValue';
import { CrudService } from '../common/service/crud.service';
import { Role } from './role';

class CRoleService {
    list(requestData: any) {
        return EenApi.list<Role>('role/list', requestData);
    }

    // async get(id: number) {
    //     const data = await LmsApi.post<Client>('/client/get', { id: id });
    //     return data;
    // }

    // async addOrUpdate(entity: Client) {
    //     if (entity.id < 1) {
    //         return await LmsApi.post<number>('/client/add', entity);
    //     }
    //     return await LmsApi.post<number>('/client/edit', entity);
    // }

    delete(ids: number[]) {
        return EenApi.delete('role/delete', { ids: ids });
    }

    getEditLink(id: number) {
        return `/role/${id}/edit`;
    }

    async selectLoadOptions(inputValue: string | (string | number)[], language: string, skip: number = 0): Promise<SelectValue[]> {
        const filter: Record<string, any> = {
            skip,
            name: typeof inputValue === 'string' ? inputValue?.trim() : undefined,
        };

        const rd = {
            filter,
            language,
        };
        const data = await RoleService.list(rd);

        const ids = Array.isArray(inputValue) ? inputValue.map((id) => String(id)) : null;
        return data.items
            .filter((item) => !ids || ids.includes(String(item.id)))
            .map((item) => ({
                value: `${item.id}`,
                label: item.name ?? '',
            }));
    }
}

export const RoleService = new CRoleService();
