import { DictionaryEntity } from "../../models/dictionaryEntity";
import DriveFileDto from "../drive/dto/drive.file.dto";
import IdNameItemDto from "../common/id.name.item.dto";
import { UserRoleData } from "../user/userRoleData";
import { ObjectType } from "../../enums/objectType";
import { SelectItemDto } from "../common/select.item.dto";

export class Client {
    id = 0;

    centerId: number|null;
    center?: IdNameItemDto;
    type?: string;
    typeView?: string;
    annualTurnover: string|null = null;
    annualTurnoverView: string|null = null;
    certificationStandarts: string[] = [];
    certificationStandartsView: string[] = [];
    languageProficiency: string[] = [];
    languageProficiencyView: string[] = [];

    hasInternationalExperience = false;

    competencyAssessment?: string;

    inn?: string;
    kpp?: string;
    ogrn?: string;
    okpo?: string;
    okved?: string;

    marketCodes?: string[];
    naceCodes?: string[];
    techCodes?: string[];

    name?: string;
    shortName?: string;
    foundationYear?: number;

    contactFullName?: string;
    contactPosition?: string;
    legalAddress?: string;
    actualAddress?: string;

    country: string|null = null
    countryEntity?: DictionaryEntity;
    memberCountryId: number|null;
    memberCountry?: SelectItemDto;
    region: string|null = null
    city?: string;
    contactPhone?: string;
    contactEmail?: string;
    directorFullName?: string;
    directorPhone?: string;
    directorEmail?: string;
    directorPosition?: string;
    communicationPreferred?: string;

    web?: string;

    eventCountries?: string[];
    eventNaceCodes?: string[];
    eventRegions?: string[];

    interestCountries?: string[];
    interestNaceCodes?: string[];
    interestRegions?: string[];

    agreement?: DriveFileDto;
    agreementId?: string;
    keyWords: string[] = [];
    created = '';
    language: string;
    createdBy?: string;

    constructor(entity: Client|undefined, language: string, userRules: UserRoleData[]|undefined) {
        Object.entries(entity ?? {}).forEach(([key, val]) => {
            if (this.hasOwnProperty(key)) {
                this[key as keyof Client] = val as never
            }
        });

        if (!entity && userRules && !userRules.find(x => !x.objectId)) {
            const center = userRules.find(x => x.objectType === ObjectType.Center);
            this.centerId = Number(center?.objectId) || null;
            this.memberCountryId = center?.memberCountryId ?? null;
        } else {
            this.centerId = entity?.center?.id ?? null;
            this.memberCountryId = entity?.memberCountry?.id ? Number(entity.memberCountry.id) : null;
        }
        this.language = language;
    }
}
