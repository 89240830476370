import { EenApi } from '../index';

import ValidationCommentDto from './dto/validationComment.dto';
import ValidationCommentEditDto from './dto/validationComment.edit.dto';

class ValidationCommentServiceImpl {

    async set(data: ValidationCommentEditDto): Promise<number> {
        return await EenApi.put<number>('validationcomment/set', data);
    }

    async list(requestData: any, fieldName: string) {
        const data = await EenApi.commentList<ValidationCommentDto>('validationcomment/list', requestData);

        if (data.length > 0) {
            return data?.filter((item) => {
                return item.fieldName === fieldName;
            });
        }

        return data;
    }

    async delete(data: ValidationCommentEditDto) {
        return await EenApi.delete('validationcomment/delete', { ...data });
    }
}

const ValidationCommentService = new ValidationCommentServiceImpl();
export default ValidationCommentService;