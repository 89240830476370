export enum CenterTypeEnum {
    Ric = 'Ric',
    CertificationAuthority = 'CertificationAuthority',
    PrototypingCenter = 'PrototypingCenter',
    EngineeringCenter = 'EngineeringCenter',
    CollectiveUsageCenter = 'CollectiveUsageCenter',
    YouthInnovativeCreativityCenter = 'YouthInnovativeCreativityCenter',
    ClusterDevelopmentCenter = 'ClusterDevelopmentCenter',
    ExpertSupportCenter = 'ExpertSupportCenter',
    Technopark = 'Technopark',
}