import { FC, useContext } from "react";
import { useCurrentUser } from '../../../hooks/useCurrentUser';
import LanguageSelector from './component/languageSelector';
import { UserContext } from "../../../context/user-context";
import Rules from "../../../rules";

const Footer: FC = () => {
    const currentUser = useCurrentUser();
    const { checkAccess } = useContext(UserContext);

    return (
        <footer className="text-center text-lg-start bg-light text-muted mt-4 mb-5 mb-sm-0">
            {currentUser.data?.user && checkAccess(Rules.Role.List) && (
                <section className="justify-content-center justify-content-lg-between p-4 border-bottom">
                    <div>
                        user id: {currentUser.data?.user?.userId}. Roles: {currentUser.data?.rules.length}
                    </div>
                    {currentUser.data?.rules.map((item, index) => (
                        <div className="mb-2" key={index}>
                            {item.objectType && `${item.objectType} - `}
                            {item.objectId && `${item.objectId} - `}
                            {item.rules.join(' ')}
                        </div>
                    ))}
                </section>
            )}
            <div className="text-center p-4" style={{ backgroundColor: 'rgba(0, 0, 0, 0.05)' }}>
                <div className='mb-2'>
                    <LanguageSelector />
                </div>
                <a className="text-reset" href="/">
                    © INTERNATIONALIZATION DIGITAL PLATFORM
                </a>
                <a className="text-reset ms-5" href="mailto:info@ruitc.ru">
                    <i className="fas fa-envelope"></i>&nbsp;info@ruitc.ru
                </a>
            </div>
        </footer>
    );
};

export default Footer;
