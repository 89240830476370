import { Route, Switch } from "react-router-dom";
import NotFound from "../../home/notFound";
import UserEdit from "../edit";
import UserList from "../list";

export default function UserRoutes() {
    return (
        <Switch>
            <Route path="/user/list" component={UserList} />
            <Route path="/user/edit/:id" component={UserEdit} />
            <Route path="*" component={NotFound} />
        </Switch>
    );
}

export const UserRoutesPath = {
    Prefix: "/user",
    List: "/user/list",
    View: "/user/:id/details",
    Create: '/user/edit/0'
};
