import { FC, useState } from "react";
import useTranslation from "../../../../hooks/translation.hook";
import FilterType from '../../types/filterType';

interface IProps {
    requestParams: any,
    items: any,
    save: (selected: string[] | undefined) => void
}

const Visibility: FC<IProps> = (props) => {
    const { t } = useTranslation();
    const initState = () => {
        const result: string[] = [];
        if (props.requestParams && Array.isArray(props.requestParams)) {
            props.requestParams.forEach((item) => {
                if (item.name) {
                    result.push(item.name);
                }
            });
        }
        return result;
    }
    const [selected, setSelected] = useState(initState);

    const clickSaveFields = () => {
        props.save(selected);
    }

    const clickCancelFields = () => {
        console.log('clickCancelFields');
        props.save(undefined);
    }

    const select = (name: string) => {
        const array = [...selected];
        const index = array.indexOf(name);
        if (index < 0) {
            array.push(name);
        }
        else {
            array.splice(index, 1);
        }
        setSelected(array);
    }

    return (
        <>
            <div className="d-flex flex-column flex-grow-1">
                <div className="row px-3">
                    {props.items.filter((x: any) => x.Type !== FilterType.Hidden).map((item: any) => {
                        return (
                            <div key={item.Name} className={`col-6 main-ui-select-inner-item main-ui-filter-field-list-item ${selected.includes(item.Name) ? 'main-ui-checked' : ''}`} onClick={() => select(item.Name)}>
                                <div className="main-ui-select-inner-label">{t(item.Title)}</div>
                            </div>
                        );
                    })}
                </div>
            </div>
            <div>
                <div className="d-flex justify-content-center mb-2">
                    <button className="btn btn-success btn-md waves-effect waves-light me-2" onClick={clickSaveFields}>{t('filter:btn-save')}</button>
                    <button className="btn btn-outline-success btn-md waves-effect" onClick={clickCancelFields}>{t('filter:btn-cancel')}</button>
                </div>
            </div>
        </>
    );
}

export default Visibility;
