import React, { FC, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Notification } from '../../../eenApi/notification/notification';
import { NotificationService } from '../../../eenApi/notification/service';
import useTranslation from '../../../hooks/translation.hook';
import { formatDate } from '../../../utils/date.utils';
import { useGroupDeleteAction, useIdColumn, useRowActions } from '../../../component/common/layout/DataTable/table.utils';
import { TableColumn } from '../../../component/common/layout/DataTable/table.model';
import { DataTable } from '../../../component/common/layout';
import { EntityQueryKey } from '../../../query/query.keys';
import { GridSelectionModel } from '@mui/x-data-grid';

interface IProps {
    filterValues?: { [key: string]: any };
}

const NotificationTable: FC<IProps> = (props) => {
    const { t, i18n } = useTranslation();

    const idColumn = useIdColumn<Notification>();
    const columns = useMemo<TableColumn<Notification>[]>(
        () => [
            idColumn,
            new TableColumn({
                headerName: t('main:field-name'),
                field: 'name',
                hideable: false,
                renderCell: ({ id, value }) => <Link to={NotificationService.getEditLink(Number(id))}>{value}</Link>,
            }),
            new TableColumn({
                headerName: t('main:field-type'),
                field: 'type',
                valueFormatter: ({ value }) => t(`notification:type-${value}`),
            }),
            new TableColumn({
                headerName: t('notification:field-created'),
                field: 'created',
                valueFormatter: ({ value }) => formatDate(value),
            }),
            new TableColumn({
                headerName: t('notification:field-lastModified'),
                field: 'lastModified',
                valueFormatter: ({ value }) => formatDate(value),
            }),
            new TableColumn({
                headerName: t('main:field-active'),
                field: 'active',
                valueFormatter: ({ value }) => t(`main:${value ? 'yes' : 'no'}`),
            }),
        ],
        [i18n.language],
    );

    const clickDelete = (ids: GridSelectionModel) => NotificationService.delete(ids.map((x) => Number(x)));

    const rowActions = useRowActions<Notification>({
        edit: (row) => NotificationService.getEditLink(row.id!),
        remove: (row) => clickDelete([row.id!]),
    });
    const groupActions = useGroupDeleteAction({ remove: clickDelete });

    return (
        <DataTable
            id="notification-table-component"
            queryKey={EntityQueryKey.Notification}
            filterValues={props.filterValues}
            columns={columns}
            fetchData={(requestData) => NotificationService.list(requestData)}
            rowActions={rowActions}
            groupActions={groupActions}
        />
    );
};

export default NotificationTable;
