import { Route, Switch } from 'react-router-dom';
import RoleList from '../list';
import RoleEdit from '../edit';
import NotFound from '../../home/notFound';
import Rules from '../../../rules';
import ProtectedRoute from '../../../component/common/ProtectedRoute';

export default function RoleRoutes() {
    return (
        <Switch>
            <ProtectedRoute path={RoleRoutesPath.List} component={RoleList} rule={Rules.Role.List} />
            <ProtectedRoute path={RoleRoutesPath.Edit} exact component={RoleEdit} rule={Rules.Role.Edit} />
            <Route path="*" component={NotFound} />
        </Switch>
    );
}

const prefix = '/role';
export const RoleRoutesPath = {
    Prefix: `${prefix}`,
    List: `${prefix}/list`,
    Edit: `${prefix}/:id/edit`,
};
