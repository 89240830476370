import TabPanel from '../../tabs/tabPanel';
import React, { useState } from 'react';
import { InputControl } from '../../common/ui';
import { Length, NotEmpty } from '../../../hooks/UseValidation/validators';
import IEditPanelProps from '../edit.panel.props';
import InputContainer from '../../inputContainer';
import ViewField from '../../fields/viewField';
import FileList from '../../fields/fileList';
import FileUploader from '../../fileUploader';
import { DictionaryType } from '../../../models/dictionaryType';
import DictionaryControl from '../../common/ui/DictionaryControl';
import { useCurrentUser } from "../../../hooks/useCurrentUser";


export default function MainEditTab({ entity, data, updateData, ...rest }: IEditPanelProps) {

    const [file, setFile] = useState(entity?.photo ? [entity.photo] : []);
    const onUploaded = (file: { id: string; name: string }) => {
      setFile([file]);
      updateData('photoId', file.id)
    };
    const onRemoveFile = () => {
      setFile([]);
      updateData('photoId', null)
    };
    const { data: currentUser } = useCurrentUser();

    return (
        <TabPanel {...rest}>
            <InputContainer>
                <ViewField title='specialist:fields.photo'>
                    <FileList files={file} onRemove={onRemoveFile} />
                </ViewField>
                {file.length < 1 && <FileUploader onFileUploaded={onUploaded} />}
            </InputContainer>
            <InputControl labelKey={'specialist:fields.name'}
                          onChange={value => updateData('name', value)}
                          value={data.name}
                          required
                          validators={[NotEmpty, Length(40)]} tab='main'/>
            <InputControl labelKey={'specialist:fields.dateOfBirth'}
                          onChange={value => updateData('dateOfBirth', value)}
                          value={data.dateOfBirth?.substring(0, 10)}
                          type="date"
                          required
                          validators={[NotEmpty]} tab='main'/>
            <DictionaryControl value={data.memberCountryId}
                               disabled={!currentUser?.rules?.some(x => !x.memberCountryId)}
                               onChange={value => updateData('memberCountryId', value)}
                               dictionary={DictionaryType.MemberCountry}
                               language={data.language}
                               labelKey="specialist:fields.citizenship"
                               required validators={[NotEmpty]} minInput={0}/>
            <DictionaryControl value={data.residenceCountryId}
                               onChange={value => updateData('residenceCountryId', value)}
                               dictionary={DictionaryType.MemberCountry}
                               language={data.language}
                               labelKey="specialist:fields.country"
                               minInput={0}/>
            <InputControl labelKey={'specialist:fields.city'}
                          onChange={value => updateData('city', value)}
                          value={data.city}
                          validators={[Length(50)]} tab='main'/>
            <InputControl labelKey={'specialist:fields.education'}
                          onChange={value => updateData('education', value)}
                          value={data.education}
                          validators={[Length(100)]} tab='main'/>
            <DictionaryControl value={data.languageSkills} multiple
                               onChange={value => updateData('languageSkills', value)}
                               dictionary={DictionaryType.Language}
                               language={data.language}
                               labelKey="specialist:fields.languageSkills"
                               minInput={0}/>
            <InputControl labelKey={'specialist:fields.speciality'}
                          onChange={value => updateData('speciality', value)}
                          value={data.speciality} multiline
                          validators={[Length(100)]} tab='main'/>
            <InputControl labelKey={'specialist:fields.biography'}
                          onChange={value => updateData('biography', value)}
                          value={data.biography}
                          multiline required
                          validators={[NotEmpty, Length(500)]} tab='main'/>
            <InputControl labelKey={'specialist:fields.specialization'}
                          onChange={value => updateData('specialization', value)}
                          value={data.specialization} multiline
                          validators={[Length(255)]} tab='main'/>
            <InputControl labelKey={'specialist:fields.workExperience'}
                          onChange={value => updateData('workExperience', value)}
                          value={data.workExperience}
                          multiline
                          validators={[Length(255)]} tab='main'/>
            <InputControl labelKey={'specialist:fields.areaOfInterest'}
                          onChange={value => updateData('areaOfInterest', value)}
                          value={data.areaOfInterest}
                          required
                          validators={[NotEmpty, Length(100)]} tab='main'/>
        </TabPanel>
    );
}