import TabPanel from '../../tabs/tabPanel';
import React, { useContext } from 'react';
import useTranslation from '../../../hooks/translation.hook';
import { Field } from '../../common/layout';
import { formatDate } from '../../../utils/date.utils';
import IDetailsPanelProps from '../details.panel.props';
import Rules from '../../../rules';
import { SpecialistStatusEnum } from '../../../eenApi/specialist/enum/specialis.status.enum';
import { UserContext } from '../../../context/user-context';
import { ObjectType } from '../../../enums/objectType';


export default function MainDetailsTab({ entity, ...rest }: IDetailsPanelProps) {
    const { t } = useTranslation();
    const { checkAccess } = useContext(UserContext);
    const canEditValidation = SpecialistStatusEnum.Validation === entity.status && checkAccess(Rules.Specialist.Validate);
    const isValidate = canEditValidation || checkAccess(Rules.Specialist.ViewValidations) &&
        (SpecialistStatusEnum.Validation === entity.status || SpecialistStatusEnum.CommentsReceived === entity.status);

    return (
        <TabPanel {...rest}>
            {entity.photo?.source && <Field labelKey='specialist:fields.photo' optional>
              <div><img src={'/' + entity.photo.source} alt={t('specialist:fields.photo')} style={{ maxHeight: '200px' }} /></div>
            </Field>}
            <Field labelKey="specialist:fields.status" value={t(`specialist:status.${entity.status}`)}/>
            <Field labelKey="specialist:fields.name">{entity.name}</Field>
            <Field labelKey="specialist:fields.dateOfBirth">{formatDate(entity.dateOfBirth)}</Field>
            <Field labelKey="specialist:fields.citizenship">{entity.memberCountry?.name}</Field>
            <Field labelKey="specialist:fields.country" optional>{entity.residenceCountry?.name}</Field>
            <Field labelKey="specialist:fields.city" optional
                   isValidate={isValidate} fieldName="city" objectType={ObjectType.Specialist} objectId={entity.id!} editable={canEditValidation}>
                {entity.city}
            </Field>
            <Field labelKey="specialist:fields.education" optional
                   isValidate={isValidate} fieldName="education" objectType={ObjectType.Specialist} objectId={entity.id!} editable={canEditValidation}>
                {entity.education}
            </Field>
            <Field labelKey="specialist:fields.languageSkills" optional value={entity.languageSkillsEntities?.map(ls => ls.name ?? '')}/>
            <Field labelKey="specialist:fields.speciality" optional
                   isValidate={isValidate} fieldName="speciality" objectType={ObjectType.Specialist} objectId={entity.id!} editable={canEditValidation}>
                {entity.speciality}
            </Field>
            <Field labelKey="specialist:fields.biography"
                   isValidate={isValidate} fieldName="biography" objectType={ObjectType.Specialist} objectId={entity.id!} editable={canEditValidation}>
                {entity.biography}
            </Field>
            <Field labelKey="specialist:fields.specialization" optional
                   isValidate={isValidate} fieldName="specialization" objectType={ObjectType.Specialist} objectId={entity.id!} editable={canEditValidation}>
                {entity.specialization}
            </Field>
            <Field labelKey="specialist:fields.workExperience" optional
                   isValidate={isValidate} fieldName="workExperience" objectType={ObjectType.Specialist} objectId={entity.id!} editable={canEditValidation}>
                {entity.workExperience}
            </Field>
            <Field labelKey="specialist:fields.areaOfInterest"
                   isValidate={isValidate} fieldName="areaOfInterest" objectType={ObjectType.Specialist} objectId={entity.id!} editable={canEditValidation}>
                {entity.areaOfInterest}
            </Field>
        </TabPanel>
    );
}