import useTranslation from "../../hooks/translation.hook";
import classNames from "classnames";

interface IProps {
    active: boolean,
    text: string,
    id: string,
    onChange: (id: string) => void,
    error?: boolean
}

export default function TabControl(props: IProps) {
    const { t } = useTranslation();

    const onClick = () => {
        props.onChange(props.id);
    };

    return (
        <button id={`tab-${props.id}`}
            aria-controls={`tabpanel-${props.id}`}
            className={classNames('btn', {
                'btn-outline-primary': !props.error && props.active,
                'btn-outline-danger': props.error,
                'btn-outline': !props.active && !props.error
            })}
            type={'button'}
            onClick={onClick}>{t(props.text)}</button>
    )
}