import DefaultBlock from "../defaultBlock";

export interface ITabPanelProps {
    children?: React.ReactNode;
    id: string;
    activeId: string;
}

export default function TabPanel(props: ITabPanelProps) {
    const { children, id, activeId, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={id !== activeId}
            id={`tabpanel-${id}`}
            aria-labelledby={`tab-${id}`}
            {...other}
        >
            {id === activeId && (
                <DefaultBlock>{children}</DefaultBlock>
            )}
        </div>
    );
}