import PageTitle from '../../../component/pageTitle';
import TopActionsContainer from '../../../component/actions/topActionsContainer';
import TopActions from '../../../component/actions/topActions';
import React, { Suspense, useMemo } from 'react';
import MainContainer from '../../../component/mainContainer';
import TabItem from '../../../component/tabs/tabItem';
import BottomActions from '../../../component/actions/bottomActions';
import BottomActionsContainer from '../../../component/actions/bottomActionsContainer';
import TabControls from '../../../component/tabs/tabControls';
import Loader from '../../../component/loader';
import { ActionItem } from '../../../models/actionItem';
import Rules from '../../../rules';
import { useDetailsStates } from '../../../hooks/UseDetailsStates';
import { CenterDto } from '../../../eenApi/center/dto/center.dto';
import { CenterService } from '../../../eenApi/center/service';
import { CenterRoutesPath } from '../routes';
import { MainDetailsTab } from '../../../component/center/MainDetailsTab';
import { useDeleteModal } from "../../../hooks/UseDeleteModal";
import { DeleteModal } from "../../../component/common/ui/DeleteModal";
import { useCountryWarning } from "../../../hooks/UseCountryWarning";
import { LoaderTab } from "../../../component/common/layout/LoaderTab";


const UsersTab = React.lazy(() => import('../../../component/center/UsersTab'));
const ClientsTab = React.lazy(() => import('../../../component/center/ClientsTab'));

export default function CenterDetails() {
    const {
        t, i18n,
        history,
        id, entity,
        activeTab, setActiveTab,
        checkAccess } = useDetailsStates<CenterDto>({ queryKey: 'center' });

    const {
        isDeleteOpen, closeDelete,
        remove,
        deleteButton
    } = useDeleteModal({ queryKey: 'center', handler: id => CenterService.remove(id), successPath: CenterRoutesPath.List });

    useCountryWarning(entity, entity?.memberCountry);

    const tabItems = useMemo(() => [
        new TabItem('main', t('center:tab.main')),
        new TabItem('users', t('center:tab.users')),
        new TabItem('clients', t('center:tab.clients'))
    ], [i18n.language]);

    const actions = useMemo(() => {
        const items: ActionItem[] = [
            {
                name: t('center:button.profiles'),
                onClick: async () => {
                    history.push(CenterRoutesPath.Profiles.replace(':centerId', id));
                },
                isTop: true
            }
        ];
        if (checkAccess(Rules.Center.Edit) || checkAccess(Rules.Center.EditOwn, [id])) {
            items.push({
                name: t('main:btn-edit'), onClick: async () => {
                    history.push(CenterService.getEditRoute(id));
                },
            });
        }

        if (id && (checkAccess(Rules.Center.Delete) || checkAccess(Rules.Center.DeleteOwn, [id]))) {
            items.push(deleteButton);
        }

        return items;
    }, [id, i18n.language]);

    return (
        <MainContainer>
            <PageTitle text={t('center:title.details', [entity?.name])} backLink={CenterRoutesPath.List} />
            <TopActionsContainer>
                <TopActions actions={actions} />
            </TopActionsContainer>
            <TabControls selectedId={activeTab} items={tabItems} onChange={setActiveTab} />
            <LoaderTab activeId={activeTab}/>
            {entity?.id && <>
                <MainDetailsTab activeId={activeTab} entity={entity}/>
                <Suspense fallback={<Loader />}>
                    <UsersTab activeId={activeTab} id='users' centerId={entity.id} />
                    <ClientsTab activeId={activeTab} id='clients' centerId={entity.id} />
                </Suspense>
                <BottomActionsContainer>
                    <BottomActions actions={actions} />
                </BottomActionsContainer>
            </>}
            <DeleteModal isDeleteOpen={isDeleteOpen} close={closeDelete} remove={remove}/>
        </MainContainer>
    );
}