import TabPanel from '../../tabs/tabPanel';
import { InputControl, SelectControl, TextEditorControl } from '../../common/ui';
import IEditPanelProps from '../edit.panel.props';
import useTranslation from '../../../hooks/translation.hook';
import CheckboxControl from '../../common/ui/CheckboxControl';
import { useOptions } from '../../../hooks/select.options.hook';
import MailingTypeEnum from '../../../eenApi/mailing/enum/mailing.type.enum';
import MailingStatusEnum from '../../../eenApi/mailing/enum/mailing.status.enum';
import { Field } from '../../common/layout';
import { formatDate } from '../../../utils/date.utils';
import MailingPeriodicityEnum from '../../../eenApi/mailing/enum/mailing.periodicity.enum';
import { WeekDayEnum } from '../../../eenApi/mailing/enum/mailing.weekday.enum';
import { Length, MinMax, NotEmpty } from '../../../hooks/UseValidation/validators';

export default function MainEditTab({ data, entity, updateData, ...rest }: IEditPanelProps) {
    const mailingTypeOptions = useOptions(MailingTypeEnum, 'mailing:mailings.fields.type.');
    const mailingStatusOptions = useOptions(MailingStatusEnum, 'mailing:mailings.fields.status.');
    const mailingPeriodicityOptions = useOptions(MailingPeriodicityEnum, 'mailing:mailings.fields.periodicity.');
    const { t } = useTranslation();


    return (
        <TabPanel {...rest}>
            <SelectControl
                labelKey="mailing:mailings.fields.status.title"
                value={data.status}
                onChange={(value) => {
                    updateData('status', value);
                }}
                options={mailingStatusOptions}
                required
                tab={'mailing'}
                validators={[NotEmpty]}
            />
            <Field className={'short-field'} labelKey="mailing:mailings.fields.sendDate">
                {data.lastSent ? formatDate(data.lastSent) : t('mailing:mailings.no')}
            </Field>

            <CheckboxControl
                value={data.isImportant}
                labelKey={'mailing:mailings.fields.isImportant'}
                onChange={(data) => updateData('isImportant', data)}
                tab="mailing"
            />
            <InputControl
                labelKey={'mailing:mailings.fields.subject'}
                onChange={(data) => updateData('subject', data)}
                value={data.subject}
                tab="mailing"
                required
                validators={[NotEmpty, Length(300)]}
            />
            <TextEditorControl
                labelKey={'mailing:mailings.fields.text'}
                onChange={(e: any, text: string) => {
                    updateData('text', text);
                }}
                value={data.text ? data.text : ''}
                tab={'mailing'}
                validators={[NotEmpty, Length(4000)]}
            />
            <SelectControl
                labelKey="mailing:mailings.fields.type.title"
                value={data.type}
                onChange={(value) => {
                    updateData('type', value);
                }}
                options={mailingTypeOptions}
                required
                tab={'mailing'}
                validators={[NotEmpty]}
            />
            {data.type === MailingTypeEnum.OneTime && (
                <InputControl
                    labelKey={'mailing:mailings.fields.plannedSendDate'}
                    onChange={(data) => updateData('sendDate', data)}
                    value={data.sendDate}
                    tab="mailing"
                    type="datetime-local"
                    focused={true}
                    color={'grey'}
                />
            )}
            {data.type === MailingTypeEnum.Cyclical && (
                <>
                    <SelectControl
                        labelKey={'mailing:mailings.fields.periodicity.title'}
                        onChange={(data) => updateData('periodicity', data)}
                        value={data.periodicity}
                        tab="mailing"
                        options={mailingPeriodicityOptions}
                    />
                    <InputControl
                        value={data.periodData.sendTime}
                        labelKey={'mailing:mailings.fields.periodData'}
                        onChange={(value) => {
                            updateData('periodData', { ...data.periodData, sendTime: value });
                        }}
                        tab="mailing"
                        type="time"
                        focused={true}
                        color={'grey'}
                    />
                    {data.periodicity === MailingPeriodicityEnum.Weekly && (
                        <>
                            {(Object.keys(WeekDayEnum).filter((key) => isNaN(Number(key))) as (keyof typeof WeekDayEnum)[]).map((key) => (
                                <CheckboxControl
                                    key={key}
                                    value={data.periodData.weekDays.includes(WeekDayEnum[key])}
                                    labelKey={`mailing:mailings.fields.weekDays.${key}`}
                                    onChange={(checked) => {
                                        const newValue = [...data.periodData.weekDays];
                                        if (checked) {
                                            newValue.push(WeekDayEnum[key]);
                                        } else {
                                            newValue.splice(data.periodData.weekDays.indexOf(WeekDayEnum[key]), 1);
                                        }
                                        updateData('periodData', {
                                            ...data.periodData,
                                            weekDays: newValue,
                                        });
                                    }}
                                    tab={'mailing'}
                                />
                            ))}
                        </>
                    )}
                    {data.periodicity === MailingPeriodicityEnum.Monthly && (
                        <InputControl
                            value={data.periodData.monthDay}
                            labelKey={'mailing:mailings.fields.periodicity.monthDay'}
                            onChange={(value) => {
                                updateData('periodData', { ...data.periodData, monthDay: value });
                            }}
                            tab="mailing"
                            type="number"
                            inputProps={{ min: 1, max: 31 }}
                            validators={[MinMax(31, 1)]}
                        />
                    )}
                    {data.periodicity === MailingPeriodicityEnum.Quarterly && (
                        <>
                            <InputControl
                                value={data.periodData.quarterDay}
                                labelKey={'mailing:mailings.fields.periodicity.quarterDay'}
                                onChange={(value) => {
                                    updateData('periodData', { ...data.periodData, quarterDay: value });
                                }}
                                tab="mailing"
                                type="number"
                                inputProps={{ min: 1, max: 31 }}
                                validators={[MinMax(31, 1)]}
                            />
                            <InputControl
                                value={data.periodData.quarterMonth}
                                labelKey={'mailing:mailings.fields.periodicity.quarterMonth'}
                                onChange={(value) => {
                                    updateData('periodData', { ...data.periodData, quarterMonth: value });
                                }}
                                tab="mailing"
                                type="number"
                                inputProps={{ min: 1, max: 12 }}
                                validators={[MinMax(12, 1)]}
                            />
                        </>
                    )}
                    {data.periodicity === MailingPeriodicityEnum.Annually && (
                        <>
                            <InputControl
                                value={data.periodData.yearDay}
                                labelKey={'mailing:mailings.fields.periodicity.yearDay'}
                                onChange={(value) => {
                                    updateData('periodData', { ...data.periodData, yearDay: value });
                                }}
                                tab="mailing"
                                type="number"
                                inputProps={{ min: 1, max: 31 }}
                                validators={[MinMax(31, 1)]}
                            />
                            <InputControl
                                value={data.periodData.yearMonth}
                                labelKey={'mailing:mailings.fields.periodicity.yearMonth'}
                                onChange={(value) => {
                                    updateData('periodData', { ...data.periodData, yearMonth: value });
                                }}
                                tab="mailing"
                                type="number"
                                inputProps={{ min: 1, max: 12 }}
                                validators={[MinMax(12, 1)]}
                            />
                        </>
                    )}
                </>
            )}
        </TabPanel>
    );
}
