import { useRef, useState } from 'react';
import useTranslation from '../../../hooks/translation.hook';
import { MDBBtn } from 'mdb-react-ui-kit';
import Loader from '../../../component/loader';
import BaseModal from '../../../component/modal/baseModal';
import ModalHeader from '../../../component/modal/modalHeader';
import { DriveService } from '../../../eenApi/drive/service';
import { ObjectType } from '../../../enums/objectType';
import './style.css';
import { useInfiniteQuery, useQuery } from "react-query";
import { DriveEntity } from "../../../eenApi/drive/driveEntity";

interface IProps {
    isOpen: boolean
    onClose: () => void;
    onSave: (selected: DriveEntity|null) => void;
}

export function MyDriveModal({ isOpen, onClose, onSave }: IProps) {
    const { t } = useTranslation();

    const take = useRef(5);
    const [id, setId] = useState<string>();

    const fetchItems = async ({ pageParam = 0 }) => {
        const result = await DriveService.list({ id, skip: pageParam, take: take.current, order: { name: 'asc' } });
        return {
            data: result.items,
            pageParams: {
                skip: pageParam,
                total: result.total,
            },
        };
    };

    const {
        data,
        fetchNextPage,
        hasNextPage,
        isFetching,
        isFetchingNextPage,
    } = useInfiniteQuery(['drive-list', id], fetchItems, {
        getNextPageParam: (lastPage) =>
          lastPage.pageParams.skip < lastPage.pageParams.total
            ? Math.min(lastPage.pageParams.skip + take.current, lastPage.pageParams.total)
            : undefined,
        enabled: isOpen
    });

    const [parentId, setParentId] = useState<string>();

    const { data: parent } = useQuery(['drive-entity', parentId], async () => {
        if (!parentId) return null;
        const data = await DriveService.list({ filter: { id: parentId } });
        return data.items?.[0]
    })

    const [selected, setSelected] = useState<DriveEntity|null>(null);

    const onClickBack = () => {
        if (isFetching || isFetchingNextPage) {
            return;
        }
        setId(parent?.parentId);
        setParentId(parent?.parentId);
    };

    return (
        <BaseModal centered scrollable isOpen={isOpen} onRequestClose={onClose} modalSize="md">
            <ModalHeader
                title={parent?.name ?? t(`drive:my-title`)}
                onClickBack={parent ? onClickBack : undefined}
                onRequestClose={onClose}
            />
            <div className="modal-body py-1 drive-move-list">
                {!isFetching && !isFetchingNextPage && (
                    <>
                        {!data?.pages?.[0]?.data?.length && <div>{t('drive:empty-folder')}</div>}
                        {data?.pages?.map((page) =>
                            page.data.map((item) => (
                                <div className="d-flex" key={item.id}>
                                    <MDBBtn
                                        className="w-100 d-flex justify-content-start"
                                        color={selected?.id === item.id ? 'primary' : 'link'}
                                        onClick={() => item.type !== ObjectType.DriveFolder ? setSelected(item) : undefined} >
                                        {item.type === ObjectType.DriveFolder && <i className="far fa-folder my-auto me-2" />}
                                        {item.name}
                                    </MDBBtn>
                                    {item.type === ObjectType.DriveFolder && <MDBBtn color="link"
                                            onClick={() => {
                                                setId(item.id);
                                                setParentId(item.id);
                                            }} >
                                        <i className="fas fa-chevron-right" />
                                    </MDBBtn>}
                                </div>
                            )),
                        )}
                    </>
                )}
                {(isFetching || isFetchingNextPage) && <Loader />}
            </div>
            {!isFetching && !isFetchingNextPage && (
                <div className="mx-auto py-1">
                    {hasNextPage && (
                        <MDBBtn color="outline-primary" size="sm" onClick={fetchNextPage}>
                            {t('main:btn-more')}
                        </MDBBtn>
                    )}
                </div>
            )}
            <div className="modal-footer">
                <MDBBtn disabled={!selected} onClick={() => onSave(selected)}>
                    {t('drive:btn-select')}
                </MDBBtn>
            </div>
        </BaseModal>
    );
}