export default {
    ViewBasic: 'specialist.viewbasic',
    ViewBasicOwn: 'specialist.viewbasicown',
    Create: 'specialist.add',
    Edit: 'specialist.edit',
    EditOwn: 'specialist.editown',
    Delete: 'specialist.delete',
    DeleteOwn: 'specialist.deleteown',
    List: 'specialist.list',
    ListValidation: 'specialist.listvalidation',
    Validate: 'specialist.validate',
    ViewContacts: "specialist.viewcontacts",
    ViewContactsOwn: 'specialist.viewcontactsown',
    ViewValidations: "specialist.viewvalidations",
    ViewValidationsOwn: 'specialist.viewvalidationsown',
    Status: "specialist.status"
}