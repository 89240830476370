import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import mainRu from './page/home/locales/ru.json';
import mainEn from './page/home/locales/en.json';
import filterEn from './component/filter/locales/en.json';
import filterRu from './component/filter/locales/ru.json';
import authenticationEn from './page/authentication/locales/en.json';
import authenticationRu from './page/authentication/locales/ru.json';
import roleEn from './page/role/locales/en.json';
import roleRu from './page/role/locales/ru.json';
import userEn from './page/user/locales/en.json';
import userRu from './page/user/locales/ru.json';
import menuEn from './page/menu/locales/en.json';
import menuRu from './page/menu/locales/ru.json';
import notificationEn from './page/notification/locales/en.json';
import notificationRu from './page/notification/locales/ru.json';
import centerEn from './page/center/locales/en.json';
import centerRu from './page/center/locales/ru.json';
import clientEn from './page/client/locales/en.json';
import clientRu from './page/client/locales/ru.json';
import logEn from './page/log/locales/en.json';
import logRu from './page/log/locales/ru.json';
import dictionaryEn from './page/dictionary/locales/en.json';
import dictionaryRu from './page/dictionary/locales/ru.json';
import extproviderEn from './page/externalProvider/locales/en.json';
import extproviderRu from './page/externalProvider/locales/ru.json';
import settingsEn from './page/settings/locales/en.json';
import settingsRu from './page/settings/locales/ru.json';
import driveEn from './page/drive/locales/en.json';
import driveRu from './page/drive/locales/ru.json';
import profileRu from './page/profile/locales/ru.json';
import profileEn from './page/profile/locales/en.json';
import supportRu from './page/support/locales/ru.json';
import supportEn from './page/support/locales/en.json';
import mailingEn from './page/mailing/locales/en.json';
import mailingRu from './page/mailing/locales/ru.json';
import newsRu from './page/news/locales/ru.json';
import newsEn from './page/news/locales/en.json';
import { AccountService } from './eenApi/account/service';
import specialistRu from './page/specialist/locales/ru.json';
import specialistEn from './page/specialist/locales/en.json';
import innovationprojectRu from './page/innovationproject/locales/ru.json';
import innovationprojectEn from './page/innovationproject/locales/en.json';
import membercountryRu from './page/membercountry/locales/ru.json';
import membercountryEn from './page/membercountry/locales/en.json';

export const resources = {
    en: {
        main: mainEn,
        authentication: authenticationEn,
        role: roleEn,
        user: userEn,
        filter: filterEn,
        menu: menuEn,
        center: centerEn,
        client: clientEn,
        log: logEn,
        dictionary: dictionaryEn,
        notification: notificationEn,
        extprovider: extproviderEn,
        settings: settingsEn,
        drive: driveEn,
        profile: profileEn,
        support: supportEn,
        mailing: mailingEn,
        news: newsEn,
        specialist: specialistEn,
        innovationproject: innovationprojectEn,
        membercountry: membercountryEn,
    },
    ru: {
        main: mainRu,
        authentication: authenticationRu,
        role: roleRu,
        user: userRu,
        filter: filterRu,
        menu: menuRu,
        center: centerRu,
        client: clientRu,
        log: logRu,
        dictionary: dictionaryRu,
        notification: notificationRu,
        extprovider: extproviderRu,
        settings: settingsRu,
        drive: driveRu,
        profile: profileRu,
        support: supportRu,
        mailing: mailingRu,
        news: newsRu,
        specialist: specialistRu,
        innovationproject: innovationprojectRu,
        membercountry: membercountryRu,
    },
};

export const i18nLocal = i18next
    .createInstance({
        resources,
        lng: 'ru',
        supportedLngs: ['en', 'ru'],
        fallbackLng: 'en',
        debug: false,
        interpolation: {
            escapeValue: false,
        },
    })
    .use(initReactI18next);

export const i18nGlobal = i18next
    .createInstance({
        resources,
        supportedLngs: ['en', 'ru'],
        fallbackLng: 'en',
        debug: false,
        interpolation: {
            escapeValue: false,
            format: (value, format, lng, options) => {
                if (format === "filesize") {
                    return new Intl.NumberFormat(lng, {
                        style: "unit",
                        unit: options?.unit,
                    }).format(value).toUpperCase();
                }

                return value
            },
        },
    })
    .use({
        type: 'languageDetector',
        ///services, detectorOptions, i18nextOptions
        init: function () {
            /* use services and options */
        },
        //callback
        detect: function () {
            // You'll receive a callback if you passed async true
            /* return detected language */
            // callback('de'); if you used the async flag
            return AccountService.getLang();
        },
        cacheUserLanguage: function (_: string) {
            /* cache language */
        },
    })
    .use(initReactI18next);

i18nGlobal.init();
i18nLocal.init();
