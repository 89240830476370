import IValidator from "./validator";
import ValidationRule from "../validation.rule.enum";

export default class MinMaxValidator implements IValidator<any[]> {
   rule = ValidationRule.MinMax;

   max : number;
   min ?: number;

   constructor(max : number, min?: number) {
      this.max  = max ;
      this.min  = min ;
   }

   validate(value: any[]) {
      if (Number(value) > this.max) {
         return ['main:error.maxNumber', this.max] as [string, ...any[]]
      }
      if (this.min && (Number(value) < this.min)) {
         return ['main:error.minNumber', this.min] as [string, ...any[]]
      }
      return null
   }

}