import { Box, Alert } from '@mui/material';
import useTranslation from '../../hooks/translation.hook';

export default function WarningMessage() {
    const { t } = useTranslation();

    return (
        <>
            <Box textAlign={'center'}>
                <Box maxWidth={800} marginX={'auto'} padding={2}>
                    <Alert severity="warning">
                        <Box textAlign={'left'}>
                            <b>{t('main:warningInternationalization.warningTitle')}</b>
                            <p>{t('main:warningInternationalization.warningText')}</p>
                            <p>{t('main:warningInternationalization.warningAccess')}</p>
                        </Box>
                    </Alert>
                </Box>
            </Box>
        </>
    );
}
