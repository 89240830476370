export default abstract class ModelValidator<T, E> {
   abstract validate(model: T, tabs?: string[], entity?: E): string[];

   protected checkRequired(value: any|any[], errors: string[], key = 'main') {
      if (!errors.includes(key)
         && (!value ||
              Array.isArray(value) && !value.length ||
              typeof value === 'string' && (value === 'null' || !value.trim()))) {
         errors.push(key)
      }
   }

   protected checkLength(value: string|string[], errors: string[], maxLength: number, key = 'main', minLength?: number) {
      if (!errors.includes(key)) {
         for (const str of (Array.isArray(value) ? value : [value])) {
            if (minLength && (str?.length < minLength) || str?.length > maxLength) {
               errors.push(key)
            }
         }
      }
   }

   protected checkCapacity(value: string|string[], errors: string[], maxCapacity: number, key = 'main', minCapacity?: number) {
      if (!errors.includes(key) &&
         (value?.length > maxCapacity || minCapacity && (!value?.length || value.length < minCapacity))) {
         errors.push(key)
      }
   }
}