export default class MailingUnsubscriptionDto {
    id?: number;
    email: string = '';
    mailing: boolean = false;
    comment: string = '';
    notification: boolean = false;

    constructor(entity: MailingUnsubscriptionDto | undefined) {
        Object.entries(entity ?? {}).forEach(([key, val]) => {
            if (this.hasOwnProperty(key)) {
                this[key as keyof MailingUnsubscriptionDto] = val as never;
            }
        });
    }
}
