import ModelValidator from '../model.validator';
import SupportSubjectEditDto from '../../../eenApi/support/subjects/dto/support.subject.edit.dto';

class EditValidator extends ModelValidator<SupportSubjectEditDto, SupportSubjectEditDto> {
    validate(dto: SupportSubjectEditDto, tabs: string[]) {
        const erroredTabs: string[] = [];

        this.checkRequired(dto.name, erroredTabs, 'main');
        this.checkRequired(dto.responsableUserId.toString(), erroredTabs, 'main');

        return erroredTabs;
    }
}

export default new EditValidator();
