import LanguageEnum from '../../../enums/language.enum';
import DriveFileDto from '../../drive/dto/drive.file.dto';
import { Center } from '../../center/center';

export class MemberCountry {
    id?: number;
    // "Флаг" (обязательное), тип "Файл" (jpeg, png), не более 4 Мб (?);
    flagFileId: string|null;
    flag?: DriveFileDto;
    // "Наименование страны" (обязательное), тип "Строка", не более 50 символов;
    name = '';
    // "Столица"  (обязательное), тип "Строка", не более 50 символов;
    capital = '';
    // "Организации-участницы" (множественное, обязательное), тип "Привязка к элементу сущности "Организация-участник". Должны быть доступны к выбору только организации-участницы без привязке к стране.
    centerIds: number[] = [];
    centerDtos: Center[] = [];
    // "Краткое описание" (необязательное), тип "Текстовая область", не более 255 символов.
    description = '';

    language: LanguageEnum
    //TODO
    constructor(entity: MemberCountry|undefined, language: LanguageEnum) {
        Object.entries(entity ?? {}).forEach(([key, val]) => {
            if (this.hasOwnProperty(key)) {
                this[key as keyof MemberCountry] = val as never
            }
        });

        this.language = entity?.language ?? language;
        this.flagFileId = entity?.flag?.id ?? null;
        this.centerIds = entity?.centerDtos?.map(c => c.id) ?? []
    }
}