import IValidator from "./validator";
import ValidationRule from "../validation.rule.enum";

export default class CapacityValidator implements IValidator<any[]> {
   rule = ValidationRule.Capacity;

   maxCapacity: number;
   minCapacity?: number;

   constructor(maxCapacity: number, minCapacity?: number) {
      this.maxCapacity = maxCapacity;
      this.minCapacity = minCapacity;
   }

   validate(value: any[]) {
      if (value?.length > this.maxCapacity) {
         return ['main:error.maxCapacity', this.maxCapacity] as [string, ...any[]]
      }
      if (this.minCapacity && (!value?.length || value.length < this.minCapacity)) {
         return ['main:error.minCapacity', this.minCapacity] as [string, ...any[]]
      }
      return null
   }

}