import { useCommonEntityStates } from "../UseCommonHooks";
import { useState } from "react";
import { QueryKey, useMutation } from "react-query";
import { ToastManager } from "../../service/toastManager";
import { resetQueryFn } from "../../query/query.utils";
import { ActionItem } from "../../models/actionItem";

interface IDeleteModalOptions {
  queryKey: QueryKey,
  handler: (id: string|number) => Promise<any>,
  successPath: string
}

export interface IDeleteModal {
  isDeleteOpen: boolean,
  closeDelete: () => void,
  remove: () => void,
  deleteButton: ActionItem
}

export function useDeleteModal (options: IDeleteModalOptions): IDeleteModal {
  const { t, history, queryClient, activeTab, setActiveTab, id } = useCommonEntityStates();
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const closeDelete = () => setIsDeleteOpen(false);

  const remove = () => {
    closeDelete();
    window.scrollTo(0, 0);
    deleteMutation.mutate(activeTab);
    setActiveTab('loading');
  };

  const deleteMutation = useMutation((_: string) => options.handler(id), {
    onSuccess: async () => {
      ToastManager.success({ title: 'main:msg-success', message: 'main:msg-entity-deleted' });
      await queryClient.removeQueries([options.queryKey, id]);
      await queryClient.resetQueries({ predicate: (query) => resetQueryFn(query, options.queryKey) });
      history.push(options.successPath);
    },
    onSettled: (data, error, tab) => {
      if (error) {
        setActiveTab(tab);
      }
    },
  });

  return {
    isDeleteOpen,
    closeDelete,
    remove,
    deleteButton: {
      name: t('main:btn-delete'), onClick: async () => setIsDeleteOpen(true),
    }
  }
}