import TabPanel, { ITabPanelProps } from '../../tabs/tabPanel';
import React, { useContext } from 'react';
import { AutocompleteControl, InputControl } from '../../common/ui';
import { Length, NotEmpty } from '../../../hooks/UseValidation/validators';
import InputContainer from '../../inputContainer';
import ViewField from '../../fields/viewField';
import FileList from '../../fields/fileList';
import FileUploader from '../../fileUploader';
import { FormHelperText } from '@mui/material';
import ValidationContext from '../../../hooks/UseValidation/validation.context';
import useTranslation from '../../../hooks/translation.hook';
import { CenterService } from '../../../eenApi/center/service';
import { MemberCountry } from '../../../eenApi/membercountry/dto/member.country';
import { useQueryClient } from 'react-query';
import Rules from '../../../rules';
import { UserContext } from '../../../context/user-context';
import { DictionaryType } from "../../../models/dictionaryType";

interface IEditPanelProps extends ITabPanelProps {
    entity: MemberCountry | undefined,
    data: MemberCountry,
    updateData: (key: keyof MemberCountry, val: any) => void,
}

export default function MainEditTab({ entity, data, updateData, ...rest }: IEditPanelProps) {
    const { checkAccess } = useContext(UserContext);

    const queryClient = useQueryClient();
    const readOnly = !checkAccess(Rules.MemberCountry.Edit);

    const onUploaded = (file: { id: string; name: string }) => {
        updateData('flag', file);
        updateData('flagFileId', file.id);
        return queryClient.invalidateQueries(['membercountry', data.id, data.language]);
    }
    const onRemoveFile = () => {
        updateData('flag', null);
        updateData('flagFileId', null);
        return queryClient.invalidateQueries(['membercountry', data.id, data.language]);
    };
    const { validateOn } = useContext(ValidationContext)
    const { t } = useTranslation();

    return (
        <TabPanel {...rest}>
            <InputContainer>
                <ViewField title='membercountry:field.flagFileId' required>
                    <FileList files={data.flag ? [data.flag] : []} onRemove={readOnly ? undefined : onRemoveFile} />
                </ViewField>
                {!data.flagFileId && <FileUploader onFileUploaded={onUploaded} />}
                {validateOn && !data.flagFileId && <FormHelperText error>{t('main:error.required')}</FormHelperText>}
            </InputContainer>
            <InputControl labelKey={'membercountry:field.name'}
                          onChange={value => updateData('name', value)}
                          value={data.name}
                          required
                          validators={[NotEmpty, Length(50)]} tab='main'
                          disabled={readOnly}/>
            <InputControl labelKey={'membercountry:field.capital'}
                          onChange={value => updateData('capital', value)}
                          value={data.capital}
                          required
                          validators={[NotEmpty, Length(255)]} tab='main'
                          disabled={readOnly}/>
            {entity?.id && <AutocompleteControl labelKey='membercountry:field.centerIds' multiple
                                                dictionary={DictionaryType.Center}
                                                onChange={(value) => {
                                                      updateData('centerIds', value?.map(item => item.value) ?? []);
                                                      updateData('centerDtos', value?.map(item => ({
                                                          id: item.value,
                                                          name: item.label,
                                                      })) ?? []);
                                                  }}
                                                options={(term, language, skip) => CenterService.selectLoadOptions(term, language, skip, entity.id)}
                                                value={data.centerIds}
                                                disabled={readOnly}/>
            }
            <InputControl labelKey={'membercountry:field.description'} multiline
                          onChange={value => updateData('description', value)}
                          value={data.description}
                          validators={[Length(2000)]} tab='main'/>
        </TabPanel>
    );
}