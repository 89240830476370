import ValidationRule from "../validation.rule.enum";
import IValidator from "./validator";

export default class LengthValidator implements IValidator<string|string[]> {
   maxLength: number;
   minLength?: number;
   rule = ValidationRule.Length;

   constructor(maxLength: number, minLength?: number) {
      this.maxLength = maxLength;
      this.minLength = minLength;
   }

   validate(value: string|string[]) {
      for (const str of (Array.isArray(value) ? value : [value])) {
         if (this.minLength && (str?.length < this.minLength)) {
            return ['main:error.lengthRequired', this.minLength] as [string, ...any[]];
         }
         if (str?.length > this.maxLength) {
            return ['main:error.lengthExceeded', this.maxLength] as [string, ...any[]];
         }
      }

      return null
   }
}