import NewsDto from './news.dto';
import DriveFileDto from '../../drive/dto/drive.file.dto';
import NewsStatusEnum from '../enum/news.status.enum';

export default class NewsEditDto {
    id?: number = 0;
    title: string = '';
    created?: string = '';
    lastModified?: string = '';
    centerId?: number = 0;
    centerName?: string = '';
    status: NewsStatusEnum | string = NewsStatusEnum.Draft;
    announcementDescription?: string = '';
    announcementFileId?: string | null;
    announcementFile?: DriveFileDto;
    detailDescription?: string = '';
    detailFileId?: string | null;
    detailFile?: DriveFileDto;
    mainRemark?: string = '';
    announcementRemark?: string = '';
    detailRemark?: string = '';

    constructor(entity?: NewsDto | undefined) {
        Object.entries(entity ?? {}).forEach(([key, val]) => {
            if (this.hasOwnProperty(key)) {
                this[key as keyof NewsEditDto] = val as never;
            }
        });
    }
}
