const developmentParams = {
    ApplicationName: 'Globinet.WebUI.Dev',
};

const productionParams = {
    ApplicationName: 'Globinet.WebUI',
};

const betaParams = {
    ApplicationName: 'Globinet.WebUI.Beta',
};

const config = {
    AuthServer: 'https://auth.ruitc.ru',
    //AuthServer: 'https://localhost:44315',
    //common values
    ...(process.env.REACT_APP_ENV === 'production' ? productionParams : process.env.REACT_APP_ENV === 'beta' ? betaParams : developmentParams),
};

export default config;
