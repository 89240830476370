import ProfileDto from "../../../../eenApi/profile/dto/profile.dto";
import { ActionItem } from "../../../../models/actionItem";
import ProfileStatusEnum from "../../../../eenApi/profile/enum/profile.status.enum";
import { useChangeStatusMutation } from "../../../../hooks/UseChangeStatusMutation";
import ProfileService from "../../../../eenApi/profile/service";
import LanguageEnum from "../../../../enums/language.enum";
import useTranslation from "../../../../hooks/translation.hook";
import { AccessActionEnum } from "../../../../models/access.dto";

export function useActions(entity: ProfileDto|undefined): ActionItem[] {
  const { t } = useTranslation();
  const changeStatusMutation = useChangeStatusMutation<ProfileStatusEnum>(
    (status) => ProfileService.status(entity!.id!, entity?.language as LanguageEnum, status), 'profile');

  if (!entity) return [];

  const items: ActionItem[] = [];

  if (entity.accessDto[AccessActionEnum.Status]) {
    if (entity?.status === ProfileStatusEnum.Draft || entity.status === ProfileStatusEnum.CommentsReceived) {
      items.push({
        name: t('main:btn-sendValidation'),
        onClick: async () => changeStatusMutation.mutate(ProfileStatusEnum.Validation)
      })
    }

    if (entity?.status === ProfileStatusEnum.Validation) {
      items.push({
        name: t('main:btn-remarks'),
        onClick: async () => changeStatusMutation.mutate(ProfileStatusEnum.CommentsReceived)
      });

      items.push({
        name: t('main:btn-active'),
        onClick: async () => changeStatusMutation.mutate(ProfileStatusEnum.Active)
      })
    }
  }

  return items;
}