import { toast } from 'react-toastify';
import ToastMessage from './toastMessage';
import { IToastProps, ToastStyle, ToastStyleType } from './types';

// {data && data.date &&
//     <small>11 mins ago//todo</small>
// }

class IToastManager {
    getStyle(style: ToastStyleType) {
        switch (style) {
            case ToastStyleType.error:
                return new ToastStyle({ bgColor: "bg-danger", textColor: "text-white", closeButton: "btn-close-white", icon: "fas fa-exclamation-circle fa-lg" });
            case ToastStyleType.warn:
                return new ToastStyle({ bgColor: "bg-warning", textColor: "text-white", closeButton: "btn-close-white", icon: "fas fa-exclamation-triangle fa-lg" });
            case ToastStyleType.info:
                return new ToastStyle({ bgColor: "bg-info", textColor: "text-white", closeButton: "btn-close-white", icon: "fas fa-info-circle fa-lg" });
            case ToastStyleType.success:
                return new ToastStyle({ bgColor: "bg-success", textColor: "text-white", closeButton: "btn-close-white", icon: "fas fa-check fa-lg" });
            case ToastStyleType.primary:
                return new ToastStyle({ bgColor: "bg-primary", textColor: "text-white", closeButton: "btn-close-white" });
        }
        return new ToastStyle({ bgColor: "bg-light" });
    }

    error(data: Partial<IToastProps>) {
        this.show(data, ToastStyleType.error);
        //this.show(data, ToastStyleType.warn);
        //this.show(data, ToastStyleType.info);
        //this.show(data, ToastStyleType.success);
        //this.show(data, ToastStyleType.primary);
        //this.show(data, ToastStyleType.light);
    }

    warn(data: Partial<IToastProps>) {
        this.show(data, ToastStyleType.warn);
    }

    info(data: Partial<IToastProps>) {
        this.show(data, ToastStyleType.info);
    }

    success(data: Partial<IToastProps>) {
        this.show(data, ToastStyleType.success);
    }

    primary(data: Partial<IToastProps>) {
        this.show(data, ToastStyleType.primary);
    }

    light(data: Partial<IToastProps>) {
        this.show(data, ToastStyleType.light);
    }

    test(data: Partial<IToastProps>) {
        this.show(data, ToastStyleType.error);
        this.show(data, ToastStyleType.warn);
        this.show(data, ToastStyleType.info);
        this.show(data, ToastStyleType.success);
        this.show(data, ToastStyleType.primary);
        this.show(data, ToastStyleType.light);
    }

    private show(data: Partial<IToastProps>, styleType: ToastStyleType) {
        const style = this.getStyle(styleType);
        toast(<ToastMessage data={data} toastStyle={style} />, {
            closeButton: false,
            className: 'toast show p-0 mb-2',
            bodyClassName: "p-0",
            progressClassName: 'toast-progress-bar'
        });
    }
}

export const ToastManager = new IToastManager();