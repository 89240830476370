import {Link as RouterLink} from "react-router-dom";
import {HtmlString, LongString} from "../../../../types/string.types";
import {PropsWithChildren} from "react";
import Field from "../Field";
import {Link as ExternalLink} from "@mui/material";

interface IProps {
   labelKey: string,
   value?: string|LongString|HtmlString,
   route?: string,
   href?: string,
   html?: boolean
   optional?: boolean
   hasAccess?: boolean
}

export default function LinkField(props: PropsWithChildren<IProps>) {
   if (props.optional && !props.value && !props.children) {
      return <></>
   }

   const content = <>
      {props.html
         ? <span dangerouslySetInnerHTML={{ __html: props.value ?? '' }}/>
         : props.value ?? ''}
      {props.children}
   </>

   const href = props.href?.startsWith('http')
      ? props.href
      : `https://${props.href}`;

   return (props.optional && !props.value && !props.children ? <></> :
      <Field labelKey={props.labelKey}>
         {props.route
            ? (props.hasAccess ?? true) ? <RouterLink to={props.route}>{content}</RouterLink> : <>{content}</>
            : <ExternalLink href={href} target="_blank">{content}</ExternalLink>
         }
      </Field>
   )
}