import Loader from "../../../loader";
import {MDBBtn} from "mdb-react-ui-kit";
import React, {useState} from "react";
import {SelectValue} from "../../../select/SelectValue";
import {DictionaryType} from "../../../../models/dictionaryType";
import {useQuery} from "react-query";
import {DictionaryService} from "../../../../eenApi/dictionary/service";
import LanguageEnum from "../../../../enums/language.enum";
import {ErrorHandler} from "../../../../service/errorHandler";
import DictionaryTreeView from "./modal.tree.view";
import useTranslation from "../../../../hooks/translation.hook";

interface IProps {
   dictionary: DictionaryType;
   language: LanguageEnum;
   isOpen: boolean;
   value: SelectValue[]|null;
   onSave: (checked: SelectValue[]|null) => void;
}

export default function ModalBody({ dictionary, language, isOpen, value, onSave }: IProps) {
   const {t} = useTranslation();
   const {data: allItems} = useQuery([isOpen],
      () => isOpen ? DictionaryService.getAll(dictionary, language) : [], {
         onError: (err) => {
            ErrorHandler.handle(`Dictionary [${dictionary}] load failed`, err);
         }
      })
   const [checked, setChecked] = useState(() => Array.isArray(value) ? [...value] : value);

   return (<>
      <div className="modal-body">
         <div className="">
            {!allItems
               ? <div className="text-center"><Loader /></div>
               : <DictionaryTreeView items={allItems} value={checked} onChange={setChecked} />
            }
         </div>
      </div>
      <div className="modal-footer">
         <MDBBtn onClick={() => onSave(checked)}>{t('main:btn-select')}</MDBBtn>
      </div>
   </>)
}