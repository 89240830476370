import { EenApi } from '..';
import { DriveRoutesPath } from '../../page/drive/routes';
import { DriveEntity } from './driveEntity';
import { DriveFileInfo } from './driveFileInfo';
import { DriveListRequestData } from './driveListRequestData';
import { concat } from "../../utils/string.utils";

class CDriveService {
    async list(requestData: DriveListRequestData, path = '') {
        return await EenApi.list<DriveEntity>(concat('/', 'drive', path, 'list'), requestData);
    }

    async addFolder(path: string, data: { name: string; parentId?: string }) {
        return await EenApi.post<string>(concat('/', 'drive', path, 'addfolder'), data);
    }

    async delete(id: string|string[], path = '') {
        const ids = Array.isArray(id) ? id : [id]
        await EenApi.delete(concat('/', 'drive', path, 'delete'), { ids });
    }

    async rename(path: string, data: { id: string; name: string }) {
        await EenApi.put(concat('/', 'drive', path, 'rename'), data);
    }

    async move(path: string, data: { id: string; parentId?: string }) {
        await EenApi.put(concat('/', 'drive', path, 'move'), data);
    }

    async getFileInfo(id: string) {
        return await EenApi.get<DriveFileInfo>(`drive/getFileInfo/${id}`);
    }

    /**
     * @param file js File
     * @param path controller URL path
     * @param id DriveFolder Id
     * @returns
     */
    uploadFile(file: File, path = '', id?: string) {
        let formData = new FormData();
        if (id) {
            formData.append('parentId', id);
        }
        formData.append('file', file);
        return EenApi.post<string>(concat('/', 'drive', path, 'upload'), formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    }

    getDownloadLink(token: string) {
        return `${window.location.origin}/file/d/${token}`;
    }

    getViewFolderLink(id: string, path = '') {
        return DriveRoutesPath.ViewFolder
          .replace('folder', concat('/', path, 'folder'))
          .replace(':id', id)
    }

    download(id: string) {
        return EenApi.get<Blob>(`drive/download/${id}`, 'blob');
    }
}

export const DriveService = new CDriveService();
