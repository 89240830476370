import queryCache from "./query.cache";
import {QueryClient} from "react-query";
import {EenApi} from "../eenApi";

const createQueryClient = (onErrorFn: Function) => new QueryClient({
   defaultOptions: {
      queries: {
         refetchOnWindowFocus: false,
         refetchOnReconnect: false,
         refetchOnMount: false,
         keepPreviousData: true,
         staleTime: Infinity,
         retry: 0,
         onError: err => onErrorFn(err),
         queryFn: async ({ queryKey }) => {
            const [controller, id, language, childPath] = queryKey;
            if (!Number(id) || Number(id) < 1) {
               throw { status: 404 }
            }
            return await EenApi.get(`${controller}/get/${childPath ?? ''}${id}${language ? `?language=${language}` : ''}`);
         }
      },
      mutations: {
         onError: err => onErrorFn(err)
      }
   },
   queryCache: queryCache
})

export default createQueryClient;