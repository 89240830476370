import LanguageEnum from "../../../enums/language.enum";
import classes from "./index.module.css";
import classNames from "classnames";

interface IProps {
   selected: LanguageEnum,
   lang: LanguageEnum,
   id: string,
   onChange: (id: string, lang: LanguageEnum) => void
}

export default function LangSwitch(props: IProps) {
   const onClick = () => {
      if (props.lang !== props.selected) {
         props.onChange(props.id, props.lang)
      }
   };

   return (
      <button id={`tab-${props.id}-${props.lang}`}
              aria-controls={`tabpanel-${props.id}-${props.lang}`}
              className={classNames('btn', classes.langControl, {
                 [classes.active]: props.selected === props.lang
              })}
              onClick={onClick}>
         <img src={`/lang/${props.lang}.jpeg`} alt={props.lang}/>
      </button>
   )
}