import { Route, Switch } from 'react-router-dom';
import ProtectedRoute from '../../../component/common/ProtectedRoute';
import Rules from '../../../rules';
import NotFound from '../../home/notFound';
import { MailingList } from '../mailings/list';
import EditMailing from '../mailings/edit/index';
import { UnsubscribeMailingList } from '../mailings/unsubscribe/list';
import EditMailingUnsubscribe from '../mailings/unsubscribe/edit';
import PreferenceMailing from '../mailings/unsubscribe/edit';

export default function MailingRoutes() {
    return (
        <Switch>
            <ProtectedRoute path={MailingRoutesPath.List} component={MailingList} rule={Rules.Mailing.List} />
            <ProtectedRoute path={MailingRoutesPath.Edit} component={EditMailing} rule={Rules.Mailing.Edit} />
            <ProtectedRoute path={MailingRoutesPath.Add} component={EditMailing} rule={Rules.Mailing.Add} />
            <ProtectedRoute path={MailingRoutesPath.UnsubscribeList} component={UnsubscribeMailingList} rule={Rules.Mailing.Settings} />
            <ProtectedRoute path={MailingRoutesPath.Unsubscribe} component={EditMailingUnsubscribe} rule={Rules.Mailing.Settings} />
            <ProtectedRoute path={MailingRoutesPath.Preference} component={PreferenceMailing} rule={Rules.Mailing.Settings} />
            <Route path="*" component={NotFound} />
        </Switch>
    );
}

const prefix = '/mailing';
export const MailingRoutesPath = {
    Prefix: `${prefix}`,
    Add: `${prefix}/create`,
    List: `${prefix}/list`,
    Edit: `${prefix}/:id/edit`,
    UnsubscribeList: `${prefix}/unsubscribe-list`,
    Unsubscribe: `${prefix}/setting/unsubscribe`,
    Preference: `${prefix}/setting/mailing`,
};
