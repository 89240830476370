export default function SplashScreen() {
    return (
        <div className="position-absolute w-100 h-100 d-flex align-items-center justify-content-center">
            <div>
                <div className="h3">GLOBINET</div>
                <div className="d-flex justify-content-center">
                    <div className="spinner-border text-dark spinner-border-sm" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            </div>
        </div>
    );
}
