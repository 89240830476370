import {
  GridFooter,
  GridFooterContainer,
  GridSelectedRowCount,
  GridSelectionModel,
  GridToolbarColumnsButton,
} from "@mui/x-data-grid";
import React, { SyntheticEvent, useState } from 'react';
import { TableGroupAction } from "./table.model";
import { Autocomplete, TextField } from '@mui/material';
import useTranslation from '../../../../hooks/translation.hook';
import { resetQueryFn } from "../../../../query/query.utils";
import { QueryKey, useQueryClient } from "react-query";
import { ErrorHandler } from "../../../../service/errorHandler";

interface IProps {
    queryKey: QueryKey,
    selectionModel: GridSelectionModel;
    groupActions: TableGroupAction[];
}

export function TableFooter(props: IProps) {
    const { t } = useTranslation();
    const queryClient = useQueryClient();
    const [action, setAction] = useState<TableGroupAction | null>(null);

  const doGroupAction = async () => {
    if (!action) return;

    try {
      await action.action(props.selectionModel);
      if (action.refreshTableAfterAction ?? true) {
        await queryClient.resetQueries({ predicate: query => resetQueryFn(query, ...new Array<QueryKey>().concat(props.queryKey)) })
      }
    } catch (e) {
      ErrorHandler.handle(action.id, e);
    }
  };

    return (<>
        <GridFooterContainer className="ps-3 py-1">
            <GridToolbarColumnsButton />
            <GridFooter />
        </GridFooterContainer>
        {(props.selectionModel?.length || false) && (
          <GridFooterContainer className="justify-content-start">
            <GridSelectedRowCount selectedRowCount={props.selectionModel?.length ?? 0} />
            <Autocomplete
              value={action}
              options={props.groupActions}
              getOptionLabel={(option) => option.label}
              onChange={(event: SyntheticEvent, value: TableGroupAction | null) => {
                setAction(value);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t('table:select-group-action')}
                  size="small"
                  InputProps={{
                    ...params.InputProps,
                    disabled: true,
                  }}
                />
              )}
              sx={{
                width: '200px',
                background: '#fff',
              }}
              className="ms-1"
            />
            {action != null && props.selectionModel.length > 0 && <button type='button' className='btn btn-primary ms-2'
                                       onClick={doGroupAction}>{t('main:btn-apply')}</button>}
          </GridFooterContainer>
        )}
    </>);
}
