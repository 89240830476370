import { Redirect, Route, Switch } from "react-router-dom";
import NotFound from '../../home/notFound';
import { CenterEdit } from '../edit';
import { CenterList } from '../list';
import ProtectedRoute from '../../../component/common/ProtectedRoute';
import Rules from '../../../rules';
import CenterDetails from '../details';
import { ProfileList } from '../../profile/list';
import { useCurrentUser } from "../../../hooks/useCurrentUser";

export default function CenterRoutes() {
    const { data: user, isFetching } = useCurrentUser();

    return (
      isFetching ? <></> :
        <Switch>
            <ProtectedRoute exact path={CenterRoutesPath.Create} rule={Rules.Center.Add} component={CenterEdit}/>
            <ProtectedRoute exact path={CenterRoutesPath.Edit} rule={[Rules.Center.Edit, Rules.Center.EditOwn]} component={CenterEdit}/>
            <ProtectedRoute exact path={CenterRoutesPath.Details} rule={[Rules.Center.View, Rules.Center.ViewOwn]} component={CenterDetails}/>
            <Route exact path={CenterRoutesPath.My} render={() => <Redirect to={CenterRoutesPath.Details.replace(':id', user!.rules.find(x => !!x.objectId)?.objectId ?? '')}/>}/>
            <ProtectedRoute exact path={CenterRoutesPath.List} rule={Rules.Center.List} component={CenterList}/>
            <ProtectedRoute exact path={CenterRoutesPath.Ric} rule={Rules.Center.PartnerList}
                            render={props => <CenterList {...props} type={'ric'}/>}/>
            <ProtectedRoute exact path={CenterRoutesPath.CertAuthority} rule={Rules.Center.PartnerList}
                            render={props => <CenterList {...props} type={'certificationauthority'}/>}/>
            <ProtectedRoute path={CenterRoutesPath.Profiles} exact
                            render={(props) => <ProfileList {...props} prefix={CenterRoutesPath.Prefix}/>}
                            rule={Rules.Center.View}/>
            <Route path="*" component={NotFound} />
        </Switch>
    );
}

const prefix = '/center';
export const CenterRoutesPath = {
    Prefix: prefix,
    Create: `${prefix}/create`,
    Edit: `${prefix}/:id/edit`,
    Details: `${prefix}/:id/details`,
    List: `${prefix}/list`,
    Ric: `${prefix}/ric`,
    CertAuthority: `${prefix}/certificationauthority`,
    Profiles: `${prefix}/:centerId/profiles`,
    My: `${prefix}/my`
};
