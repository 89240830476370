import TabPanel from '../../tabs/tabPanel';
import React, { useState } from 'react';
import IEditPanelProps from '../edit.panel.props';
import InputContainer from '../../inputContainer';
import ViewField from '../../fields/viewField';
import FileList from '../../fields/fileList';
import FileUploader from '../../fileUploader';

export default function AttachmentEditTab({ entity, data, updateData, ...rest }: IEditPanelProps) {

    const [files, setFile] = useState(entity?.files ?? []);
    const onUploaded = (file: { id: string; name: string }) => {
        setFile(prev => [...prev, file]);
        updateData('filesId', [...data.filesId, file.id]);
    };
    const onRemoveFile = (fileId: string) => {
        data.filesId.splice(data.filesId.indexOf(fileId), 1)
        setFile(prev => {
            prev.splice(prev.map(x => x.id).indexOf(fileId), 1);
            return prev
        });
        updateData('filesId', [...data.filesId]);
    };

    return (
        <TabPanel {...rest}>
            <InputContainer>
                <ViewField title="mailing:mailings.fields.files">
                    <FileList files={files} onRemove={onRemoveFile} />
                </ViewField>

                <FileUploader onFileUploaded={onUploaded} />
            </InputContainer>

            {/* Временно скрыты
            <CheckboxControl
                value={data.isProfile}
                labelKey={'mailing:mailings.fields.profiles'}
                onChange={(data) => updateData('isProfile', data)}
                tab={'attachment'}
            /> */}
        </TabPanel>
    );
}
