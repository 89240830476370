import { FC } from "react";
import useTranslation from "../../hooks/translation.hook";
import { useHistory } from "react-router-dom";
import { ActionItem } from "../../models/actionItem";
import BaseModal from "../modal/baseModal";
import ModalHeader from "../modal/modalHeader";
import ActionModalButton from "./actionModalButton";

interface IProps {
    actions: ActionItem[],
    onClick: (action: ActionItem) => void
}

const ModalActions: FC<IProps> = (props: IProps) => {
    const { t } = useTranslation();
    const history = useHistory();

    const onRequestClose = () => {
        closeModal(false);
    }

    const closeModal = (callback: any) => {
        history.goBack();
        if (callback) {
            callback();
        }
    }

    const clickBack = () => {
        history.goBack();
    }


    return (
        <BaseModal isOpen={true} onRequestClose={onRequestClose} shouldCloseOnEsc={false} modalSize={'md'}>
            <ModalHeader title={t('main:btn-actions')} onClickBack={clickBack} onRequestClose={onRequestClose} hideBorder />
            <div className="modal-body py-2 px-0">
                <div className="d-flex flex-column">
                    {props.actions.map((x: ActionItem, index) => (
                        <ActionModalButton key={index} action={x} onClick={action => {
                            onRequestClose()
                            props.onClick(action)}} />
                    ))}
                </div>
            </div>
        </BaseModal>
    );
}

export default ModalActions;
