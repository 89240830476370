import { EenApi } from "..";

class CFilterService {
    async list(id: string) {
        const data = await EenApi.get<any>(`filter/list/${id}`);
        return data;
    }

    async saveSorting(id: string, sorting: any) {
        try {
            await EenApi.post('filter/sorting', { table: id, data: sorting });
        }
        catch (err) {
            console.log('save sort', id, err);
        }
    }

    async saveFilter(rd: any) {
        return await EenApi.post<{ id: number }>('filter/edit', rd);
    }

    async delete(ids: string[]) {
        await EenApi.post('filter/delete', { id: ids });
    }

    // async get(id: number) {
    //     const data = await LmsApi.post<Client>('/client/get', { id: id });
    //     return data;
    // }

    // async addOrUpdate(entity: Client) {
    //     if (entity.id < 1) {
    //         return await LmsApi.post<number>('/client/add', entity);
    //     }
    //     return await LmsApi.post<number>('/client/edit', entity);
    // }

    // async delete(id: number[]) {
    //     const data = await LmsApi.post('/client/delete', { id: id });
    //     return data;
    // }
}

export const FilterService = new CFilterService();
