import IControlProps from '../control.props';
import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import { StyledTextField } from '../index';
import { useValidation } from '../../../../hooks/UseValidation';
import InputContainer from '../../../inputContainer';
import ValidationContext from '../../../../hooks/UseValidation/validation.context';
import { FormHelperText } from '@mui/material';
import useTranslation from '../../../../hooks/translation.hook';

interface IProps extends IControlProps {
    type?: string;
    multiline?: boolean;
    inputProps?: any;

    focused?: boolean;
    color?: any;
}

export default function InputControl(props: IProps) {
    const { t } = useTranslation();
    const validate = useValidation(props.validators);
    const { validateOn } = useContext(ValidationContext);
    const [errorText, setErrorText] = useState<string>();

    useEffect(() => {
        setErrorText(props.error || validate(props.value, props.tab ?? 'main'));
    }, [props.value, props.error, validateOn]);

    return (
        <InputContainer inModal={props.inModal}>
            <StyledTextField
                fullWidth
                size="small"
                label={t(props.labelKey)}
                value={props.value ?? ''}
                required={props.required}
                type={props.type ?? 'text'}
                onChange={(event: ChangeEvent<HTMLInputElement>) => props.onChange(event.target.value)}
                error={!!errorText}
                helperText={errorText}
                multiline={props.multiline}
                disabled={props.disabled}
                InputLabelProps={{
                    shrink: ['date', 'datetime', 'number'].includes(props.type ?? '') || undefined,
                }}
                inputProps={props.inputProps}
                focused={props.focused}
                color={props.color}
            />
            {props.hintKey && <FormHelperText>{t(props.hintKey)}</FormHelperText>}
        </InputContainer>
    );
}
