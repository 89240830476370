import { MDBCheckbox, MDBInput } from 'mdb-react-ui-kit';
import { FC, useState } from 'react';
import useTranslation from '../../../hooks/translation.hook';
import { useHistory, useParams } from 'react-router';
import DefaultBlock from '../../../component/defaultBlock';
import InputContainer from '../../../component/inputContainer';
import MainContainer from '../../../component/mainContainer';
import PageTitle from '../../../component/pageTitle';
import { EenApi } from '../../../eenApi';
import { Role } from '../../../eenApi/role/role';
import { ErrorHandler } from '../../../service/errorHandler';
import { ToastManager } from '../../../service/toastManager';
import RuleBlock from './ruleBlock';
import { RoleService } from '../../../eenApi/role/service';
import { useCurrentUser } from '../../../hooks/useCurrentUser';
import { useQuery, useQueryClient } from "react-query";
import { intersection, flatMap, map } from 'lodash';


const RoleEdit: FC = () => {
    const { id } = useParams<{ id?: string }>();
    const { t, i18n } = useTranslation();
    const history = useHistory();
    const currentUser = useCurrentUser();

    const queryClient = useQueryClient();
    const { data: ruleTree } = useQuery('rule-tree', async () => (await EenApi.get<any>(`role/rules`))?.items ?? {});
    const { data: role } = useQuery<Role>(['role', id, i18n.language], {
        onSuccess: data => {
            setEntity({
                ...entity,
                name: data.name,
                description: data.description ?? '',
                unregisteredUser: data.unregisteredUser,
                rules: intersection(data.rules, map(flatMap(ruleTree), 'code')),
            });
        },
        enabled: Number(id ?? 0) > 0 && ruleTree !== undefined
    })

    const [entity, setEntity] = useState<Role>(role ?? {
        id: id ? parseInt(id) || 0 : 0,
        name: '',
        description: '',
        rules: [],
        unregisteredUser: false,
    });

    const saveAction = async () => {
        try {
            const rd = {
                ...entity,
                language: currentUser.data?.language,
            };
            const data = entity.id > 0 ? await EenApi.put<number>('role/edit', rd) : await EenApi.post<number>('role/add', rd);
            ToastManager.success({ title: 'main:msg-success', message: 'main:msg-changes-saved' });
            if (entity.id < 1) {
                history.push(RoleService.getEditLink(data));
            }
            setEntity({ ...entity, id: data });
            await queryClient.invalidateQueries(['role', id, i18n.language]);
        } catch (err) {
            ErrorHandler.handle('role add/edit', err);
        }
    };

    const onChangeRule = (e: any) => {
        const rules = entity.rules;
        if (rules.includes(e.target.name)) {
            setEntity({ ...entity, rules: rules.filter((x) => x !== e.target.name) });
        } else {
            rules.push(e.target.name);
            setEntity({ ...entity, rules: rules });
        }
    };

    const onClickSave = async () => {
        saveAction();
    };

    const onClickClear = () => {
        setEntity({ ...entity, rules: [] });
    };

    return (
        <MainContainer>
            <PageTitle text={t(entity.id > 0 ? 'role:edit-title' : 'role:create-title')} backLink="/role/list" />
            <DefaultBlock>
                <InputContainer>
                    <MDBInput
                        type="text"
                        size="lg"
                        value={entity.name}
                        name="name"
                        onChange={(e: any) => setEntity({ ...entity, name: e.target.value })}
                        required
                        label={t('main:field-name')}
                    />
                </InputContainer>
                <InputContainer>
                    <MDBInput
                        type="text"
                        size="lg"
                        value={entity.description}
                        name="description"
                        onChange={(e: any) => setEntity({ ...entity, description: e.target.value })}
                        required
                        label={t('main:field-description')}
                    />
                </InputContainer>
                <InputContainer>
                    <MDBCheckbox
                        label={t('role:field-unregistered-user')}
                        id="role-unregistered-user"
                        checked={entity.unregisteredUser}
                        onChange={(e: any) => setEntity({ ...entity, unregisteredUser: e.target.checked })}
                    />
                </InputContainer>
                {ruleTree && (
                    <InputContainer>
                        {Object.keys(ruleTree).map((key) => (
                            <RuleBlock key={key} code={key}>
                                <div>
                                    {ruleTree[key].map((item: any) => (
                                        <div className="mt-2" key={item.code}>
                                            <MDBCheckbox
                                                label={t(`role:${item.code}`)}
                                                id={`cb-${item.code}`}
                                                name={item.code}
                                                checked={entity.rules.includes(item.code)}
                                                onChange={onChangeRule}
                                            />
                                        </div>
                                    ))}
                                </div>
                            </RuleBlock>
                        ))}
                    </InputContainer>
                )}
                <div>
                    <button className="btn btn-primary" onClick={onClickSave}>
                        {t('main:btn-save')}
                    </button>
                    <button className="btn btn-outline-primary ms-2" onClick={onClickClear}>
                        {t('main:btn-clear')}
                    </button>
                </div>
            </DefaultBlock>
        </MainContainer>
    );
};

export default RoleEdit;
