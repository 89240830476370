import { useContext, useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import useTranslation from '../../hooks/translation.hook';
import { EenApi } from '../../eenApi';
import { UserContext } from '../../context/user-context';
import MainContainer from '../../component/mainContainer';
import Rules from '../../rules';
import PageTitle from '../../component/pageTitle';
import { ErrorHandler } from '../../service/errorHandler';
import DefaultBlock from '../../component/defaultBlock';
import Loader from '../../component/loader';
import SortableContainer from '../../component/sortableContainer';
import { MenuItem } from '../../eenApi/menu/menuItem';
import { MenuOrder } from '../../eenApi/menu/menuOrder';
import { MenuService } from '../../eenApi/menu/service';
import { useCurrentUser } from '../../hooks/useCurrentUser';

export default function MenuList() {
    const context = useContext(UserContext);
    const currentUser = useCurrentUser();
    const { t } = useTranslation();
    const [isLoaded, setIsLoaded] = useState(true);
    const [items, setMenuItems] = useState<MenuItem[]>([]);
    const arMenuOrder = useRef<MenuOrder[]>();

    const loadItems = async () => {
        if (!currentUser.data?.language) return;
        setIsLoaded(false);

        const result = await MenuService.get({ language: currentUser.data?.language });
        if (result) {
            const menuItems = Array.isArray(result) ? result.map((x: MenuItem) => x) : [];
            createMenuOrder(menuItems);
            setMenuItems(menuItems);
            setIsLoaded(true);
        }
    };

    const createMenuOrder = (items: MenuItem[]) => {
        var arData: MenuOrder[] = [];
        items.forEach((item, i) => {
            arData = arData.concat(MenuService.itemToArray(item, i));
        });
        arMenuOrder.current = arData;
    };

    useEffect(() => {
        loadItems();
    }, []);

    const onEnd = (e: any) => {
        console.log(e);
        const id = parseInt(e.item.dataset.id);
        const parent = parseInt(e.to.dataset.parent);
        if (arMenuOrder && arMenuOrder.current) {
            const item = arMenuOrder.current.find((x) => x.id === id);
            if (item) {
                if (item.parentId === parent && item.sort === e.newIndex) {
                    return;
                }
                item.changed = true;
                item.parentId = parent;
                item.sort = e.newIndex;
                arMenuOrder.current
                    .filter((x) => x.parentId === parent)
                    .forEach((orderItem, index) => {
                        if (orderItem.id !== id && orderItem.sort >= e.newIndex) {
                            orderItem.changed = true;
                            orderItem.sort = orderItem.sort + 1;
                        }
                    });
            }
        }
    };

    const clickSave = async () => {
        if (!arMenuOrder.current) {
            return;
        }
        const rd = arMenuOrder.current.filter((x) => x.changed);
        if (rd.length < 1) {
            return;
        }
        try {
            await EenApi.put('menu/order', { items: rd });
            loadItems();
        } catch (err) {
            ErrorHandler.handle('save menu order', err);
        }
    };

    if (!isLoaded) {
        return <Loader />;
    }

    return (
        <MainContainer>
            <PageTitle text={t('menu:menu-title')} backLink="/" />
            <div className="d-xl-flex mb-1 mb-xl-3">
                <div className="app-filter-actions mb-2 mb-xl-0">
                    {context.checkAccess(Rules.Menu.Edit) && (
                        <Link className="btn btn-success me-2" to="/menu/edit/0">
                            {t('main:btn-add')}
                        </Link>
                    )}
                </div>
            </div>
            <DefaultBlock>
                <SortableContainer parentId={0} onEnd={onEnd} group="menu">
                    {items
                        .filter((x) => x.parentId === 0)
                        .map((menuItem) => (
                            <MenuItemView key={menuItem.id} item={menuItem} onEnd={onEnd} />
                        ))}
                </SortableContainer>
                <div className="mt-3">
                    <button className="btn btn-primary" onClick={clickSave}>
                        {t('main:btn-save')}
                    </button>
                </div>
            </DefaultBlock>
        </MainContainer>
    );
}

interface IProps {
    item: MenuItem;
    onEnd: (e: any) => void;
}

function MenuItemView(props: IProps) {
    const { t } = useTranslation();
    return (
        <div data-id={props.item.id}>
            <div className="d-flex px-1 py-2 border">
                <i className="fas fa-bars text-black-50 cursor-move my-auto me-2 px-1"></i>
                {props.item.icon && props.item.icon.length > 0 && (
                    <i
                        className={`${props.item.icon} my-auto me-2`}
                        style={{ color: props.item.color && props.item.color.length > 0 ? props.item.color : undefined }}
                    ></i>
                )}
                <div>
                    <span>
                        {props.item.name} {props.item.isAction === 'Y' ? `(${t('menu:field-action')})` : ''}
                    </span>
                </div>
                <div>
                    <Link to={`/menu/edit/${props.item.id}`}>
                        <i className="ms-2 fas fa-pen text-black-50"></i>
                    </Link>
                </div>
            </div>
            <div className="ms-2 ms-xl-4 py-1 mt-1">
                <SortableContainer parentId={props.item.id} onEnd={props.onEnd} group="menu">
                    {props.item.children &&
                        props.item.children.length > 0 &&
                        props.item.children.map((child) => <MenuItemView key={child.id} item={child} onEnd={props.onEnd} />)}
                </SortableContainer>
            </div>
        </div>
    );
}
