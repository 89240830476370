import moment from "moment";

export const formatDate = (str?: any, format?: string): string => {
   if (!str) return '';
   if (!format) {
      format = 'DD.MM.YYYY'
   }

   const date = moment(str);
   return date.isValid() ? date.format(format) : ''
}

export const formatDateTime = (str?: any): string => {
   return formatDate(str, 'DD.MM.YYYY HH:mm:ss')
}