import { Route, Switch } from 'react-router-dom';
import NotFound from '../../home/notFound';
import ProfileCreate from '../create';
import Rules from '../../../rules';
import ProtectedRoute from '../../../component/common/ProtectedRoute';
import ProfileDetails from '../details';
import ProfileEdit from '../edit';
import { ProfileList } from '../list';
import InterestEdit from '../interest/edit';
import { InterestList } from '../interest/list';
import InterestDetails from '../interest/details';

export default function ProfileRoutes() {
  return (
    <Switch>
      <ProtectedRoute path={ProfileRoutesPath.List} exact rule={Rules.Profile.List}
                      render={(props) => <ProfileList {...props} prefix="een" />} />
      <ProtectedRoute path={ProfileRoutesPath.My} exact rule={Rules.Profile.ListMy}
                      render={(props) => <ProfileList {...props} prefix="my" />} />
      <ProtectedRoute
        path={ProfileRoutesPath.Validation}
        exact
        render={(props) => <ProfileList {...props} prefix="validation" validation />}
        rule={Rules.Profile.ListValidation}
      />
      <ProtectedRoute path={ProfileRoutesPath.Create} exact component={ProfileCreate} rule={Rules.Profile.Add} />
      <ProtectedRoute path={ProfileRoutesPath.Edit} exact component={ProfileEdit} rule={[Rules.Profile.Edit, Rules.Profile.EditOwn]} />
      <ProtectedRoute path={ProfileRoutesPath.View} exact component={ProfileDetails}
                      rule={[Rules.Profile.ViewBasic, Rules.Profile.ViewBasicOwn,
                        Rules.Profile.ViewContact, Rules.Profile.ViewContactOwn,
                        Rules.Profile.ViewValidation, Rules.Profile.ViewValidationOwn]} />
      <ProtectedRoute path={ProfileRoutesPath.InterestCreate} exact component={InterestEdit} rule={Rules.Profile.InterestAdd} />
      <ProtectedRoute path={ProfileRoutesPath.InterestEdit} exact component={InterestEdit} rule={[Rules.Profile.InterestEdit, Rules.Profile.InterestEditOwn]} />
      <ProtectedRoute path={ProfileRoutesPath.InterestMy} exact rule={Rules.Profile.InterestListMy}
                      render={(props) => <InterestList {...props} list="my-interest" />}/>
      <ProtectedRoute path={ProfileRoutesPath.InterestList} exact component={InterestList} rule={Rules.Profile.InterestList} />
      <ProtectedRoute path={ProfileRoutesPath.InterestView} exact component={InterestDetails} rule={Rules.Profile.InterestView} />
      <Route path="*" component={NotFound} />
    </Switch>
  );
}

const prefix = '/profile';
export const ProfileRoutesPath = {
  Prefix: `${prefix}`,
  Create: `${prefix}/create`,
  List: `${prefix}/list`,
  My: `${prefix}/my`,
  Validation: `${prefix}/validation`,
  Edit: `${prefix}/:id/edit`,
  View: `${prefix}/:id/details`,
  Validate: `${prefix}/:id/validate`,
  InterestCreate: `${prefix}/interest/create`,
  InterestEdit: `${prefix}/interest/:id/edit`,
  InterestView: `${prefix}/interest/:id/details`,
  InterestList: `${prefix}/interest/list`,
  InterestMy: `${prefix}/interest/my`,
};
