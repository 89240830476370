import { FC } from 'react';
import { useSpring, animated } from 'react-spring';

interface IProps {
    isOpen: boolean;
    count: number;
    children?: React.ReactNode;
}

const Childs: FC<IProps> = (props) => {
    const springContentProps = useSpring({
        from: {
            transform: `translate3d(${!props.isOpen ? 0 : -300}px,0,0)`,
            height: `${!props.isOpen ? props.count * 42 : 0}px`,
        },
        to: {
            transform: `translate3d(${props.isOpen ? 0 : -300}px,0,0)`,
            height: `${props.isOpen ? props.count * 42 : 0}px`,
        },
    });
    return (
        <div className="childs">
            <animated.div style={springContentProps}>{props.children}</animated.div>
        </div>
    );
};

export default Childs;
