import React from "react";
import {SelectControl} from "../ui";
import LanguageEnum from "../../../enums/language.enum";
import useTranslation from "../../../hooks/translation.hook";

interface IProps {
   required?: boolean
   labelKey?: string
   value: string|undefined
   error?: boolean
   onChange: (selected: string) => void
}

export default function LanguageSelect(props: IProps) {
   const { t } = useTranslation();

   return (
      <SelectControl {...props} error={props.error ? 'main:error.required' : ''}
                     labelKey="main:field.language"
                     options={[
                        { value: LanguageEnum.RU, label: t('main:language.ru') },
                        { value: LanguageEnum.EN, label: t('main:language.en') }
                     ]}
      />
   )
}