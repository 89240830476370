export type IToastProps = {
    message: string,
    title: string,
    icon: string,
    params?: any[]
}

export enum ToastStyleType {
    error,
    warn,
    info,
    success,
    primary,
    light
}

export interface IToastStyleProps {
    bgColor: string;
    textColor: string;
    closeButton: string;
    icon: string;
}

export class ToastStyle {
    bgColor: string;
    textColor: string;
    icon: string;
    closeButton: string;
    constructor(props: Partial<IToastStyleProps>) {
        this.bgColor = props?.bgColor ?? '';
        this.textColor = props?.textColor ?? '';
        this.icon = props?.icon ?? '';
        this.closeButton = props?.closeButton ?? '';
    }
}
