
import { Redirect, Route, Switch } from 'react-router-dom';
import { useContext } from 'react';
import { UserContext } from '../../../context/user-context';
import Rules from '../../../rules';
import MenuEdit from '../menuEdit';
import MenuList from '../menuList';
import NotFound from '../../home/notFound';

export default function MenuRoutes() {
    const context = useContext(UserContext);
    return (
        <Switch>
            {context.checkAccess(Rules.Menu.Edit)
                ? <Route path="/menu" exact component={MenuList} />
                : <Redirect to="/error/access-denied" />
            }
            {context.checkAccess(Rules.Menu.Edit)
                ? <Route path="/menu/edit/:id" component={MenuEdit} />
                : <Redirect to="/error/access-denied" />
            }
            <Route path="*" component={NotFound} />
        </Switch>
    );
}

export const MenuRoutesPath = {
    Prefix: '/menu'
};
