import React, { FC, useContext, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Client } from '../../../eenApi/client/client';
import { ClientService } from '../../../eenApi/client/service';
import useTranslation from '../../../hooks/translation.hook';
import Rules from '../../../rules';
import { UserContext } from '../../../context/user-context';
import { useCanViewClient } from '../access.hook';
import { DataTable } from '../../../component/common/layout';
import { TableColumn } from '../../../component/common/layout/DataTable/table.model';
import { EntityQueryKey } from '../../../query/query.keys';
import {
    useGroupDeleteAction,
    useIdColumn,
    useRowActions
} from "../../../component/common/layout/DataTable/table.utils";
import { GridSelectionModel } from "@mui/x-data-grid";

interface IProps {
    filterValues?: { [key: string]: any };
}

const ClientTable: FC<IProps> = (props) => {
    const { t, i18n } = useTranslation();
    const { checkAccess } = useContext(UserContext);
    const canViewClient = useCanViewClient();

    const idColumn = useIdColumn<Client>();
    const header = useMemo<TableColumn<Client>[]>(
        () => [
            idColumn,
            new TableColumn({
                headerName: t('client:field.name'),
                field: 'name',
                renderCell: (params) =>
                    canViewClient(params.row) ? <Link to={ClientService.getViewLink(Number(params.id))}>{params.value}</Link> : params.value,
            }),
            new TableColumn({
                headerName: t('client:field.short-name'),
                field: 'shortName',
                flex: 0.5,
            }),
            new TableColumn({
                headerName: t('client:field.type'),
                field: 'typeView',
                flex: 0.5,
            }),
            new TableColumn({
                headerName: t('client:field.inn'),
                field: 'inn',
                flex: 0.5,
            }),
        ],
        [i18n.language],
    );

    const clickDelete = (ids: GridSelectionModel) => ClientService.delete(ids.map(x => Number(x)));

    const rowActions = useRowActions<Client>({
        edit: checkAccess(Rules.Client.Edit) ? row => ClientService.getEditLink(row.id) : null,
        hideEdit: row => !checkAccess(Rules.Client.Edit, null, row?.memberCountry?.id),
        remove: checkAccess(Rules.Client.Delete) ? row => clickDelete([row.id]) : null
    });
    const groupActions = useGroupDeleteAction({
        remove: checkAccess(Rules.Client.Delete) ? clickDelete : null,
    });

    return (
        <DataTable
            id="my-clients"
            queryKey={EntityQueryKey.Client}
            columns={header}
            fetchData={ClientService.list}
            filterValues={props.filterValues}
            rowActions={rowActions}
            groupActions={groupActions}
        />
    );
};

export default ClientTable;
