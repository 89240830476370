import React, { FC, Suspense, useMemo, useState } from 'react';
import MainContainer from '../../../component/mainContainer';
import PageTitle from '../../../component/pageTitle';
import Loader from '../../../component/loader';
import BottomActionsContainer from '../../../component/actions/bottomActionsContainer';
import BottomActions from '../../../component/actions/bottomActions';
import { ActionItem } from '../../../models/actionItem';
import Utils from '../../../utils';
import { useHistory, useParams } from 'react-router-dom';
import { ErrorHandler } from '../../../service/errorHandler';
import { ToastManager } from '../../../service/toastManager';
import BaseModal from '../../../component/modal/baseModal';
import ModalHeader from '../../../component/modal/modalHeader';
import { MDBBtn } from 'mdb-react-ui-kit';
import { NewsService } from '../../../eenApi/news/service';
import { NewsEntity } from '../../../eenApi/news/news';
import { NewsRoutesPath } from '../routes';
import TopActionsContainer from '../../../component/actions/topActionsContainer';
import TabItem from '../../../component/tabs/tabItem';
import { useMutation, UseMutationResult, useQuery } from 'react-query';
import TabControls from '../../../component/tabs/tabControls';
import TopActions from '../../../component/actions/topActions';
import InputContainer from '../../../component/inputContainer';
import { SelectControl } from '../../../component/common/ui';
import { useOptions } from '../../../hooks/select.options.hook';
import { NewsStatusType } from '../../../enums/NewsStatusType';
import ValidateViewTab from '../../../component/news/ValidateViewTab';
import styled from '@emotion/styled';
import useTranslation from "../../../hooks/translation.hook";

const AnnouncementTab = React.lazy(() => import('../../../component/news/ValidateAnnouncementTab'));
const DetailsTab = React.lazy(() => import('../../../component/news/ValidateDetailTab'));

const StyledInputContainer = styled(InputContainer)`
  margin-top: 25px;
  margin-left: 28%;
  z-index: 50;
`;

const ValidateNews: FC = () => {
    const { id } = useParams<{ id?: string }>();
    const { t } = useTranslation();
    const history = useHistory();

    const [activeTab, setActiveTab] = useState('view');
    const [isChangeStatusOpen, setIsChangeStatusOpen] = useState(false);

    const statusOptions = useOptions(NewsStatusType, 'news:status.');

    let entityId = Utils.getEntityId(id);

    const tabItems = useMemo(() => {
        return [
            new TabItem('view', t('news:tabs.news')),
            new TabItem('announcement', t('news:tabs.announcement')),
            new TabItem('detail', t('news:tabs.detail')),
        ];
    }, []);

    const actions = useMemo<ActionItem[]>(() => {
        const items: ActionItem[] = [];
        items.push({
            name: t('main:btn-change-status'),
            onClick: () => {
                setIsChangeStatusOpen(true);
            },
        });


        return items;
    }, []);

    const { data: entity } = useQuery<NewsEntity>(['news', entityId], {
        onError: async (err) => {
            if (isNaN(entityId)) {
                history.push('*');
            }
            ErrorHandler.handle('news refreshData', err);
        },
    });

    const [validationStatus, setValidationStatus] = useState<string>('');

    const validateMutation = useMutation(() => NewsService.validate(entityId, validationStatus), {
        onSuccess: async () => {
            ToastManager.success({ title: 'main:msg-success', message: 'main:msg-changes-saved' });
        },
        onError: async (err) => {
            ErrorHandler.handle('News validated', err);
        },
        onSettled: async () => {
            closeIsChangeStatusOpen();
        },
    });

    const executeModalAction = (mutation: UseMutationResult<any, unknown, void>) => {
        mutation.mutate();
    };

    const closeIsChangeStatusOpen = () => setIsChangeStatusOpen(false);

    return (
        <MainContainer>
            <PageTitle text={t('news:validate-title')} backLink={NewsRoutesPath.CenterList} />
            <TopActionsContainer>
                <TopActions actions={actions} />
            </TopActionsContainer>
            <TabControls selectedId={activeTab} items={tabItems} onChange={setActiveTab} />
            {entity?.id && <>
                <BaseModal isOpen={isChangeStatusOpen} onRequestClose={closeIsChangeStatusOpen} modalSize='md'>
                    <ModalHeader title={t('news:modal.change-status')} onRequestClose={closeIsChangeStatusOpen} />
                    <StyledInputContainer inModal>
                        <SelectControl
                            labelKey='news:fields.field-status'
                            value={entity.status ?? null}
                            onChange={value => {
                                setValidationStatus(value);
                            }}
                            options={statusOptions}
                        />
                    </StyledInputContainer>
                    <div className='text-center p-3'>
                        <MDBBtn
                            onClick={() => {
                                executeModalAction(validateMutation);
                            }}
                        >{t('main:btn-apply')}</MDBBtn>
                    </div>
                </BaseModal>
                <ValidateViewTab activeId={activeTab} id='view' data={entity} />
                <Suspense fallback={<Loader />}>
                    <AnnouncementTab activeId={activeTab} id='announcement' data={entity} />
                    <DetailsTab activeId={activeTab} id='detail' data={entity} />
                </Suspense>
            </>}
            <BottomActionsContainer>
                <BottomActions actions={actions} />
            </BottomActionsContainer>
        </MainContainer>
    );
};

export default ValidateNews;
