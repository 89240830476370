import { EenApi } from '../index';
import SupportSubjectDto from './subjects/dto/support.subject.dto';
import SupportSubjectEditDto from './subjects/dto/support.subject.edit.dto';
import SupportDto from './supports/dto/support.dto';
import SupportEditDto from './supports/dto/support.edit.dto';
import { SelectValue } from '../../component/select/SelectValue';
import SupportStatusEnum from './supports/enum/support.status.enum';

class SupportServiceImpl {
    async addSubject(data: SupportSubjectEditDto): Promise<number> {
        return await EenApi.post<number>('support/addSubject', data);
    }

    async listSubjects(requestData: any) {
        return await EenApi.list<SupportSubjectDto>('support/subjects', requestData);
    }

    async getSubject(id: number) {
        return await EenApi.get<SupportSubjectDto>(`support/getSubject/${id}`);
    }

    async makeClosed(requestData: SupportEditDto) {
        requestData.status = SupportStatusEnum.Closed;
        return await EenApi.put<number>('support/edit', requestData);
    }

    async markAllAsRead() {
        return await EenApi.post('support/mark_all_viewed');
    }

    async addOrUpdateSubject(data: SupportSubjectEditDto): Promise<any> {
        if (data.id === 0 || data.id === undefined) {
            return await this.addSubject(data);
        }
        return await EenApi.put<any>('support/editSubject', data);
    }

    async list(requestData: any) {
        return await EenApi.list<SupportDto>('support/list', requestData);
    }

    async add(requestData: SupportEditDto) {
        return await EenApi.post<number>('support/add', requestData);
    }

    async addOrUpdate(requestData: SupportEditDto) {
        if (requestData.id === 0 || requestData.id === undefined) {
            return await this.add(requestData);
        }
        return await EenApi.put<number>('support/edit', requestData);
    }

    async removeSubject(id: number) {
        return await EenApi.delete('support/deleteSubject', { ids: [id] });
    }

    async remove(id: number) {
        return await EenApi.delete('support/delete', { ids: [id] });
    }

    async delete(id: number[]) {
        return await EenApi.delete('support/delete', { ids: id });
    }

    getSubjectTitle(supportSubject: SupportSubjectDto): string {
        return supportSubject.name;
    }

    async selectLoadOptions(): Promise<SelectValue[]> {
        const arItems: SelectValue[] = [];
        const rd = {
            filter: {},
        };
        const data = await SupportService.listSubjects(rd);
        data.items.forEach((item) => {
            arItems.push({
                value: `${item.id}` ?? '',
                label: SupportService.getSubjectTitle(item),
            });
        });
        return arItems;
    }
}

const SupportService = new SupportServiceImpl();
export default SupportService;