import { FC, useContext, useMemo, useState } from 'react';
import useTranslation from '../../../hooks/translation.hook';
import { Link } from 'react-router-dom';
import PageTitle from '../../../component/pageTitle';
import Filter from '../../../component/filter';
import ClientTable from './table';
import FilterItemHidden from '../../../component/filter/types/filterItem/items/hidden';
import MainContainer from '../../../component/mainContainer';
import { UserContext } from '../../../context/user-context';
import FilterItemText from '../../../component/filter/types/filterItem/items/text';
import FilterItemAsyncSelect from '../../../component/filter/types/filterItem/items/asyncSelect';
import { DictionaryService } from '../../../eenApi/dictionary/service';
import { DictionaryType } from '../../../models/dictionaryType';
import Rules from '../../../rules';
import { ClientRoutesPath } from "../routes";

const ClientList: FC = () => {
    const context = useContext(UserContext);
    const { t, i18n } = useTranslation();
    const [filterValues, setFilterValues] = useState<{ [key: string]: any }>();

    const filterItems = useMemo(() => [
        new FilterItemHidden('alias', { isTyping: 'Y' }),
        new FilterItemText('name', 'client:field.name', { isDefault: "Y" }),
        new FilterItemText('inn', 'client:field.inn'),
        new FilterItemAsyncSelect('type', 'client:field.type', {
            loadOptions: async (term) => {
                const response = await DictionaryService.getByFilter(DictionaryType.ClientType, i18n.language, { name: term?.trim() });
                return response.items.map(item => ({ value: item.code, label: item.name ?? item.code }))
            },
            isDefault: "Y",
            multiple: true
        }),
    ], []);

    return (
        <MainContainer>
            <PageTitle text={t('client:menu-title')} backLink="/" />
            <div className="d-xl-flex mb-1 mb-xl-3">
                <div className="app-filter-actions mb-2 mb-xl-0">
                    {context.checkAccess(Rules.Client.Add) &&
                        <Link className="btn btn-success me-2" to={ClientRoutesPath.Create}>{t('main:btn-add')}</Link>
                    }
                </div>
                <Filter id="filter-client" items={filterItems} onFind={setFilterValues} />
            </div>
            <ClientTable filterValues={filterValues} />
        </MainContainer>
    );
}

export default ClientList;
