enum LanguageEnum {
    RU = 'ru',
    EN = 'en',
}

export const isSupportedLanguage = (lang: string) => {
    return Object.values(LanguageEnum).includes(lang as LanguageEnum);
};

export default LanguageEnum;
