import React from "react";
import Loader from "../../../loader";
import TabPanel from "../../../tabs/tabPanel";

interface IProps {
  activeId: string
}

export function LoaderTab(props: IProps) {
  return (
    <TabPanel activeId={props.activeId} id="loading">
      <Loader />
    </TabPanel>
  )
}