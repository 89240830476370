import { MDBBtn } from 'mdb-react-ui-kit';
import React, { FC, useState } from 'react';
import useTranslation from '../../../../hooks/translation.hook';
import BaseModal from '../../../../component/modal/baseModal';
import ModalHeader from '../../../../component/modal/modalHeader';
import { DriveService } from '../../../../eenApi/drive/service';
import { ErrorHandler } from '../../../../service/errorHandler';
import { InputControl } from "../../../../component/common/ui";

interface IProps {
    isOpened: boolean;
    parentId?: string;
    onClose: () => void;
    onSave: () => void;
    path: string;
}

const AddFolderModal: FC<IProps> = ({ isOpened, parentId, onClose, onSave, path }) => {
    const { t } = useTranslation();
    const [name, setName] = useState('');

    const onModalClose = () => {
        setName('');
        onClose();
    };

    const onDataSave = () => {
        setName('');
        onSave();
    };

    const onSubmit = async (e: React.FormEvent) => {
        try {
            e.preventDefault();
            await DriveService.addFolder(path, { name: name, parentId: parentId });
            onDataSave();
        } catch (err) {
            ErrorHandler.handle('drivefolder add', err);
        }
    };

    return (
        <BaseModal centered isOpen={isOpened} onRequestClose={onModalClose} modalSize="md">
            <ModalHeader title={t('drive:add-folder-modal-title')} onClickBack={onModalClose} onRequestClose={onModalClose} />
            <div className="modal-body py-2">
                <form onSubmit={onSubmit}>
                    <InputControl labelKey="main:field-name" value={name} onChange={(value) => setName(value)} required inModal/>
                    <div>
                        <MDBBtn type="submit">{t('main:btn-save')}</MDBBtn>
                    </div>
                </form>
            </div>
        </BaseModal>
    );
};

export default AddFolderModal;
