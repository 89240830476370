import React from 'react';

interface IProps {
    className: string;
    defaultClassName: string;
    children?: React.ReactNode;
}

export default class DefaultBlock extends React.Component<IProps> {
    public static defaultProps = {
        className: '',
        defaultClassName: 'default-block',
    };

    public render(): JSX.Element {
        return <main className={`${this.props.defaultClassName} ${this.props.className}`}>{this.props.children}</main>;
    }
}
