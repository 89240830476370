import { FC, useState } from "react";
import useTranslation from "../../../hooks/translation.hook";
import { animated, useSpring } from "react-spring";

interface IProps {
    code: string
}

const RuleBlock: FC<React.PropsWithChildren<IProps>> = (props: React.PropsWithChildren<IProps>) => {
    const { t } = useTranslation();
    const [visible, setVisible] = useState(false);
    const springContentProps = useSpring({
        opacity: visible ? 1 : 0,
        transform: `translate3d(${visible ? 0 : 20}px,0,0)`
    });
    const springTitleProps = useSpring({
        transform: `rotate(${visible ? 0 : 180}deg)`
    });
    return (
        <div className="mb-3">
            <div role="button" className="d-inline-block user-select-none" onClick={() => setVisible(!visible)}>
                <span className="me-2">{t(`role:rule-block-title-${props.code}`)}</span>
                <animated.i className="fas fa-chevron-down" style={springTitleProps}></animated.i>
            </div>
            {visible &&
                <animated.div style={springContentProps}>{props.children}</animated.div>
            }
        </div>
    );
}

export default RuleBlock;
