import { EenApi } from '..';
import { SelectValue } from '../../component/select/SelectValue';
import { DictionaryEntity } from '../../models/dictionaryEntity';
import { ExternalProviderRoutesPath } from '../../page/externalProvider/routes';
import { ExternalProvider } from './externalProvider';
import { ExternalProviderParamDescription } from './externalProviderParam';

class CExternalProviderService {
    async list(requestData: any) {
        return await EenApi.list<ExternalProvider>('externalprovider/list', requestData);
    }

    async get(id: number, language: string) {
        return await EenApi.get<ExternalProvider>(`externalprovider/get/${id}?language=${language}`);
    }

    async addOrUpdate(entity: ExternalProvider) {
        if (entity.id < 1) {
            return await EenApi.post<number>('externalprovider/add', entity);
        }
        return await EenApi.put<number>('externalprovider/edit', entity);
    }

    async getTypes() {
        return await EenApi.get<DictionaryEntity[]>('externalprovider/types');
    }

    async getTypeParams(type: string) {
        return await EenApi.get<ExternalProviderParamDescription[]>(`externalprovider/params/${type}`);
    }

    async delete(ids: number[]) {
        await EenApi.delete('externalprovider/delete', { ids: ids });
    }

    getViewLink(id: number | string) {
        return `${ExternalProviderRoutesPath.View}${id}`;
    }

    getEditLink(id: number) {
        return `${ExternalProviderRoutesPath.Edit}${id}`;
    }

    async selectLoadOptions(inputValue: string): Promise<SelectValue[]> {
        const arItems: SelectValue[] = [];
        const rd = {
            filter: {
                name: inputValue,
            },
        };
        const data = await ExternalProviderService.list(rd);
        data.items.forEach((item) => {
            arItems.push({
                value: `${item.id}` ?? '',
                label: item.name,
            });
        });
        return arItems;
    }
}

export const ExternalProviderService = new CExternalProviderService();
