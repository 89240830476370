import { EenApi } from '..';
import { SelectValue } from "../../component/select/SelectValue";
import { Center } from './center';
import { CenterDto } from './dto/center.dto';
import { CrudService } from '../common/service/crud.service';
import { CenterRoutesPath } from '../../page/center/routes';
import { UserRole } from '../user/user.role';
import IdNameItemDto from "../common/id.name.item.dto";

class CCenterService extends CrudService<CenterDto> {

    constructor() {
        super('center');
    }

    users(requestData: any) {
        return EenApi.list<UserRole>("center/users", requestData);
    }

    get(id: number, language: string) {
        return EenApi.get<Center>(`center/get/${id}?language=${language}`);
    }

    deleteUsers(id: number) {
        return EenApi.delete("center/deleteuser", { id: id });
    }

    /**
     * link /center/${id}/details
     * @param id Center ID
     * @returns
     */
    getDetailsRoute(id: number|string) {
        return CenterRoutesPath.Details.replace(':id', String(id))
    }

    getEditRoute(id: number|string) {
        return CenterRoutesPath.Edit.replace(':id', String(id))
    }

    async selectLoadOptions(inputValue: string|(string|number)[], language: string, skip: number = 0, countryId?: number|null): Promise<SelectValue[]> {
        const filter: Record<string, any> = {
            countryId,
            skip
        };
        if (Array.isArray(inputValue)) {
            filter.id = inputValue
        } else {
            filter.name = inputValue?.trim()
        }
        const rd = {
            filter,
            language
        };
        const data = await EenApi.list<IdNameItemDto>(`${CenterService.baseUrl}/centers`, rd);

        return data.items.map((item) => ({
            value: `${item.id}`,
            label: item.name ?? '',
        }))
    }
}

export const CenterService = new CCenterService();
