import { FC } from "react";
import { ExternalProvider } from "../../../../eenApi/externalProvider/externalProvider";

interface IProps {
    provider: ExternalProvider;
}

const UnioneHooks: FC<IProps> = ({ provider }) => {
    return <div></div>;
};

export default UnioneHooks;
