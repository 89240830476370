import { ToastManager } from "./toastManager";
import {HomeRoutesPath} from "../page/home/routes";

class CErrorHandler {
    handle(name: string, error: any) {
        error = error?.response ? error.response : error;
        if (error && error.data?.status === 400) {
            if (error.data.errors) {
                console.log(name, 'validation error', error.data.errors);
                ToastManager.error({ message: 'main:validation-error-message', title: 'main:validation-error-title' });
                return null;
            }
            console.log(name, 'bad request', error);
            ToastManager.error({ message: 'main:bad-request-error-message', title: 'main:bad-request-error-title' });
            return null;
        }

        if (error && error.status === 401) {
            console.log(name, 'access error');
            ToastManager.error({ message: 'main:access-error-message', title: 'main:access-error-title' });
            return HomeRoutesPath.AccessDenied;
        }

        if (error && error.status === 404) {
            console.log(name, 'not found error');
            ToastManager.error({ message: 'main:not-found-error-message', title: 'main:not-found-error-title' });
            return HomeRoutesPath.NotFound;
        }

        if (error && error.status === 500) {
            console.error(name, error);
            ToastManager.error({ message: 'main:unhandled-exception-error-message', title: 'main:unhandled-exception-error-title'})
            return HomeRoutesPath.Home;
        }

        console.error(name, error);
    }
}

export const ErrorHandler = new CErrorHandler();