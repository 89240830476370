import { Route, Switch } from 'react-router-dom';
import DriveList from '../list';
import { FC } from 'react';
import NotFound from '../../home/notFound';
import ProtectedRoute from "../../../component/common/ProtectedRoute";
import Rules from "../../../rules";

const DriveRoutes: FC = () => {
    return (
        <Switch>
            <ProtectedRoute rule={Rules.Drive.List} exact path={DriveRoutesPath.My} component={DriveList}/>
            <ProtectedRoute rule={Rules.Drive.Public} exact path={DriveRoutesPath.Public}
                            render={props => <DriveList {...props} path="public" ruleSet={{
                              Add: Rules.Drive.PublicAdd,
                              Edit: Rules.Drive.PublicEdit,
                              Delete: Rules.Drive.PublicDelete
                            }}/>}/>
            <ProtectedRoute rule={Rules.Drive.List} exact path={DriveRoutesPath.ViewFolder} component={DriveList}/>
            <ProtectedRoute rule={Rules.Drive.Public} exact path={DriveRoutesPath.PublicFolder}
                            render={props => <DriveList {...props} path="public" ruleSet={{
                              Add: Rules.Drive.PublicAdd,
                              Edit: Rules.Drive.PublicEdit,
                              Delete: Rules.Drive.PublicDelete
                            }}/>}/>
            <Route path="*" component={NotFound} />
        </Switch>
    );
}

export default DriveRoutes;

export const DriveRoutesPath = {
    Prefix: '/drive',
    My: '/drive/my',
    Public: '/drive/public',
    ViewFolder: '/drive/folder/:id',
    PublicFolder: '/drive/public/folder/:id'
};
