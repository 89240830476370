import React from 'react';
import { IRestrictedEntity } from "../models/restricted.entity";

export interface UserContextProps {
    checkAccess: (key: string, objectIds?: string[] | null, memberCountryId?: number|string) => boolean;
    checkFullAccess: <T extends Record<string, string>>(entity: IRestrictedEntity, rules: T, ...checkRule: [Extract<keyof T, string>, ...Extract<keyof T, string>[]]) => boolean;
    checkAccessUser: <T extends Record<string, string>>(userId: string|undefined, rules: T, ...checkRule: [Extract<keyof T, string>, ...Extract<keyof T, string>[]]) => boolean;
    checkAccessCountry: <T extends Record<string, string>>(countryId: number|undefined, rules: T, ...checkRule: [Extract<keyof T, string>, ...Extract<keyof T, string>[]]) => boolean;
    checkAccessAdmin: (...keys: [string, ...string[]]) => boolean;
    checkAccessOwner: (key: string, objectId: string|number, objectType?: string) => boolean;
}

export const UserContext = React.createContext<UserContextProps>({
    checkAccess: () => false,
    checkFullAccess: () => false,
    checkAccessUser: () => false,
    checkAccessAdmin: () => false,
    checkAccessOwner: () => false,
    checkAccessCountry: () => false,
});