export enum SocketActionEnum {
    SEND_MESSAGE = 'SEND_MESSAGE',
}

export interface SendMessageAction {
    type: SocketActionEnum.SEND_MESSAGE;
    payload: string;
}

export type SocketAction = SendMessageAction;
