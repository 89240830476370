import TabPanel from '../../../tabs/tabPanel';
import React, { useState } from 'react';
import { InputControl, SelectControl } from '../../../common/ui';
import { NotEmpty } from '../../../../hooks/UseValidation/validators';
import IEditPanelProps from '../edit.panel.props';
import SupportService from '../../../../eenApi/support/service';
import SupportStatusEnum from '../../../../eenApi/support/supports/enum/support.status.enum';
import SupportCriticalityEnum from '../../../../eenApi/support/supports/enum/support.criticality.enum';
import { useQuery } from 'react-query';
import InputContainer from '../../../inputContainer';
import ViewField from '../../../fields/viewField';
import FileList from '../../../fields/fileList';
import FileUploader from '../../../fileUploader';
import { useOptions } from "../../../../hooks/select.options.hook";

export default function MainEditTab({ data, entity, updateData, validate, ...rest }: IEditPanelProps) {
    const { data: subjectsOptions } = useQuery(['subject'], SupportService.selectLoadOptions);

    const supportStatusOptions = useOptions(SupportStatusEnum, 'support:supports.fields.status.');
    const supportCriticalityOptions = useOptions(SupportCriticalityEnum, 'support:supports.fields.criticality.');

    const [files, setFile] = useState(entity?.files ?? []);
    const onUploaded = (file: { id: string; name: string }) => {
        setFile((prev) => [...prev, file]);
        updateData('filesId', [...data.filesId, file.id]);
    };
    const onRemoveFile = (fileId: string) => {
        data.filesId.splice(data.filesId.indexOf(fileId), 1);
        setFile((prev) => {
            prev.splice(prev.map((x) => x.id).indexOf(fileId), 1);
            return prev;
        });
        updateData('filesId', [...data.filesId]);
    };

    return (
        <TabPanel {...rest}>
            <InputControl
                labelKey={'support:supports.fields.title'}
                onChange={(value) => updateData('title', value)}
                value={data.title}
                required
                validators={[NotEmpty]}
                tab="main"
            />
            <SelectControl
                labelKey="support:supports.fields.supportSubject-name"
                value={data.supportSubjectId ? data.supportSubjectId.toString() : null}
                onChange={(value) => {
                    updateData('supportSubjectId', parseInt(value));
                }}
                options={subjectsOptions ?? []}
                required
                validators={[NotEmpty]}
            />
            <InputControl labelKey={'support:supports.fields.text'} onChange={(value) => updateData('text', value)} value={data.text} tab="main" multiline />
            <InputContainer>
                <ViewField title="support:supports.fields.files">
                    <FileList files={files} onRemove={onRemoveFile} />
                </ViewField>

                <FileUploader onFileUploaded={onUploaded} />
            </InputContainer>
            <SelectControl
                labelKey="support:supports.fields.status.title"
                value={data.status ? data.status : null}
                onChange={(value) => {
                    updateData('status', value);
                }}
                options={supportStatusOptions}
                required
                validators={[NotEmpty]}
            />
            <SelectControl
                labelKey="support:supports.fields.criticality.title"
                value={data.criticality ? data.criticality : null}
                onChange={(value) => {
                    updateData('criticality', value);
                }}
                options={supportCriticalityOptions}
                required
                validators={[NotEmpty]}
            />
        </TabPanel>
    );
}
