import React, { Suspense, useMemo } from 'react';
import PageTitle from '../../../component/pageTitle';
import TopActionsContainer from '../../../component/actions/topActionsContainer';
import TopActions from '../../../component/actions/topActions';
import TabControls from '../../../component/tabs/tabControls';
import BottomActionsContainer from '../../../component/actions/bottomActionsContainer';
import BottomActions from '../../../component/actions/bottomActions';
import MainContainer from '../../../component/mainContainer';
import { ProfileRoutesPath } from '../routes';
import { ActionItem } from '../../../models/actionItem';
import TabItem from '../../../component/tabs/tabItem';
import ProfileDto from '../../../eenApi/profile/dto/profile.dto';
import DetailsTab from '../../../component/profile/DetailsTab';
import ProfileService from '../../../eenApi/profile/service';
import Loader from '../../../component/loader';
import ProfileStatusEnum from '../../../eenApi/profile/enum/profile.status.enum';
import { useCurrentUser } from '../../../hooks/useCurrentUser';
import { ObjectType } from '../../../enums/objectType';
import Rules from '../../../rules';
import { useActions } from '../hooks/UseActions';
import { AccessActionEnum } from '../../../models/access.dto';
import { LoaderTab } from '../../../component/common/layout/LoaderTab';
import { DeleteModal } from '../../../component/common/ui/DeleteModal';
import { useDetailsStates } from '../../../hooks/UseDetailsStates';
import { useDeleteModal } from '../../../hooks/UseDeleteModal';

const ContactsTab = React.lazy(() => import('../../../component/profile/ContactsTab'));
const StatusTab = React.lazy(() => import('../../../component/profile/StatusTab'));

export default function ProfileDetails() {
    const { t, i18n, history, id, entity, activeTab, setActiveTab, checkAccess } = useDetailsStates<ProfileDto>({ queryKey: 'profile' });

    const { isDeleteOpen, closeDelete, remove, deleteButton } = useDeleteModal({
        queryKey: 'profile',
        handler: (id) => ProfileService.remove(id),
        successPath: ProfileRoutesPath.My,
    });
    const currentUser = useCurrentUser();
    const commonActions = useActions(entity);

    const actions = useMemo(() => {
        let items: ActionItem[] = [];
        if (entity?.accessDto[AccessActionEnum.Update]) {
            items.push({
                name: t('main:btn-edit'),
                link: `${ProfileRoutesPath.Edit.replace(':id', id)}`,
                icon: 'fas fa-pen',
            });
        }

        items = [...items, ...commonActions];

        if (entity?.accessDto[AccessActionEnum.Delete]) {
            items.push(deleteButton);
        }

        if (
            entity?.status === ProfileStatusEnum.Active &&
            checkAccess(Rules.Profile.InterestAdd) &&
            currentUser.data?.rules?.find((r) => r.objectType === ObjectType.Client)
        ) {
            items.push({
                name: t('main:btn-interest'),
                onClick: () => history.push(`${ProfileRoutesPath.InterestCreate}?profileId=${entity.id}`),
            });
        }

        return items;
    }, [entity, i18n.language]);

    const tabItems = useMemo(() => {
        const items: TabItem[] = [];
        if (checkAccess(Rules.Profile.ViewBasic) || checkAccess(Rules.Profile.ViewBasicOwn, [String(entity?.client?.center?.id)])) {
            items.push(new TabItem('main', t('profile:tabs.main')));
        }
        if (checkAccess(Rules.Profile.ViewContact) || checkAccess(Rules.Profile.ViewContactOwn, [String(entity?.client?.center?.id)])) {
            items.push(new TabItem('contacts', t('profile:tabs.contacts')));
        }
        if (
            entity?.statusHistory?.length &&
            (checkAccess(Rules.Profile.ViewValidation) || checkAccess(Rules.Profile.ViewValidationOwn, [String(entity?.client?.center?.id)]))
        ) {
            items.push(new TabItem('status', t('profile:tabs.validation')));
        }
        return items;
    }, [entity?.client?.center?.id, i18n.language]);

    return (
        <>
            <MainContainer>
                <PageTitle text={t('profile:details.title')} backLink={ProfileRoutesPath.List} />
                <TopActionsContainer>
                    <TopActions actions={actions} />
                </TopActionsContainer>
                <TabControls selectedId={activeTab} items={tabItems} onChange={setActiveTab} />
                <LoaderTab activeId={activeTab} />
                {entity?.id && (
                    <>
                        <DetailsTab activeId={activeTab} id="main" entity={entity} />
                        <Suspense fallback={<Loader />}>
                            <ContactsTab activeId={activeTab} id="contacts" entity={entity?.client} />
                            <StatusTab activeId={activeTab} id="status" entity={entity} />
                        </Suspense>
                    </>
                )}
                <BottomActionsContainer>
                    <BottomActions actions={actions} />
                </BottomActionsContainer>
                <DeleteModal isDeleteOpen={isDeleteOpen} close={closeDelete} remove={remove} />
            </MainContainer>
        </>
    );
}
