import React, { useContext, useMemo } from 'react';
import { Link } from 'react-router-dom';
import MailingDto from '../../../../eenApi/mailing/dto/mailing.dto';
import { UserContext } from '../../../../context/user-context';
import Rules from '../../../../rules';
import { MailingService } from '../../../../eenApi/mailing/service';
import { MailingRoutesPath } from '../../routes';
import useTranslation from '../../../../hooks/translation.hook';
import { formatDate } from '../../../../utils/date.utils';
import MailingTypeEnum from '../../../../eenApi/mailing/enum/mailing.type.enum';
import { ClientRoutesPath } from '../../../client/routes';
import { TableColumn } from '../../../../component/common/layout/DataTable/table.model';
import { DataTable } from '../../../../component/common/layout';
import { EntityQueryKey } from '../../../../query/query.keys';
import { useGroupDeleteAction, useIdColumn, useRowActions } from '../../../../component/common/layout/DataTable/table.utils';
import { GridSelectionModel } from '@mui/x-data-grid';

interface IProps {
    filterValues?: { [key: string]: any };
}

export function MailingTable(props: IProps) {
    const { checkAccess } = useContext(UserContext);
    const { t, i18n } = useTranslation();

    const idColumn = useIdColumn<MailingDto>();
    const columns = useMemo<TableColumn<MailingDto>[]>(
        () => [
            idColumn,
            new TableColumn({
                headerName: t('mailing:mailings.fields.subject'),
                field: 'subject',
                renderCell: ({ id, value }) =>
                    checkAccess(Rules.Mailing.Edit) ? <Link to={`${MailingRoutesPath.Edit.replace(':id', id.toString())}`}>{value}</Link> : value,
            }),
            new TableColumn({
                headerName: t('mailing:mailings.fields.created'),
                field: 'created',
                valueFormatter: ({ value }) => formatDate(value),
            }),
            new TableColumn({
                headerName: t('mailing:mailings.fields.status.title'),
                field: 'status',
                valueFormatter: ({ value }) => t(`mailing:mailings.fields.status.${value}`),
            }),
            new TableColumn({
                headerName: t('mailing:mailings.fields.type.title'),
                field: 'type',
                valueFormatter: ({ value }) => t(`mailing:mailings.fields.type.${value}`),
            }),
            new TableColumn({
                headerName: t('mailing:mailings.fields.periodicity.title'),
                field: 'periodicity',
                valueGetter: ({ row }) => (row.type === MailingTypeEnum.Cyclical ? t(`mailing:mailings.fields.periodicity.${row.periodicity}`) : ''),
            }),
            new TableColumn({
                headerName: t('mailing:mailings.fields.nextDate'),
                field: 'nextDate',
                valueFormatter: ({ value }) => formatDate(value),
            }),
            new TableColumn({
                headerName: t('mailing:mailings.fields.center'),
                field: 'center',
                renderCell: ({ value }) =>
                    value?.id ? <Link to={`${ClientRoutesPath.View.replace(':id', value.id.toString() ?? '')}`}>{value.name}</Link> : value.name,
            }),
        ],
        [i18n.language],
    );

    const clickDelete = (ids: GridSelectionModel) => MailingService.remove(ids.map((x) => Number(x)));

    const rowActions = useRowActions<MailingDto>({
        edit: checkAccess(Rules.Mailing.Edit) ? (row) => MailingRoutesPath.Edit.replace(':id', String(row.id)) : null,
        remove: checkAccess(Rules.Mailing.Delete) ? (row) => clickDelete([row.id!]) : null,
    });
    const groupActions = useGroupDeleteAction({ remove: clickDelete });

    return (
        <DataTable
            id="mailing-table-component"
            queryKey={EntityQueryKey.Mailing}
            filterValues={props.filterValues}
            columns={columns}
            fetchData={(requestData) => MailingService.list(requestData)}
            rowActions={rowActions}
            groupActions={groupActions}
        />
    );
}
