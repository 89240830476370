
export interface LayoutState {
    pageTitle: string,
    backLink?: string,
}

export enum LayoutActionEnum {
    SET_TITLE = "SET_TITLE",
    SET_BACKLINK = "SET_BACKLINK"
}

export interface SetTitleAction {
    type: LayoutActionEnum.SET_TITLE,
    payload: string
}

export interface SetBacklinkAction {
    type: LayoutActionEnum.SET_BACKLINK,
    payload?: string
}

export type LayoutAction =
    SetTitleAction |
    SetBacklinkAction;
