import ModalHeader from "../../../modal/modalHeader";
import BaseModal from "../../../modal/baseModal";
import React from "react";
import {DictionaryType} from "../../../../models/dictionaryType";
import LanguageEnum from "../../../../enums/language.enum";
import ModalBody from "./modal.body";
import {SelectValue} from "../../../select/SelectValue";

interface IProps {
   dictionary: DictionaryType;
   language: LanguageEnum;
   header: string,
   isOpen: boolean;
   onClose: () => void;
   value: SelectValue[]|null;
   onSave: (checked: SelectValue[]|null) => void;
}

export default function Modal({ dictionary, language, header, isOpen, onClose, value, onSave }: IProps) {

   return (
      <BaseModal isOpen={isOpen} onRequestClose={onClose} scrollable>
         <ModalHeader title={header} onRequestClose={onClose} />
         <ModalBody dictionary={dictionary} language={language} isOpen={isOpen} value={value} onSave={onSave}/>
      </BaseModal>
   )
}