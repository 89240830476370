import { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { MenuItem } from '../../../eenApi/menu/menuItem';
import BottomMenuModal from './bottomMenuModal';
import './style.css';

interface IProps {
    menu: MenuItem[]
}

export default function BottomMenu(props: IProps) {
    const [activeButton, setActiveButton] = useState('menu');
    const history = useHistory();
    const location = useLocation<any>();
    const buttons = [
        {
            id: 'menu', onClick: () => {
                history.push(`${location.pathname}#menu`);
            }, className: 'main', Component: <i className="fas fa-bars"></i>
        },
        { id: 'bookmark', onClick: () => { }, className: '', Component: <i className="fas fa-bookmark"></i> },
        { id: 'users', onClick: () => { }, className: '', Component: <i className="fas fa-user-friends"></i> },
        { id: 'search', onClick: () => { }, className: '', Component: <i className="fas fa-search"></i> },
        { id: 'messages', onClick: () => { history.push('/chat'); }, className: '', Component: <i className="fas fa-envelope"></i> },
        { id: 'help', onClick: () => { }, className: '', Component: <i className="fas fa-question-circle"></i> },
    ];

    return (
        <>
            <div className="d-block d-lg-none fixed-bottom">
                <div className="d-flex">
                    {buttons.map(button => (
                        <button key={button.id} className={`btn-bottom-menu flex-fill ${button.className} ${activeButton === button.id ? 'active' : ''}`} onClick={() => {
                            setActiveButton(button.id)
                            if (button.onClick) {
                                button.onClick();
                            }
                        }}>
                            {button.Component}
                        </button>
                    ))}
                </div>
            </div>
            {location.hash === '#menu' &&
                <BottomMenuModal menu={props.menu} />
            }
        </>
    )
}
