import MainContainer from '../../component/mainContainer';
import PageTitle from '../../component/pageTitle';

export default function AccessDenied() {
    return (
        <MainContainer>
            <PageTitle text={'accessDenied page'} backLink="/" />
            <div>accessDenied page</div>
        </MainContainer>
    );
}