import { Route, Switch } from 'react-router-dom';
import { useContext } from 'react';
import { UserContext } from '../../../context/user-context';
import Chat from '../chat';
import NotFound from '../../home/notFound';

export default function ChatRoutes() {
    const context = useContext(UserContext);
    return (
        <Switch>
            <Route path="/chat" component={Chat} />
            <Route path="*" component={NotFound} />
        </Switch>
    );
}

export const ChatRoutesPath = {
    Prefix: '/chat'
};
