import { InnovationProjectDto } from './dto/innovation.project.dto';
import { CrudService } from '../common/service/crud.service';
import { SelectValue } from "../../component/select/SelectValue";
import { InnovationProjectRoutesPath } from "../../page/innovationproject/routes";


class InnovationProjectServiceImpl extends CrudService<InnovationProjectDto>{

    constructor() {
        super('innovationproject');
    }

    async selectLoadOptions(inputValue: string|(string|number)[], language: string, skip: number = 0, countryId?: number|null): Promise<SelectValue[]> {
        const filter: Record<string, any> = {
            countryId,
            skip
        };
        if (Array.isArray(inputValue)) {
            filter.id = inputValue
        } else {
            filter.name = inputValue?.trim()
        }
        const rd = {
            filter,
            language
        };
        const data = await InnovationProjectService.list(rd);
        return data.items.map((item) => ({
            value: `${item.id}`,
            label: item.name ?? '',
        }))
    }

    getDetailsRoute(id: number|string) {
        return InnovationProjectRoutesPath.View.replace(':id', String(id))
    }

    getEditRoute(id: number|string) {
        return InnovationProjectRoutesPath.Edit.replace(':id', String(id))
    }
}
export const InnovationProjectService = new InnovationProjectServiceImpl();