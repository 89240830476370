import { MDBBtn } from 'mdb-react-ui-kit';
import React, { FC, useEffect, useState } from 'react';
import useTranslation from '../../../../hooks/translation.hook';
import BaseModal from '../../../../component/modal/baseModal';
import ModalHeader from '../../../../component/modal/modalHeader';
import { DriveEntity } from '../../../../eenApi/drive/driveEntity';
import { DriveService } from '../../../../eenApi/drive/service';
import { ErrorHandler } from '../../../../service/errorHandler';
import { InputControl } from "../../../../component/common/ui";

interface IProps {
    entity?: DriveEntity;
    onClose: () => void;
    onSave: () => void;
    path: string;
}

const RenameEntityModal: FC<IProps> = ({ entity, onClose, onSave, path }) => {
    const { t } = useTranslation();
    const [name, setName] = useState('');
    const isModalOpen = !!entity;

    useEffect(() => {
        setName(entity?.name ?? '');
    }, [entity]);

    const onModalClose = () => {
        setName('');
        onClose();
    };

    const onSubmit = async (e: React.FormEvent) => {
        try {
            if (!entity) return;
            e.preventDefault();
            await DriveService.rename(path, { id: entity.id, name: name });
            onSave();
        } catch (err) {
            ErrorHandler.handle('driveentity rename', err);
        }
    };

    return (
        <BaseModal centered isOpen={isModalOpen} onRequestClose={onModalClose} modalSize="md">
            <ModalHeader title={t('drive:rename-folder-modal-title')} onClickBack={onModalClose} onRequestClose={onModalClose} />
            <div className="modal-body py-2">
                <form onSubmit={onSubmit}>
                    <InputControl labelKey="main:field-name" value={name} onChange={(value) => setName(value)} required inModal/>
                    <div>
                        <MDBBtn type="submit">{t('main:btn-save')}</MDBBtn>
                    </div>
                </form>
            </div>
        </BaseModal>
    );
};

export default RenameEntityModal;
