import axios, { AxiosRequestConfig } from 'axios';
import authService from '../component/auth-api/AuthorizeService';
import { DictionaryEntity } from '../models/dictionaryEntity';
import { AccountService } from './account/service';
import { ListRequestData } from './common/listRequestData';
import { User } from './user/user';
import { UserRoleData } from './user/userRoleData';
import LanguageEnum from '../enums/language.enum';

class CEenApi {
    prefix: string = 'api';

    async getCurrentUser(renew: boolean = true): Promise<{ user: User | null; rules: UserRoleData[]; language: LanguageEnum }> {
        const user = await authService.getUser();
        if (user) {
            const result = await this.get<{ user: User | null; rules: UserRoleData[]; language: DictionaryEntity }>('account/data');
            if (result) {
                const userValues = Object.assign(user, result?.user);
                //AccountService.saveLanguageInStorage(result?.language.code);
                return {
                    user: userValues as User,
                    rules: result?.rules,
                    language: AccountService.getLang(result?.language.code) as LanguageEnum,
                };
            }
        }
        return {
            user: null, //user,
            rules: [],
            language: AccountService.getLang() as LanguageEnum,
        };
    }

    private prepareLink(link: string) {
        return `/${this.prefix}/${link}`;
    }

    async getAccessToken() {
        try {
            const token = await authService.getAccessToken();
            return token;
        } catch (err) {
            console.warn('getAccessToken', err);
            return null;
        }
    }

    async get<T>(link: string, responseType?: 'blob') {
        const token = await this.getAccessToken();
        const response = await axios.get(this.prepareLink(link), {
            headers: !token ? {} : { Authorization: `Bearer ${token}` },
            responseType
        });
        return response.data as T;
    }

    /**
     * @deprecated
     */
    async getRequest(link: string) {
        const token = await this.getAccessToken();
        return axios.get(this.prepareLink(link), {
            headers: !token ? {} : { Authorization: `Bearer ${token}` },
        });
    }

    async put<T>(link: string, data: any = {}) {
        const token = await this.getAccessToken();
        const response = await axios.put(this.prepareLink(link), data, {
            headers: !token ? {} : { Authorization: `Bearer ${token}` },
        });
        return response.data as T;
    }

    /**
     * @deprecated
     */
    async putRequest(link: string, data: any = {}) {
        const token = await this.getAccessToken();
        return axios.put(this.prepareLink(link), data, {
            headers: !token ? {} : { Authorization: `Bearer ${token}` },
        });
    }

    async post<T>(link: string, data: any = {}, config: AxiosRequestConfig = {}) {
        const token = await authService.getAccessToken();
        if (!config.headers) {
            config.headers = {};
        }
        if (!!token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        const requestResult = await axios.post(this.prepareLink(link), data, config);
        return requestResult.data as T;
    }

    /**
     * @deprecated
     */
    async postRequest(link: string, data: any = {}) {
        const token = await authService.getAccessToken();
        return axios.post(this.prepareLink(link), data, {
            headers: !token ? {} : { Authorization: `Bearer ${token}` },
        });
    }

    async delete(link: string, data: any = {}) {
        const token = await this.getAccessToken();
        return axios.delete(this.prepareLink(link), {
            data: data,
            headers: !token ? {} : { Authorization: `Bearer ${token}` },
        });
    }

    /**
     * Post request for item list
     * @param link method
     * @param data request data
     * @returns list result
     */
    async list<T>(method: string, data: ListRequestData = {}) {
        const token = await this.getAccessToken();
        const response = await axios.post(this.prepareLink(method), data, {
            headers: !token ? {} : { Authorization: `Bearer ${token}` },
        });

        const result: { items: T[]; total: number } = response.data;
        return result;
    }

    async commentList<T>(method: string, data: ListRequestData = {}) {
        const token = await this.getAccessToken();
        const response = await axios.post(this.prepareLink(method), data, {
            headers: !token ? {} : { Authorization: `Bearer ${token}` },
        });

        const result: T[] = response.data;
        return result;
    }
}

export const EenApi = new CEenApi();
