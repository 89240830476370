import { FC } from "react";
import { ActionItem } from "../../models/actionItem";
import ActionList from "./actionList";

interface IProps {
    actions: ActionItem[]
}

const TopActions: FC<IProps> = (props) => {
    return (
        <ActionList top actions={props.actions} />
    );
}

export default TopActions;
