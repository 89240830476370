import { FC, useContext, useState } from "react";
import useTranslation from "../../../../hooks/translation.hook";
import UserRoleTable from "./table";
import { UserContext } from "../../../../context/user-context";
import Rules from "../../../../rules";
import BaseModal from "../../../../component/modal/baseModal";
import ModalHeader from "../../../../component/modal/modalHeader";
import UserRoleAddForm from "../userRoleAddForm";

interface IProps {
    userId: number;
}

const UserList: FC<IProps> = ({ userId }) => {
    const context = useContext(UserContext);
    const { t } = useTranslation();
    const [filterValues, setFilterValues] = useState<{ [key: string]: any }>({});
    const [isModalOpen, setIsModalOpen] = useState(false);

    const onModalClose = () => {
        setIsModalOpen(false);
    };

    const onDataSave = () => {
        setIsModalOpen(false);
        setFilterValues({});
    }

    return (
        <>
            <div className="d-xl-flex mb-1 mb-xl-3 mt-3">
                <div className="app-filter-actions mb-2 mb-xl-0">
                    {context.checkAccess(Rules.User.AddRole) && (
                        <button className="btn btn-success me-2" onClick={() => setIsModalOpen(true)}>
                            {t("main:btn-add")}
                        </button>
                    )}
                </div>
            </div>
            <UserRoleTable filterValues={filterValues} userId={userId} showActions />
            <BaseModal isOpen={isModalOpen} onRequestClose={onModalClose} modalSize="md">
                <ModalHeader title={t("user:add-role-modal-title")} onClickBack={onModalClose} onRequestClose={onModalClose} />
                <div className="modal-body py-2">
                    <UserRoleAddForm userId={userId} afterSave={onDataSave} />
                </div>
            </BaseModal>
        </>
    );
};

export default UserList;
