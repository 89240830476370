import { FC, useMemo } from 'react';
import { UserSubscription } from '../../../../eenApi/notification/userSubscription';
import { NotificationService } from '../../../../eenApi/notification/service';
import NotificationSubscriptionState from '../notificationSubscriptionState';
import { TableColumn } from '../../../../component/common/layout/DataTable/table.model';
import useTranslation from '../../../../hooks/translation.hook';
import { DataTable } from '../../../../component/common/layout';
import { EntityQueryKey } from '../../../../query/query.keys';

interface IProps {
    userId: number;
    filterValues?: { [key: string]: any };
}

const UserSubcriptionTable: FC<IProps> = (props) => {
    const { t, i18n } = useTranslation();
    const columns = useMemo<TableColumn<UserSubscription>[]>(
        () => [
            new TableColumn({
                headerName: t('main:field-name'),
                hideable: false,
                field: 'name',
            }),
            new TableColumn({
                headerName: t('notification:channel-email'),
                hideable: false,
                field: 'email',
                renderCell: ({ row }) => (
                    <NotificationSubscriptionState
                        userId={props.userId}
                        channel={'email'}
                        channelAvailable={row.isEmail}
                        isSubscribed={row.isSubscribedEmail}
                        notificationId={row.id}
                    />
                ),
            }),
            new TableColumn({
                headerName: t('notification:channel-feed'),
                hideable: false,
                field: 'feed',
                renderCell: ({ row }) => (
                    <NotificationSubscriptionState
                        userId={props.userId}
                        channel={'feed'}
                        channelAvailable={row.isFeed}
                        isSubscribed={row.isSubscribedFeed}
                        notificationId={row.id}
                    />
                ),
            }),
            new TableColumn({
                headerName: t('notification:channel-push'),
                hideable: true,
                field: 'push',
                renderCell: ({ row }) => (
                    <NotificationSubscriptionState
                        userId={props.userId}
                        channel={'push'}
                        channelAvailable={row.isPush}
                        isSubscribed={row.isSubscribedPush}
                        notificationId={row.id}
                    />
                ),
            }),
        ],
        [i18n.language],
    );

    return (
        <DataTable
            id="user-notification-subscriptions-table-component"
            queryKey={[EntityQueryKey.Notification, props.userId]}
            filterValues={props.filterValues}
            columns={columns}
            fetchData={(requestData) => NotificationService.subscriptions({ ...requestData, userId: props.userId })}
        />
    );
};

export default UserSubcriptionTable;
