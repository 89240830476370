import { FC } from 'react';

interface IProps {
    className?: string;
    children?: React.ReactNode;
}

const BottomActionsContainer: FC<IProps> = (props) => {
    return <main className={props.className ?? 'actions-bottom mt-3 d-sm-block'}>{props.children}</main>;
};

export default BottomActionsContainer;
