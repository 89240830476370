import ModelValidator from "../model.validator";
import { CenterDto } from '../../../eenApi/center/dto/center.dto';

class EditValidator extends ModelValidator<CenterDto, never> {

   validate(dto: CenterDto) {
      const erroredTabs: string[] = [];

      this.checkRequired(dto.type, erroredTabs, 'main');
      this.checkRequired(dto.memberCountryId, erroredTabs, 'main');
      this.checkRequired(dto.acronym, erroredTabs, 'main');
      this.checkLength(dto.acronym ?? '', erroredTabs, 255, 'main');
      this.checkRequired(dto.name, erroredTabs, 'main');
      this.checkLength(dto.name ?? '', erroredTabs, 500, 'main');
      this.checkRequired(dto.fullName, erroredTabs, 'main');
      this.checkLength(dto.fullName ?? '', erroredTabs, 1000, 'main');
      this.checkRequired(dto.postAddress, erroredTabs, 'main');
      this.checkLength(dto.postAddress ?? '', erroredTabs, 1000, 'main');
      this.checkRequired(dto.actualAddress, erroredTabs, 'main');
      this.checkLength(dto.actualAddress ?? '', erroredTabs, 1000, 'main');
      this.checkRequired(dto.city, erroredTabs, 'main');
      this.checkLength(dto.city ?? '', erroredTabs, 255, 'main');
      this.checkRequired(dto.directorName, erroredTabs, 'main');
      this.checkLength(dto.directorName ?? '', erroredTabs, 500, 'main');
      this.checkLength(dto.web ?? '', erroredTabs, 255, 'main');
      this.checkLength(dto.phone ?? '', erroredTabs, 255, 'main');
      this.checkLength(dto.email ?? '', erroredTabs, 255, 'main');
      this.checkLength(dto.description ?? '', erroredTabs, 10000, 'main');

      return erroredTabs;
   }
}
export const CenterEditValidator = new EditValidator()