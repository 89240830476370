import { TableColumn, TableRowAction } from "./table.model";
import { MDBDropdown, MDBDropdownItem, MDBDropdownLink, MDBDropdownMenu, MDBDropdownToggle } from "mdb-react-ui-kit";
import React from "react";
import { GridRenderCellParams } from "@mui/x-data-grid";
import useTranslation from "../../../../hooks/translation.hook";
import { QueryKey, useQueryClient } from "react-query";
import { resetQueryFn } from "../../../../query/query.utils";
import { ErrorHandler } from "../../../../service/errorHandler";

interface IProps<T> {
  queryKey: QueryKey,
  params: GridRenderCellParams<T>,
  rowActions: TableRowAction<T>[]
}

function TableRowActionsCell<T>({ queryKey, params, rowActions }: IProps<T>) {
  const queryClient = useQueryClient();

  const doDropdownAction = async (action: TableRowAction<T>, item: T) => {
    try {
      await action.action(item);
      if (action.refreshTableAfterAction ?? true) {
        await queryClient.resetQueries({ predicate: query => resetQueryFn(query, ...new Array<QueryKey>().concat(queryKey))})
      }
    } catch (e) {
      ErrorHandler.handle(action.id, e);
    }
  };

  return (<>
    {rowActions.some(x => !x.isHide || !x.isHide(params.row)) && <MDBDropdown>
      <MDBDropdownToggle className='shadow-0 px-3' color='white'>
        <i className='fas fa-bars'/>
      </MDBDropdownToggle>
      <MDBDropdownMenu>
        {rowActions.filter(x => !x.isHide || !x.isHide(params.row)).map((action, idx) => (
          <MDBDropdownItem key={idx}>
            <MDBDropdownLink tag='button'
                             onClick={() => doDropdownAction(action, params.row)}>{action.label}</MDBDropdownLink>
          </MDBDropdownItem>
        ))}
      </MDBDropdownMenu>
    </MDBDropdown>}
  </>)
}

export function useTableRowActionsColumn<T>(queryKey: QueryKey, rowActions: TableRowAction<T>[]) {
  const { t } = useTranslation();
  return new TableColumn<T>({
    field: '__action__',
    headerName: t('main:table.rowActions'),
    hideable: false,
    width: 50,
    cellClassName: 'px-0',
    renderCell: params => <TableRowActionsCell queryKey={queryKey} params={params} rowActions={rowActions}/>,
    renderHeader: () => <></>
  })
}