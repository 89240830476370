import { FC } from 'react';

interface IProps {
    className?: string;
    inModal?: boolean;
    children?: React.ReactNode;
}

const InputContainer: FC<IProps> = (props) => {
    return (
        <div className="input-control row mb-4">
            <div className={`${props.inModal ? 'col-12' : 'col-md-9 col-lg-9 col-xl-7 col-xxl-5'} ${props.className ? props.className : ''}`}>
                {props.children}
            </div>
        </div>
    );
};

export default InputContainer;
