import profile from './profile.rules';
import support from './support.rules';
import news from './news.rules';
import role from './role.rules';
import specialist from './specialist.rules';
import client from './client.rules';
import memberCountry from './member.country.rules';
import mailing from './mailing.rules';
import innovationProject from './innovation.project.rules';
import center from './center.rules';
import user from './user.rules';
import configuration from './configuration.rules';
import drive from './drive.rules'
import notification from './notification.rules'
import menu from './menu.rules'
import externalProvider from './external.provider.rules'
import log from './log.rules'

const Rules = {
    Profile: { ...profile },
    News: { ...news },
    Support: { ...support },
    Mailing: { ...mailing },
    Role: { ...role },
    Specialist: { ...specialist },
    Client: { ...client },
    InnovationProject: { ...innovationProject },
    MemberCountry: { ...memberCountry },
    Center: { ...center },
    User: { ...user },
    Configuration: { ...configuration },
    Drive: { ...drive },
    Notification: { ...notification },
    Menu: { ...menu },
    ExternalProvider: { ...externalProvider },
    Log: { ...log },
    Public: 'public'
};

export default Rules;
