import { SelectValue } from '../../component/select/SelectValue';
import { MemberCountry } from './dto/member.country';
import { CrudService } from '../common/service/crud.service';
import { EenApi } from "../index";
import { MemberCountryRoutesPath } from "../../page/membercountry/routes";

class MemberCountryServiceImpl extends CrudService<MemberCountry>{

    constructor() {
        super('membercountry');
    }

    async selectLoadOptions(inputValue: string, language?: string): Promise<SelectValue[]> {
        const arItems: SelectValue[] = [];
        const rd = {
            filter: {
                name: inputValue,
            },
            language
        };
        const data = await MemberCountryService.list(rd);
        data.items.forEach(item => {
            arItems.push({
                value: `${item.id}` ?? "",
                label: item.name ?? "",
            });
        });
        return arItems;
    }

    save(data: MemberCountry, fullEdit: boolean): Promise<any> {
        return fullEdit
          ? super.save(data)
          : EenApi.put<any>(`${this.baseUrl}/edit-basic`, {
              id: data.id,
              language: data.language,
              description: data.description
          })
    }

    getDetailsRoute(id: number|string) {
        return MemberCountryRoutesPath.View.replace(':id', String(id))
    }

    getEditRoute(id: number|string) {
        return MemberCountryRoutesPath.Edit.replace(':id', String(id))
    }
}
export const MemberCountryService = new MemberCountryServiceImpl();