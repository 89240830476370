import { FC, useState } from "react";
import UserSubcriptionTable from "./table";

interface IProps {
    userId: number;
}

const NotificationSubscriptions: FC<IProps> = ({ userId }) => {
    const [filterValues] = useState<{ [key: string]: any }>({});

    return (
        <>
            <div className="d-xl-flex mb-1 mb-xl-3 mt-3">
                <div className="app-filter-actions mb-2 mb-xl-0"></div>
            </div>
            <UserSubcriptionTable filterValues={filterValues} userId={userId} />
        </>
    );
};

export default NotificationSubscriptions;
