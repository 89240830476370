export enum AccessActionEnum {
  Create = "add",
  Update = "edit",
  Delete = "delete",
  View = "view",
  Validate = "validate",
  Status = "status",
}

export class AccessDto {
  accessDto: Partial<Record<AccessActionEnum, boolean>> = {};
}