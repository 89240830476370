import ProfileEditDto from "../../../eenApi/profile/dto/profile.edit.dto";
import ModelValidator from "../model.validator";
import ProfileTypeEnum from "../../../eenApi/profile/enum/profile.type.enum";
import ProfileDto from "../../../eenApi/profile/dto/profile.dto";

class EditValidator extends ModelValidator<ProfileEditDto, ProfileDto> {

   validate(dto: ProfileEditDto, tabs: string[], entity: ProfileDto) {
      const erroredTabs: string[] = [];

      this.checkRequired(dto.title, erroredTabs, 'main');
      this.checkLength(dto.title, erroredTabs, 255, 'main');
      this.checkLength(dto.podNumber, erroredTabs, 255, 'main');
      this.checkRequired(dto.annotation, erroredTabs, 'main');
      this.checkLength(dto.annotation, erroredTabs, 500, 'main');
      this.checkRequired(dto.description, erroredTabs, 'main');
      this.checkLength(dto.description, erroredTabs, 4000, 'main', 100);
      this.checkRequired(dto.stage, erroredTabs, 'main');
      this.checkLength(dto.stageComment, erroredTabs, 4000, 'main');
      this.checkRequired(dto.iprStatuses, erroredTabs, 'main');
      this.checkLength(dto.iprStatusComment, erroredTabs, 4000, 'main');
      this.checkLength(dto.comment, erroredTabs, 255, 'main');
      if (entity.type !== ProfileTypeEnum.ResearchRequest) {
         this.checkRequired(dto.advantages, erroredTabs, 'main');
         this.checkRequired(dto.keyWords, erroredTabs, 'main');
      }
      if (entity.type === ProfileTypeEnum.ResearchRequest) {
         this.checkRequired(dto.innovationProjectId, erroredTabs, 'main');
         this.checkLength(dto.frameworkProgramme, erroredTabs, 255, 'main');
      }
      this.checkLength(dto.advantages, erroredTabs, 4000, 'main',50);
      this.checkRequired(dto.partnerRole, erroredTabs, 'main');
      this.checkLength(dto.partnerRole, erroredTabs, 300, 'main');
      this.checkRequired(dto.partnershipTypes, erroredTabs, 'main');

      return erroredTabs;
   }
}
export default new EditValidator()