import TabPanel, { ITabPanelProps } from '../../../tabs/tabPanel';
import { Field } from '../index';
import React, { ReactNode } from "react";

interface ICustomField<T> {
    render: (entity: T) => ReactNode
}

interface ICommonField<T> {
    name: string,
    value?: (entity: T) => string|string[]|undefined,
    format?: (value: any) => string,
    show?: boolean,
    render?: false
    children?: ReactNode
}

type IField<T> = ICustomField<T>|ICommonField<T>

interface IProps<T> extends ITabPanelProps {
    namespace: string
    prefix?: string
    entity: T,
    fields: (Extract<keyof T, string>|IField<T>)[];
}

export function InfoTab<T extends Record<string, any>>({ namespace, prefix, entity, fields, ...rest }: IProps<T>) {
    return (
        <TabPanel {...rest}>
            {fields.map((field, idx) => {
                if (typeof field === 'object' && field.render) {
                    return field.render(entity)
                }

                let value;
                let label;
                let children = undefined;
                let optional = true;
                if (typeof field === 'string') {
                    label = field;
                    value = entity[field];
                } else {
                    label = field.name;
                    if (field.value) {
                        value = field.value(entity)
                    } else {
                        value = entity[field.name]
                    }

                    if (field.format) {
                        value = field.format(value)
                    }

                    if (field.children) {
                        value = field.children
                    }

                    if (field.show) {
                        optional = false
                    }
                }

                return (
                    <Field key={idx} labelKey={`${namespace}:${prefix ?? 'field'}.${label}`} value={value} optional={optional}>
                        {children}
                    </Field>
                );
            })}
        </TabPanel>
    );
}