import IdNameItemDto from '../../common/id.name.item.dto';
import DriveFileDto from '../../drive/dto/drive.file.dto';
import { CenterTypeEnum } from '../enum/center.type.enum';
import { UserRoleData } from "../../user/userRoleData";
import { SelectItemDto } from "../../common/select.item.dto";

export class CenterDto {
    id?: number;
    memberCountryId: number|null;
    memberCountry?: SelectItemDto;
    active = true;
    alias = '';
    acronym = '';
    email = '';
    phone = '';
    web = '';
    communicationLink = '';
    name = '';
    fullName = '';
    directorName = '';
    actualAddress = '';
    postAddress = '';
    description = '';
    city = '';
    regionCode = '';
    region?: IdNameItemDto;
    federalDistrictCode = '';
    federalDistrict?: IdNameItemDto;
    type: CenterTypeEnum|string = '';
    logoFileId: string|null;
    logoFile?: DriveFileDto;
    language: string;

    constructor(entity: CenterDto|undefined, language: string, userRules: UserRoleData[]|undefined) {
        Object.entries(entity ?? {}).forEach(([key, val]) => {
            if (this.hasOwnProperty(key)) {
                this[key as keyof CenterDto] = val as never
            }
        });

        const userCountry = userRules?.find(x => !!x.memberCountryId)?.memberCountryId;
        if (!entity && userCountry !== undefined) {
            this.memberCountryId = userCountry;
        } else {
            this.memberCountryId = entity?.memberCountry?.id ? Number(entity.memberCountry.id) : null;
        }
        this.logoFileId = entity?.logoFile?.id ?? null;
        this.regionCode = entity?.region?.id.toString() ?? '';
        this.federalDistrictCode = entity?.federalDistrict?.id.toString() ?? '';
        this.language = language;
    }
}