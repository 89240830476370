export enum NewsStatusType {
    Draft = "Draft",
    Validation = "Validation",
    Active = "Active",
    Remarks = "Remarks",
}

export enum NewsStatusCreateType {
    Draft = "Draft",
    Validation = "Validation",
}