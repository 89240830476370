import { useEffect } from 'react';
import Sortable from 'sortablejs';

const SortableContainer: React.FunctionComponent<{ parentId: number; onEnd: (e: any) => void; group: string; children?: React.ReactNode }> = (props) => {
    useEffect(() => {
        var el = document.getElementById(`sortable-${props.parentId}`);
        if (el) {
            Sortable.create(el, {
                group: props.group,
                delay: 100,
                delayOnTouchOnly: true,
                direction: 'vertical',
                onEnd: (evt) => {
                    props.onEnd(evt);
                },
            });
        }
    }, []);
    return (
        <div id={`sortable-${props.parentId}`} data-parent={props.parentId}>
            {props.children}
        </div>
    );
};

export default SortableContainer;
