import { FC, useEffect, useRef, useState } from 'react';
import useTranslation from '../../../../hooks/translation.hook';
import { MDBBtn } from 'mdb-react-ui-kit';
import Loader from '../../../../component/loader';
import BaseModal from '../../../../component/modal/baseModal';
import ModalHeader from '../../../../component/modal/modalHeader';
import { DriveEntity } from '../../../../eenApi/drive/driveEntity';
import { DriveService } from '../../../../eenApi/drive/service';
import { ObjectType } from '../../../../enums/objectType';
import { ErrorHandler } from '../../../../service/errorHandler';
import './style.css';

interface IProps {
    entity?: DriveEntity;
    onClose: () => void;
    onSave: () => void;
    path: string;
}

/**
 * Показывает список папок для перемещения сущности
 * TODO добавить список с прокруткой
 * @param param0
 * @returns
 */
const MoveEntityModal: FC<IProps> = ({ entity, onClose, onSave, path }) => {
    const { t } = useTranslation();
    const take = useRef(5);
    const [isLoading, setIsLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [parentId, setParentId] = useState<string>();
    const [items, setItems] = useState<DriveEntity[]>([]);
    const [skip, setSkip] = useState(0);
    const [total, setTotal] = useState(0);
    const [parent, setParent] = useState<DriveEntity>();
    const [modalName, setModalName] = useState('');
    const [moveTarget, setMoveTarget] = useState<string | null>(null);

    useEffect(() => {
        setIsModalOpen(!!entity);
        setParentId(entity?.parentId);
    }, [entity]);

    useEffect(() => {
        if (!isModalOpen) {
            resetValues();
            return;
        }

        loadData();
    }, [parentId, skip]);

    useEffect(() => {
        loadParent();
    }, [parentId]);

    /**
     * загружаем родителя чтобы показывать его имя (если перемещаемся вверх)
     * @returns 
     */
    const loadParent = async () => {
        if (!parentId) {
            setParent(undefined);
            setModalName('');
            return;
        }

        if (parent) {
            return;
        }
        const data = await DriveService.list({ skip: skip, filter: { id: parentId } }, path);

        if (data.items.length > 0) {
            setParent(data.items[0]);
            setModalName(data.items[0].name);
        }
    };

    const resetValues = () => {
        setIsModalOpen(false);
        setMoveTarget(null);
        setSkip(0);
        setParentId(undefined);
        setModalName('');
        setTotal(0);
        setItems([]);
    };

    /**
     * загружаем список папок
     * @returns 
     */
    const loadData = async () => {
        setIsLoading(true);
        const currentFolders = await DriveService.list({ id: parentId, skip: skip, take: take.current, order: { name: 'asc' } }, path);
        if (!isModalOpen) return;

        setItems(items.concat(currentFolders.items));
        setTotal(currentFolders.total);
        setIsLoading(false);
    };

    const onModalClose = () => {
        resetValues();
        onClose();
    };

    const moveTo = (id?: string, parentEntity?: DriveEntity) => {
        setItems([]);
        setSkip(0);
        setMoveTarget(null);
        setParent(parentEntity);
        if (parentEntity) {
            setModalName(parentEntity?.name);
        }
        setParentId(id);
    };

    const onClickMoveTarget = (id: string) => {
        if (moveTarget === id) {
            setMoveTarget(null);
            return;
        }

        setMoveTarget(id);
    };

    const onClickBack = () => {
        if (isLoading) {
            return;
        }
        moveTo(parent?.parentId);
    };

    const incrementSkip = () => {
        setSkip(skip + take.current);
    };

    /**
     * запрещаем перемещать если родитель такой же как цель перемещения
     * @returns 
     */
    const isMoveDisabled = () => {
        if (!moveTarget) {
            return entity?.parentId === parentId;
        }
        return entity?.parentId === moveTarget;
    };

    const onMove = async () => {
        try {
            if (!entity) return;

            if (moveTarget) {
                await DriveService.move(path, { id: entity.id, parentId: moveTarget });
            } else {
                await DriveService.move(path, { id: entity.id, parentId: parentId });
            }
            resetValues();
            onSave();
        } catch (err) {
            ErrorHandler.handle('drive move error', err);
        }
    };

    return (
        <BaseModal centered scrollable isOpen={isModalOpen} onRequestClose={onModalClose} modalSize="md">
            <ModalHeader
                title={modalName.length > 0 ? modalName : t(`drive:${path || 'my'}-title`)}
                onClickBack={modalName.length > 0 ? onClickBack : undefined}
                onRequestClose={onModalClose}
            />
            <div className="modal-body py-1 drive-move-list">
                {items.length > 0 &&
                    items.map((driveEntity) => (
                        <div className="d-flex" key={driveEntity.id}>
                            <MDBBtn
                                disabled={driveEntity.type !== ObjectType.DriveFolder || entity?.id === driveEntity.id}
                                className="w-100 d-flex justify-content-start"
                                color={moveTarget === driveEntity.id ? 'primary' : 'link'}
                                onClick={() => onClickMoveTarget(driveEntity.id)}
                            >
                                {driveEntity.type === ObjectType.DriveFolder && <i className="far fa-folder my-auto me-2"/>}
                                {driveEntity.name}
                            </MDBBtn>
                            {driveEntity.type === ObjectType.DriveFolder && entity?.id !== driveEntity.id && (
                                <MDBBtn color="link" onClick={() => moveTo(driveEntity.id, driveEntity)}>
                                    <i className="fas fa-chevron-right"/>
                                </MDBBtn>
                            )}
                        </div>
                    ))}
                {!isLoading && items.length < 1 && <div>{t('drive:empty-folder')}</div>}
                {isLoading && skip < 1 && <Loader />}
            </div>
            {!isLoading && (
                <div className="mx-auto py-1">
                    {skip + take.current < total && (
                        <MDBBtn color="outline-primary" size="sm" onClick={incrementSkip}>
                            {t('main:btn-more')}
                        </MDBBtn>
                    )}
                </div>
            )}
            <div className="modal-footer">
                <MDBBtn disabled={isMoveDisabled()} onClick={onMove}>
                    {!!moveTarget ? t('main:btn-move') : t('drive:btn-move-to-this-folder')}
                </MDBBtn>
            </div>
        </BaseModal>
    );
};

export default MoveEntityModal;
