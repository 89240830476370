import ModelValidator from "../model.validator";
import { MemberCountry } from '../../../eenApi/membercountry/dto/member.country';

class Validator extends ModelValidator<MemberCountry, MemberCountry> {

   validate(dto: MemberCountry, tabs: string[], entity: MemberCountry) {
      const erroredTabs: string[] = [];

      this.checkRequired(dto.flagFileId, erroredTabs, 'main');
      this.checkRequired(dto.name, erroredTabs, 'main');
      this.checkLength(dto.name, erroredTabs, 50, 'main');
      this.checkRequired(dto.capital, erroredTabs, 'main');
      this.checkLength(dto.capital, erroredTabs, 50, 'main');
      this.checkLength(dto.description, erroredTabs, 2000, 'main');

      return erroredTabs;
   }
}
export const MemberCountryEditValidator = new Validator()