import TabPanel from '../../tabs/tabPanel';
import { InputControl } from '../../common/ui';
import CheckboxControl from '../../common/ui/CheckboxControl';
import IEditUnsubscribeProps from '../edit.unsubscribe.props';
import { Field } from '../../common/layout';
import useTranslation from '../../../hooks/translation.hook';
import { Length } from '../../../hooks/UseValidation/validators';

export default function UnsubscribeEmailEdit({ data, updateData, ...rest }: IEditUnsubscribeProps) {
    const { t } = useTranslation();

    return (
        <TabPanel {...rest}>
            <Field labelKey="mailing:unsubscribe.titleUnsubscribe">{data.email}</Field>
            <CheckboxControl value={data.notification} labelKey={t('mailing:unsubscribe.notification')} onChange={(data) => updateData('notification', data)} />
            <CheckboxControl value={data.mailing} labelKey={t('mailing:unsubscribe.mailing')} onChange={(data) => updateData('mailing', data)} />
            <InputControl
                labelKey={t('mailing:unsubscribe.comment')}
                onChange={(data) => updateData('comment', data)}
                value={data.comment}
                validators={[Length(300)]}
            />
        </TabPanel>
    );
}
