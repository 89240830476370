import React, { useContext, useMemo } from 'react';
import useTranslation from '../../../hooks/translation.hook';
import { UserContext } from '../../../context/user-context';
import MainContainer from '../../../component/mainContainer';
import PageTitle from '../../../component/pageTitle';
import Rules from '../../../rules';
import { Link, useHistory } from 'react-router-dom';
import { MemberCountryRoutesPath } from '../routes';
import Loader from '../../../component/loader';
import { useQuery } from 'react-query';
import { MemberCountryService } from '../../../eenApi/membercountry/member.country.service';
import cl from './index.module.css';
import classNames from 'classnames';


export function MemberCountryList() {
    const { t, i18n } = useTranslation();
    const { checkAccess } = useContext(UserContext);
    const { push } = useHistory();

    const { data: list } = useQuery(['membercountry', 'list', i18n.language],
        () => MemberCountryService.list({ language: i18n.language, order: { 'Datas.Name': 'asc' } }))

    const click = useMemo(() => (id: number) => {
        if (checkAccess(Rules.MemberCountry.View)) {
            push(MemberCountryRoutesPath.View.replace(':id', id.toString()))
        }
    }, []);

    return (
        <MainContainer>
            <PageTitle text={t('membercountry:title.list')} backLink='/' />
            <div className='d-xl-flex mb-1 mb-xl-3'>
                <div className='app-filter-actions mb-2 mb-xl-0'>
                    {checkAccess(Rules.MemberCountry.Add) &&
                        <Link className='btn btn-success me-2'
                              to={MemberCountryRoutesPath.Create}>{t('main:btn-add')}</Link>
                    }
                </div>
            </div>
            { list?.items === undefined ? <Loader /> : <>
                <div className={`${cl.memberCountryContainer}`}>
                    {list.items.map(item => <div key={item.id} className={classNames({
                        [cl.memberCountry]: true,
                        [cl.link]: checkAccess(Rules.MemberCountry.View),
                    })} onClick={() => click(item.id!)}>
                        <div style={{backgroundImage: `url(/${item.flag?.source})`}} className={cl.flagImg}/>
                        <div className="text-center">{item.name}</div>
                    </div>)}
                </div>
            </>}
        </MainContainer>
    );
}