import { FC } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { MDBDropdown, MDBDropdownItem, MDBDropdownMenu, MDBDropdownToggle } from 'mdb-react-ui-kit';
import { ApplicationPaths } from './ApiAuthorizationConstants';
import useTranslation from '../../hooks/translation.hook';
import { useCurrentUser } from '../../hooks/useCurrentUser';
import { Button } from '@mui/material';

const LoginMenu: FC = () => {
    const currentUser = useCurrentUser();

    if (!currentUser.data?.user) {
        return <AnonymousView registerPath={ApplicationPaths.Register} loginPath={ApplicationPaths.Login} />;
    } else {
        const profilePath = `${ApplicationPaths.Profile}`;
        const logoutPath = { pathname: `${ApplicationPaths.LogOut}`, state: { local: true } };
        return <AuthenticatedView userName={currentUser.data?.user.email} profilePath={profilePath} logoutPath={logoutPath} />;
    }
};

const AuthenticatedView: FC<{ userName: string; profilePath: string; logoutPath: { pathname: string; state: { local: boolean } } }> = ({
    userName,
    profilePath,
    logoutPath,
}) => {
    const { t } = useTranslation();
    return (
        <li className="nav-item">
            <MDBDropdown>
                <MDBDropdownToggle tag="a" className="nav-link" role={'button'}>
                    {userName}
                </MDBDropdownToggle>
                <MDBDropdownMenu>
                    <MDBDropdownItem>
                        <Link className="dropdown-item" to={profilePath}>
                            {t('authentication:profile')}
                        </Link>
                    </MDBDropdownItem>
                    <MDBDropdownItem>
                        <Link className="dropdown-item" to={logoutPath}>
                            {t('authentication:logout')}
                        </Link>
                    </MDBDropdownItem>
                </MDBDropdownMenu>
            </MDBDropdown>
        </li>
    );
};

const AnonymousView: FC<{ registerPath: string; loginPath: string }> = ({ registerPath, loginPath }) => {
    const { t } = useTranslation();
    const history = useHistory();
    return (
        <>
            <li>
                <Button onClick={() => history.push(registerPath)} sx={{ color: 'white' }}>
                    {t('authentication:register')}
                </Button>
            </li>
            <li>
                <Button onClick={() => history.push(loginPath)} sx={{ color: 'white' }}>
                    {t('authentication:login')}
                </Button>
            </li>
        </>
    );
};

export default LoginMenu;
