import ModelValidator from "../model.validator";
import { InterestDto } from '../../../eenApi/profile/dto/interest.dto';

class Validator extends ModelValidator<InterestDto, InterestDto> {

   validate(dto: InterestDto, tabs: string[], entity: InterestDto) {
      const erroredTabs: string[] = [];

      this.checkRequired(dto.profileId, erroredTabs, 'main');
      this.checkRequired(dto.clientId, erroredTabs, 'main');
      this.checkRequired(dto.status ?? '', erroredTabs, 'main');
      this.checkRequired(dto.cooperationKind ?? '', erroredTabs, 'main');
      this.checkLength(dto.cooperationKind ?? '', erroredTabs, 255, 'main');
      this.checkRequired(dto.missingInformation ?? '', erroredTabs, 'main');
      this.checkLength(dto.missingInformation ?? '', erroredTabs, 255, 'main');

      if (Number(dto.clientId) === Number(dto.profileClientId)) {
         erroredTabs.push('main')
      }

      return erroredTabs;
   }
}
export const ProfileInterestValidator = new Validator()