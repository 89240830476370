import React, { FC, useCallback, useContext, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { MDBBtn } from 'mdb-react-ui-kit';
import { useDropzone } from 'react-dropzone';
import MainContainer from '../../../component/mainContainer';
import PageTitle from '../../../component/pageTitle';
import { DriveTable } from '../component/table';
import AddFolderModal from '../component/addFolderModal';
import Filter from '../../../component/filter';
import { DriveEntity } from '../../../eenApi/drive/driveEntity';
import { DriveService } from '../../../eenApi/drive/service';
import { ErrorHandler } from '../../../service/errorHandler';
import RenameEntityModal from '../component/renameEntityModal';
import MoveEntityModal from '../component/moveEntityModal';
import AddEntityModal from '../component/addEntityModal';
import { ObjectType } from '../../../enums/objectType';
import DownloadEntityModal from '../component/downloadEntityModal';
import useTranslation from '../../../hooks/translation.hook';
import { UserContext } from '../../../context/user-context';
import Rules from '../../../rules';
import FilterItemText from '../../../component/filter/types/filterItem/items/text';
import { Breadcrumbs } from '../../../component/common/layout';
import { DriveRoutesPath } from '../routes';
import { TableGroupAction, TableRowAction } from '../../../component/common/layout/DataTable/table.model';
import { useQueryClient } from 'react-query';
import { resetQueryFn } from '../../../query/query.utils';
import { EntityQueryKey } from '../../../query/query.keys';

interface DriveRuleSet {
    Add: string;
    Edit: string;
    Delete: string;
}

interface IProps {
    path: string;
    ruleSet: DriveRuleSet;
}

const DriveList: FC<IProps> = ({ path = '', ruleSet = { Add: Rules.Public, Edit: Rules.Public, Delete: Rules.Public } }: IProps) => {
    const { t, i18n } = useTranslation();
    const { id } = useParams<{ id?: string }>();
    const [isAddEntityOpened, setIsAddEntityOpened] = useState(false);
    const [isAddFolderOpened, setIsAddFolderOpened] = useState(false);
    const [filterValues, setFilterValues] = useState<{ [key: string]: any }>();
    const [renameEntity, setRenameEntity] = useState<DriveEntity>();
    const [moveEntity, setMoveEntity] = useState<DriveEntity>();
    const [downloadEntity, setDownloadEntity] = useState<DriveEntity>();
    const { checkAccess } = useContext(UserContext);
    const queryClient = useQueryClient();

    const onDrop = useCallback(
        (acceptedFiles: File[]) => {
            onChangeAcceptedFiles(acceptedFiles);
        },
        [id],
    );

    const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
        noClick: true,
        noKeyboard: true,
        onDrop: onDrop,
    });

    const onChangeAcceptedFiles = async (acceptedFiles: File[]) => {
        for (const file of acceptedFiles) {
            await uploadFile(file);
        }
    };

    const uploadFile = async (file: File) => {
        try {
            await DriveService.uploadFile(file, path, id);
        } catch (err) {
            ErrorHandler.handle('drive upload', err);
        }
        await queryClient.resetQueries({ predicate: (query) => resetQueryFn(query, EntityQueryKey.Drive) });
    };

    const filterItems = useMemo(() => [new FilterItemText('name', 'main:field-name', { isTyping: 'Y' })], [i18n.language]);

    const rowActions = useMemo(() => {
        const items: TableRowAction<DriveEntity>[] = [
            {
                id: 'download',
                label: t('drive:btn-get-link'),
                action: (item: DriveEntity) => clickDownload(item),
                refreshTableAfterAction: false,
                isHide: (item: DriveEntity) => item.type !== ObjectType.DriveFile,
            },
        ];

        if (checkAccess(ruleSet.Edit)) {
            items.push({
                id: 'rename',
                label: t('main:btn-rename'),
                action: (item: DriveEntity) => clickRename(item),
            });
            items.push({
                id: 'move',
                label: t('main:btn-move'),
                action: (item: DriveEntity) => clickMove(item),
            });
        }

        if (checkAccess(ruleSet.Delete)) {
            items.push({
                id: 'delete',
                label: t('main:btn-delete'),
                action: (item: DriveEntity) => clickDelete(item.id),
            });
        }

        return items;
    }, [i18n.language, ruleSet]);

    const groupActions = useMemo<TableGroupAction[]>(
        () =>
            checkAccess(ruleSet.Delete)
                ? [
                      {
                          id: 'delete',
                          label: t('main:btn-delete'),
                          action: (selected) => clickDelete(selected as string[]),
                      },
                  ]
                : [],
        [i18n.language, ruleSet],
    );

    const clickDownload = (item: DriveEntity) => setDownloadEntity(item);

    const clickRename = (item: DriveEntity) => setRenameEntity(item);

    const clickMove = (item: DriveEntity) => setMoveEntity(item);

    const clickDelete = (id: string | string[]) => DriveService.delete(id, path);

    const onClickAddEntity = () => setIsAddEntityOpened(true);

    const onClickCloseAddEntity = () => setIsAddEntityOpened(false);

    const onClickAddFolder = () => {
        setIsAddEntityOpened(false);
        setIsAddFolderOpened(true);
    };

    const onClickAddFile = () => {
        setIsAddEntityOpened(false);
        open();
    };

    //#endregion

    //#region AddFolder

    const onCloseAddFolderModal = () => {
        setIsAddFolderOpened(false);
    };

    const onSaveAddFolderModal = () => {
        setIsAddFolderOpened(false);
        setFilterValues({ ...filterValues });
    };

    //#endregion

    //#region Rename

    const onCloseRenameModal = () => {
        setRenameEntity(undefined);
    };

    const onSaveRenameFolderModal = () => {
        setRenameEntity(undefined);
        setFilterValues({ ...filterValues });
    };

    //#endregion

    //#region Move

    const onCloseMoveModal = () => {
        setMoveEntity(undefined);
    };

    const onSaveMoveFolderModal = () => {
        setMoveEntity(undefined);
        setFilterValues({ ...filterValues });
    };

    //#endregion

    //#region Download

    const onCloseDownloadModal = () => {
        setDownloadEntity(undefined);
    };

    //#endregion

    return (
        <MainContainer>
            <PageTitle text={t(`drive:${path || 'my'}-title`)} backLink="/" />
            <Breadcrumbs
                getLink={(id) => DriveService.getViewFolderLink(String(id), path)}
                root={{ id: `${DriveRoutesPath.Prefix}/${path || 'my'}`, name: t(`drive:${path || 'my'}-title`) }}
            />
            <div className="d-xl-flex mb-1 mb-xl-3">
                <div className="app-filter-actions mb-2 mb-xl-0">
                    {checkAccess(ruleSet.Add) && (
                        <MDBBtn className="text-nowrap me-2" color="success" onClick={onClickAddEntity}>
                            {t('main:btn-add')}
                        </MDBBtn>
                    )}
                </div>
                <Filter id={`filter-${path}drive-list`} items={filterItems} onFind={setFilterValues} />
            </div>
            <div {...getRootProps({ className: 'dropzone' })}>
                <input {...getInputProps()} />
                <DriveTable
                    isDragActive={isDragActive}
                    parentId={id}
                    filterValues={filterValues}
                    rowActions={rowActions}
                    groupActions={groupActions}
                    path={path}
                />
            </div>

            <AddEntityModal isOpened={isAddEntityOpened} onAddFile={onClickAddFile} onAddFolder={onClickAddFolder} onClose={onClickCloseAddEntity} />
            <AddFolderModal parentId={id} isOpened={isAddFolderOpened} onClose={onCloseAddFolderModal} onSave={onSaveAddFolderModal} path={path} />
            <RenameEntityModal entity={renameEntity} onClose={onCloseRenameModal} onSave={onSaveRenameFolderModal} path={path} />
            <MoveEntityModal entity={moveEntity} onClose={onCloseMoveModal} onSave={onSaveMoveFolderModal} path={path} />
            <DownloadEntityModal entity={downloadEntity} onClose={onCloseDownloadModal} />
        </MainContainer>
    );
};

export default DriveList;
