export default {
    List: 'center.list',
    PartnerList: 'center.partnerlist',
    Add: 'center.add',
    Edit: 'center.edit',
    EditOwn: 'center.editown',
    View: 'center.view',
    ViewOwn: 'center.viewown',
    Delete: 'center.delete',
    DeleteOwn: 'center.deleteown',
    DeleteUser: 'center.deleteuser',
}