import { FC } from "react";
import useTranslation from "../../hooks/translation.hook";
import { IToastProps, ToastStyle } from "./types";

interface IProps {
    data: Partial<IToastProps>,
    toastStyle: ToastStyle
}

const ToastMessage: FC<IProps> = (props) => {
    const { t } = useTranslation();
    const data = props.data;
    return (
        <div className={props.toastStyle.bgColor}>
            {data && data.title &&
                <div className={`toast-header ${props.toastStyle.textColor} ${props.toastStyle.bgColor}`}>
                    {props.toastStyle.icon &&
                        <i className={`${props.toastStyle.icon} me-2`}></i>
                    }
                    <strong className="me-auto">{t(data.title, data.params)}</strong>

                    <button type="button" className="btn-close btn-close-white" aria-label="Close"></button>
                </div>
            }
            {data && data.message &&
                <div className={`toast-body ${props.toastStyle.textColor}`}>{t(data.message)}</div>
            }
        </div>
    );
}// onClick={props.closeButton}

export default ToastMessage;
