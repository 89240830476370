import { MDBBtn } from 'mdb-react-ui-kit';
import { FC, useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { DriveService } from '../../eenApi/drive/service';
import { ErrorHandler } from '../../service/errorHandler';

import './style.css';
import DriveFileDto from '../../eenApi/drive/dto/drive.file.dto';
import useTranslation from '../../hooks/translation.hook';
import Loader from '../loader';
import { MyDriveModal } from "./MyDriveModal";

interface IProps {
    onFileUploaded: (file: DriveFileDto) => void;
    onlyOneFile?: boolean;
    files?: { id: string, name: string } [];
}

const FileUploader: FC<IProps> = (props) => {
    const { t } = useTranslation();

    const onDrop = useCallback((acceptedFiles: File[]) => {
        onChangeAcceptedFiles(acceptedFiles);
    }, []);

    const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
        noClick: true,
        noKeyboard: true,
        onDrop: onDrop,
        multiple: !props.onlyOneFile,
        disabled: props.onlyOneFile && props.files && props.files.length > 0,
    });

    const [isUploading, setIsUploading] = useState(false);
    const [myDriveOpened, setMyDriveOpened] = useState(false);

    const onChangeAcceptedFiles = async (acceptedFiles: File[]) => {
        setIsUploading(true);
        for (const file of acceptedFiles) {
            await uploadFile(file);
        }
        setIsUploading(false);
    };

    const uploadFile = async (file: File) => {
        try {
            const fileId = await DriveService.uploadFile(file);
            props.onFileUploaded({ id: fileId, name: file.name });
        } catch (err) {
            ErrorHandler.handle('drive upload', err);
        }
    };


    return (
        <div {...getRootProps({ className: 'file-uploader-container' })}>
            <input {...getInputProps()} />
            {isDragActive ? (
                <div>
                    <div className={`py-4 file-uploader file-uploader-drag`}>
                        <i className='fas fa-cloud-upload-alt my-auto me-2'/>
                        {t('main:file-upload-description')}
                    </div>
                </div>
            ) : (
                <div>
                    <div className='row mb-2'>
                        <div className='col-12 col-sm-6'>
                            <MDBBtn type='button' onClick={open} outline block
                                    disabled={props.onlyOneFile && props.files && props.files.length > 0}>
                                <div>
                                    <i className='fas fa-cloud-upload-alt fa-2x'/>
                                </div>
                                <div>{t('drive:btn-upload')}</div>
                            </MDBBtn>
                        </div>
                        <div className='col-12 col-sm-6'>
                            <MDBBtn type='button' outline block onClick={() => setMyDriveOpened(true)}
                                    disabled={props.onlyOneFile && props.files && props.files.length > 0}>
                                <div>
                                    <i className='fas fa-hdd fa-2x'/>
                                </div>
                                <div>{t('drive:btn-drive')}</div>
                            </MDBBtn>
                        </div>
                    </div>
                    <div
                        className={`py-2 ${isDragActive ? 'file-uploader' : 'file-uploader'}`}>{t('main:file-upload-description')}</div>
                </div>
            )}
            <MyDriveModal isOpen={myDriveOpened}
                          onSave={(selected) => {
                              if (selected) {
                                  props.onFileUploaded(selected)
                              }
                          }}
                          onClose={() => setMyDriveOpened(false)}/>
            { isUploading && <Loader small/>}
        </div>
    );
};

export default FileUploader;
