import React from 'react';

interface IProps {
    className: string;
    children?: React.ReactNode;
}

export default class MainContainer extends React.Component<IProps> {
    public static defaultProps = {
        className: 'container-fluid',
    };

    public render(): JSX.Element {
        return <main className={this.props.className}>{this.props.children}</main>;
    }
}
