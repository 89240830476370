import DriveFileDto from '../../drive/dto/drive.file.dto';
import IdNameItemDto from '../../common/id.name.item.dto';
import LanguageEnum from '../../../enums/language.enum';
import { InnovationProjectStatusEnum } from '../enum/innovation.project.status.enum';
import { UserRoleData } from "../../user/userRoleData";
import { ObjectType } from "../../../enums/objectType";
import { SelectItemDto } from "../../common/select.item.dto";

export class InnovationProjectDto {
    id?: number;

    // Блок "Основные данные"
    // "Логотип" (обязательное), тип "Файл" (jpeg, png), не более 4 Мб;
    logoFileId: string|null;
    logo?: DriveFileDto
    // "Название проекта" (обязательное), тип "Строка", не более 50 символов;
    name = '';
    // "Ссылка на конкурс" (необязательно), тип "Строка", не более 255 символов;
    webLink = '';
    // "Страна" (обязательное), тип "Привязка к элементу сущности "Страна-участница".
    // Если добавление происходит представителем какой-то страны-участницы, то поле должно устанавливаться автоматически
    // (исходя из страны представителя из лк) без возможности его редактирования представителем;
    memberCountryId: number|null;
    memberCountryDto?: SelectItemDto;
    // "Организация" (обязательное), тип "Привязка к элементу сущности "Организация участник".
    // Если добавление происходит представителем какой-то организации-участницы, то поле должно устанавливаться автоматически
    // (исходя из организации представителя из лк) без возможности его редактирования представителем;
    centerId: number|null = null;
    centerDto?: IdNameItemDto;
    // "Краткое описание проекта" (обязательное), тип "Текстовая область", не более 255 символов.
    description = '';

    // Блок "Страны-партнеры"
    // "Страна-партнер" (множественное, необязательное), тип "Привязка к элементу сущности "Страна-участница".
    // Если добавление происходит представителем какой-то страны-участницы, то в поле должен отсутствовать выбор страны-участницы этого представителя;
    partnerCountryIds: number[] = [];
    partnerCountries?: SelectItemDto[];
    // "Организация-партнер" (необязательное), тип "Привязка к элементу сущности "Организация участник".
    // Если добавление происходит представителем какой-то организации-участницы, то в поле должен отсутствовать выбор организации-участницы этого представителя.
    partnerCenterId: number|null = null;
    partnerCenterDto?: SelectItemDto;

    language?: LanguageEnum;
    status = InnovationProjectStatusEnum.Draft;
    createdBy?: string
    countryProfiles?: IdNameItemDto[]

    constructor(entity: InnovationProjectDto|undefined, language: LanguageEnum, userRules: UserRoleData[]|undefined) {
        Object.entries(entity ?? {}).forEach(([key, val]) => {
            if (this.hasOwnProperty(key)) {
                this[key as keyof InnovationProjectDto] = val as never
            }
        });

        if (!this.language) {
            this.language = language
        }

        if (!entity && userRules && !userRules.some(x => !x.objectId)) {
            const center = userRules.find(x => x.objectType === ObjectType.Center);
            this.centerId = Number(center?.objectId) || null;
            this.memberCountryId = center?.memberCountryId ?? null;
        } else {
            this.centerId = entity?.centerDto?.id ?? null;
            this.memberCountryId = Number(entity?.memberCountryDto?.id) || null;
        }

        this.logoFileId = entity?.logo?.id ?? null;
        this.partnerCountryIds = entity?.partnerCountries?.map(x => Number(x.id)) ?? [];
        this.partnerCenterId = Number(entity?.partnerCenterDto?.id) || null;
    }
}