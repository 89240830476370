import { AppDispatch, RootState } from "../..";
import { EenApi } from "../../../eenApi";
import { DictionaryService } from "../../../eenApi/dictionary/service";
import { DictionaryEntity } from "../../../models/dictionaryEntity";
import { DictionaryType } from "../../../models/dictionaryType";
import { SetLoadingAction, DictionaryActionEnum, SetDictionaryAction } from "./types";

export const DictionaryActionCreators = {
    setDictionary: (key: string, items: DictionaryEntity[]): SetDictionaryAction => ({ type: DictionaryActionEnum.SET_DICTIONARY, payload: { key: key, items: items } }),
    setLoading: (key: string, isLoading: boolean): SetLoadingAction => ({ type: DictionaryActionEnum.SET_LOADING, payload: { key: key, state: isLoading } }),
    loadDictionary: (type: DictionaryType, language: string) => async (dispatch: AppDispatch, getState: () => RootState) => {
        try {
            dispatch(DictionaryActionCreators.setLoading(type, true));

            const result = await DictionaryService.getAll(type, language);

            dispatch(DictionaryActionCreators.setDictionary(type, result));
        } catch (err) {
            console.error("dictionary load error", err);
            dispatch(DictionaryActionCreators.setLoading(type, false));
        }
    },
};
