import TabPanel, { ITabPanelProps } from '../../tabs/tabPanel';
import React from 'react';
import { InputControl, SelectControl, TextEditorControl } from '../../common/ui';
import { Length, NotEmpty } from '../../../hooks/UseValidation/validators';
import InputContainer from '../../inputContainer';
import ViewField from '../../fields/viewField';
import FileList from '../../fields/fileList';
import FileUploader from '../../fileUploader';
import useTranslation from '../../../hooks/translation.hook';
import { CenterDto } from '../../../eenApi/center/dto/center.dto';
import { useOptions } from '../../../hooks/select.options.hook';
import { CenterTypeEnum } from '../../../eenApi/center/enum/center.type.enum';
import CheckboxControl from '../../common/ui/CheckboxControl';
import DictionaryControl from '../../common/ui/DictionaryControl';
import { DictionaryType } from '../../../models/dictionaryType';
import LanguageEnum from '../../../enums/language.enum';
import { useCurrentUser } from "../../../hooks/useCurrentUser";

interface IEditPanelProps extends ITabPanelProps {
    data: CenterDto,
    updateData: (key: keyof CenterDto, val: any) => void,
}

export function MainEditTab({ data, updateData, ...rest }: IEditPanelProps) {
    const onUploaded = (file: { id: string; name: string }) => {
      updateData('logoFile', file);
      updateData('logoFileId', file.id)
    };
    const onRemoveFile = () => {
      updateData('logoFile', null)
      updateData('logoFileId', null)
    };
    const { i18n } = useTranslation();
    const typeOptions = useOptions(CenterTypeEnum, 'center:type.');
    const { data: currentUser } = useCurrentUser();

    return (
        <TabPanel {...rest}>
            <InputContainer>
                <ViewField title='center:field.logoFileId'>
                    <FileList files={data?.logoFile ? [data.logoFile] : []} onRemove={onRemoveFile} />
                </ViewField>
                {!data.logoFile && <FileUploader onFileUploaded={onUploaded} />}
            </InputContainer>
            <SelectControl options={typeOptions} labelKey="center:field.type"
                           value={data.type} onChange={value => updateData('type', value)}
                           required validators={[NotEmpty]}/>
            <CheckboxControl labelKey="center:field.active" onChange={value => updateData('active', value)} value={data.active}/>
            <InputControl labelKey={'center:field.fullName'}
                          onChange={value => updateData('fullName', value)}
                          value={data.fullName}
                          required
                          validators={[NotEmpty, Length(1000)]}/>
            <InputControl labelKey={'center:field.name'}
                          onChange={value => updateData('name', value)}
                          value={data.name} multiline
                          required
                          validators={[NotEmpty, Length(500)]}/>
            <InputControl labelKey={'center:field.acronym'}
                          onChange={value => updateData('acronym', value)}
                          value={data.acronym} multiline
                          required
                          validators={[NotEmpty, Length(255)]}/>
            <InputControl labelKey={'center:field.postAddress'}
                          onChange={value => updateData('postAddress', value)}
                          value={data.postAddress} multiline
                          required
                          validators={[NotEmpty, Length(1000)]}/>
            <InputControl labelKey={'center:field.actualAddress'}
                          onChange={value => updateData('actualAddress', value)}
                          value={data.actualAddress} multiline
                          required
                          validators={[NotEmpty, Length(1000)]}/>
            <DictionaryControl labelKey="center:field.memberCountryId"
                               value={data.memberCountryId?.toString() ?? null}
                               disabled={!currentUser?.rules?.some(x => !x.memberCountryId)}
                               onChange={value => updateData('memberCountryId', value ?? null)}
                               dictionary={DictionaryType.MemberCountry}
                               language={i18n.language as LanguageEnum}
                               required validators={[NotEmpty]} minInput={0}/>
            <InputControl labelKey={'center:field.city'}
                          onChange={value => updateData('city', value)}
                          value={data.city}
                          required
                          validators={[NotEmpty, Length(255)]}/>
            <InputControl labelKey={'center:field.web'}
                          onChange={value => updateData('web', value)}
                          value={data.web}
                          validators={[Length(255)]}/>
            <InputControl labelKey={'center:field.directorName'}
                          onChange={value => updateData('directorName', value)}
                          value={data.directorName}
                          required
                          validators={[NotEmpty, Length(500)]}/>
            <InputControl labelKey={'center:field.phone'}
                          onChange={value => updateData('phone', value)}
                          value={data.phone}
                          validators={[Length(255)]}/>
            <InputControl labelKey={'center:field.email'}
                          onChange={value => updateData('email', value)}
                          value={data.email}
                          validators={[Length(255)]}/>
            <TextEditorControl
                labelKey={'center:field.description'}
                onChange = {(e: any, text: string) => {
                    updateData('description', text)}}
                value={data.description ?? ''}
                validators={[Length(10000)]}/>
        </TabPanel>
    );
}