import React, { FC, useMemo } from 'react';
import { Log } from '../../../eenApi/log/log';
import { LogService } from '../../../eenApi/log/service';
import useTranslation from '../../../hooks/translation.hook';
import { TableColumn } from '../../../component/common/layout/DataTable/table.model';
import { formatDateTime } from '../../../utils/date.utils';
import { DataTable } from '../../../component/common/layout';
import { EntityQueryKey } from '../../../query/query.keys';
import { useIdColumn } from "../../../component/common/layout/DataTable/table.utils";

interface IProps {
    filterValues?: { [key: string]: any };
    language: string;
}

const LogTable: FC<IProps> = (props) => {
    const { t, i18n } = useTranslation();

    const idColumn = useIdColumn<Log>();
    const columns = useMemo<TableColumn<Log>[]>(
        () => [
          idColumn,
            new TableColumn({
                headerName: t('main:field-type'),
                field: 'type',
                hideable: false,
                valueFormatter: ({ value }) => t(`log:type-${value}`),
            }),
            new TableColumn({
                headerName: t('log:field-object-type'),
                field: 'objectType',
                hideable: false,
                valueFormatter: ({ value }) => t(`main:object-type-${value}`),
                width: 120,
            }),
            new TableColumn({
                headerName: t('log:field-object-id'),
                field: 'objectId',
            }),
            new TableColumn({
                headerName: t('main:field-user'),
                field: 'createdBy',
            }),
            new TableColumn({
                headerName: t('main:field-description'),
                field: 'description',
                width: 120
            }),
            new TableColumn({
                headerName: t('main:field-ip'),
                field: 'ip',
                width: 130
            }),
            new TableColumn({
                headerName: t('main:field-date-and-time'),
                field: 'created',
                valueFormatter: ({ value }) => formatDateTime(value),
                width: 150,
            }),
        ],
        [i18n.language],
    );

    return (
        <DataTable
            id="admin-log-table-component"
            queryKey={EntityQueryKey.Log}
            filterValues={props.filterValues}
            columns={columns}
            fetchData={(requestData) => LogService.list(requestData)}
        />
    );
};

export default LogTable;
