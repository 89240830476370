import IControlProps from "../control.props";
import {Checkbox, FormControlLabel} from "@mui/material";
import InputContainer from "../../../inputContainer";
import useTranslation from "../../../../hooks/translation.hook";

export default function CheckboxControl(props: IControlProps) {
   const {t} = useTranslation();

   return (
      <InputContainer>
         <FormControlLabel control={<Checkbox checked={props.value === true || props.value === 'Y'}
                                              onChange={event => {
                                                 if (typeof props.value === "string") {
                                                    props.onChange(event.target.checked ? 'Y' : 'N')
                                                 } else {
                                                    props.onChange(event.target.checked)
                                                 }
                                              }}/>}
                           label={t(props.labelKey)} />
      </InputContainer>
   )
}