import { Route, Switch } from 'react-router-dom';
import Rules from '../../../rules';
import NotFound from '../../home/notFound';
import NotificationEdit from '../edit';
import NotificationList from '../list';
import ProtectedRoute from "../../../component/common/ProtectedRoute";

export default function NotificationRoutes() {
    return (
        <Switch>
            <ProtectedRoute exact rule={Rules.Notification.Edit} path={NotificationRoutesPath.List} component={NotificationList}/>
            <ProtectedRoute exact rule={Rules.Notification.Edit} path={NotificationRoutesPath.Create} component={NotificationEdit}/>
            <ProtectedRoute exact rule={Rules.Notification.Edit} path={NotificationRoutesPath.Edit} component={NotificationEdit}/>
            <Route path="*" component={NotFound} />
        </Switch>
    );
}

const prefix = '/notification';
export const NotificationRoutesPath = {
    Prefix: prefix,
    List: `${prefix}/list`,
    Create: `${prefix}/create`,
    Edit: `${prefix}/:id/edit`,
};
