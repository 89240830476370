import IControlProps from '../control.props';
import React, { useContext, useEffect, useState } from 'react';
import { useValidation } from '../../../../hooks/UseValidation';
import InputContainer from '../../../inputContainer';
import ValidationContext from '../../../../hooks/UseValidation/validation.context';
import { FormControl, FormHelperText } from '@mui/material';
import { styled } from '@mui/material/styles';
import TextEditor from '../../../textEditor';


interface IProps extends IControlProps {
    onChange: (e: any, editor?: any) => void;
}

const StyledTextEditor = styled(TextEditor)`
  & .MuiInputLabel-asterisk {
    color: red !important
  }
`;

export default function TextEditorControl(props: IProps) {
    const validate = useValidation(props.validators);
    const { validateOn } = useContext(ValidationContext);
    const [errorText, setErrorText] = useState<string>();

    useEffect(() => {
        setErrorText(props.error || validate(props.value, props.tab ?? 'main'));
    }, [props.value, props.error, validateOn]);

    return (
        <InputContainer>
            <FormControl required={props.required} error={!!errorText} fullWidth size='small'>
                <StyledTextEditor
                    text={props.value ?? ''}
                    onChange={props.onChange}
                    required={props.required}
                    labelKey={props.labelKey}
                />
                <FormHelperText>{errorText}</FormHelperText>
            </FormControl>
        </InputContainer>
    );
}