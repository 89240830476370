import ModelValidator from "../model.validator";
import { InnovationProjectDto } from '../../../eenApi/innovationproject/dto/innovation.project.dto';

class Validator extends ModelValidator<InnovationProjectDto, InnovationProjectDto> {

   validate(dto: InnovationProjectDto, tabs: string[], entity: InnovationProjectDto) {
      const erroredTabs: string[] = [];

      this.checkRequired(dto.logoFileId, erroredTabs, 'main');
      this.checkRequired(dto.name, erroredTabs, 'main');
      this.checkLength(dto.name, erroredTabs, 100, 'main');
      this.checkLength(dto.webLink, erroredTabs, 255, 'main');
      this.checkRequired(dto.memberCountryId, erroredTabs, 'main');
      this.checkRequired(dto.centerId, erroredTabs, 'main');
      this.checkRequired(dto.description, erroredTabs, 'main');
      this.checkLength(dto.description, erroredTabs, 255, 'main');

      return erroredTabs;
   }
}
export const InnovationProjectEditValidator = new Validator()