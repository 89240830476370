import { FC } from 'react';
import { ToastContainer } from 'react-toastify';
import Modal from 'react-modal';
import { QueryClientProvider } from 'react-query';
import { useHistory } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ruRU, enUS } from '@mui/material/locale';
import Layout from './component/layout';
import { ErrorHandler } from './service/errorHandler';
import createQueryClient from './query/query.client';
import useTranslation from "./hooks/translation.hook";

Modal.setAppElement('#root');

const App: FC = () => {
    const history = useHistory();
    const { i18n } = useTranslation();

    const theme = createTheme({}, i18n.language === 'ru' ? ruRU : enUS);

    const queryClient = createQueryClient((error: any) => {
        const path = ErrorHandler.handle('query-error', error);
        if (path) {
            history.replace(path);
        }
    });

    return (
        <QueryClientProvider client={queryClient} contextSharing={true}>
            <ThemeProvider theme={theme}>
                <Layout />
                <ToastContainer />
            </ThemeProvider>
        </QueryClientProvider>
    );
};

export default App;
