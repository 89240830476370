import { useHistory } from 'react-router-dom';
import React, { useState } from 'react';
import ProfileCreateType from '../../../eenApi/profile/type/profile.create.type';
import MainContainer from '../../../component/mainContainer';
import PageTitle from '../../../component/pageTitle';
import Loader from '../../../component/loader';
import DefaultBlock from '../../../component/defaultBlock';
import BottomActionsContainer from '../../../component/actions/bottomActionsContainer';
import BottomActions from '../../../component/actions/bottomActions';
import { ProfileRoutesPath } from '../routes';
import { ActionItem } from '../../../models/actionItem';
import LanguageSelect from '../../../component/common/LanguageSelect';
import { AutocompleteControl, InputControl, SelectControl } from '../../../component/common/ui';
import { ClientService } from '../../../eenApi/client/service';
import { ToastManager } from '../../../service/toastManager';
import { ErrorHandler } from '../../../service/errorHandler';
import ProfileService from '../../../eenApi/profile/service';
import LanguageEnum from '../../../enums/language.enum';
import ProfileTypeEnum from '../../../eenApi/profile/enum/profile.type.enum';
import { useOptions } from '../../../hooks/select.options.hook';
import TopActionsContainer from '../../../component/actions/topActionsContainer';
import TopActions from '../../../component/actions/topActions';
import useTranslation from '../../../hooks/translation.hook';
import { useCurrentUser } from '../../../hooks/useCurrentUser';
import { DictionaryType } from "../../../models/dictionaryType";

export default function ProfileCreate() {
    const { t } = useTranslation();
    const history = useHistory();
    const currentUser = useCurrentUser();

    const [data, setData] = useState<ProfileCreateType>({
        clientId: null,
        language: currentUser.data?.language ?? LanguageEnum.EN,
    });
    const [isLoading, setIsLoading] = useState(false);
    const [validate, setValidate] = useState(false);

    const typeOptions = useOptions<ProfileTypeEnum>(ProfileTypeEnum, 'profile:type.');

    const updateData = (field: keyof ProfileCreateType, value: any) => {
        setData({ ...data, [field]: value });
    };

    const isValid = () => {
        return !!Number(data.clientId) && !!data.type && !!data.title && (data.language === LanguageEnum.RU || data.language === LanguageEnum.EN);
    };

    const saveAction = async () => {
        setValidate(true);
        if (!isValid()) return;

        setIsLoading(true);
        try {
            const result = await ProfileService.create(data);
            ToastManager.success({ title: 'main:msg-success', message: 'main:msg-changes-saved' });
            history.replace(`${ProfileRoutesPath.Edit.replace(':id', '' + result)}?language=${data.language}`);
        } catch (e) {
            ErrorHandler.handle('profile create', e);
        } finally {
            setIsLoading(false);
        }
    };

    const actions: ActionItem[] = [
        {
            name: t('profile:button.create'),
            onClick: saveAction,
        },
    ];

    return (
        <>
            <MainContainer>
                <PageTitle text={t('profile:create.title')} backLink={ProfileRoutesPath.List} />
                {isLoading ? (
                    <Loader />
                ) : (
                    <form noValidate>
                        <TopActionsContainer className="mb-3 d-sm-none">
                            <TopActions actions={actions} />
                        </TopActionsContainer>
                        <DefaultBlock>
                            <LanguageSelect
                                value={data.language}
                                onChange={(value) => updateData('language', value)}
                                required
                                error={validate && !data.language}
                            />
                            <SelectControl
                                labelKey="profile:fields.profileType"
                                required
                                value={data.type || ''}
                                onChange={(value) => updateData('type', value)}
                                options={typeOptions}
                                error={validate && !data.type ? t('main:error.required') : ''}
                            />
                            <InputControl
                                labelKey="profile:fields.title"
                                onChange={(value) => updateData('title', value)}
                                value={data.title}
                                required
                                error={validate && !data.title ? t('main:error.required') : ''}
                            />
                            <AutocompleteControl
                                labelKey="profile:fields.clientId"
                                dictionary={DictionaryType.Client}
                                onChange={(value) => {
                                    updateData('clientId', value?.value);
                                }}
                                options={ClientService.selectLoadOptions}
                                value={data.clientId}
                                required
                                error={validate && !data.clientId ? t('main:error.required') : ''}
                            />
                        </DefaultBlock>

                        <BottomActionsContainer>
                            <BottomActions actions={actions} />
                        </BottomActionsContainer>
                    </form>
                )}
            </MainContainer>
        </>
    );
}
