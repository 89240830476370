import { useEffect, useState } from 'react';
import LeftMenu from './leftMenu';
import BottomMenu from './bottomMenu';
import { ErrorHandler } from '../../service/errorHandler';
import { MenuService } from '../../eenApi/menu/service';
import { MenuItem } from '../../eenApi/menu/menuItem';
import { useCurrentUser } from '../../hooks/useCurrentUser';

export default function Menu() {
    const currentUser = useCurrentUser();
    const [loaded, setLoaded] = useState<string>();
    const [menu, setMenu] = useState<MenuItem[]>([]);

    const loadItems = async () => {
        if (!currentUser?.data?.language) return;
        try {
            const menu = await MenuService.getUserMenu({ language: currentUser?.data?.language });
            setMenu(menu);
            setLoaded(currentUser?.data?.language);
        } catch (err) {
            ErrorHandler.handle('load user menu', err);
        }
    };

    const reloadItems = () => {
        setLoaded(undefined);
    };

    useEffect(() => {
        if (loaded === currentUser?.data?.language) return;
        loadItems();
    }, [loaded, currentUser?.data?.language]);

    if (!loaded) {
        return <></>;
    }

    return (
        <>
            <LeftMenu menu={menu} reloadItems={reloadItems} key={loaded}/>
            <BottomMenu menu={menu} />
        </>
    );
}
