enum ProfilePartnershipTypeEnum {
   ScientificCooperation = 'ScientificCooperation',
   TechnicalCooperation = 'TechnicalCooperation',
   LicenseAgreement = 'LicenseAgreement',
   ProductionAgreement = 'ProductionAgreement',
   ServiceAgreement = 'ServiceAgreement',
   FinancialAgreement = 'FinancialAgreement',
   JointVentureAgreement = 'JointVentureAgreement',
   CommercialAgreement = 'CommercialAgreement',
   CommercialAgreementWithAssistance = 'CommercialAgreementWithAssistance',
   SalesServiceAgreement = 'SalesServiceAgreement',
   TradeAgencyAgreement = 'TradeAgencyAgreement',
   FranchiseAgreement = 'FranchiseAgreement',
   OutsourcingAgreement = 'OutsourcingAgreement',
   Subcontract = 'Subcontract',
   TechnicalCooperationAgreement = 'TechnicalCooperationAgreement',
}

export default ProfilePartnershipTypeEnum;

const BusinessOrTechnicalTypeEnum = {
   LicenseAgreement: 'LicenseAgreement',
   ProductionAgreement: 'ProductionAgreement',
   ServiceAgreement: 'ServiceAgreement',
   FinancialAgreement: 'FinancialAgreement',
   CommercialAgreement: 'CommercialAgreement',
}

export const ResearchRequestPartnershipTypeEnum = {
   ScientificCooperation: 'ScientificCooperation'
}

export const TechnicalPartnersipTypeEnum = {
   TechnicalCooperation: 'TechnicalCooperation',
   TechnicalCooperationAgreement: 'TechnicalCooperationAgreement',
   CommercialAgreementWithAssistance: 'CommercialAgreementWithAssistance',
   ...ResearchRequestPartnershipTypeEnum,
   ...BusinessOrTechnicalTypeEnum
}

export const BusinessPartnershipTypeEnum = {
   JointVentureAgreement: 'JointVentureAgreement',
   SalesServiceAgreement: 'SalesServiceAgreement',
   TradeAgencyAgreement: 'TradeAgencyAgreement',
   FranchiseAgreement: 'FranchiseAgreement',
   OutsourcingAgreement: 'OutsourcingAgreement',
   Subcontract: 'Subcontract',
   ...BusinessOrTechnicalTypeEnum
}






