import { Route, Switch } from 'react-router-dom';
import NotFound from '../../home/notFound';
import Import from '../import';

export default function DictionaryRoutes() {
    //const context = useContext(UserContext);
    return (
        <Switch>
            <Route path={DictionaryRoutesPath.Import} component={Import} />
            <Route path="*" component={NotFound} />
        </Switch>
    );
}

export const DictionaryRoutesPath = {
    Prefix: '/dictionary',
    Import: '/dictionary/import'
};
