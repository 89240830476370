import { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import useTranslation from "../../../hooks/translation.hook";
import BaseModal from "../../modal/baseModal";
import ModalHeader from "../../modal/modalHeader";
import { MenuItem } from "../../../eenApi/menu/menuItem";

interface IProps {
    menu: MenuItem[];
}

export default function BottomMenuModal(props: IProps) {
    const { t } = useTranslation();
    const items = props.menu;
    const location = useLocation<any>();
    const history = useHistory();

    function howDeep(): number[] {
        return location.state && location.state.deep !== undefined ? location.state.deep : [0];
    }

    function setCurrentParent(parent: number) {
        var deep = howDeep();
        //deep++;
        deep.push(parent);
        history.push(`${location.pathname}${location.hash}`, { deep: deep });
    }

    function onRequestClose() {
        closeModal(false);
    }

    function closeModal(callback: any) {
        const deep = howDeep();
        if (deep.length > 0) {
            history.go(-deep.length);
        }
        if (callback) {
            callback();
        }
    }

    function getCurrentParent(): number {
        return location.state && location.state.deep !== undefined ? location.state.deep[location.state.deep.length - 1] : 0;
    }

    function clickBack() {
        history.goBack();
    }

    function getMenuItemByParent(parentId: number): MenuItem | undefined {
        const deep = howDeep();
        var item: MenuItem | undefined;
        deep.forEach(id => {
            if (item) {
                item = item.children.find(x => x.id === id);
            } else {
                item = items.find(x => x.id === id);
            }
        });
        return item;
    }

    // function getMenuActionItemByParent(parentId: number): MenuItem | undefined {
    //     const deep = howDeep();
    //     var item: MenuItem | undefined;
    //     deep.forEach(id => {
    //         if (item) {
    //             item = item.children.find(x => x.parentId === id && x.IsAction);
    //         }
    //         else {
    //             item = items.find(x => x.parentId === id && x.IsAction);
    //         }
    //     });
    //     return item;
    // }

    function getMenuItems() {
        const currentParent = getCurrentParent();
        if (currentParent < 1) {
            return items.filter(x => x.isAction !== "Y");
        }
        return getMenuItemByParent(currentParent)?.children.filter(x => x.isAction !== "Y") ?? [];
    }

    function getAction() {
        const currentParent = getCurrentParent();
        if (currentParent < 1) {
            return items.find(x => x.isAction === "Y");
        }
        return getMenuItemByParent(currentParent)?.children.find(x => x.isAction === "Y");
    }

    function getMenuTitle() {
        const currentParent = getCurrentParent();
        if (currentParent < 1) {
            return t("main:menu-title");
        }
        return getMenuItemByParent(currentParent)?.name ?? "";
    }

    function clickNavButton(menuItem: MenuItem) {
        if (menuItem.children.length > 0) {
            //(!menuItem.link || menuItem.link.length < 1) &&
            setCurrentParent(menuItem.id);
            return;
        }

        if (menuItem.link && menuItem.link.length > 0) {
            closeModal(() => {
                setTimeout(() => {
                    history.push(menuItem.link);
                }, 50);
            });
            return;
        }
    }

    const onPressEscape = (e: any) => {
        if (e.keyCode === 27) {
            history.goBack();
        }
    };

    useEffect(() => {
        document.addEventListener("keydown", onPressEscape);

        return () => {
            document.removeEventListener("keydown", onPressEscape);
        };
    }, []);

    const menuItems = getMenuItems();
    const action = getAction();

    //console.log('location', location, `deep: ${howDeep()}`);

    return (
        <BaseModal isOpen={true} onRequestClose={onRequestClose} shouldCloseOnEsc={false}>
            <ModalHeader title={getMenuTitle()} onClickBack={clickBack} onRequestClose={onRequestClose} hideBorder />
            <div className="modal-body py-2 px-0">
                <div className="d-flex flex-column">
                    {menuItems.map((menuItem: any) => (
                        <NavButton key={menuItem.id} menuItem={menuItem} clickNavButton={clickNavButton} />
                    ))}
                </div>
                {action && (
                    <div className="px-2">
                        <NavButton key={action.id} className="btn btn-primary btn-block" menuItem={action} clickNavButton={clickNavButton} />
                    </div>
                )}
            </div>
        </BaseModal>
    );
}

interface IButtonProps {
    menuItem: MenuItem;
    clickNavButton: any;
    className?: string;
}

function NavButton(props: IButtonProps) {
    const menuItem = props.menuItem;

    return (
        <button className={props.className ? props.className : "btn-mobile-menu"} onClick={() => props.clickNavButton(menuItem)}>
            {menuItem.icon && menuItem.icon.length > 0 && <i className={menuItem.icon} style={{ color: menuItem.color && menuItem.color.length > 0 ? menuItem.color : undefined }}></i>}
            <span>{menuItem.name}</span>
        </button>
    );
}
