enum ProfileStageEnum {
   ConceptStage = "ConceptStage",
   AlreadyOnTheMarket = "AlreadyOnTheMarket",
   Research = "Research",
   TrialsCompleted = "TrialsCompleted",
   ApplicationInPreparation = "ApplicationInPreparation",
   ProjectUnderNegotiation = "ProjectUnderNegotiation",
   DemoSample = "DemoSample",
   Prototype = "Prototype",
   AlreadyStarted = "AlreadyStarted"
}

export default ProfileStageEnum