import { Route, Switch } from 'react-router-dom';
import NotFound from '../../home/notFound';
import Rules from '../../../rules';
import ProtectedRoute from '../../../component/common/ProtectedRoute';
import { MemberCountryEdit } from '../edit';
import { MemberCountryList } from '../list';
import MemberCountryDetails from '../details';

export default function MemberCountryRoutes() {
    return (
        <Switch>
            <ProtectedRoute path={MemberCountryRoutesPath.List} exact component={MemberCountryList}
                            rule={Rules.MemberCountry.List} />
            <ProtectedRoute path={MemberCountryRoutesPath.Create} exact component={MemberCountryEdit}
                            rule={Rules.MemberCountry.Add} />
            <ProtectedRoute path={MemberCountryRoutesPath.Edit} exact component={MemberCountryEdit}
                            rule={[Rules.MemberCountry.Edit, Rules.MemberCountry.EditBasic]} />
            <ProtectedRoute path={MemberCountryRoutesPath.View} exact component={MemberCountryDetails}
                            rule={Rules.MemberCountry.View} />
            <Route path='*' component={NotFound} />
        </Switch>
    );
}

const prefix = '/member-country';
export const MemberCountryRoutesPath = {
    Prefix: `${prefix}`,
    Create: `${prefix}/create`,
    Edit: `${prefix}/:id/edit`,
    View: `${prefix}/:id/details`,
    List: `${prefix}/list`,
};