import { FC, useEffect } from 'react';
import { useActions } from '../hooks/useActions';

interface IProps {
    text: string;
    defaultClassName?: string;
    className?: string;
    backLink?: string;
}

const PageTitle: FC<IProps> = (props: IProps) => {
    const { setTitle, setBacklink } = useActions();

    useEffect(() => {
        setTitle(props.text);
        setBacklink(props.backLink);
    }, [props.text]);

    if (!props.text?.length) {
        return <></>;
    }

    return (
        <>
            <h2 className={`${props.defaultClassName ?? 'd-none d-sm-block mb-2'} ${props.className ?? ''}`}>{props.text}</h2>
        </>
    );
};

export default PageTitle;
