import { MDBBtn } from 'mdb-react-ui-kit';
import { FC } from 'react';
import DriveFileDto from '../../../eenApi/drive/dto/drive.file.dto';
import FileSvg from '../../../images/files/xls.svg';
import styled from '@emotion/styled';

interface IProps {
    files: DriveFileDto[];
    filesToShow?: boolean;
    download?: boolean;
    onRemove?: (fileId: string) => void;
}

const StyledImg = styled.img`
  padding: 8px 10px;
  border-radius: 4px;
  width: 32px;
  height: 32px;
  align-items: center;
  background-color: #82C250;
`;

const FileName = styled.div`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  color: #212121;
`;
const FileLength = styled.div`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  color: #929FAA;
`;

const FileList: FC<IProps> = (props) => {
    return (
        <>
            {props.files.map((x) => (
                    props.filesToShow ?
                        <div key={x.id}>
                            {props.download && x.source ?
                                <a key={x.id} className='d-flex align-items-center' download
                                   href={`/${x.source.replace('\\', '/')}`}>
                                    <StyledImg src={`${FileSvg}`} />
                                    <div className='ps-2'>
                                        <FileName>{x.name}</FileName>
                                        <FileLength>{(x.contentLength! / 1024 / 1024).toFixed(2)} Mb</FileLength>
                                    </div>
                                </a>
                                :
                                <div key={x.id} className='d-flex align-items-center'>
                                    <StyledImg src={`${FileSvg}`} />
                                    <div className='ps-2'>
                                        <FileName>{x.name}</FileName>
                                        <FileLength>{(x.contentLength! / 1024 / 1024).toFixed(2)} Mb</FileLength>
                                    </div>
                                </div>}
                        </div>

                        :
                        <div key={x.id} className='d-flex align-items-center'>
                            <div className='flex-grow-1'>{x.name}</div>
                            {props.onRemove && (
                                <MDBBtn className='overflow-visible ms-2' type='button' color='danger' outline
                                        onClick={() => props.onRemove!(x.id)}>x</MDBBtn>
                            )}
                        </div>
            ))}
        </>
    );
};

export default FileList;
