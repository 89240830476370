import {useMemo} from "react";
import {SelectValue} from "../component/select/SelectValue";
import useTranslation from "./translation.hook";

export const useOptions = <T> (src: Record<string, T>, prefix: string, local = false): SelectValue[] => {
   const {t, i18n} = useTranslation(local);
   return useMemo(() =>
      Object.entries(src)
         .map(([key, value]) => ({ value: String(value), label: t(`${prefix}${key}`) })),
      [i18n.language]);
}