import MailingPrefTypeEnum from '../mailing/enum/mailing.pref.type.enum';

export default class MailingConfiguraton {
    type?: MailingPrefTypeEnum = MailingPrefTypeEnum.Default;
    apiKey?: string;
    user?: string;
    email?: string;
    sender?: string;

    constructor(data: MailingConfiguraton | undefined) {
        Object.entries(data ?? {}).forEach(([key, val]) => {
            if (this.hasOwnProperty(key)) {
                this[key as keyof MailingConfiguraton] = val as never;
            }
        });
    }
}
