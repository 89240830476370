import React, { FC } from "react";
import { MDBInput } from "mdb-react-ui-kit";

interface IProps {
    value?: string
    onChange: (value: string) => Promise<void>
    title?: string,
    required?: boolean
}

const FieldInt: FC<IProps> = (props) => {

    return (
        <MDBInput type="number" size="lg" value={props.value ?? ''} onChange={(e: React.ChangeEvent<HTMLInputElement>) => props.onChange(e.target.value)} required={props.required} label={props.title} />
    );
}

export default FieldInt;
