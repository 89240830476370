import SupportSubjectDto from './support.subject.dto';
import { User } from '../../../user/user';

export default class SupportSubjectEditDto {
    id: number = 0;
    name: string = '';
    responsableUser: User | undefined = undefined;
    responsableUserId: number = 0;
    isMessageComplaint: boolean = false;

    constructor(entity?: SupportSubjectDto | undefined) {
        Object.entries(entity ?? {}).forEach(([key, val]) => {
            if (this.hasOwnProperty(key)) {
                this[key as keyof SupportSubjectEditDto] = val as never;
            }
        });
        this.responsableUserId = this.responsableUser?.id ?? 0;
    }
}