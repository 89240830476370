import { FC, useContext, useMemo, useState } from "react";
import useTranslation from "../../../hooks/translation.hook";
import { Link } from "react-router-dom";
import PageTitle from '../../../component/pageTitle';
import Filter from '../../../component/filter';
import NotificationTable from './table';
import MainContainer from "../../../component/mainContainer";
import { UserContext } from "../../../context/user-context";
import Rules from "../../../rules";
import { NotificationRoutesPath } from "../routes";
import FilterItemText from "../../../component/filter/types/filterItem/items/text";
import FilterItemSelect from "../../../component/filter/types/filterItem/items/select";

const NotificationList: FC = () => {
    const context = useContext(UserContext);
    const { t } = useTranslation();
    const [filterValues, setFilterValues] = useState<{ [key: string]: any }>();

    const filterItems = useMemo(() => [
        new FilterItemText('name', t('main:field-name'), { isTyping: 'Y' }),
        new FilterItemSelect('active', t('main:field-active'), {
          options: [{ value: 'true', label: t('main:yes')}, { value: 'false', label: t('main:no')}],
          multiple: false
        })
    ], []);

    return (
        <MainContainer>
            <PageTitle text={t('notification:menu-title')} backLink="/" />
            <div className="d-xl-flex mb-1 mb-xl-3">
                <div className="app-filter-actions mb-2 mb-xl-0">
                    {context.checkAccess(Rules.Notification.Edit) &&
                        <Link className="btn btn-success me-2" to={NotificationRoutesPath.Create}>{t('main:btn-add')}</Link>
                    }
                </div>
                <Filter id="filter-notifications" items={filterItems} onFind={setFilterValues} />
            </div>
            <NotificationTable filterValues={filterValues} />
        </MainContainer>
    );
}

export default NotificationList;
