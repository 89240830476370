import DriveFileDto from '../../drive/dto/drive.file.dto';
import LanguageEnum from '../../../enums/language.enum';
import { DictionaryEntity } from '../../../models/dictionaryEntity';
import { SpecialistStatusEnum } from '../enum/specialis.status.enum';
import { UserRoleData } from "../../user/userRoleData";

export class SpecialistDto {
    id?: number;
    // "Фото" (необязательное), тип "Файл" (jpeg, png), не более 4 Мб;
    photoId?: string;
    photo?: DriveFileDto;
    // "Имя" (обязательное), тип "Строка", не более 50 символов;
    name = '';
    // "Год рождения" (обязательное), тип "Дата";
    dateOfBirth = '';
    // "Гражданство" (обязательное), тип "Привязка к элементу сущности "Страна-участница".
    // Если добавление происходит представителем какой-то страны-участницы, то поле должно устанавливаться автоматически
    // (исходя из страны представителя из лк) без возможности его редактирования представителем;
    memberCountryId: string|null = null;
    memberCountry?: DictionaryEntity
    // "Страна проживания" (необязательное), тип "Привязка к элементу справочника со странами";
    residenceCountryId = '';
    residenceCountry?: DictionaryEntity
    // "Город проживания" (необязательное), тип "Строка", не более 50 символов;
    city = '';
    // "Образование" (необязательное), тип "Строка", не более 100 символов;
    education = '';
    // "Владение языками" (необязательное, множественное), тип "Привязка к элементу справочника владение языками";
    languageSkills: string[] = [];
    languageSkillsEntities?: DictionaryEntity[]
    // "Специальность" (необязательное), тип "Строка", не более 100 символов;
    speciality = '';
    // "Краткая биография" (обязательно), тип "Текстовая область", не более 255 символов;
    biography = '';
    // "Специализация" (необязательное), тип "Строка", не более 100 символов;
    specialization = '';
    // "Опыт работы" (необязательное), тип "Текстовая область", не более 255 символов;
    workExperience = '';
    // "Интересующая область деятельности" (обязательное), тип "Строка", не более 100 символов.
    areaOfInterest = '';

    // "Email" (обязательное);
    email = '';
    // "Телефон" (необязательное);
    phone?: string
    // "LinkedIn" (необязательное).
    linkedIn?: string;

    language: LanguageEnum;
    status?: SpecialistStatusEnum;
    statusHistory: {
        status: SpecialistStatusEnum,
        created: string,
        user: {
            id: number,
            fullName?: string,
            email: string,
        }
    }[] = [];
    createdBy?: string;

    constructor(entity: SpecialistDto|undefined, language: LanguageEnum, userRules: UserRoleData[]|undefined) {
        Object.entries(entity ?? {}).forEach(([key, val]) => {
            if (this.hasOwnProperty(key)) {
                this[key as keyof SpecialistDto] = val as never
            }
        });
        this.language = entity?.language ?? language;

        const userCountry = userRules?.find(x => !!x.memberCountryId)?.memberCountryId;
        if (!entity && userCountry !== undefined) {
            this.memberCountryId = String(userCountry);
        } else {
            this.memberCountryId = entity?.memberCountry?.id?.toString() ?? null;
        }
        this.residenceCountryId = entity?.residenceCountry?.id ?? ''
        this.photoId = entity?.photo?.id
    }
}