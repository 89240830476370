export default {
   List:                  "profile.list", // Просмотр раздела
   ListMy:                "profile.listmy", // Просмотр раздела "Мои профили"
   Add:                   "profile.add", // Создание профиля
   Edit:                  "profile.edit", // Редактирование профиля
   EditOwn:               "profile.editown", // Редактирование профиля (собственные элементы)
   Delete:                "profile.delete", // Удаление профиля
   DeleteOwn:             "profile.deleteown", // Удаление профиля (собственные элементы)
   ViewBasic:             "profile.viewbasic", // Просмотр детальной страницы профиля. Вкладка "Основные данные"
   ViewBasicOwn:          "profile.viewbasicown", // Просмотр детальной страницы профиля. Вкладка "Основные данные" (собственные элементы)
   ViewContact:           "profile.viewcontact", // Просмотр детальной страницы профиля. Вкладка "Контактная информация"
   ViewContactOwn:        "profile.viewcontactown", // Просмотр детальной страницы профиля. Вкладка "Контактная информация" (собственные элементы)
   ViewValidation:        "profile.viewvalidation", // Просмотр детальной страницы профиля. Вкладка "Журнал валидации"
   ViewValidationOwn:     "profile.viewvalidationown", // Просмотр детальной страницы профиля. Вкладка "Журнал валидации" (собственные элементы)
   Status:                "profile.status",        // Запрос на изменение статуса профиля
   Validate:              "profile.validation",     // Добавление комментариев к полям(при запросе на валидацию)
   ListValidation:        "profile.listvalidation", // Просмотр раздела Запросы на валидацию.
   ListDeleted:           "profile.listdeleted",    // Просмотр раздела "Удалённые профили"
   RestoreDeleted:        "profile.restoredeleted", // Восстановление профилей из раздела "Удалённые профили"
   InterestAdd:           "profile.interestadd",   // Создание выражений интереса
   InterestEdit:          "profile.interestedit",   // Редактирование выражений интереса
   InterestEditOwn:       "profile.interesteditown",   // Редактирование выражений интереса (собственные элементы)
   InterestView:          "profile.interestview",   // Просмотр выражений интереса
   InterestDelete:        "profile.interestdelete", // Удаление выражения интереса
   InterestDeleteOwn:     "profile.interestdeleteown", // Удаление выражения интереса (собственные элементы)
   InterestStatus:        "profile.intereststatus", // Изменение статуса выражения интереса
   InterestMessage:       "profile.interestmessage", // Добавление сообщения к выражению интереса
   InterestList:          "profile.interestlist",   // Просмотр раздела "Выражения интереса"
   InterestListMy:          "profile.interestlistmy",   // Просмотр раздела "Выражения интереса"
   InterestListArchive:   "profile.interestlistarchive", // Просмотр раздела "Архив выражений интереса"
   AppealAdd:             "profile.appealadd",      // Добавление обращений для профилей
   AppealDelete:          "profile.appealdelete",   // Удаление обращений у профилей
   ListForeign:           "profile.foreignview",    // Просмотр профилей из других стран
   ForeignImport:         "profile.foreignimport",  // Импорт профилей из других стран
   AppealAddForeign:      "profile.appealaddforeign", // Добавление обращений для иностранных профилей
   AppealDeleteForeign:   "profile.appealdeleteforeign", // Удаление обращений у иностранных профилей
   EditActive:            "profile.editactive",     // Изменение активных профилей
   StatusActive:          "profile.statusactiveactive",   // Изменение статуса профиля на Активен
   StatusEen:             "profile.statuseens",      // Отправка профиля на сайт EEN
   AddBookmark:           "profile.addbookmark",    // Добавление профилей в закладки
   AddNote:               "profile.addnote",        // Добавление заметок к профилям
   ViewRecommended:       "profile.viewrecommended", // Просмотр блока «Рекомендуемые профили»
   ViewPopular:           "profile.viewpopular",    // Просмотр блока «Популярные профили»
   ViewAttendance:        "profile.viewattendance", // Просмотр вкладки "Посещаемость"
}