import IControlProps from "../control.props";
import React, {ChangeEvent, useContext, useEffect, useState} from "react";
import {StyledInputLabel} from "../index";
import {useValidation} from "../../../../hooks/UseValidation";
import InputContainer from "../../../inputContainer";
import ValidationContext from "../../../../hooks/UseValidation/validation.context";
import {SelectValue} from "../../../select/SelectValue";
import {FormControl, FormControlLabel, FormHelperText, Radio, RadioGroup} from "@mui/material";
import styles from "./index.module.css";
import useTranslation from "../../../../hooks/translation.hook";

interface IProps extends IControlProps {
   items: SelectValue[]
}

export default function RadioControl(props: IProps) {
   const {t} = useTranslation();
   const validate = useValidation(props.validators)
   const {validateOn} = useContext(ValidationContext)
   const [errorText, setErrorText] = useState<string>();

   useEffect(() => {
      setErrorText(props.error || validate(props.value, props.tab ?? 'main'))
   }, [props.value, props.error, validateOn])

   return (
      <InputContainer>
         <FormControl  required={props.required} error={!!errorText} fullWidth size="small">
            <StyledInputLabel id={styles.radioLabel}>{t(props.labelKey)}</StyledInputLabel>
            <RadioGroup
               onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  props.onChange(event.target.value);
               }}>
               {props.items.map((item, idx) =>
                  <FormControlLabel key={idx} value={item.value} control={<Radio />} label={item.label} checked={item.value === props.value} />)}
            </RadioGroup>
            <FormHelperText>{errorText}</FormHelperText>
         </FormControl>
      </InputContainer>
   )
}