import TabPanel from '../../tabs/tabPanel';
import { AutocompleteControl, InputControl } from '../../common/ui';
import IEditPanelProps from '../edit.panel.props';
import CheckboxControl from '../../common/ui/CheckboxControl';
import { RoleService } from '../../../eenApi/role/service';
import useTranslation from '../../../hooks/translation.hook';
import { DictionaryType } from '../../../models/dictionaryType';
import { Length } from '../../../hooks/UseValidation/validators';

export default function RecepientEditTab({ data, entity, updateData, ...rest }: IEditPanelProps) {
    const { t } = useTranslation();

    return (
        <TabPanel {...rest}>
            {
                <AutocompleteControl
                    labelKey={t('notification:field-roles')}
                    multiple
                    dictionary={DictionaryType.Role}
                    onChange={(value) => {
                        updateData('rolesIds', value?.map((item) => item.value) ?? []);
                    }}
                    options={(term, language, skip) => RoleService.selectLoadOptions(term, language, skip)}
                    value={data.rolesIds}
                />
            }
            <CheckboxControl
                value={data.isAllClients}
                labelKey={'mailing:mailings.fields.isAllClients'}
                onChange={(data) => updateData('isAllClients', data)}
                tab={'recepient'}
            />

            {/* Временно скрыты
            <CheckboxControl
                value={data.isAllEenClients}
                labelKey={'mailing:mailings.fields.isAllEenClients'}
                onChange={(data) => updateData('isAllEenClients', data)}
                tab={'recepient'}
            />
            <CheckboxControl
                value={data.isEventInterestedClients}
                labelKey={'mailing:mailings.fields.isEventInterestedClients'}
                onChange={(data) => updateData('isEventInterestedClients', data)}
                tab={'recepient'}
            /> */}
            <InputControl
                labelKey={'mailing:mailings.fields.additionalEmails'}
                onChange={(data) => updateData('additionalEmails', data)}
                value={data.additionalEmails}
                tab={'recepient'}
                multiline
                validators={[Length(400)]}
            />
        </TabPanel>
    );
}
