import ModelValidator from '../model.validator';
import SupportEditDto from '../../../eenApi/support/supports/dto/support.edit.dto';

class EditValidator extends ModelValidator<SupportEditDto, SupportEditDto> {
    validate(dto: SupportEditDto, tabs: string[]) {
        const erroredTabs: string[] = [];

        this.checkRequired(dto.title, erroredTabs, 'main');
        this.checkRequired(dto.supportSubjectId.toString(), erroredTabs, 'main');
        this.checkRequired(dto.status, erroredTabs, 'main');
        this.checkRequired(dto.criticality, erroredTabs, 'main');

        return erroredTabs;
    }
}

export default new EditValidator();
