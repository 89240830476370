import { MDBBtn } from 'mdb-react-ui-kit';
import { FC, useContext, useMemo } from 'react';
import DefaultBlock from '../../component/defaultBlock';
import MainContainer from '../../component/mainContainer';
import { UserContext } from '../../context/user-context';
import { useActions } from '../../hooks/useActions';
import { DictionaryRoutesPath } from '../dictionary/routes';
import { ExternalProviderRoutesPath } from '../externalProvider/routes';
import AdminPanel from './component/adminPanel';
import { SettingsRoutesPath } from './routes';
import useTranslation from '../../hooks/translation.hook';
import Rules from '../../rules';

const SettingsMain: FC = () => {
    const context = useContext(UserContext);
    const { socketSendMessage } = useActions();
    const { t } = useTranslation();

    const panels = useMemo(() => {
        const result: { link: string; name: string; icon: string }[] = [];

        if (context.checkAccess(Rules.Configuration.EditMailing)) {
            result.push({
                link: SettingsRoutesPath.Mailing,
                name: 'settings:menu-title-delivery',
                icon: 'mx-auto far fa-list-alt fa-5x',
            });
        }
        //todo fix after add new rules
        if (context.checkAccess(Rules.Configuration.EditMailing)) {
            result.push({
                link: SettingsRoutesPath.Notification,
                name: 'settings:menu-title-notification',
                icon: 'mx-auto far fa-list-alt fa-5x',
            });
        }
        //todo fix after add new rules
        if (context.checkAccess(Rules.Configuration.EditMailing)) {
            result.push({
                link: ExternalProviderRoutesPath.List,
                name: 'extprovider:menu-title',
                icon: 'mx-auto far fa-list-alt fa-5x',
            });
        }

        result.push({
            link: DictionaryRoutesPath.Import,
            name: 'settings:menu-title-import',
            icon: 'mx-auto far fa-list-alt fa-5x',
        });

        return result;
    }, []);

    const onClickTest = () => {
        socketSendMessage('Hi!');
    };

    return (
        <MainContainer>
            <AdminPanel panels={panels} />
            <DefaultBlock>
                <MDBBtn onClick={onClickTest}>{t('settings:btn-test-socket')}</MDBBtn>
            </DefaultBlock>
        </MainContainer>
    );
};

export default SettingsMain;
