import { Client } from '../../eenApi/client/client';
import { useContext } from 'react';
import { UserContext, UserContextProps } from '../../context/user-context';
import Rules from '../../rules';

const checkBasic = (client: Client | undefined, { checkAccess, checkAccessUser }: UserContextProps) => {
    return (
        client !== undefined &&
        (checkAccessUser(client?.createdBy, Rules.Client, 'ViewContacts') ||
            checkAccess(Rules.Client.ViewBasic, null, client?.memberCountry?.id) ||
            checkAccess(Rules.Client.Edit, null, client?.memberCountry?.id))
    );
};

const checkContacts = (client: Client | undefined, { checkAccess, checkAccessUser }: UserContextProps) => {
    return (
        client !== undefined &&
        (checkAccessUser(client?.createdBy, Rules.Client, 'ViewContacts') ||
            checkAccess(Rules.Client.ViewContacts, null, client?.memberCountry?.id) ||
            checkAccess(Rules.Client.Edit, null, client?.memberCountry?.id))
    );
};

export function useCanViewClient() {
    const context = useContext(UserContext);
    return (client: Client | undefined) => checkBasic(client, context) || checkContacts(client, context);
}

export function useCanViewBasic() {
    const context = useContext(UserContext);
    return (client: Client | undefined) => checkBasic(client, context);
}

export function useCanViewContacts() {
    const context = useContext(UserContext);
    return (client: Client | undefined) => checkContacts(client, context);
}
