import { FC, useEffect, useMemo, useState } from 'react';
import useTranslation from '../../../hooks/translation.hook';
import { useParams } from 'react-router-dom';
import BottomActions from '../../../component/actions/bottomActions';
import BottomActionsContainer from '../../../component/actions/bottomActionsContainer';
import DefaultBlock from '../../../component/defaultBlock';
import ViewField from '../../../component/fields/viewField';
import Loader from '../../../component/loader';
import MainContainer from '../../../component/mainContainer';
import PageTitle from '../../../component/pageTitle';
import { ExternalProvider } from '../../../eenApi/externalProvider/externalProvider';
import { ExternalProviderService } from '../../../eenApi/externalProvider/service';
import { ActionItem } from '../../../models/actionItem';
import { ErrorHandler } from '../../../service/errorHandler';
import { ExternalProviderRoutesPath } from '../routes';
import { ExternalProviderType } from '../types/externalProviderType';
import UnioneHooks from '../component/unioneHooks';
import { useCurrentUser } from '../../../hooks/useCurrentUser';

const ExternalProviderView: FC = () => {
    const { id } = useParams<{ id?: string }>();
    const { t } = useTranslation();
    const [entity, setEntity] = useState<ExternalProvider>();
    const currentUser = useCurrentUser();
    const entityId = useMemo(() => {
        return parseInt(id || '') || 0;
    }, [id]);

    const actions = useMemo<ActionItem[]>(() => {
        const items: ActionItem[] = [];
        items.push({
            name: t('main:btn-edit'),
            link: ExternalProviderService.getEditLink(entityId),
            icon: 'fas fa-pen',
        });
        items.push({ name: t('main:btn-delete'), icon: 'far fa-trash-alt' });
        return items;
    }, []);

    useEffect(() => {
        refreshData();
    }, [id]);

    const refreshData = async () => {
        const entityId = parseInt(id || '') || 0;
        if (entityId < 1 || !currentUser.data?.language) {
            return;
        }

        try {
            const result = await ExternalProviderService.get(entityId, currentUser.data?.language);
            if (result) {
                setEntity({
                    ...result,
                });
            }
        } catch (err) {
            ErrorHandler.handle('extproviderView refreshData', err);
        }
    };

    return (
        <MainContainer>
            <PageTitle text={t('extprovider:view-title')} backLink={ExternalProviderRoutesPath.List} />
            <DefaultBlock>
                {!entity && <Loader />}
                {!!entity && (
                    <>
                        <ViewField title="main:field-name">{entity.name}</ViewField>
                        <ViewField title="main:field-type">{entity.type}</ViewField>
                        {entity.type === ExternalProviderType.Unione && <UnioneHooks provider={entity} />}
                    </>
                )}
            </DefaultBlock>
            <BottomActionsContainer>
                <BottomActions actions={actions} />
            </BottomActionsContainer>
        </MainContainer>
    );
};

export default ExternalProviderView;
