import { ObjectType } from "../../enums/objectType";

export class MenuTemEditDto {
  id = 0;
  name = '';
  active = true;
  isAction = false;
  color = '#BBBBBB';
  helpHintId = '';
  icon = '';
  link = '';
  rule = '';
  objectTypes: ObjectType[] = [];

  constructor(entity?: Partial<MenuTemEditDto>) {
    if (entity) {
      Object.assign(this, entity)
    }
  }
}