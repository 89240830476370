export default {
    Add: 'client.add',
    List: 'client.list',
    Edit: 'client.edit',
    Delete: 'client.delete',
    AuditList: 'audit.list',
    AuditAdd: 'audit.add',
    AuditEdit: 'audit.edit',
    AuditAgreement: 'audit.delete',
    ViewBasic: 'client.viewbasic',
    ViewContacts: 'client.viewcontacts',
    ViewBasicOwn: 'client.viewbasicown',
    ViewContactsOwn: 'client.viewcontactsown',
}