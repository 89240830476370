import TabPanel from '../../tabs/tabPanel';
import React, { useContext, useState } from 'react';
import { AutocompleteControl, InputControl } from '../../common/ui';
import { Length, NotEmpty } from '../../../hooks/UseValidation/validators';
import IEditPanelProps from '../edit.panel.props';
import InputContainer from '../../inputContainer';
import ViewField from '../../fields/viewField';
import FileList from '../../fields/fileList';
import FileUploader from '../../fileUploader';
import { DictionaryType } from '../../../models/dictionaryType';
import DictionaryControl from '../../common/ui/DictionaryControl';
import { FormHelperText } from '@mui/material';
import ValidationContext from '../../../hooks/UseValidation/validation.context';
import useTranslation from '../../../hooks/translation.hook';
import { CenterService } from '../../../eenApi/center/service';
import { useCurrentUser } from "../../../hooks/useCurrentUser";


export default function MainEditTab({ entity, data, updateData, ...rest }: IEditPanelProps) {

    const [file, setFile] = useState(entity?.logo ? [entity.logo] : []);
    const onUploaded = (file: { id: string; name: string }) => {
      setFile([file]);
      updateData('logoFileId', file.id)
    };
    const onRemoveFile = () => {
      setFile([]);
      updateData('logoFileId', null)
    };
    const { validateOn } = useContext(ValidationContext)
    const { t } = useTranslation();
    const { data: currentUser } = useCurrentUser();

    return (
        <TabPanel {...rest}>
            <InputContainer>
                <ViewField title='innovationproject:field.logoFileId' required>
                    <FileList files={file} onRemove={onRemoveFile} />
                </ViewField>
                {file.length < 1 && <FileUploader onFileUploaded={onUploaded} />}
                {validateOn && !data.logoFileId && <FormHelperText error>{t('main:error.required')}</FormHelperText>}
            </InputContainer>
            <InputControl labelKey={'innovationproject:field.name'}
                          onChange={value => updateData('name', value)}
                          value={data.name} multiline
                          required
                          validators={[NotEmpty, Length(100)]} tab='main'/>
            <InputControl labelKey={'innovationproject:field.webLink'}
                          onChange={value => updateData('webLink', value)}
                          value={data.webLink}
                          validators={[Length(255)]} tab='main'/>
            <DictionaryControl value={data.memberCountryId?.toString() ?? null} disabled={!!entity?.id || !currentUser?.rules?.find(x => !x.memberCountryId)}
                               onChange={value => updateData('memberCountryId', value)}
                               dictionary={DictionaryType.MemberCountry}
                               language={data.language!}
                               labelKey="innovationproject:field.memberCountryId"
                               required validators={[NotEmpty]} minInput={0}/>
            <AutocompleteControl labelKey='innovationproject:field.centerId'
                                 dictionary={DictionaryType.Center}
                                 onChange={(value) => {
                                     updateData('centerId', value?.value || null)
                                 }}
                                 options={(term, language, skip) => CenterService.selectLoadOptions(term, language, skip, data.memberCountryId)}
                                 value={data.centerId}
                                 disabled={!data.memberCountryId || !!entity?.id}
                                 required
                                 validators={[NotEmpty]}
            />
            <InputControl labelKey={'innovationproject:field.description'}
                          onChange={value => updateData('description', value)}
                          value={data.description} multiline
                          required
                          validators={[NotEmpty, Length(255)]} tab='main'/>
        </TabPanel>
    );
}