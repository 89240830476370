import TabPanel from '../../tabs/tabPanel';
import { Field } from '../../common/layout';
import React, { useContext } from "react";
import useTranslation from '../../../hooks/translation.hook';
import IValidatePanelProps from '../validate.panel.props';
import { ObjectType } from '../../../enums/objectType';
import { UserContext } from "../../../context/user-context";
import NewsStatusEnum from "../../../eenApi/news/enum/news.status.enum";
import Rules from "../../../rules";

export default function ValidateViewTab({ data, ...rest }: IValidatePanelProps) {

    const { t } = useTranslation();
  const { checkAccess } = useContext(UserContext);

    return (
        <TabPanel {...rest}>
            <Field labelKey='news:field.title'
                   html={true}
                   value={data.title}
                   isValidate
                   objectId={data.id}
                   objectType={ObjectType.News}
                   fieldName={'title'}
                   editable={data.status === NewsStatusEnum.Validation && checkAccess(Rules.News.Validate)}
            />
            <Field labelKey='news:field.status'
                   html={true}
                   value={t(`news:status.${data.status}`)}
            />
        </TabPanel>
    );
}