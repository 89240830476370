import LanguageEnum from '../../../enums/language.enum';
import classes from './index.module.css';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';


interface IProps {
    link: string;
}

export default function LangSwitch(props: IProps) {
    const history = useHistory();

    const onClick = () => {
        history.push(props.link);
    };

    return (
        <button id={`tab-add-lang`}
                aria-controls={`tabpanel-add-lang`}
                className={classNames('btn', classes.langControl, classes.active)}
                onClick={onClick}>
            <img src={'/lang/addLang.svg'} alt='' />
        </button>
    );
}