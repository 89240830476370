import { CenterDto } from '../../../eenApi/center/dto/center.dto';
import useTranslation from '../../../hooks/translation.hook';
import { Field } from '../../common/layout';
import LinkField from '../../common/layout/LinkField';
import ViewTextEditorField from '../../fields/viewTextEditorField';
import { InfoTab } from '../../common/layout';
import React from 'react';

interface IProps {
    entity: CenterDto,
    activeId: string
}
export function MainDetailsTab({ entity, activeId }: IProps) {
    const { t } = useTranslation();
    return (
        <InfoTab activeId={activeId} id='main' entity={entity} namespace='center'
                 fields={[
                     {
                         render: (entity) => entity.logoFile?.source ?
                             <Field key="logoFileId" labelKey='center:field.logoFileId' optional>
                                 <img src={`/${entity.logoFile.source}`} style={{ maxHeight: '200px' }}
                                      alt={t('center:field.logoFileId')} />
                             </Field> : null,
                     },
                     {
                         name: 'type',
                         value: entity => t(`center:type.${entity.type}`)
                     },
                     'active', 'alias', 'fullName', 'name', 'acronym', 'postAddress', 'actualAddress',
                     {
                         name: 'memberCountryId',
                         value: entity => entity.memberCountry?.name ?? ''
                     },
                     {
                         name: 'region',
                         value: entity => entity.region?.name ?? ''
                     },
                     {
                         name: 'federalDistrict',
                         value: entity => entity.federalDistrict?.name ?? ''
                     },
                     'city',
                     {
                         render: entity => <LinkField key="web" labelKey="center:field.web" optional>{entity.web}</LinkField>
                     },
                     'directorName', 'phone', 'email',
                     {
                         render: entity => <ViewTextEditorField key="description" title="center:field.description" text={entity.description} />
                     }
                 ]} />
    )
}