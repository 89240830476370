import { FC } from 'react';
import { Button, ButtonGroup } from '@mui/material';
import LanguageEnum, { isSupportedLanguage } from '../../../../../enums/language.enum';
import { AccountService } from '../../../../../eenApi/account/service';
import { ErrorHandler } from '../../../../../service/errorHandler';
import { useCurrentUser } from '../../../../../hooks/useCurrentUser';

const LanguageSelector: FC = () => {
    const currentUser = useCurrentUser();

    const onChangeLanguage = async (selected: string) => {
        try {
            if (currentUser.isLoading) return;
            if (selected === currentUser.data?.language) return;
            //window.location.reload();
            if (!isSupportedLanguage(selected)) return;
            await AccountService.selectLanguage(currentUser.data?.user ?? undefined, selected);
            currentUser.refetch();
        } catch (err) {
            ErrorHandler.handle('on change language', err);
        }
    };

    return (
        <ButtonGroup variant="text">
            {Object.values(LanguageEnum).map((x) => (
                <Button
                    key={x}
                    onClick={() => onChangeLanguage(x)}
                    sx={x === currentUser.data?.language ? { opacity: '0.6', cursor: 'default!important' } : {}}
                >
                    {x.toLocaleUpperCase()}
                </Button>
            ))}
        </ButtonGroup>
    );
};

export default LanguageSelector;
