import {DriveEntity} from "../../../../eenApi/drive/driveEntity";
import FileItem from "../FileItem";
import DriveFileDto from '../../../../eenApi/drive/dto/drive.file.dto';

interface IProps {
   files: DriveEntity|DriveEntity[]|DriveFileDto|DriveFileDto[]|undefined
}

export default function FileList({ files }: IProps) {
   const items = Array.isArray(files) ? files : [files];
   return (<div className="d-flex flex-column">
      {items.map((item, idx) => <FileItem key={idx} file={item}/>)}
   </div>)
}