import MailingDto from '../../../eenApi/mailing/dto/mailing.dto';
import ModelValidator from '../model.validator';

class EditValidator extends ModelValidator<MailingDto, MailingDto> {
    validate(dto: MailingDto, tabs: string[], entity: MailingDto) {
        const erroredTabs: string[] = [];

        this.checkRequired(dto.status, erroredTabs, 'mailing');
        this.checkRequired(dto.type, erroredTabs, 'mailing');
        this.checkRequired(dto.text, erroredTabs, 'mailing');
        this.checkLength(dto.text, erroredTabs, 4000, 'mailing');
        this.checkRequired(dto.subject, erroredTabs, 'mailing');
        this.checkLength(dto.subject, erroredTabs, 300, 'mailing');

        this.checkLength(dto.additionalEmails ?? '', erroredTabs, 400, 'recipient');

        return erroredTabs;
    }
}
const MailingEditValidator = new EditValidator();
export default MailingEditValidator;
