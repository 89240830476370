import TabPanel, { ITabPanelProps } from '../../tabs/tabPanel';
import { Field } from '../../common/layout';
import React from 'react';
import { NewsEntity } from '../../../eenApi/news/news';
import useTranslation from '../../../hooks/translation.hook';
import styled from '@emotion/styled';

const StyledImg = styled.img`
  max-width: 30%;
`;

interface IProps extends ITabPanelProps {
    data: NewsEntity;
}

export default function ViewTab({ data, ...rest }: IProps) {

    const { t } = useTranslation();

    return (
        <TabPanel {...rest}>
            {data.detailFile &&
                <Field labelKey='news:detail-picture' optional>
                    <StyledImg src={`/${data.detailFile!.source!.replace('\\', '/')}`} alt={t('news:detail-picture')} />
                </Field>
            }
            <Field labelKey='news:detail-description' optional
                   html={true}
                   value={data.detailDescription}
            />
        </TabPanel>
    );
}