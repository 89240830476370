import React, { FC, useContext, useEffect, useState } from 'react';
import { MDBBtn } from 'mdb-react-ui-kit';
import { useParams } from 'react-router';
import useTranslation from '../../../hooks/translation.hook';
import { UserContext } from '../../../context/user-context';
import UserRoles from '../component/userRoles';
import MainContainer from '../../../component/mainContainer';
import { ErrorHandler } from '../../../service/errorHandler';
import Rules from '../../../rules';
import PageTitle from '../../../component/pageTitle';
import { User } from '../../../eenApi/user/user';
import { UserService } from '../../../eenApi/user/service';
import { UserRoutesPath } from '../routes';
import NotificationSubscriptions from '../component/notificationSubscriptions';
import { useCurrentUser } from '../../../hooks/useCurrentUser';

const UserEdit: FC = () => {
    const context = useContext(UserContext);
    const currentUser = useCurrentUser();
    const { id } = useParams<{ id?: string }>();
    const { t } = useTranslation();
    const [entity, setEntity] = useState<User>();

    useEffect(() => {
        refreshData();
    }, [id]);

    const refreshData = async () => {
        const userId = parseInt(id ?? '') || 0;
        if (userId < 1) return;

        try {
            console.log('user', currentUser.data?.user);
            const result = await UserService.get(userId);
            setEntity({ ...result });
        } catch (err) {
            ErrorHandler.handle('get user profile', err);
        }
    };

    const onSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
    };

    return (
        <MainContainer>
            <PageTitle text={entity ? entity.email : ''} backLink={UserRoutesPath.List} />
            <form onSubmit={onSubmit}>
                {JSON.stringify(entity)}
                <div>
                    <MDBBtn type="submit">{t('main:btn-save')}</MDBBtn>
                </div>
            </form>
            {entity && (entity.id === currentUser.data?.user?.id || context.checkAccess(Rules.Notification.Subscribe)) && (
                <div>
                    <NotificationSubscriptions userId={entity.id} />
                </div>
            )}
            {entity && (entity.id === currentUser.data?.user?.id || context.checkAccess(Rules.User.AddRole)) && (
                <div>
                    <UserRoles userId={entity.id} />
                </div>
            )}
        </MainContainer>
    );
};

export default UserEdit;
