import TabPanel from '../../../tabs/tabPanel';
import { InputControl, SelectControl } from '../../../common/ui';
import { NotEmpty } from '../../../../hooks/UseValidation/validators';
import IEditPanelProps from '../edit.panel.props';
import { UserService } from '../../../../eenApi/user/service';
import CheckboxControl from '../../../common/ui/CheckboxControl';
import useTranslation from '../../../../hooks/translation.hook';

import { useQuery } from 'react-query';

export default function MainEditTab({ data, updateData, validate, ...rest }: IEditPanelProps) {
    const { t } = useTranslation();

    const { data: userOptions } = useQuery(['support/subjects'], () => UserService.selectLoadOptions(''));

    return (
        <TabPanel {...rest}>
            <InputControl
                labelKey={'support:fields.name'}
                onChange={(value) => updateData('name', value)}
                value={data.name}
                required
                validators={[NotEmpty]}
                tab="main"
            />
            <SelectControl
                labelKey="support:fields.fullName"
                value={data.responsableUserId ? data.responsableUserId.toString() : null}
                onChange={(value) => {
                    updateData('responsableUserId', parseInt(value));
                }}
                options={userOptions ?? []}
                required
                validators={[NotEmpty]}
            />
            <CheckboxControl
                labelKey={'support:fields.isMessageComplaint'}
                onChange={(value) => updateData('isMessageComplaint', value)}
                value={data.isMessageComplaint}
            />
        </TabPanel>
    );
}
