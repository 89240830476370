import React, { useContext, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { InnovationProjectDto } from '../../../eenApi/innovationproject/dto/innovation.project.dto';
import { UserContext } from '../../../context/user-context';
import Rules from '../../../rules';
import { InnovationProjectRoutesPath } from '../routes';
import useTranslation from '../../../hooks/translation.hook';
import { InnovationProjectService } from '../../../eenApi/innovationproject/service';
import { ProfileRoutesPath } from '../../profile/routes';
import ProfileTypeEnum from '../../../eenApi/profile/enum/profile.type.enum';
import { TableColumn } from '../../../component/common/layout/DataTable/table.model';
import { DataTable } from '../../../component/common/layout';
import { EntityQueryKey } from '../../../query/query.keys';
import { useGroupDeleteAction, useIdColumn, useRowActions } from '../../../component/common/layout/DataTable/table.utils';
import { GridSelectionModel } from '@mui/x-data-grid';

interface IProps {
    filterValues?: { [key: string]: any };
}

export function InnovationProjectTable(props: IProps) {
    const { checkAccess, checkAccessUser } = useContext(UserContext);
    const { t, i18n } = useTranslation();

    const idColumn = useIdColumn<InnovationProjectDto>();
    const columns = useMemo<TableColumn<InnovationProjectDto>[]>(
        () => [
            idColumn,
            new TableColumn({
                headerName: t('innovationproject:field.name'),
                field: 'name',
                renderCell: ({ id, value }) =>
                    checkAccess(Rules.InnovationProject.View) ? (
                        <Link to={`${InnovationProjectRoutesPath.View.replace(':id', id.toString())}`}>{value}</Link>
                    ) : (
                        value
                    ),
                flex: 1.5,
            }),
            new TableColumn({
                headerName: t('innovationproject:field.memberCountryId'),
                field: 'memberCountryId',
                valueGetter: ({ row }) => row.memberCountryDto?.name,
            }),
            new TableColumn({
                headerName: t('innovationproject:field.profiles'),
                field: 'countryProfiles',
                renderCell: ({ row }) =>
                    row.countryProfiles?.length ? (
                        row.countryProfiles.map((x) => (
                            <Link
                                key={x.id}
                                to={{
                                    pathname: ProfileRoutesPath.List,
                                    state: {
                                        query: {
                                            type: [
                                                {
                                                    value: ProfileTypeEnum.ResearchRequest,
                                                    label: t(`profile:type.${ProfileTypeEnum.ResearchRequest}`),
                                                },
                                            ],
                                            memberCountryId: [
                                                {
                                                    value: x.id,
                                                    label: x.name,
                                                },
                                            ],
                                            innovationProjectId: [
                                                {
                                                    value: row.id,
                                                    label: row.name,
                                                },
                                            ],
                                        },
                                    },
                                }}
                            >
                                {`${x.name}(${x.attrs?.count ?? 0})`}
                                <br />
                            </Link>
                        ))
                    ) : (
                        <></>
                    ),
            }),
            new TableColumn({
                headerName: t('innovationproject:field.webLink'),
                field: 'webLink',
            }),
            new TableColumn({
                headerName: t('innovationproject:field.status'),
                field: 'status',
                sortable: false,
                valueFormatter: ({ value }) => t(`innovationproject:status.${value}`),
                width: 100,
            }),
        ],
        [i18n.language],
    );

    const clickDelete = (ids: GridSelectionModel) => InnovationProjectService.remove(ids.map((x) => Number(x)));

    const rowActions = useRowActions<InnovationProjectDto>({
        edit: (row) => InnovationProjectRoutesPath.Edit.replace(':id', String(row.id)),
        hideEdit: (row) => !checkAccessUser(row.createdBy, Rules.InnovationProject, 'Edit'),
        remove: (row) => clickDelete([row.id!]),
        hideRemove: (row) => !checkAccessUser(row.createdBy, Rules.InnovationProject, 'Delete'),
    });
    const groupActions = useGroupDeleteAction({
        remove: checkAccess(Rules.InnovationProject.Delete) || checkAccess(Rules.InnovationProject.DeleteOwn) ? clickDelete : null,
    });

    return (
        <DataTable
            id="innovation-project-table-component"
            queryKey={EntityQueryKey.InnovationProject}
            filterValues={props.filterValues}
            columns={columns}
            fetchData={(requestData) => InnovationProjectService.list(requestData)}
            rowActions={rowActions}
            groupActions={groupActions}
        />
    );
}
