import React, { FC, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { ExternalProviderService } from '../../../eenApi/externalProvider/service';
import { ExternalProvider } from '../../../eenApi/externalProvider/externalProvider';
import useTranslation from '../../../hooks/translation.hook';
import { TableColumn } from '../../../component/common/layout/DataTable/table.model';
import { DataTable } from '../../../component/common/layout';
import { EntityQueryKey } from '../../../query/query.keys';
import { useGroupDeleteAction, useRowActions } from '../../../component/common/layout/DataTable/table.utils';
import { GridSelectionModel } from '@mui/x-data-grid';

interface IProps {
    filterValues?: { [key: string]: any };
}

const ExternalProviderTable: FC<IProps> = (props) => {
    const { t, i18n } = useTranslation();

    const columns = useMemo<TableColumn<ExternalProvider>[]>(
        () => [
            new TableColumn({
                headerName: t('main:field-id'),
                field: 'id',
                sortable: true,
            }),
            new TableColumn({
                headerName: t('main:field-name'),
                field: 'name',
                renderCell: ({ id, value }) => <Link to={ExternalProviderService.getViewLink(id)}>{value}</Link>,
            }),
            new TableColumn({
                headerName: t('main:field-type'),
                field: 'type',
            }),
        ],
        [i18n.language],
    );

    const clickDelete = (ids: GridSelectionModel) => ExternalProviderService.delete(ids.map((x) => Number(x)));

    const rowActions = useRowActions<ExternalProvider>({
        edit: row => ExternalProviderService.getEditLink(row.id!),
        remove: row => clickDelete([row.id!]),
    });
    const groupActions = useGroupDeleteAction({ remove: clickDelete });

    return (
        <DataTable
            id="external-provider-table-component"
            queryKey={EntityQueryKey.ExternalProvider}
            filterValues={props.filterValues}
            columns={columns}
            fetchData={(requestData) => ExternalProviderService.list(requestData)}
            rowActions={rowActions}
            groupActions={groupActions}
        />
    );
};

export default ExternalProviderTable;
