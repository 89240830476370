import React, { useContext, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { SpecialistDto } from '../../../eenApi/specialist/dto/specialist.dto';
import { UserContext } from '../../../context/user-context';
import Rules from '../../../rules';
import { SpecialistService } from '../../../eenApi/specialist/service';
import { SpecialistRoutesPath } from '../routes';
import useTranslation from '../../../hooks/translation.hook';
import { SpecialistStatusEnum } from '../../../eenApi/specialist/enum/specialis.status.enum';
import {
    useGroupDeleteAction,
    useIdColumn,
    useRowActions
} from "../../../component/common/layout/DataTable/table.utils";
import { TableColumn, TableRowAction } from "../../../component/common/layout/DataTable/table.model";
import { GridSelectionModel } from "@mui/x-data-grid";
import { DataTable } from "../../../component/common/layout";
import { EntityQueryKey } from "../../../query/query.keys";

interface IProps {
    filterValues?: { [key: string]: any };
    validation: boolean
}

export function SpecialistTable(props: IProps) {
    const { checkAccess, checkAccessUser } = useContext(UserContext);
    const { t, i18n } = useTranslation();

    const idColumn = useIdColumn<SpecialistDto>();
    const columns = useMemo(() => {
        const items: TableColumn<SpecialistDto>[] = [
            idColumn,
            new TableColumn({
                headerName: t('specialist:fields.photo'),
                field: 'photo',
                renderCell: ({ value }) =>
                  value?.source ? <img src={'/' + value.source} alt={t('specialist:fields.photo')} style={{ maxHeight: '75px' }} /> : <></>
            }),
            new TableColumn({
                headerName: t('specialist:fields.name'),
                field: 'name',
                renderCell: ({ id, value, row }) => checkAccessUser(row.createdBy, Rules.Specialist, 'ViewBasic', 'ViewContacts', 'ViewValidations', 'Edit', 'Validate')
                                ? <Link to={`${SpecialistRoutesPath.View.replace(':id', id.toString())}`}>{value}</Link>
                                : value
            }),
            new TableColumn({
                headerName: t('specialist:fields.citizenship'),
                field: 'memberCountry',
                valueFormatter: ({ value }) => value?.name
            }),
            new TableColumn({
                headerName: t('specialist:fields.country'),
                field: 'residenceCountry',
                valueFormatter: ({ value }) => value?.name
            }),
            new TableColumn({
                headerName: t('specialist:fields.education'),
                field: 'education',
            }),
            new TableColumn({
                headerName: t('specialist:fields.speciality'),
                field: 'speciality',
            }),
            new TableColumn({
                headerName: t('specialist:fields.areaOfInterest'),
                field: 'areaOfInterest',
            }),
            new TableColumn({
                headerName: t('specialist:fields.email'),
                field: 'email',
            }),
        ];

        if (props.validation) {
            items.push(
                new TableColumn({
                    headerName: t('specialist:fields.status'),
                    field: 'status',
                    valueFormatter: ({ value }) => t(`specialist:status.${value}`)
                })
            )
        }

        return items
    }, [props.validation, i18n.language]);

    const clickDelete = (ids: GridSelectionModel) => SpecialistService.remove(ids.map(id => Number(id)));

    const clickStatusChange = (id: number, status: SpecialistStatusEnum) => SpecialistService.updateStatus(id, status);

    const rowActions = useRowActions<SpecialistDto>({
        edit: row => SpecialistRoutesPath.Edit.replace(':id', row.id!.toString()),
        hideEdit: row => !checkAccessUser(row.createdBy, Rules.Specialist, 'Edit'),
        remove: row => clickDelete([row.id!]),
        hideRemove: row => !checkAccessUser(row.createdBy, Rules.Specialist, 'Delete')
    },
      useMemo(() => {
        const items: TableRowAction<SpecialistDto>[] = [];
        if (props.validation) {
            items.push({
                id: 'validation',
                label: t('main:btn-sendValidation'),
                action: row => clickStatusChange(row.id!, SpecialistStatusEnum.Validation),
                isHide: row => row.status !== SpecialistStatusEnum.Draft && row.status !== SpecialistStatusEnum.CommentsReceived
            })
        }
        if (checkAccess(Rules.Specialist.Validate)) {
            items.push({
                id: 'remorks',
                label: t('main:btn-remarks'),
                action: row => clickStatusChange(row.id!, SpecialistStatusEnum.CommentsReceived),
                isHide: row => row.status !== SpecialistStatusEnum.Validation
            });
            items.push({
                id: 'activate',
                label: t('main:btn-active'),
                action: row => clickStatusChange(row.id!, SpecialistStatusEnum.Active),
                isHide: row => row.status !== SpecialistStatusEnum.Validation
            })
        }

        return items;
    }, [props.validation, i18n.language]));

    const groupActions = useGroupDeleteAction({
        remove: checkAccess(Rules.Specialist.Delete) || checkAccess(Rules.Specialist.DeleteOwn) ? clickDelete : null
    });

    return (
        <DataTable
            id='specialist-table-component'
            queryKey={[EntityQueryKey.Specialist, props.validation]}
            filterValues={props.filterValues}
            columns={columns}
            fetchData={requestData => SpecialistService.list(requestData, props.validation)}
            rowActions={rowActions}
            groupActions={groupActions}
        />
    );
}